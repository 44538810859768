import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import Layout from "../Layout";
import JobInformation from "./JobInformation";
import Managing from "./Managing";
import OrganisationLevels from "./OrganisationLevels";
import PayInformation from "./PayInformation";
import { useMainCalculationGroup } from "../api";
import { formatSubmittedData } from "../helpers";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";

export const fields = [
	"hourlyRate",
	"hourlyOrSalaried",
	"currency",
	"hiringDate",
	"locations",
	"supervisors",
	"jobGroup",
	"jobs",
	"payGroup",
	"calculationGroup",
	"level1",
	"level2",
	"level3",
	"level4",
	"employeeType",
	"manageAbility",
	"managedLevels",
	"managedGroupings",
	"managedUserGroups",
	"additionalLevels",
];

function Step3({
	data,
	setData,
	setStep,
	loading,
	error,
	submit,
	close,
	step,
}) {
	const { t } = useTranslation();
	const [form] = Form.useForm();

	const goBack = useCallback(() => {
		setData(data);
		setStep(1);
	}, [setStep, setData, data]);

	const onFinish = useCallback(
		(values) => {
			const updatedData = {
				...data,
				...values,
				employmentType: values?.employmentType?.id
			};

			setData(updatedData);

			submit(formatSubmittedData(updatedData), close);
		},
		[close, data, submit, setData],
	);

	const { loading: loadingCalculationGroup, data: defaultCalculationGroup } =
		useMainCalculationGroup();

	useEffect(() => {
		form.setFieldsValue({
			hourlyRate: data?.hourlyRate,
			salary: data?.salary,
			hourlyOrSalaried: data?.hourlyOrSalaried
				? data.hourlyOrSalaried
				: "H",
			currency: data?.currency,
			locations: data?.locations,
			supervisors: data?.supervisors,
			jobGroup: data?.jobGroup,
			jobs: data?.jobs,
			payGroup: data?.payGroup,
			calculationGroup: data?.calculationGroup,
			level1: data?.level1,
			level2: data?.level2,
			level3: data?.level3,
			level4: data?.level4,
			employeeType: data?.employeeType,
			roles: data?.roles,
			manageAbility: data?.manageAbility,
			managedLevels: data?.managedLevels,
			managedGroupings: data?.managedGroupings,
			managedUserGroups: data?.managedUserGroups,
			additionalLevels: data?.additionalLevels,
			expectedHours: data?.expectedHours,
			employmentType: data?.employmentType
		});
	}, [form, data]);

	useEffect(() => {
		if (!defaultCalculationGroup) {
			return;
		}
		const existing = form.getFieldValue("calculationGroup");
		if (!existing) {
			form.setFieldsValue({
				calculationGroup: defaultCalculationGroup,
			});
		}
	}, [form, defaultCalculationGroup]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(fields, error);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form onFinish={onFinish} form={form}>
			<Layout step={step}loading={loading} close={goBack}>
				<h3 className="text-dark d-flex align-items-start w-100 border-bottom pb-4 px-4 mx-0 mb-4">
					{t("job-and-pay")}
				</h3>

				<div className="p-4">
					<div className="border-bottom mb-4">
						<h5 className="mb-3 text-muted font-weight-400 text-uppercase">
							{t("job-information")}
						</h5>

						<div className="px-4">
							<JobInformation
								form={form}
								loadingCalculationGroup={loadingCalculationGroup}
							/>
						</div>
					</div>

					<div className="border-bottom mb-4 w-100">
						<h5 className="mb-3 text-muted font-weight-400 text-uppercase">
							{t("organization-levels")}
						</h5>

						<div className="px-4">
							<OrganisationLevels />
						</div>
					</div>

					<div className="w-100">
						<h5 className="mb-3 text-muted font-weight-400 text-uppercase">
							{t("pay-information")}
						</h5>

						<div className="px-4">
							<PayInformation />
						</div>
					</div>

					<Managing />
				</div>
			</Layout>
		</Form>
	);
}

export default Step3;
