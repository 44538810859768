export const defaultFilterOptions = ({ type = "", t }) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const options = [
        { value: "exact", label: "exact", types: ["all"], },
        { value: "equals", label: "equals", types: ["all"] },
        { value: "notEquals", label: "not-equals", types: ["all"] },
        { value: "contains", label: "contains", types: ["string"] },
        { value: "notContains", label: "not-contains", types: ["string"] },
        { value: "includes", label: "includes", types: ["string"] },
        // { value: "in", label: "in", notIncluded: ["resource-select", "select"] },
        { value: "startsWith", label: "starts-with", types: ["string"] },
        { value: "notStartsWith", label: "not-starts-with", types: ["string"] },
        { value: "endsWith", label: "ends-with", types: ["string"] },
        { value: "notEndsWith", label: "not-ends-with", types: ["string"] },
        { value: "gt", label: "greater-than", types: ["integer", "float", "money"] },
        { value: "gte", label: "greater-than-or-equals", types: ["integer", "float", "money"] },
        { value: "lt", label: "less-than", types: ["integer", "float", "money"] },
        { value: "lte", label: "less-than-or-equals", types: ["integer", "float", "money"] },
        { value: "beforeDate", label: "before-date", types: ["date", "timestamp", "datepicker"] },
        { value: "afterDate", label: "after-date", types: ["date", "timestamp", "datepicker"] },
        // { value: "between", label: "between", types: ["date", "datepicker", "float", "integer", "timestamp", "time"] }
    ];

    return options.filter(
        (option) => option.types.includes("all") || option.types.includes(type.toLowerCase())
    );
};