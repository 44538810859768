import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel
} from "../../../../../utils/helpers/settings";

export const useEmploymentTypeFilter = () => {
    const { t } = useTranslation();

    return useMemo(
        () => ({
            id: "employmentType",
            Header: t("employment-type"),
            filter: "select",
            canFilter: true,
            Filter: (
                <ResourceSelect
                    resourcePath="/user-demographic/employment-type"
                    mode="multiple"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    hasSearch
                />
            ),
        }),
        [t]
    );
};
