import { useCallback, useEffect, useMemo, useState } from "react";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Modal from "../../../../../../components/Modal";
import Button from "../../../../../../components/Button";
import AddBulkForm from "./Form";
import Preview from "./Preview";
import { useGenerateBulkTimeSheets } from "./useGenerateBulkTimeSheets";
import { useAddBulkCall } from "../api";
import "./_style.scss";

const AddBulkModal = ({ users, close, visible, filters, refetch }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const [step, setStep] = useState(0);
    const [values, setValues] = useState({});
    const [timeSheets, setTimeSheets] = useState({});

    const generateBulkTimeSheets = useGenerateBulkTimeSheets(users);
    const onNext = useCallback(async () => {
        try {
            await form.validateFields();
        } catch (err) {
            return;
        }
        const values = form.getFieldsValue();
        setValues(values);
        setTimeSheets(generateBulkTimeSheets(values));
        setStep(1);
    }, [form, generateBulkTimeSheets, setStep, setValues]);

    const { submit, loading } = useAddBulkCall({
        filters: useMemo(() => ({
            from: moment(values.startDate).toISOString(),
            to: moment(values.endDate).toISOString(),
            filters,
        }), [values, filters]),
        onSuccess: useCallback(() => {
            refetch();
            close();
        }, [refetch, close]),
    });

    const onFinish = useCallback(() => {
        submit(timeSheets)
    }, [submit, timeSheets]);

    const modalSize = useMemo(() => {
        switch (step) {
            case 0: return "md";
            case 1: return "lg";
        }
    }, [step]);

    useEffect(() => {
        if (!visible) {
            setStep(0);
            form.resetFields();
            setValues({});
            setTimeSheets({});
        }
    }, [visible, setStep, form, setValues, setTimeSheets]);

    return (
        <Modal
            className="add-bulk-modal"
            size={modalSize}
            title={
                <div className="d-flex justify-content-between align-items-center text-primary">
                    {t("add-crew-timesheets")}
                    <i
                        className="fa-solid fa-close cursor-pointer"
                        onClick={close}
                    />
                </div>
            }
            toggle={close}
            isOpen={visible}
            scrollable
            centered
            footer={(
                <div className="d-flex justify-content-end">
                    <Button className="text-dark" color="link" onClick={close} disabled={loading}>
                        {t("cancel")}
                    </Button>

                    {step > 0 && (
                        <Button color="secondary" onClick={() => setStep(0)} disabled={loading}>
                            {t("go-back")}
                        </Button>
                    )}

                    {step === 0 && (
                        <Button color="primary" onClick={onNext}>
                            {t("next")}
                        </Button>
                    )}

                    {step === 1 && (
                        <Button color="primary" loading={loading} onClick={onFinish}>
                            {t("submit")}
                        </Button>
                    )}
                </div>
            )}
        >
            {step === 0 && (
                <AddBulkForm form={form} users={users} />
            )}

            {step === 1 && (
                <Preview users={users} values={values} timeSheets={timeSheets} />
            )}
        </Modal>
    );
}

export default AddBulkModal;
