import classNames from "classnames";
import Form from "rc-field-form";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Field from "../../../../components/Field";
import DateInput from "../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Select from "../../../../components/Inputs/Select";
import Text from "../../../../components/Inputs/Text";
import { getSettingsResourceSearchFilters } from "../../../../utils/helpers/settings";
import { firstToUpper } from "../../../../utils/helpers/string";
import { getCompaniesSearchFilters } from "../../../Profile/Personal/Actions/ChangeCompanyInformation";
import { renderLabel } from "../../../Reports/CustomReports/CreateReport/ReportDispaly/useColumns";
import { useDateFilters } from "../../../../components/DateRangeFilters/useDateFilters";

export const getDefaultFilterValue = (type) => {
  switch (type) {
    case "resource-select":
      return [];
    case "select":
      return null;
    case "text":
    case "datepicker":
    default:
      return "";
  }
}

export const getFieldOperator = (type) => {
  switch (type) {
    case "text":
      return "contains";
    case "datepicker":
    case "resource-select":
    case "select":
      return "exact";
    default:
      return "contains";
  }
};

function Filter({
  changeFieldMethod,
  changeFieldValue,
  config,
  name,
  add,
  remove,
}) {
  const { t } = useTranslation();
  const list = Form.useWatch("filters");
  const current = useMemo(() => list?.[name], [name, list]);
  const { mode, payGroup } = useDateFilters();
  const hasPayGroup = useMemo(() => {
    return mode === "pay-group" &&
      payGroup &&
      (current?.member || "").toLowerCase().includes("paygroup")
  }, [current?.member, mode, payGroup]);

  const { fieldType: type, disabled } = useMemo(() => {
    return config?.find((configItem) => configItem?.name === current?.member) || {};
  }, [config, current?.member]);

  const fields = useMemo(() => {
    return config
      ?.filter(({ name }) => {
        return (
          !(list?.findIndex((item) => item?.member === name) !== -1) ||
          current?.member === name
        );
      })
      .map(({ name, table, fieldType, endpoint, display_name }) => ({ name, type: fieldType, table, endpoint, display_name }));
  }, [config, list, current]);

  const input = useMemo(() => {
    const itemConfig = config?.find(
      (configItem) => `${configItem?.table}.${configItem?.name}` === current?.member,
    ) || {};

    if (itemConfig?.type?.toLowerCase() === "resource-select") {
      const endpoint = itemConfig?.endpoint === "/companies"
        ? "/companies?pagination=false"
        : itemConfig?.endpoint;

      return (
        <ResourceSelect
          disabled={disabled || hasPayGroup}
          placeholder={t("value")}
          resourcePath={endpoint}
          renderLabel={(item) =>
            renderLabel(itemConfig?.display_name || itemConfig?.name, item)
          }
          mode="multiple"
          getSearchFilters={
            itemConfig?.endpoint === "/companies"
              ? getCompaniesSearchFilters
              : getSettingsResourceSearchFilters
          }
          hasSearch
        />
      );
    } else if (itemConfig?.fieldType === "select") {
      return (
        <Select
          disabled={disabled}
          placeholder={t("value")}
        >
          {itemConfig?.options?.map(({ value, label }) => (
            <Select.Option key={value} value={value}>{label}</Select.Option>
          ))}
        </Select>
      );
    } else if (itemConfig?.fieldType === "datepicker") {
      return (
        <DateInput placeholderText={t("date")} />
      );
    } else {
      return (
        <Text
          className="form-control-sm px-4"
          type="text"
          placeholder={t("value")}
          disabled={disabled}
        />
      );
    }
  }, [config, current?.member, disabled, hasPayGroup, t]);

  const onFieldChange = useCallback(
    (fieldName) => {
      const type = config?.find(
        (configItem) => `${configItem?.table}.${configItem?.name}` === fieldName,
      )?.fieldType || config?.find(
        (configItem) => `${configItem?.table}.${configItem?.name}` === fieldName,
      )?.type || "text";
      const operator = getFieldOperator(type?.toLowerCase());
      changeFieldMethod(name, operator);
      if (type !== "text") {
        changeFieldValue(name, getDefaultFilterValue(type));
      }
    },
    [config, name, changeFieldMethod, changeFieldValue],
  );

  useEffect(() => {
    const operator = getFieldOperator(type);
    changeFieldMethod(name, operator);
  }, [name, type, changeFieldMethod]);

  return (
    <div className="d-flex justify-content-end align-items-center">
      <div style={{ width: 180 }}>
        <Field
          name={[name, "member"]}
          className="mb-0 mr-2"
        >
          <Select
            placeholder={t("field")}
            onChange={onFieldChange}
            disabled={disabled || hasPayGroup}
            showSearch
          >
            {fields?.map(({ name, display_name, table }) => (
              <Select.Option key={`${table}.${name}`} value={`${table}.${name}`}>
                {firstToUpper(t(display_name) || t(name))}
              </Select.Option>
            ))}
          </Select>
        </Field>
      </div>

      <div style={{ width: 180 }}>
        <Field name={[name, "values"]} className="mb-0 mr-2 w-100">
          {input}
        </Field>
      </div>

      {(
        list?.length < config?.length &&
        list?.length < 3
      ) && (
          <span className="mr-1" style={{ height: 25, width: 25 }}>
            <i
              className="ni ni-fat-add text-info"
              style={{ fontSize: "25px", cursor: "pointer" }}
              onClick={add}
            />
          </span>
        )}

      {list?.length > 1 && (
        <span style={{ height: 25, width: 25 }}>
          <i
            className={classNames("ni ni-fat-delete", (disabled || hasPayGroup) ? "text-gray" : "text-info")}
            style={{ fontSize: "25px", cursor: (disabled || hasPayGroup) ? "initial" : "pointer" }}
            onClick={(disabled || hasPayGroup) ? undefined : remove}
          />
        </span>
      )}
    </div>
  );
}

export default Filter;
