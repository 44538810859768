import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment-timezone";
import Form from "rc-field-form";
import Content from "../../../../../Request/AddSchedule/Form/Content";
import { PayCodesProvider } from "../../../../../NewScheduler/Component/Drawers/Event/PayCodeSelect/usePayCodes";
import { useEarningGroupsContext } from "../../../../earningGroupsContext";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";
import { useAddedPayCode } from "../../../../components/TimeSheetTable/hooks/useAddedPayCode";
import { useCalculatedSections } from "../../../../../NewScheduler/Component/hooks/useCalculatedSections";
import {
    calculateSectionsWithAutomaticBreaks,
    getAutomaticBreaks,
} from "../../../../../NewScheduler/Component/helpers/sections";
import { getPrimaryActiveResource } from "../../../../../../utils/helpers/user";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../../../utils/helpers/date";
import { useCompany } from "../../../../../../utils/hooks/company";

function Schedule({ data: events, user }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const company = useCompany();
    const [selected, setSelected] = useState();

    const key = useMemo(() => {
        const keys = Object.keys(selected || {});
        return keys?.[0] ? Number(keys[0]) : undefined;
    }, [selected]);

    const data = useMemo(() => {
        return key ? selected?.[key] : events?.[0];
    }, [events, key, selected]);

    const { data: earningGroups } = useEarningGroupsContext();
    const { allocatedGroups } = useGroups();
    const defaultEarningGroup = earningGroups?.find(group => group?.code === "REG");
    const defaultPayCode = useAddedPayCode(user);
    const primaryJob = getPrimaryActiveResource(user?.jobs);
    const primaryLocation = getPrimaryActiveResource(user?.locations);

    const levels = useMemo(() => {
        return allocatedGroups?.reduce((total, group) => {
            const level = group?.level.replace("_", "");
            if (user && user[level]) {
                return { ...total, [level]: user[level] };
            } else if (data && data[level]) {
                return { ...total, [level]: data[level] };
            } else {
                return total;
            }
        }, {});
    }, [allocatedGroups, data, user]);

    const defaultSection = useMemo(() => {
        const scheduleSettings = data?.calculationGroup?.schedule || user?.calculationGroup?.schedule || company?.settings?.schedule;
        const shiftStart = scheduleSettings?.shiftStart ? moment.parseZone(scheduleSettings.shiftStart) : moment().startOf("day").hour(9);
        const shiftEnd = scheduleSettings?.shiftEnd ? moment.parseZone(scheduleSettings.shiftEnd) : moment().startOf("day").hour(17);

        let start = moment.parseZone(shiftStart);
        let end = moment.parseZone(shiftEnd);

        if (data) {
            start = moment.parseZone(data?.startDate);
            start = moment.parseZone(data?.endDate);
        }

        start = combineDateAndTime(moment(data?.date), moment(start));
        end = combineDateAndTime(moment(data?.date), moment(end));

        const duration = moment(end).diff(moment(start), "seconds");

        return {
            type: "regular",
            payCode: defaultPayCode,
            location: primaryLocation,
            job: primaryJob,
            duration: renderDurationAsFormat(duration, "HH:mm"),
            start,
            end,
            ...levels
        };
    }, [
        data,
        user?.calculationGroup?.schedule,
        company?.settings?.schedule,
        defaultPayCode,
        primaryLocation,
        primaryJob,
        levels
    ]);

    const sectionsWithAutomaticBreak = useMemo(() => {
        let end = moment(data?.endDate).isBefore(moment(data?.startDate), "seconds")
            ? moment(data?.endDate).add(1, "day")
            : moment(data?.endDate);

        const duration = moment(end).diff(moment(data?.startDate), "seconds");

        let sections = [{
            ...defaultSection,
            start: data?.startDate,
            end,
            duration: renderDurationAsFormat(duration, "HH:mm"),
            canCalculateAutomaticBreaks: false,
        }];

        const breaks = getAutomaticBreaks(
            data?.calculationGroup?.schedule || user?.calculationGroup?.breaks,
            sections,
            sections[0]
        );

        if (breaks?.length > 0) {
            sections = calculateSectionsWithAutomaticBreaks(sections, breaks);
        }
        return sections;
    }, [defaultSection, data, user?.calculationGroup?.breaks]);

    const sections = useCalculatedSections({
        startTime: data?.startDate,
        endTime: data?.endDate,
        eventRecord: { ...data, isCreating: false },
        schedulerInstance: { viewPreset: { data: { id: "hourAndDay" } } },
        defaultSection,
        sections: sectionsWithAutomaticBreak
    });

    const initialValues = useMemo(() => {
        const startDate = data?.startDate ? moment.parseZone(data?.startDate).toDate() : defaultSection?.start;
        let endDate = data?.endDate ? moment.parseZone(data?.endDate).toDate() : defaultSection?.end;

        if (moment(endDate).isBefore(moment(startDate), "seconds")) {
            endDate = moment(endDate).add(1, "day");
        }

        const duration = moment(endDate).diff(moment(startDate), "seconds");
        const eventType = data?.eventType || defaultEarningGroup;
        return {
            date: data?.date && moment(data.date).toDate(),
            startDate: !eventType?.allowSegmentation ? moment.parseZone(sections?.[0]?.start).toDate() : startDate,
            endDate: !eventType?.allowSegmentation ? moment.parseZone(sections?.[sections?.length - 1]?.end).toDate() : endDate,
            duration: renderDurationAsFormat(duration, "HH:mm"),
            resourceId: data?.user || user?.id,
            eventType,
            payCode: !eventType?.allowSegmentation && (data?.payCode || defaultPayCode),
            job: !eventType?.allowSegmentation && (sections?.[0]?.job || primaryJob),
            location: !eventType?.allowSegmentation && (sections?.[0]?.location || primaryLocation),
            sections: sections?.length > 0 ? sections : [defaultSection],
            ...levels
        };
    }, [data, defaultSection, defaultEarningGroup, sections, user?.id, defaultPayCode, primaryJob, primaryLocation, levels]);

    useEffect(() => {
        form.setFieldsValue(initialValues);
    }, [form, initialValues]);

    return (
        !data ? (
            <div className="w-100 d-flex flex-column justify-content-center align-items-center p-7">
                <i
                    className="fa-solid fa-inbox text-muted"
                    style={{ fontSize: "20px" }}
                />

                <span className="text-gray font-weight-400 text-xs">
                    {t("no-data")}
                </span>
            </div>
        ) : (
            <PayCodesProvider>
                <div className="d-flex align-items-center gap-2 mx-4 flex-wrap">
                    {events?.map((_, i) => (
                        <span
                            className={classNames(
                                "cursor-pointer px-4 py-1 rounded border-info border",
                                (key === i || (!key && i === 0)) ? "bg-info text-white" : "text-info"
                            )}
                            onClick={() => setSelected({ [i]: events[i] })}
                        >
                            {t("shift") + (i + 1)}
                        </span>
                    ))}
                </div>

                <Form
                    form={form}
                    initialValues={initialValues}
                    className="h-100"
                >
                    <div
                        className="p-4"
                        style={{
                            height: "calc(100% - 70px)",
                            overflowY: "auto",
                            overflowX: "hidden"
                        }}
                    >
                        <Content
                            form={form}
                            eventRecord={data}
                            defaultSection={defaultSection}
                            mode="timesheet-view"
                            resource={user}
                            disabled={true}
                        />
                    </div>
                </Form>
            </PayCodesProvider>
        )
    );
}

export default Schedule;
