import { useContext, useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { Card, CardHeader, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import JobPayActions from "../JobPay/Actions";
import PersonalActions from "../Personal/Actions";
import TimeOffActions from "../TimeOff/Actions";
import DocumentActions from "../Documents/Actions";
import SettingsActions from "../Settings/Actions";
import ScheduleActions from "../Schedule/Actions";
import { useAccessInList } from "../../../utils/hooks/access";
import { ProfileContext } from "../context";
import LicensesActions from "../Qualifications/Licenses/Actions";
import CertificationsActions from "../Qualifications/Certifications/Actions";

function Comp({ title, children }) {
	return (
		<Card className="h-100" style={{ position: "initial" }}>
			<CardHeader>{title}</CardHeader>

			<CardBody
				className="h-100 profile-scroll"
				style={{ overflowY: "auto" }}
			>
				{children}
			</CardBody>
		</Card>
	);
}

function Actions() {
	const { t } = useTranslation();
	const { isSelf } = useContext(ProfileContext);

	const jobAndPayActions = useMemo(
		() => [
			{
				permission: isSelf
					? "profile.changeJobInformation"
					: "people.changeJobInformation",
			},
			{
				permission: isSelf
					? "profile.changeHiringInformation"
					: "people.changeHiringInformation",
			},
			{
				permission: isSelf
					? "profile.changeOrganisation"
					: "people.changeOrganisation",
			},
		],
		[isSelf],
	);

	const jobAndPayPermissions = useMemo(
		() => jobAndPayActions.map(({ permission }) => permission),
		[jobAndPayActions],
	);

	const { hasAccess: jobAndPayAccess } =
		useAccessInList(jobAndPayPermissions);

	const personalActions = useMemo(
		() => [
			{
				permission: isSelf
					? "profile.changePersonalInformation"
					: "people.changePersonalInformation",
			},
			{
				permission: isSelf
					? "profile.changeCompanyInformation"
					: "people.changeCompanyInformation",
			},
			{
				permission: isSelf
					? "profile.changeAddress"
					: "people.changeAddress",
			},
			{
				permission: isSelf
					? "profile.changeStatus"
					: "people.changeStatus",
			},
			{
				permission: isSelf
					? "profile.changeKeyDates"
					: "people.changeKeyDates",
			},
			{
				permission: isSelf
					? "profile.changeManagement"
					: "people.changeManagement",
			},
			{
				permission: "people.changeRole",
			},
			{
				permission: "people.terminateEmployee",
			},
		],
		[isSelf],
	);

	const personalPermissions = useMemo(
		() => personalActions.map(({ permission }) => permission),
		[personalActions],
	);

	const { hasAccess: personalAccess } = useAccessInList(personalPermissions);

	const settingsActions = useMemo(
		() => [
			{
				permission: isSelf
					? "profile.changeCalculationGroupRules"
					: "people.changeCalculationGroupRules",
			},
			{
				permission: isSelf
					? "profile.changeTwoFactorAuth"
					: "people.changeTwoFactorAuth",
			},
		],
		[isSelf],
	);

	const settingsPermissions = useMemo(
		() => settingsActions.map(({ permission }) => permission),
		[settingsActions],
	);

	const { hasAccess: settingsAccess } = useAccessInList(settingsPermissions);

	return (
		<Routes>
			{jobAndPayAccess && (
				<Route
					path="job-pay"
					element={
						<Comp title={t("job-and-pay")}>
							<JobPayActions />
						</Comp>
					}
				/>
			)}

			{personalAccess && (
				<Route
					path="personal"
					element={
						<Comp title={t("personal")}>
							<PersonalActions />
						</Comp>
					}
				/>
			)}

			<Route path="time-off" element={<TimeOffActions />} />

			<Route path="qualifications/licenses" element={<LicensesActions />} />

			<Route path="qualifications/certifications" element={<CertificationsActions />} />

			<Route path="documents" element={<DocumentActions />} />

			{settingsAccess && (
				<Route
					path="settings"
					element={
						<Comp title={t("settings")}>
							<SettingsActions />
						</Comp>
					}
				/>
			)}

			<Route path="schedule" element={<ScheduleActions />} />
		</Routes>
	);
}

export default Actions;
