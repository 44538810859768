import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	ListGroup,
	ListGroupItem,
	ListGroupItemHeading,
	ListGroupItemText,
	Tooltip
} from "reactstrap";
import { faBriefcase, faInfo, faLocationDot, faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import moment from "moment-timezone";
import Checkbox from "../../../../components/Inputs/Checkbox";
import Avatar from "../../../../components/Avatar";
import { useGroups } from "../../../Settings/General/OrganisationLevelGroups/context";
import { getUploadUrl } from "../../../../utils/helpers/upload";
import { getPrimaryResources, renderUserName } from "../../../../utils/helpers/user";
import { useCompany, useCompanyDateFormat, useCompanyTimeFormat } from "../../../../utils/hooks/company";
import { initials } from "../../../../utils/helpers/string";
import { dateTimeFormat } from "../../../../utils/helpers/date";
import { renderOrgLevelResourceLabel, renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import { renderDeviceIcon } from "../../../../utils/helpers/shift/clock";

const getStatus = ({ lastClock }) => {
	if (lastClock?.type === "BREAK" && lastClock?.mode === "START") {
		return "on-break";
	}

	if (lastClock?.type === "SHIFT" && lastClock?.mode === "END") {
		return "clocked-out";
	}

	return "clocked-in";
};

function OriginListItem({ value, id }) {
	const { t } = useTranslation();
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const toggle = useCallback(() => {
		setIsTooltipOpen(!isTooltipOpen);
	}, [setIsTooltipOpen, isTooltipOpen]);

	return (
		<div>
			<span id={`device${id}`}>
				{renderDeviceIcon(value)}
			</span>

			<Tooltip
				target={`device${id}`}
				isOpen={isTooltipOpen}
				toggle={toggle}
			>
				<span className="text-sm font-weight-bolder px-2">
					{t(value)}
				</span>
			</Tooltip>
		</div>
	);
}

function InfoToolTip({ item, id }) {
	const { t } = useTranslation();
	const timeFormat = useCompanyTimeFormat();
    const dateFormat = useCompanyDateFormat();
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const job = getPrimaryResources(item?.user?.jobs || []);
	const location = getPrimaryResources(item?.user?.locations || []);
	const { groups } = useGroups();

	const useInClockInGroup = useMemo(() => groups?.find(({ useInClockIn }) => useInClockIn), [groups]);

	const toggle = useCallback(() => {
		setIsTooltipOpen(!isTooltipOpen);
	}, [setIsTooltipOpen, isTooltipOpen]);

	const name = useCallback(
        ({ type, mode }) => {
            if (type === "BREAK") {
                if (mode === "START") {
                    return t("started-break");
                } else {
                    return t("ended-break");
                }
            } else {
                if (mode === "START") {
                    return t("clocked-in");
                } else {
                    return t("clocked-out");
                }
            }
        },
        [t]
    );

	return (
		<div className="w-100">
			<span id={`info${id}`}>
				<FontAwesomeIcon icon={faInfo} size="lg" className="text-muted mb-1"/>
			</span>

			<Tooltip
				target={`info${id}`}
				isOpen={isTooltipOpen}
				toggle={toggle}
				className="clock-in-info-tooltip"
			>
				<div className="w-100 d-flex gap-3 info-tooltip-dashed-border">
					<Avatar user={item} />
					<div>						
						<span className="p-0 m-0 font-weight-bolder text-sm d-flex justify-content-start">
							{renderUserName(item)}
						</span>
						{item?.lastClock ? 				
							<div className={classnames("d-flex justify-content-start", `text-${getStatus(item)}`)}>
								<span className="font-weight-bolder mr-1">{name(item.lastClock)}:</span>
								<span>{moment(item.lastClock?.time).format(dateFormat)} -&nbsp;</span>
								<span>{dateTimeFormat(item.lastClock?.time, timeFormat)}</span>
							</div>
						: null}
						<span className="d-flex gap-2 align-items-center my-1">
							<FontAwesomeIcon icon={faBriefcase} />
							{job || item?.jobs[0]
								? renderSettingsResourceLabel(job || item.jobs[0])
								: undefined}
						</span>
						<span className="d-flex gap-2 align-items-center">
							<FontAwesomeIcon icon={faLocationDot} />
							{location || item?.locations[0]
								? renderSettingsResourceLabel(location || item?.locations[0])
								: undefined}
						</span>
						{item[useInClockInGroup.level.replaceAll("_", "")] && (
							<div className="d-flex gap-2 my-1">
								<span className="text-nowrap font-weight-bolder">
									{useInClockInGroup?.description}:
								</span>
								{renderOrgLevelResourceLabel(
									item[useInClockInGroup.level.replaceAll("_", "")]
								)}
							</div>
						)}
					</div>					
				</div>
			</Tooltip>
		</div>
	)
}

function List({ 
	data, 
	status, 
	selectedUsers,
	setSelectedUsers,
	openProfileDrawer
}) {
	const { t } = useTranslation();
	const company = useCompany();
	const dateFormat = useCompanyDateFormat();

	const getClockType = useCallback(
		(lastClock) => {
			if (lastClock?.type === "BREAK" && lastClock?.mode === "START") {
				return t("on-break");
			}

			if (lastClock?.type === "SHIFT" && lastClock?.mode === "END") {
				return t("clocked-out");
			}

			return t("clocked-in");
		},
		[t],
	);

	const timeOffOrNotActiveUserListDescription = useCallback(
		(user) => {
			if (user.status === "not-active") {
				return (
					<h5 className="font-weight-bold text-muted">
						{t("not-active")}
					</h5>
				);
			}

			if (user.status === "leave") {
				return (
					<h5
						className="font-weight-bold"
						style={{ color: "#FB7185" }}
					>
						{t("time-off")}
					</h5>
				);
			}
		},
		[t],
	);

	const formatTime = (time, timeZone) => {
		return moment(time).tz(timeZone).format(company?.settings?.timeFormat || 'YYYY-MM-DD hh:mm A');
	}

	const onCheckboxClick = useCallback(
		(e, item) => {
			if (e.target.checked) {
				setSelectedUsers((prev) => prev?.concat(item));
			} else {
				setSelectedUsers((prev) =>
					prev?.filter((user) => user?.id !== item?.id),
				);
			}
		},
		[setSelectedUsers],
	);

	return (
		<>
			<ListGroup style={{ height: "calc(100% - 57px)", overflowY: "auto" }} className="team-list-scroll">
				{data &&
					data.map((item, i) => (
						<ListGroupItem className="d-flex align-items-center m-0 px-5 py-3 justify-content-between">
							<div className="d-flex align-items-center">
								<Checkbox
									id={item?.id}
									onClick={(e) => onCheckboxClick(e, item)}
									checked={
										selectedUsers &&
										selectedUsers?.find(
											(user) => user?.id === item?.id,
										)
									}
									hidden={status === "time-off"}
								/>

								{item?.image ? (
									<img
										alt=""
										src={getUploadUrl(item?.image)}
										className="avatar rounded-circle mr-2"
										width={37}
										height={37}
									/>
								) : (
									<div
										className="avatar rounded-circle d-flex align-items-center mr-3 bg-primary"
										style={{ cursor: "auto" }}
									>
										<h3 className="text-white mb-0">
											{initials(item)}
										</h3>
									</div>
								)}

								<div className="d-flex flex-column mb-0">
									<div
										className="d-flex align-items-center mb-1"
									>
										<FontAwesomeIcon
											icon={faUserAlt}
											className="cursor-pointer text-gray mr-2 text-xs"
											onClick={() => openProfileDrawer(item)}
										/>

										<ListGroupItemHeading className="text-sm mb-0">
											{renderUserName(item)}
											{item?.lastClock?.geofence
												?.address && "  -  "}
										</ListGroupItemHeading>

										<ListGroupItemText className="text-sm mb-0">
											{item?.lastClock?.geofence?.address}
										</ListGroupItemText>
									</div>

									<div className="d-flex mb-0 align-items-center">
										{item.lastClock ? (
											<>
												<p
													className={classnames(
														`d-flex mb-0 align-items-center
														text-${getStatus(item)}`
													)}
												>
													<p className="mb-0 mr-1 text-sm font-weight-bolder">
														{moment(item?.lastClock?.time).format(dateFormat)} {" - "}
														{formatTime(item?.lastClock?.time, item?.lastClock?.timeZone || moment.tz.guess())}
													</p>
													-
													<p className="mb-0 mx-2 text-sm font-weight-500">
														{getClockType(
															item.lastClock
														)}
													</p>
												</p>

												<p className="mb-0 text-sm text-muted font-weight-400">
													(
													{moment
														.duration(
															moment().diff(
																item?.lastClock
																	?.time
															)
														)
														.humanize()}{" "}
													ago)
												</p>
											</>
										) : (
											timeOffOrNotActiveUserListDescription(
												item
											)
										)}
									</div>
								</div>
							</div>
							
							<div className="d-flex align-items-center gap-4">
								<InfoToolTip item={item} id={i}/>
								<OriginListItem value={item?.lastClock?.device} id={item?.id} />
							</div>
						</ListGroupItem>
					))}
			</ListGroup>
		</>
	);
}

export default List;
