import { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../../features/Authentication/context";
import useApi from "../../../utils/api";
import { normalize } from "../../../utils/helpers/normalize";

export const useResource = (resourcePath, hasCompany, identifier = "id", getSearchFilters ) => {
	const [hasMore, setHasMore] = useState(undefined);
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);
	const { authGet } = useApi();
	const { company } = useAuthentication();

	const fetch = useCallback(
		async (filters = {}) => {
			if (hasCompany && !company?.id) {
				return;
			}

			setLoading(true);
			setData({});
			try {
				const response = await authGet(resourcePath, {
					params: {
						company: hasCompany ? company?.id : undefined,
						...filters,
					},
				});
				if (response) {
					setData(normalize(response.result, identifier));
					// setHasMore((prev) => {
					// 	// if (prev !== undefined && response.totalItems === response.result.length) {
					// 	// 	return prev;
					// 	// } else {
					// 	// 	return response.totalItems > response.result.length;
					// 	// }

					// 	// if (prev !== undefined && response.totalItems === 0) {
					// 	// 	return prev;
					// 	// } else {
					// 	// 	return response.totalItems > response.result.length;
					// 	// }
						
					// 	// if(prev !== undefined && response.totalItems === response.result.length) {
					// 	// 	return false;
					// 	// } else if (prev === true && response.totalItems >= response.result.length)  {
					// 	// 	return true;
					// 	// } else {
					// 	// 	return response.totalItems > response.result.length;
					// 	// }
					// });
					setHasMore(true)
				}
			} catch (err) {
			} finally {
				setLoading(false);
			}
		},
		[
			setLoading,
			setHasMore,
			setData,
			authGet,
			resourcePath,
			identifier,
			hasCompany,
			company?.id,
		]
	);

	useEffect(() => {
		if(getSearchFilters) {
			fetch(getSearchFilters(""))
		} else {
			fetch();
		}
	}, [fetch, getSearchFilters]);

	return { hasMore, data, loading, fetch };
};
