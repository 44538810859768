import { useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ListGroupItem, Tooltip } from "reactstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapLocation, faImage } from "@fortawesome/free-solid-svg-icons";
import NoteModal from "./NoteModal";
import GeofenceTooltip from "../../../../../TimeSheet/Home/Actions/HistoryDrawer/Clocks/Clock/GeofenceTooltip";
import ImageTooltip from "../../../../../TimeSheet/Home/Actions/HistoryDrawer/Clocks/Clock/ImageTooltip";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../../../../utils/hooks/company";
import { useGroups } from "../../../../../Settings/General/OrganisationLevelGroups/context";
import { dateTimeFormat } from "../../../../../../utils/helpers/date";
import { useVisible } from "../../../../../../utils/hooks/useVisible";
import { renderSettingsResourceLabel, renderOrgLevelResourceLabel } from "../../../../../../utils/helpers/settings";
import { renderDeviceIcon } from "../../../../../../utils/helpers/shift/clock";
import moment from "moment-timezone";

const Clock = ({ clock: { type, mode, time, note, device, project, location, job, user, id, image, geofence }, isShiftView = false }) => {
	const { t } = useTranslation();
	const timeFormat = useCompanyTimeFormat();
	const dateFormat = useCompanyDateFormat();
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);
	const { visible, open, toggle, close } = useVisible();

	const {
		visible: isOpenGeofenceTooltip,
		open: openGeofenceTooltip,
		close: closeGeofenceTooltip,
		selected: selectedGeofence
	} = useVisible();

	const {
		visible: isOpenImageTooltip,
		open: openImageTooltip,
		close: closeImageTooltip,
		selected: selectedImage
	} = useVisible();


	const toggleTooltip = useCallback(() => {
		setIsTooltipOpen(!isTooltipOpen);
	}, [setIsTooltipOpen, isTooltipOpen]);

	const { groups } = useGroups();

	const useInClockInGroup = useMemo(() =>
		groups?.find(({ useInClockIn }) => useInClockIn),
		[groups])

	const itemType = useMemo(() => {
		if (type === "BREAK") {
			return "checklist-item-warning";
		} else if (type === "SHIFT" && mode === "START") {
			return "checklist-item-info"
		} else {
			return "checklist-item-danger";
		}
	}, [mode, type]);

	const name = useMemo(() => {
		if (type === "BREAK") {
			if (mode === "START") {
				return t("started-break");
			} else {
				return t("ended-break");
			}
		} else {
			if (mode === "START") {
				return t("clocked-in");
			} else {
				return t("clocked-out");
			}
		}
	}, [t, mode, type]);

	return (
		<>
			<ListGroupItem
				className={classNames(
					"checklist-entry flex-column align-items-start py-4 px-1 border-muted",
					((type === "SHIFT" && mode === "START") ||
						type === "BREAK") &&
					"border-0",
				)}
			>
				<div className={classNames("checklist-item", itemType)}>
					<div className="checklist-info d-flex flex-column text-sm">
						<div className="d-flex align-items-center">
							<h4 className="checklist-title mx-1 mb-0">{name}</h4>
							<span className="checklist-title mx-1">{" - "}{moment(time).format(dateFormat)}</span>
							<span className="checklist-title mx-1">{" - "}{dateTimeFormat(time, timeFormat)}</span>
						</div>

						<span className="mb-0 mx-1">
							{job && <>
								<i className="fa-solid fa-briefcase mr-2" />
								{renderSettingsResourceLabel(job)}
							</>
							}
						</span>

						<span className="mb-0 mx-1">
							{location && <>
								<i className="fa-solid fa-location-dot mr-2" />
								{renderSettingsResourceLabel(location)}</>}
						</span>

						<span className="mb-0 mx-1">
							{project && <>
								<span className="font-weight-bolder text-sm mr-2 mb-1">{useInClockInGroup && useInClockInGroup?.description}:</span>
								{renderOrgLevelResourceLabel(project)}
							</>}
						</span>
					</div>

					<div className="d-flex align-items-center">
						{isShiftView && note && (
							<small
								className={classNames(itemType, "cursor-pointer mx-2 text-lg")}
							>
								<i
									className="fa-solid fa-note-sticky text-lg cursor-pointer text-gray"
									onClick={open}
								/>
							</small>
						)}

						{geofence &&
							<small
								id={`clock${location?.code + id}`}
								className={classNames(itemType, "cursor-pointer mx-2 text-lg")}
							>
								<FontAwesomeIcon
									className="text-lg text-gray cursor-pointer"
									icon={faMapLocation}
									onMouseEnter={() => openGeofenceTooltip({
										code: location?.code,
										id,
										geofence,
									})}
								/>
							</small>
						}

						{image &&
							<small
								id={`clock${id}`}
								className={classNames(itemType, "cursor-pointer mx-2")}
							>
								<FontAwesomeIcon
									className="text-lg text-gray cursor-pointer"
									icon={faImage}
									onMouseEnter={() => openImageTooltip({
										id,
										image,
										user
									})}
								/>
							</small>
						}

						<span
							id={`clock${device}`}
							className={classNames(itemType, "cursor-pointer ml-2 text-sm")}
						>
							{renderDeviceIcon(device)}
						</span>
					</div>
				</div>
			</ListGroupItem>

			<NoteModal
				visible={visible}
				note={note}
				toggle={toggle}
				close={close}
			/>

			{selectedGeofence && <GeofenceTooltip
				isOpen={isOpenGeofenceTooltip}
				close={closeGeofenceTooltip}
				data={selectedGeofence}
				placement="top"
			/>}

			{selectedImage && <ImageTooltip
				isOpen={isOpenImageTooltip}
				close={closeImageTooltip}
				data={selectedImage}
				placement="top"
			/>}

			<Tooltip
				target={`clock${device}`}
				isOpen={isTooltipOpen}
				toggle={toggleTooltip}
				placement="left"
			>
				<span className="text-sm font-weight-bolder px-2">
					{t(device)}
				</span>
			</Tooltip>
		</>
	);
};

export default Clock;
