import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Card, CardBody, CardText, CardTitle } from "reactstrap";
import useSWR from "swr";

function StatisticBox ({ label, value, endpoint }) {
	const { t } = useTranslation();
	const { data, isLoading, error } = useSWR(
		endpoint
			? {
				url: endpoint,
			}
			: null,
		{
			shouldRetryOnError: false,
			revalidateOnFocus: false,
		}
	);

	const renderValue = useCallback(
		(value, displayHours = true) => {
			if (isNaN(Number(value))) {
				return null;
			}
			if (!displayHours) {
				return value;
			}
			if (value === 1) {
				return t("1-hour");
			}
			return t("{{amount}}-hours", {
				amount: value,
			});
		},
		[t]
	);

	const amount = renderValue(endpoint ? data : value, endpoint && value > 0 ? false : true);
	const plannedHours = Number(`${data}`) + Number(`${value}`);

	if (error)
		return (
			<Card className="statistic-box text-danger">
				<CardBody className="p-2">
					<CardTitle tag="h5">{label}</CardTitle>
					<CardText>{t(`unable-to-retrieve`, { label })}</CardText>
				</CardBody>
			</Card>
		);
	if (isLoading)
		return (
			<Card className="statistic-box">
				<CardBody className="p-2">
					<CardTitle tag="h5">{label}</CardTitle>
					<CardText>{t("loading")}...</CardText>
				</CardBody>
			</Card>
		);

	return (
		<Card className="statistic-box">
			<CardBody className="p-2">
				<CardTitle tag="h5">{label}</CardTitle>
				<CardText>
					{(amount === 0 && endpoint) ? null : amount}
					{(value && endpoint && value > 0)
						? ` ${amount > 0 ? " + " : ""} ${value} ${amount > 0 ? ` = ${plannedHours}` : ""} ${t("hours")}`
						: null}
				</CardText>
			</CardBody>
		</Card>
	);
};

export default StatisticBox;
