export const ruleTypes = [
	"consecutive-day",
	"daily-double-time",
	"daily-double-time-and-half",
	"daily-overtime",
	"weekly-overtime",
	"all-hours-worked-on",
	"six-consecutive-day",
];

export const guaranteeRuleTypes = [
	"daily-guarantee",
	"shift-guarantee",
	"weekly-guarantee",
	"weekend-guarantee",
];

export const onFormProviderFinish = (name, { values, forms }) => {
	const { calculationGroup: calculationGroupForm } = forms;

	if (ruleTypes.includes(name)) {
		const overtime = calculationGroupForm.getFieldValue("overtime");
		Object.assign(overtime[name], values);
		calculationGroupForm.setFieldValue("overtime", overtime);
	} else if (guaranteeRuleTypes.includes(name)) {
		const guarantee = calculationGroupForm.getFieldValue("guarantee");
		Object.assign(guarantee[name], values);
		calculationGroupForm.setFieldValue("guarantee", guarantee);
	} else if (name === "meal-premium") {
		const formValues = calculationGroupForm.getFieldValue("mealPremium");
		calculationGroupForm.setFieldValue(
			"mealPremium", {
			...formValues,
			...values?.mealPremium,
		});
	} else if (name === "shift-premium") {
		const formValues = calculationGroupForm.getFieldValue("shiftPremium");
		calculationGroupForm.setFieldValue(
			"shiftPremium", {
			...formValues,
			...values?.shiftPremium,
		});
	} else if (name === "clock-exception") {
		const formValues = calculationGroupForm.getFieldValue("clockException");
		calculationGroupForm.setFieldValue(
			"clockException", {
			...formValues,
			...values?.clockException,
		});
	} else if (name === "amount-per-unit") {
		const formValues = calculationGroupForm.getFieldValue("amountPerUnit");
		calculationGroupForm.setFieldValue("amountPerUnit", {
			...formValues,
			...values?.amountPerUnit,
		});
	} else if (name === "base-holiday-rule" || name === "work-holiday-rule") {
		const holidayRules = calculationGroupForm.getFieldValue("holidayRules");

		const data = name === "base-holiday-rule" ? {
			base: { ...holidayRules?.base, ...values?.holidayRules?.base }
		} : {
			worked: { ...holidayRules?.worked, ...values?.holidayRules?.worked }
		};

		calculationGroupForm.setFieldValue("holidayRules", { ...holidayRules, ...data });
	} else if (name === "clock-in-rule-union") {
		const unionRules = calculationGroupForm.getFieldValue("unionRules");

		calculationGroupForm.setFieldValue(
			"unionRules", {
			...unionRules,
			clockIn: {
				...unionRules?.clockIn,
				...values?.unionRules?.clockIn
			}
		});
	}
};
