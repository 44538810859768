import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";

function Break({ first, remove, name, disabled: disabledField }) {
    const { t } = useTranslation();

    return (
        <>
            <BaseField shouldUpdate noStyle>
                {({ }, { }, { getFieldValue, getFieldsError }) => {
                    const disabled = !getFieldValue(["breaks", "rest", "status"]);

                    const [error] = getFieldsError([
                        ["breaks", "rest", "breaks", name, "amount"],
                        ["breaks", "rest", "breaks", name, "after"],
                    ])
                        .map(({ errors }) => errors)
                        .flat();

                    return (
                        <>
                            <div className="d-flex justify-content-between mb-2">
                                <div className="d-flex align-items-center">
                                    <p className="text-xs text-dark font-weight-500 mr-1 mb-0 text-nowrap">
                                        {t("add")}
                                    </p>

                                    <div className="w-25 mx-2">
                                        <BaseField
                                            name={[name, "amount"]}
                                            noStyle
                                            rules={
                                                !disabled
                                                    ? [
                                                        {
                                                            required: true,
                                                            message: t("required-amount"),
                                                        },
                                                        {
                                                            validator(_, value) {
                                                                if (value && parseFloat(value) <= 0) {
                                                                    return Promise.reject(
                                                                        new Error(t("amount-bigger-than-zero")),
                                                                    );
                                                                }

                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]
                                                    : undefined
                                            }
                                            dependencies={[["breaks", "rest", "status"]]}
                                            className="mb-0"
                                        >
                                            <Input
                                                type="number"
                                                size="sm"
                                                className="input-number"
                                                disabled={disabled || disabledField}
                                            />
                                        </BaseField>
                                    </div>

                                    <p className="text-xs text-dark font-weight-500 mr-2 mb-0 text-nowrap">
                                        {t("minutes")} {" "}
                                        {t("rest-break-time-after")}
                                    </p>

                                    <div className="w-25">
                                        <BaseField
                                            name={[name, "after"]}
                                            noStyle
                                            rules={
                                                !disabled
                                                    ? [
                                                        {
                                                            required: true,
                                                            message: t("required-after"),
                                                        },
                                                        {
                                                            validator(_, value,) {
                                                                const amount = getFieldValue(["breaks", "rest", "breaks", name, "amount"]);

                                                                if (value && parseFloat(value) <= 0) {
                                                                    return Promise.reject(
                                                                        new Error(t("after-bigger-than-zero")),
                                                                    );
                                                                }
                                                                if (parseFloat(value) < parseFloat(amount)) {
                                                                    return Promise.reject(
                                                                        new Error(
                                                                            t("after-bigger-than-amount"),
                                                                        ),
                                                                    );
                                                                }

                                                                return Promise.resolve();
                                                            },
                                                        },
                                                    ]
                                                    : undefined
                                            }
                                            dependencies={[
                                                ["breaks", "rest", "status"],
                                                ["breaks", "rest", "breaks", name, "amount"],
                                            ]}
                                            className="mb-0"
                                        >
                                            <Input
                                                type="number"
                                                size="sm"
                                                disabled={disabled || disabledField}
                                                className="mr-2 mb-0 input-number"
                                            />
                                        </BaseField>
                                    </div>

                                    <p className="text-xs text-dark font-weight-500 mr-2 mb-0 text-nowrap">
                                        {t("minutes")}
                                    </p>
                                </div>

                                <div className="d-flex align-items-center">
                                    {!first && (
                                        <FontAwesomeIcon
                                            icon={faMinus}
                                            className="text-danger text-sm cursor-pointer"
                                            onClick={
                                                disabled || disabledField
                                                    ? undefined
                                                    : () => remove(name)
                                            }
                                        />
                                    )}
                                </div>
                            </div>

                            {error && (
                                <span className="invalid-feedback d-block">
                                    {error}
                                </span>
                            )}
                        </>
                    );
                }}
            </BaseField>
        </>
    );
}

export default Break;
