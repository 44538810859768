import { useCallback } from "react";
import { Field } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Text from "../../../../../../../components/Inputs/Text";

function Item({ name, getFieldValue, setFieldValue, data, disabled }) {
    const { t } = useTranslation();

    const onChange = useCallback((field, value) => {
        setFieldValue(["intervals", name, field], value);
    }, [name, setFieldValue]);

    const removeItem = useCallback(() => {
        let tiers = getFieldValue("tiers");
        tiers = tiers.filter((_, i) => name !== i);
        tiers = tiers.map((tier, i) => ({
            ...tier,
            start: tiers[i - 1]?.end ? Number(tiers[i - 1]?.end) + 1 : (i === 0 ? 0 : ""),
            end: tier.end || "",
        }));

        setFieldValue("tiers", tiers);
    }, [getFieldValue, name, setFieldValue]);

    return (
        <div key={name} className="d-flex gap-2 align-items-center mb-2">
            <div className="w-100">
                <Label>{t("name")}</Label>
                <Field
                    name={[name, "name"]}
                    rules={[
                        {
                            required: true,
                            message: t("required-name")
                        },
                        {
                            validator: (_, value) => {
                                if (value) {
                                    const tiers = getFieldValue("tiers");
                                    const hasError = tiers?.find(
                                        (tier, i) => i !== name && (tier?.name || "").toLowerCase() === value.toLowerCase()
                                    );

                                    if (!!hasError) {
                                        return Promise.reject(new Error(t("unique-name")));
                                    } else {
                                        return Promise.resolve();
                                    }
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Text disabled={disabled} />
                </Field>
            </div>

            <div className="w-100">
                <Label>{t("start")}</Label>
                <Field
                    name={[name, "start"]}
                    dependencies={[
                        ["tiers", name, "end"],
                        ["tiers", name - 1, "end"]
                    ]}
                    rules={[
                        {
                            required: data.length > 1 && name !== 0,
                            message: t("required-start")
                        },
                        {
                            validator: (_, value) => {
                                if (value && name > 0) {
                                    if (Number(value) < 0) {
                                        return Promise.reject(new Error(t("start-bigger-than-zero")));
                                    }
                                    const prevEnd = getFieldValue(["tiers", name - 1, "end"]);
                                    const currentEnd = getFieldValue(["tiers", name, "end"]);

                                    if (!isNaN(prevEnd) && prevEnd !== null && prevEnd !== "" && Number(value) !== (Number(prevEnd) + 1)) {
                                        return Promise.reject(new Error(t("start-equal-to-prev-end")));
                                    }

                                    if (!isNaN(currentEnd) && currentEnd !== null && currentEnd !== "" && Number(value) >= Number(currentEnd)) {
                                        return Promise.reject(
                                            new Error(t("timeoff-start-smaller-than-end"))
                                        );
                                    }

                                    return Promise.resolve();
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Text
                        type="number"
                        disabled={disabled || name === 0}
                        onChange={(e) => onChange("start", e.target.value)}
                    />
                </Field>
            </div>

            <div className="w-100">
                <Label>{t("end")}</Label>
                <Field
                    name={[name, "end"]}
                    dependencies={[
                        ["tiers", name, "start"],
                        ["tiers", name + 1, "start"]
                    ]}
                    rules={[
                        {
                            required: data.length > 1 && name < data.length - 1,
                            message: t("required-end")
                        },
                        {
                            validator: (_, value) => {
                                if (value && data.length > 1) {
                                    if (Number(value) < 0) {
                                        return Promise.reject(new Error(t("end-bigger-than-zero")));
                                    }
                                    const currentStart = getFieldValue(["tiers", name, "start"]);
                                    const nextStart = getFieldValue(["tiers", name + 1, "start"]);

                                    if (!isNaN(currentStart) && currentStart !== "" && currentStart !== null && Number(value) <= Number(currentStart)) {
                                        return Promise.reject(
                                            new Error(t("timeoff-end-bigger-than-start"))
                                        );
                                    }

                                    if (!isNaN(nextStart) && nextStart !== "" && nextStart !== null && Number(value) !== (Number(nextStart) - 1)) {
                                        return Promise.reject(
                                            new Error(t("end-equal-to-following-start"))
                                        );
                                    }
                                    return Promise.resolve();
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Text
                        type="number"
                        disabled={disabled || data?.length - 1 === name}
                        onChange={(e) => onChange("end", e.target.value)}
                    />
                </Field>
            </div>

            <div>
                <div style={{ height: "5px" }}></div>
                <div className="d-flex align-items-center pt-4">
                    {data?.length > 1 && (
                        <FontAwesomeIcon
                            className="text-danger cursor-pointer"
                            icon={faMinus}
                            onClick={!disabled ? removeItem : undefined}
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default Item;