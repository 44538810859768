import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Switch from "../../../../../../components/Inputs/Switch";
import Field from "../../../../../../components/Field";
import Text from "../../../../../../components/Inputs/Text";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../../../utils/helpers/settings";
import { firstToUpper } from "../../../../../../utils/helpers/string";

function Prohibition({ form, data, disabled }) {
    const { t } = useTranslation();
    const [prohibition, setProhibition] = useState(!!data?.prohibition || false);

    const onChange = useCallback(() => {
        setProhibition((prev) => !prev);
        if (prohibition) {
            form.setFieldValue("prohibition", null);
        }
    }, [form, setProhibition, prohibition]);

    return (
        <div className="rounded my-3 p-4" style={{ background: "#f8f9f9" }}>
            <Switch checked={prohibition} onChange={onChange}>
                {t("probation")}
            </Switch>

            <BaseField name="prohibition" hidden />

            {prohibition && (
                <div className="my-3">
                    <Field
                        label={firstToUpper(t("days"))}
                        name={["prohibition", "value"]}
                        rules={[
                            {
                                required: true,
                                message: t("required-days")
                            },
                            {
                                validator: (_, value) => {
                                    if (value && !isNaN(value) && Number(value) <= 0) {
                                        return Promise.reject(new Error(t("days-bigger-than-0")))
                                    }
                                    return Promise.resolve();
                                }
                            }
                        ]}
                    >
                        <Text
                            type="number"
                            placeholder={firstToUpper(t("days"))}
                            disabled={disabled}
                            step="1"
                        />
                    </Field>

                    <Field name={["prohibition", "allowAccrual"]} valuePropName="checked">
                        <Switch>
                            {t("allow-accrual-during-probation-period")}
                        </Switch>
                    </Field>

                    <Field name={["prohibition", "allowRequests"]} valuePropName="checked">
                        <Switch>
                            {t("allow-user-to-make-request-during-probation-period")}
                        </Switch>
                    </Field>

                    <BaseField shouldUpdate>
                        {({ }, { }, { getFieldValue }) => {
                            const allowRequest = getFieldValue(["prohibition", "allowRequests"]);

                            return allowRequest && (
                                <Field
                                    label={t("pay-code")}
                                    name={["prohibition", "payCode"]}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-pay-code")
                                        }
                                    ]}
                                >
                                    <ResourceSelect
                                        hasSearch
                                        placeholder={t("pay-code")}
                                        resourcePath="/pay-code"
                                        renderLabel={renderSettingsResourceLabel}
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                        disabled={disabled}
                                    />
                                </Field>
                            )
                        }}
                    </BaseField>
                </div>
            )}
        </div>
    );
}

export default Prohibition;
