import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../utils/helpers/settings";

export const useFiltersConfig = ({ columns, timeoffTeam = false, isTeam = false }) => {
    const { t } = useTranslation();

    const config = useMemo(() => {
        const initialValue = !timeoffTeam ? [
            {
                id: "plan",
                name: "plan",
                label: t("plan"),
                type: "resource-select",
                component: (
                    <ResourceSelect
                        mode="multiple"
                        labelPropName="description"
                        resourcePath="/timeoff"
                        renderLabel={renderSettingsResourceLabel}
                        hasSearch
                        getSearchFilters={getSettingsResourceSearchFilters}
                    />
                )
            }
        ] : [];

        const config = columns?.filter((item) => item.accessorKey !== "type").reduce((total, current) => {
            if (current?.enableColumnFilter) {
                total = [
                    ...total,
                    {
                        name: current?.accessorKey,
                        label: current?.columnToggleLabel || current?.header,
                        type: current?.filterType || "text",
                        component: current?.Filter,
                        disabled: false,
                    }
                ]
            }
            return total;
        }, initialValue);

        if (isTeam) {
            config.push(
                {
                    name: "grouping",
                    label: t("groupings"),
                    type: "resource-select",
                    component: (
                        <ResourceSelect
                            resourcePath="/grouping?pagination=off"
                            mode="multiple"
                            renderLabel={renderSettingsResourceLabel}
                            getSearchFilters={getSettingsResourceSearchFilters}
                            hasSearch
                        />
                    ),
                },
                {
                    name: "employmentType",
                    label: t("employment-type"),
                    type: "select",
                    component: (
                        <ResourceSelect
                            resourcePath="/user-demographic/employment-type"
                            renderLabel={renderSettingsResourceLabel}
                            getSearchFilters={getSettingsResourceSearchFilters}
                            hasSearch
                            mode="multiple"
                        />
                    )
                },
            );
        }

        return config;
    }, [timeoffTeam, t, isTeam, columns]);

    return { config }
}