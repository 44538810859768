import { Field } from "rc-field-form";
import Item from "./Item";

function HourlyOption({ disabled }) {
    return (
        <Field shouldUpdate>
            {({ }, { }, { getFieldsError, getFieldValue, setFieldValue }) => {
                const tiers = getFieldValue("tiers");

                return (
                    <div className="my-3">
                        {tiers?.map((tier, index) => {
                            const [error] = getFieldsError([
                                ["intervals", index, "earned"],
                                ["intervals", index, "worked"],
                                ["intervals", index, "max"]
                            ]).map(({ errors }) => errors).flat();

                            return (
                                <>
                                    <Item
                                        name={index}
                                        disabled={disabled}
                                        tier={tier}
                                    />

                                    <div className="invalid-feedback d-block">
                                        {error}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                );
            }}
        </Field>
    );
}

export default HourlyOption;
