import { useMemo, useCallback, useEffect, useState, forwardRef } from "react";
import { Option } from "rc-select";
import Select from "../Select";
import get from "lodash/get";
import debounce from "lodash/debounce";
import { useResource } from "./api";

export const SEARCH_DEBOUNCE_WAIT = 600;

const hasOptionOnlyValue = (option, valuePropName) => {
	const keys = Object.keys(option);
	return keys.length === 1 && keys[0] === valuePropName;
};

const getIdentifierForValue = (value, valuePropName) => {
	if (value === null || value === undefined) {
		return undefined;
	}
	return Array.isArray(value)
		? value.map((singularValue) => singularValue[valuePropName]?.toString())
		: value[valuePropName]?.toString();
};

function ResourceSelect(
	{
		resourcePath,
		hasCompany = true,
		labelPropName,
		renderLabel,
		valuePropName = "id",
		loading: parentLoading,
		value,
		defaultValue,
		onChange,
		onSelect,
		onDeselect,
		readOnly,
		hasSearch = false,
		getSearchFilters,
		...rest
	},
	ref,
) {
	const [searchValue, setSearchValue] = useState("");

	const {
		data,
		loading: dataLoading,
		fetch,
		hasMore,
	} = useResource(resourcePath, hasCompany, valuePropName, getSearchFilters);

	const componentValue = useMemo(
		() => getIdentifierForValue(value, valuePropName),
		[value, valuePropName],
	);

	const componentDefaultValue = useMemo(
		() => getIdentifierForValue(value, valuePropName),
		[value, valuePropName],
	);

	const componentOnChange = useCallback(
		(newValue) => {
			if (onChange) {
				let newFormattedValue;

				if (Array.isArray(newValue)) {
					newFormattedValue = newValue.map((singularValue) => {
						if (singularValue in data) {
							return data[singularValue];
						} else {
							// TODO: find a better performing solution
							// TODO: there is a special case where the input type is changed from single to multi select, manage it
							return value?.find(
								(v) => v[valuePropName] === singularValue,
							);
						}
					});
				} else {
					newFormattedValue = data[newValue];
				}
				onChange(newFormattedValue);
			}
		},
		[data, onChange, value, valuePropName],
	);

	const componentOnSelect = useCallback(
		(value, option) => {
			if (onSelect) {
				onSelect(data[value], option);
			}
		},
		[onSelect, data],
	);

	const componentOnDeselect = useCallback(
		(value, option) => {
			if (onDeselect) {
				onDeselect(data[value], option);
			}
		},
		[onDeselect, data],
	);

	const debouncedSearch = useMemo(
		() =>
			getSearchFilters
				? debounce(
					(value) => fetch(getSearchFilters(value)),
					SEARCH_DEBOUNCE_WAIT,
				)
				: undefined,
		[fetch, getSearchFilters],
	);

	const refetch = useCallback(() => {
		if (getSearchFilters) {
			fetch(getSearchFilters(searchValue));
		} else {
			fetch();
		}
	}, [fetch, searchValue, getSearchFilters]);
	useEffect(() => {
		if (!ref) {
			return;
		}
		ref.current = { refetch };
	}, [ref, refetch]);

	const searchProps = useMemo(() => {
		if (!hasSearch) {
			return {};
		}

		if (hasMore === false || !getSearchFilters) {
			return {
				searchValue,
				onSearch: setSearchValue,
				showSearch: true,
				optionFilterProp: "children",
			};
		}

		const onSearch = (value) => {
			setSearchValue(value);
			if (debouncedSearch) {
				debouncedSearch(value);
			}
		};

		return {
			showSearch: true,
			searchValue,
			filterOption: false,
			onSearch,
		};
	}, [
		hasMore,
		hasSearch,
		debouncedSearch,
		getSearchFilters,
		searchValue,
		setSearchValue,
	]);

	const loading = parentLoading || dataLoading;

	const open = readOnly ? false : rest.open;

	const getLabel = useCallback(
		(item) => {
			if (renderLabel) {
				return renderLabel(item);
			} else {
				return get(item, labelPropName);
			}
		},
		[renderLabel, labelPropName],
	);

	const getValue = useCallback(
		(item) => {
			return item[valuePropName]?.toString();
		},
		[valuePropName],
	);

	const searching = searchValue.length > 0;
	const options = useMemo(() => {
		if (searching) {
			return Object.values(data);
		}
		return Object.values(data).filter((item) => {
			if (!value) {
				return true;
			}
			if (Array.isArray(value)) {
				const index = value.findIndex(
					(valueItem) =>
						valueItem[valuePropName] === item[valuePropName],
				);
				if (index === -1) {
					return true;
				} else if (hasOptionOnlyValue(value[index], valuePropName)) {
					return true;
				}
			} else {
				if (hasOptionOnlyValue(value, valuePropName)) {
					return true;
				}
				return value[valuePropName] !== item[valuePropName];
			}
		});
	}, [searching, data, value, valuePropName]);

	useEffect(() => {
		return () => debouncedSearch?.cancel();
	}, [debouncedSearch]);

	return (
		<Select
			open={open}
			loading={loading}
			value={componentValue}
			defaultValue={componentDefaultValue}
			onChange={componentOnChange}
			onSelect={componentOnSelect}
			onDeselect={componentOnDeselect}
			notFoundContent={
				loading ? (
					<div className="flex justify-center">
						<div
							className="spinner spinner-border spinner-border-sm mr-2"
							role="status"
						/>
					</div>
				) : undefined
			}
			searchValue={searchValue}
			{...rest}
			{...searchProps}
			dropdownMatchSelectWidth={false}
		>
			{!searching &&
				value &&
				(Array.isArray(value) ? (
					value.map((item) =>
						!hasOptionOnlyValue(item, valuePropName) ? (
							<Option
								key={item[valuePropName]}
								value={getValue(item)}
							>
								{getLabel(item)}
							</Option>
						) : null,
					)
				) : !hasOptionOnlyValue(value, valuePropName) ? (
					<Option value={getValue(value)}>{getLabel(value)}</Option>
				) : null)}
			{!loading &&
				options.map((item) => (
					<Option key={item[valuePropName]} value={getValue(item)}>
						{getLabel(item)}
					</Option>
				))}
		</Select>
	);
}

export default forwardRef(ResourceSelect);
