import { useTranslation } from "react-i18next";
import { Input } from "reactstrap";
import Field from "../../../../../components/Field";
import BaseSettingsFormContent from "../../../../../components/Form/BaseSettingsFormContent";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
	getSettingsResourceSearchFilters,
	renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";
import SkillsList from "./Lists/SkillsList";
import LicensesList from "./Lists/LicensesList";
import CertificationsList from "./Lists/CertificationsList";
import "./style.scss";

function FormContent({ mode, form, disabled }) {
	const { t } = useTranslation();

	return (
		<>
			<BaseSettingsFormContent mode={mode} disabled={disabled} />

			<Field name="salary" noStyle>
				<Input type="number" hidden />
			</Field>

			<Field name="group" label={t("group")}>
				<ResourceSelect
					labelPropName="description"
					renderLabel={renderSettingsResourceLabel}
					resourcePath="/job-groups"
					placeholder={t("group")}
					allowClear
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
					disabled={disabled}
				/>
			</Field>

			<Field name="family" label={t("family")}>
				<ResourceSelect
					labelPropName="description"
					renderLabel={renderSettingsResourceLabel}
					resourcePath="/job-families"
					placeholder={t("family")}
					allowClear
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
					disabled={disabled}
				/>
			</Field>

			<SkillsList disabled={disabled} />

			<LicensesList disabled={disabled} />

			<CertificationsList disabled={disabled} />
		</>
	);
}

export default FormContent;
