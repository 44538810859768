import { Tooltip } from "reactstrap";
import Map from "../../../../../../../../components/Map";
import "./style.scss";

function GeofenceTooltip({ data, close, isOpen, placement = "left" }) {
    return <Tooltip
        target={`clock${data?.code + data?.id}`}
        isOpen={isOpen}
        toggle={close}
        trigger="hover"
        autohide={false}
        placement={placement}
        className="geofence-tooltip"
        style={{ height: "300px", width: "400px" }}
    >
        <Map
            marker={data?.geofence}
            center={data?.geofence}
            zoom={17}
            options={{
                mapTypeControl: false,
                streetViewControl: false,
                fullscreenControl: false,
                zoomControl: false,
            }}
        />
    </Tooltip>
}

export default GeofenceTooltip;