import { List } from "rc-field-form";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../../components/Button";
import Rule from "./Rule";

const Rules = ({ form }) => {
    const { t } = useTranslation();

    return (
        <List name="rules">
            {(data, { add, remove }) => {
                return (
                    <>
                        {data?.map(({ name }) => (
                            <Rule
                                name={name}
                                form={form}
                                remove={remove}
                            />
                        ))}

                        <Button
                            className="w-100"
                            onClick={() => add({})}
                        >
                            + {t("add-rule")}
                        </Button>
                    </>
                );
            }}
        </List>
    );
};

export default Rules;
