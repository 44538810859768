import {  useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Collapse, Row } from "reactstrap";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../../../components/Loaders/Loading";
import AnalyticsTable from "../../components/Topbar/AnalyticsTable";
import Button from "../../../../components/Button";
import DateFilters from "./DateFilters";
import { useOverview } from "../../components/Topbar/context";
import { useTotals } from "../../components/Topbar/hooks/useTotals";
import { renderDurationAsFormat } from "../../../../utils/helpers/date";
import { normalize } from "../../../../utils/helpers/normalize";
import { useRenderPayment } from "../../../../utils/hooks/useRenderPayment.js";

const hourTypes = [
    "regular",
    "overtime",
    "double-time",
    "pto",
    "amount",
    "unit",
    "holiday",
];

const Topbar = () => {
    const { t } = useTranslation();
    const { data: apiData, loading, visible, toggle } = useOverview();

    const { data, budget } = useMemo(() => {
        if (Array.isArray(apiData)) {
            return {
                data: apiData,
                budget: { hours: 0, amount: 0 },
            };
        }
        return { data: apiData.data, budget: apiData.budget };
    }, [apiData]);

    const { totalPayment, totalDuration } = useTotals(data);

    const config = useMemo(() => {
        return normalize(data || [], "hourType");
    }, [data]);

    const renderPayment = useRenderPayment();
    const durationVar = useMemo(() => {
        let diff = budget.hours - totalDuration;
        let sign = "";
        const isNegative = diff < 0;
        if (isNegative) {
            diff = diff * -1;
            sign = "-";
        }
        return sign + renderDurationAsFormat(diff, "HH:mm")
    }, [budget.hours, totalDuration]);

    return (
        <div className="timesheet-analytics">
            <Row className='mb-3'>
                <Col xs="12" md="auto">
                    <Button
                        color="primary"
                        icon={<FontAwesomeIcon icon={visible ? faChevronUp : faChevronDown} />}
                        onClick={toggle}
                    >
                        {t("analytics")}
                    </Button>
                </Col>

                <Col xs='12' md='auto'>
                    <DateFilters />
                </Col>
            </Row>

            <Collapse isOpen={visible}>
                <div className="d-flex justify-content-between">
                    <AnalyticsTable
                        hourTypes={hourTypes}
                        config={config}
                        data={data}
                    />

                    <div className="d-flex">
                        <div className="d-flex flex-column mr-2">
                            <Card className="small-statistic mb-2 light">
                                {loading ? (
                                    <div className="d-flex justify-content-center">
                                        <Loading />
                                    </div>
                                ) : (
                                    <>
                                        <h4 className="title">{t("budget-hours")}</h4>
                                        <h4 className="value">{renderDurationAsFormat(budget.hours, "HH:mm")}</h4>
                                    </>
                                )}
                            </Card>

                            <Card className="small-statistic mb-0 dark">
                                {loading ? (
                                    <div className="d-flex justify-content-center">
                                        <Loading />
                                    </div>
                                ) : (
                                    <>
                                        <h4 className="title">{t("hours-var")}</h4>
                                        <h4 className="value">{durationVar}</h4>
                                    </>
                                )}
                            </Card>
                        </div>

                        <div className="d-flex flex-column">
                            <Card className="small-statistic mb-2 light">
                                {loading ? (
                                    <div className="d-flex justify-content-center">
                                        <Loading />
                                    </div>
                                ) : (
                                    <>
                                        <h4 className="title">{t("budget-amount")}</h4>
                                        <h4 className="value">{renderPayment(budget.amount)}</h4>
                                    </>
                                )}
                            </Card>

                            <Card className="small-statistic mb-0 dark">
                                {loading ? (
                                    <div className="d-flex justify-content-center">
                                        <Loading />
                                    </div>
                                ) : (
                                    <>
                                        <h4 className="title">{t("amount-var")}</h4>
                                        <h4 className="value">{renderPayment(budget.amount - totalPayment)}</h4>
                                    </>
                                )}
                            </Card>
                        </div>
                    </div>
                </div>
            </Collapse>
        </div>
    );
}

export default Topbar;
