import { useCallback, useState } from 'react';
import TableView from './TableView';
import ChartView from './ChartView';
import { ButtonGroup, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { DateFiltersProvider } from '../../../components/DateRangeFilters/useDateFilters';
import { useNavigate } from 'react-router-dom';

const ReportPreview = ({ values, isPreview }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState('table');

  const back = useCallback(() => {
    return navigate("/reports/custom-reports")
  }, [navigate]);

  return (
    <DateFiltersProvider>
      <div className="h-100">
        <div className="d-flex align-items-center justify-content-between w-100">
          <ButtonGroup className="mb-3">
            <Button
              color={viewMode === 'table' ? 'primary' : 'secondary'}
              onClick={() => setViewMode('table')}
            >
              {t('table-view')}
            </Button>
            <Button
              color={viewMode === 'chart' ? 'primary' : 'secondary'}
              onClick={() => setViewMode('chart')}
            >
              {t('chart-view')}
            </Button>
          </ButtonGroup>

          <Button
            className="mb-3"
            color="dark"
            onClick={back}
          >
            {t("back")}
          </Button>
        </div>

        {viewMode === 'table' ? <TableView values={values} isPreview={isPreview} /> : <ChartView values={values} />}
      </div>
    </DateFiltersProvider>
  );
};

export default ReportPreview;
