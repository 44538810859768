import { useTranslation } from "react-i18next";
import { List, Field as BaseField } from "rc-field-form";
import { Button, Label } from "reactstrap";
import Field from "../../../../../../components/Field";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import Switch from "../../../../../../components/Inputs/Switch";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel,
} from "../../../../../../utils/helpers/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const SkillsList = ({ disabled }) => {
    const { t } = useTranslation();

    return (
        <BaseField shouldUpdate>
            {({}, {}, { getFieldsValue }) => {
                return (
                    <>
                        <Label>{t("skills")}</Label>
                        <List name="skills">
                            {(fields, { add, remove }) => {
                                return (
                                    <div className="job-qualifications-list-container shadow-sm">
                                        {fields.map(({ key, name }) => {
                                            return (
                                                <div key={key}>
                                                    <div className="d-flex align-items-center gap-2">
                                                        <Field name={[name, "skill"]} className="w-100"
                                                            rules={[
                                                                {
                                                                    validator: (_, value) => {
                                                                        const skills = getFieldsValue(["skills"])?.skills || [];    
                                                                        const skillExists = skills.some((skill, index) => 
                                                                            index !== name && skill?.skill?.id === value?.id
                                                                        );

                                                                        if(name > 0 && !value) {
                                                                            return Promise.reject(new Error(t("required-field")));

                                                                        } else if(name === 0 && skills?.length > 1 && !value) {
                                                                            return Promise.reject(new Error(t("required-field")));
                                                                        }

                                                                        if (skillExists) {
                                                                            return Promise.reject(new Error(t("duplicate-skill")));
                                                                        }
                                                            
                                                                        return Promise.resolve();
                                                                    }
                                                                }
                                                            ]}
                                                        >
                                                            <ResourceSelect
                                                                labelPropName="description"
                                                                renderLabel={renderSettingsResourceLabel}
                                                                resourcePath="/skill"
                                                                placeholder={t("skills")}
                                                                allowClear
                                                                hasSearch
                                                                getSearchFilters={getSettingsResourceSearchFilters}
                                                                disabled={disabled}
                                                            />
                                                        </Field>

                                                        {fields.length > 1 && !disabled && (
                                                            <FontAwesomeIcon
                                                                className="text-md text-danger cursor-pointer mb-4 mx-2"
                                                                onClick={() => remove(name)}
                                                                icon={faXmark}
                                                            />
                                                        )}
                                                    </div>

                                                    <Field valuePropName="checked" name={[name, "isRequired"]}>
                                                        <Switch disabled={disabled}>{t("is-required")}</Switch>
                                                    </Field>
                                                </div>
                                            );
                                        })}
                                        <Button
                                            type="button"
                                            className="w-100"
                                            color="primary"
                                            onClick={() => add({ isRequired: false }, fields?.length)}
                                        >
                                            + {t("add")}
                                        </Button>
                                    </div>
                                );
                            }}
                        </List>
                    </>
                );
            }}
        </BaseField>
    );
};

export default SkillsList;
