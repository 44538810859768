export const defaultValue = {
	canManageUsers: false,
	dashboard: {
		canView: false,
		canViewManagerDashboar: false,
	},
	people: {
		canView: false,
		canCreate: false,
		canEdit: false,
		canDelete: false,
		changePassword: false,
		changeJobInformation: false,
		changeHiringInformation: false,
		changeOrganisation: false,
		changePersonalInformation: false,
		changeCompanyInformation: false,
		changeManagement: false,
		changeAddress: false,
		changeStatus: false,
		changeKeyDates: false,
		terminateEmployee: false,
		manageDocuments: false,
		manageNote: false,
		manageAllNotes: false,
		manageTimeOff: false,
		viewHistory: false,
		canAccessContractors: false,
		canAccessHourlyEmployees: false,
		canAccessSalaryEmployees: false,
		changeRole: false,
		changeTwoFactorAuth: false,
		changeUserGroups: false,
		canViewSchedule: false,
		canViewTerminatedUsers: false,
		showHourlyRate: false,
	},
	profile: {
		canView: true,
		canEdit: false,
		changeJobInformation: false,
		changeHiringInformation: false,
		changeOrganisation: false,
		changePersonalInformation: true,
		changeCompanyInformation: false,
		changeManagement: false,
		changeAddress: true,
		changeStatus: false,
		changeKeyDates: false,
		manageDocuments: false,
		manageTimeOff: false,
		viewHistory: false,
		changeTwoFactorAuth: false,
		changeUserGroups: false,
		canViewSchedule: false
	},
	clockin: {
		canCreate: false,
		canDelete: false,
		canView: false,
		canEdit: false,
		canClockInOnWeb: false,
		canClockInOnMobile: false,
		canClockInOnQrcode: false,
		canClockInOnDevice: false,
		canClockInOnPhoneCall: false,
		canViewOriginalClock: false,
		canViewAttestation: false
	},
	reports: {
		canView: false,
		canEdit: false,
		canGenerate: false,
		canExport: false,
		canCreate: false,
	},
	device: {
		canCreate: false,
		canDelete: false,
		canView: false,
		canEdit: false,
	},
	qrCode: {
		canCreate: false,
		canDelete: false,
		canView: false,
		canEdit: false,
	},
	whitelistNumber: {
		canCreate: false,
		canDelete: false,
		canView: false,
		canEdit: false,
	},
	timeSheet: {
		canCreate: false,
		canDelete: false,
		canView: false,
		canEdit: false,
		canApprove: false,
		canExport: false,
		canLock: false,
		canCreateMyTimesheet: false,
		canDeleteMyTimesheet: false,
		canViewMyTimesheet: false,
		canEditMyTimesheet: false,
		canApproveMyTimesheet: false,
		canExportMyTimesheet: false,
		canLockMyTimesheet: false,
		manageNote: false,
		manageAllNotes: false,
		canViewGeolocation: false,
		canViewPicture: false,
		manageNoteMyTimesheet: false,
		manageAllNotesMyTimesheet: false,
		canViewGeolocationMyTimesheet: false,
		canViewPictureMyTimesheet: false,
		canUpdateRate: false,
		canViewHistoryMyTimesheet: false,
		canViewHistory: false,
		canViewMyOriginalClocks: false,
		canViewOriginalClocks: false,
		canUpdateDuration: false,
		canViewClockExceptionsMyTimesheet: false,
		canViewClockExceptions: false,
		canEditClockExceptionsMyTimesheet: false,
		canEditClockExceptions: false
	},
	schedule: {
        canCreate: false,
        canDelete: false,
        canView: false,
        canEdit: false,
        canApprove: false,
        canExport: false,
        canLock: false,
		canUnlock: false,
        canTransfer: false,
        canCopy: false,
        canCopyMySchedule: false,
        canTransferMySchedule: false,
        canCreateMySchedule: false,
        canDeleteMySchedule: false,
        canViewMySchedule: false,
        canEditMySchedule: false,
        canApproveMySchedule: false,
        canExportMySchedule: false,
        canLockMySchedule: false,
		canUnlockMySchedule: false,
        canViewHistory: false,
        canViewHistoryMySchedule: false
    },
	request: {
		canCreate: false,
		canDelete: false,
		canView: false,
		canEdit: false,
		canExport: false,
		canViewTeamRequest: false,
		canExportTeamRequest: false,
		canApproveTeamRequest: false,
		canDenyTeamRequest: false,
	},
	communication: {
		canCreatePost: false,
		canDeletePost: false,
		canCreateComment: false,
	},
	notificationSettings: {
		canView: true,
		canManage: true,
		notification: {
			employeeDidNotShowUp: true,
			employeeRequestShift: true,
			employeeRequestAbsence: true,
			employeeRequestSchedule: true,
			employeeRequestEditShift: true,
			employeeRequestEditTimesheet: true,
			employeeRequestAprove: true,
			employeeRequestReject: true,
			employeeRequestCancel: true,
		},
		reminder: {
			nearStartOfShift: true,
			nearStartOfBreak: true,
			nearEndOfBreak: true,
			nearEndOfShift: true
		},
		email: {
			dailyMisspunchReport: true,
			weeklyMisspunchReport: true,
			dailyOvertimeReport: true,
			weeklyOvertimeReport: true,
			dailyExceptionReport: true,
		}
	},
	settings: {
		canRead: false,
		general: {
			canView: false,
			canRead: false,
			canEdit: false,
			customFields: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canEdit: false,
				canRead: false,
			},
			companies: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			organizationLevelGroup: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
				organizationLevel: {
					canCreate: false,
					canDelete: false,
					canView: false,
					canRead: false,
					canEdit: false,
				},
			},
			employeeTypes: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			union: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			locations: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			jobGroups: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			jobFamilies: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			jobs: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			roles: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			tasks: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
		},
		payPolicies: {
			payGroup: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			payCode: {
				canCreate: false,
				canView: false,
				canRead: false,
				canDelete: false,
				canEdit: false,
			},
			holidays: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			timeOff: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			calculationGroup: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			budget: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
		},
		integration: {
			ultipro: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			uta: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
		},
		rules: {
			calculationGroup: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
		},
		scheduling: {
			reports: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			jobs: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
		},
		flyer: {
			canCreate: false,
			canDelete: false,
			canView: false,
			canRead: false,
			canEdit: false,
		},
		attestation: {
			canCreate: false,
			canDelete: false,
			canView: false,
			canRead: false,
			canEdit: false,
		},
		configuration: {
			timesheet: {
				myTimesheet: {
					canCreate: false,
					canDelete: false,
					canView: false,
					canRead: false,
					canEdit: false,
				},
				team: {
					canCreate: false,
					canDelete: false,
					canView: false,
					canRead: false,
					canEdit: false,
				},
				managerView: {
					canCreate: false,
					canDelete: false,
					canView: false,
					canRead: false,
					canEdit: false,
				},
				rate: {
					canCreate: false,
					canDelete: false,
					canView: false,
					canRead: false,
					canEdit: false,
				},
			},
		},
		userPreferences: {
			timesheet: {
				myTimesheet: {
					canCreate: false,
					canView: false,
					canRead: false,
					canEdit: false
				},
				team: {
					canCreate: false,
					canView: false,
					canRead: false,
					canEdit: false
				},
				managerView: {
					canCreate: false,
					canView: false,
					canRead: false,
					canEdit: false
				}
			}
		},
		userDemographics: {
			gender: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			},
			race: {
				canCreate: false,
				canDelete: false,
				canView: false,
				canRead: false,
				canEdit: false,
			}
		}
	},
};

export default defaultValue;
