import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../../../../components/Field";
import Checkbox from "../../../../../../../../../components/Inputs/Checkbox";
import Select from "../../../../../../../../../components/Inputs/Select";
import Modal from "../../../../../../../../../components/Modal";
import Details from "./Details";
import { weekdays } from "../../../../../../../../../components/Inputs/WeekdaySelect";

const getFieldName = (name) => ["overtime", "all-hours-worked-on", name];

function AllHoursWorkedOn({ disabled }) {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);

	const openModal = useCallback(() => setVisible(true), [setVisible]);
	const closeModal = useCallback(() => setVisible(false), [setVisible]);

	return (
		<div className="calculation-group-rule">
			<div className="d-flex align-items-center w-100">
				<Field
					name={getFieldName("status")}
					valuePropName="checked"
					noStyle
					className="mb-0"
				>
					<Checkbox id="all-hours-worked-on" disabled={disabled}>
						{t("all-hours-worked-on")}
					</Checkbox>
				</Field>
			</div>

			<BaseField noStyle shouldUpdate>
				{({ }, { }, { getFieldValue, getFieldError }) => {
					const data = getFieldValue([
						"overtime",
						"all-hours-worked-on",
					]);

					const status = data.status;
					const error = getFieldError(getFieldName("value"));

					return (
						<>
							<div className="d-flex align-items-center">
								<BaseField
									name={getFieldName("value")}
									rules={
										status
											? [
												{
													required: true,
													message: t("required-all-hours-worked-on"),
												},
											]
											: undefined
									}
									dependencies={[getFieldName("status")]}
									className="mb-0"
								>
									<Select disabled={!status || disabled} mode="multiple">
										{weekdays.map((day) => (
											<Select.Option
												key={day}
												value={day}
											>
												{t(day)}
											</Select.Option>
										))}
									</Select>
								</BaseField>

								<BaseField
									name={getFieldName("defaultPayCode")}
									hidden
								/>

								<BaseField
									name={getFieldName("payCodes")}
									hidden
								/>

								<BaseField
									name={getFieldName("hourTypes")}
									hidden
								/>

								{/* <BaseField
									name={getFieldName("workedHours")}
									hidden
								/>
								<BaseField
									name={getFieldName("payCode")}
									hidden
								/> */}
							</div>

							<div className="d-flex align-items-center justify-content-end">
								<FontAwesomeIcon
									className="text-sm cursor-pointer"
									icon={faPen}
									onClick={(status || disabled) ? openModal : undefined}
								/>
							</div>

							{error && (
								<span className="invalid-feedback d-block">
									{error}
								</span>
							)}

							{visible && (
								<Modal
									title={t("all-hours-worked-on-rule-parameters")}
									isOpen={visible}
									close={closeModal}
									centered={true}
									className="modal-lg"
								>
									<Details
										close={closeModal}
										overtime={data}
										disabled={disabled}
									/>
								</Modal>
							)}
						</>
					);
				}}
			</BaseField>
		</div>
	);
}

export default AllHoursWorkedOn;
