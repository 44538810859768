import { useCallback, useEffect, useMemo } from "react";
import { Marker, Tooltip, useMap } from "react-leaflet";
import { useTranslation } from "react-i18next";
import { getPrimaryResources, renderUserName } from "../../../../utils/helpers/user";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../../utils/hooks/company";
import { dateTimeFormat } from "../../../../utils/helpers/date";
import { renderOrgLevelResourceLabel, renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import { useGroups } from "../../../Settings/General/OrganisationLevelGroups/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcase, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import L from "leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import classNames from "classnames";
import moment from "moment-timezone";

const icon = L.icon({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
});

const UserMarker = ({ data }) => {
    const { t } = useTranslation();
    const map = useMap();
    const timeFormat = useCompanyTimeFormat();
    const dateFormat = useCompanyDateFormat();
    const { groups } = useGroups();

    const useInClockInGroup = useMemo(() => groups?.find(({ useInClockIn }) => useInClockIn), [groups]);

    const groupedMarkers = useMemo(() => {
        const markerGroups = {};

        data.forEach((user) => {
            if (!user.lastClock?.geofence) return;
            const lat = user.lastClock.geofence.lat;
            const lng = user.lastClock.geofence.lng;
            const key = `${lat},${lng}`;

            if (!markerGroups[key]) {
                markerGroups[key] = {
                    lat,
                    lng,
                    userDetails: [],
                };
            }

            markerGroups[key].userDetails.push({ ...user });
        });

        return Object.values(markerGroups);
    }, [data]);

    const name = useCallback(
        ({ type, mode }) => {
            if (type === "BREAK") {
                if (mode === "START") {
                    return t("started-break");
                } else {
                    return t("ended-break");
                }
            } else {
                if (mode === "START") {
                    return t("clocked-in");
                } else {
                    return t("clocked-out");
                }
            }
        },
        [t]
    );

    useEffect(() => {
        if (!map) return;

        if (groupedMarkers.length === 0) {
            map.setView([40.7128, -74.006], 11);
        } else {
            const bounds = L.latLngBounds(groupedMarkers.map((marker) => [marker.lat, marker.lng]));
            map.fitBounds(bounds, { padding: [50, 50] });

            if (groupedMarkers.length === 1) {
                map.setView([groupedMarkers[0].lat, groupedMarkers[0].lng], 11);
            }
        }
    }, [map, groupedMarkers]);

    return groupedMarkers.length > 0
        ? groupedMarkers.map((marker, i) => (
              <Marker key={i} icon={icon} position={[marker.lat, marker.lng]}>
                  <Tooltip direction="top" offset={[-15, -10]} opacity={1} permanent={false}>
                      {marker.userDetails.map((user, index) => {
                          return (
                              <div key={index} className={classNames("d-flex flex-column gap-2", index < marker.userDetails.length - 1 && "mb-3")} style={{ lineHeight: "12px" }}>
                                    <span className="p-0 m-0 font-weight-bolder text-sm">{renderUserName(user)}</span>
                                  <div className="d-flex gap-2">
                                      <span className="font-weight-bolder">{name(user.lastClock)}</span>
                                      <span>{moment(user?.lastClock?.time).format(dateFormat)}</span>
                                      <span>{dateTimeFormat(user?.lastClock?.time, timeFormat)}</span>
                                  </div>
                                  <span className="d-flex gap-2 align-items-center">
                                      <FontAwesomeIcon icon={faBriefcase} />
                                      {renderSettingsResourceLabel(user.lastShift.job)}
                                  </span>
                                  <span className="d-flex gap-2 align-items-center">
                                      <FontAwesomeIcon icon={faLocationDot} />
                                      {renderSettingsResourceLabel(user.lastShift.location)}
                                  </span>
                                  {user.lastShift.project && (
                                      <div className="text-nowrap d-flex gap-2">
                                          <span className="font-weight-bolder">
                                              {useInClockInGroup?.description}:
                                          </span>
                                          {renderOrgLevelResourceLabel(user.lastShift.project)}
                                      </div>
                                  )}
                              </div>
                          );
                      })}
                  </Tooltip>
              </Marker>
          ))
        : null;
};

export default UserMarker