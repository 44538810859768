import { get, merge, pick } from "lodash";
import Form, { Field, useForm } from "rc-field-form";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardBody, CardHeader, Row } from "reactstrap";
import useSWR from "swr";
import Button from "../../../../../components/Button";
import Loading from "../../../../../components/Loaders/Loading";
import useApi from "../../../../../utils/api";
import { useCompany } from "../../../../../utils/hooks/company";
import Body from "./Body";
import { config } from "./config";
import Header from "./Header";
import AppLoader from "../../../../../components/Loaders/AppLoader";


/**
 * 
 * @param {object} props 
 * @param {'profile' | 'company' | 'mainCompany'} props.page 
 * @returns 
 */
function Index({
    values,
    loading,
    submitting,
    submit,
    page,
}) {
    const { t } = useTranslation();
    const company = useCompany();
    const { authGet } = useApi();
    const {
        data: mainCompanyPreferences,
        isLoading: isLoadingMainCompanyPreferences,
    } = useSWR(
        () => {
            if (["profile", "company"].includes(page)) {
                if (company.isMasterCompany) {
                    return `/preferencies/company/${company.id}?preferenceKey=notifications`;
                }
                return `/preferencies/company/${company.masterCompanyId}?preferenceKey=notifications`;
            }
            return null;
        },
        async (url) => {
            try {
                return (await authGet(url))?.result?.[0]?.config || null;
            } catch (err) {
                return null;
            }
        },
    );
    const {
        data: companyPreferences,
        isLoading: isLoadingCompanyPreferences,
    } = useSWR(
        () => {
            if (company.isMasterCompany) {
                return null;
            }
            if (page === "profile") {
                return `/preferencies/company/${company.id}?preferenceKey=notifications`;
            }
            return null;
        },
        async (url) => {
            try {
                return (await authGet(url))?.result?.[0]?.config || null;
            } catch (err) {
                return null;
            }
        },
    );

    if (isLoadingMainCompanyPreferences || isLoadingCompanyPreferences) {
        return <AppLoader>{t('loading-company-notification-settings')}</AppLoader>
    }

    return (
        <Content {...{ values, loading, submitting, submit, page, mainCompanyPreferences, companyPreferences }} />
    );
}

/**
 * 
 * @param {object} props 
 * @param {'profile' | 'company' | 'mainCompany'} props.page 
 * @param {object} props.mainCompanyPreferences 
 * @param {object} props.companyPreferences 
 * @returns 
 */
function Content({
    values,
    loading,
    submitting,
    submit,
    page,
    mainCompanyPreferences,
    companyPreferences,
}) {
    const { t } = useTranslation();
    const [form] = useForm();
    const [type, setType] = useState("notification");

    const onFinish = useCallback((data) => {
        submit(data);
    }, [submit]);

    useEffect(() => {
        const initialValues = Object
            .entries(config)
            .reduce((total, [key, value]) => {
                total[key] = Object
                    .entries(value)
                    .reduce((_total, [_key, _value]) => {
                        return {
                            ..._total,
                            [_key]: _value.reduce((total, item) => {
                                const mainCompanyConfig = mainCompanyPreferences
                                    ? get(mainCompanyPreferences, [key, _key, item.type], null)
                                    : null;
                                const companyConfig = companyPreferences
                                    ? get(companyPreferences, [key, _key, item.type], null)
                                    : null;

                                if (
                                    page === "company"
                                    && mainCompanyConfig?.force
                                ) {
                                    return {
                                        ...total,
                                        [item.type]: mainCompanyConfig,
                                    };
                                }
                                if (
                                    page === "profile"
                                    && (mainCompanyConfig?.force || companyConfig?.force)
                                ) {
                                    let value;
                                    if (mainCompanyConfig?.force) {
                                        value = { ...mainCompanyConfig };
                                    }
                                    if (companyConfig) {
                                        value = { ...companyConfig };
                                    }
                                    delete value.force;
                                    return {
                                        ...total,
                                        [item.type]: value,
                                    };
                                }

                                const defaultValues = {};
                                if (item.email) {
                                    defaultValues.email = false;
                                }
                                if (item.push) {
                                    defaultValues.push = false;
                                }
                                if (item.text) {
                                    defaultValues.text = false;
                                }
                                if (item.duration) {
                                    defaultValues.value = null;
                                }
                                if (item.day) {
                                    defaultValues.day = "sunday";
                                }
                                if (["mainCompany", "company"].includes(page)) {
                                    defaultValues.force = false;
                                }
                                let value = get(values?.notifications, [key, _key, item.type])
                                    || companyConfig
                                    || mainCompanyConfig
                                    || {};
                                return {
                                    ...total,
                                    [item.type]: merge(defaultValues, value),
                                };
                            }, {}),
                        };
                    }, {});
                return total;
            }, {});
        form.setFieldsValue(initialValues);
    }, [form, values?.notifications, type, page, mainCompanyPreferences, companyPreferences]);

    return (
        <div className="h-100 m-0 p-0" key={type}>
            <Form className="h-100" form={form} onFinish={onFinish}>
                <CardHeader className="border-0 mb-0 pb-0">
                    <Row className="d-flex align-items-center justify-content-between mx-2 flex-wrap">
                        <Header
                            values={values?.notifications}
                            form={form}
                            type={type}
                            setType={setType}
                        />
                        <Button
                            color="primary"
                            className="text-sm px-5 mb-1"
                            type="submit"
                            loading={submitting}
                        >
                            {t("save")}
                        </Button>
                    </Row>
                </CardHeader>

                <CardBody style={{ height: "calc(100% - 85px)", overflowY: "auto" }}>
                    {loading ? (
                        <div className="d-flex align-items-center justify-content-center h-50 w-100">
                            <Loading />
                        </div>
                    ) : (
                        <Body {...{type, page, mainCompanyPreferences, companyPreferences}} />
                    )}
                </CardBody>

                <Field name="notification" />
                <Field name="reminder" />
                <Field name="email" />
            </Form>
        </div>
    );
}

export default Index;
