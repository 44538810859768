import { useCallback, useEffect } from "react";
import FormElement from "rc-field-form";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useCompanyTimeZone, useIsMasterCompany } from "../../../../../utils/hooks/company";
import { usePayCodesMap } from "./hooks/usePayCodeMap";
import { useOvertimePayCodes } from "./hooks/useOvertimePayCodes";
import { useRegularPayCode } from "./hooks/useRegularPayCode";
import { useUnallocatedPayCode } from "./hooks/useUnallocatedPayCode";
import { onFormProviderFinish } from "./onFormProviderFinish";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { initialValues } from "./initialValues";
import { applyTimezone } from "../../../../../utils/helpers/date";

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const timezone = useCompanyTimeZone();
	const { access } = useModuleAccess("settings.payPolicies.calculationGroup");
	const disabled = useIsFieldDisabled({ ...access, mode });

	const payCodesMap = usePayCodesMap();
	const overtimeMap = useOvertimePayCodes(payCodesMap);
	const regularPayCode = useRegularPayCode(payCodesMap);
	const unallocatedPayCode = useUnallocatedPayCode(payCodesMap);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onFinish = useCallback(
		(formValues) => {
			const {
				schedule: {
					shiftStart,
					shiftEnd,
					status,
					amount
				}
			} = formValues;

			const data = {
				...formValues,
				mealPremium: {
					...formValues?.mealPremium,
					id: formValues?.mealPremium?.id?.id
				},
				shiftPremium: {
					...formValues?.shiftPremium,
					id: formValues?.shiftPremium?.id?.id
				},
				clockException: {
					...formValues?.clockException,
					id: formValues?.clockException?.id?.id
				},
				amountPerUnit: {
					...formValues?.amountPerUnit,
					id: formValues?.amountPerUnit?.id?.id
				},
				schedule: (shiftEnd || shiftStart || (status && amount)) && {
					...formValues.schedule,
					shiftStart: shiftStart
						&& timezone
						? applyTimezone(shiftStart, timezone, true).toISOString(true)
						: shiftStart,
					shiftEnd: shiftEnd
						&& timezone
						? applyTimezone(shiftEnd, timezone, true).toISOString(true)
						: shiftEnd,
				},
				rounding: formValues?.rounding ? formValues.rounding : 0,
			};
			submit(data);
		},
		[submit, timezone],
	);

	useEffect(() => {
		form.setFieldsValue({
			...values,
			status: values?.status ? values?.status : "active",
			startDay: values?.startDay || initialValues.startDay,
			isMain: values?.isMain || false,
			preventTimeClockToTimesheet: values?.preventTimeClockToTimesheet || false,
			splitOvernight: values?.splitOvernight || false,
			weightedRate: values?.weightedRate || false,
			showUnallocatedDefaultPayCode: values?.showUnallocatedDefaultPayCode || false,
			breaks: values?.breaks ? values?.breaks : initialValues.breaks,
			useEmployeeTypeAsDefaultPayCode:
				values?.useEmployeeTypeAsDefaultPayCode,
			timesheetMethod: values?.timesheetMethod || "team",
			mealPremium: values?.mealPremium ? values.mealPremium : initialValues.mealPremium,
			shiftPremium: values?.shiftPremium ? values.shiftPremium : initialValues.shiftPremium,
			clockException: values?.clockException ? values.clockException : initialValues.clockException,
			amountPerUnit: values?.amountPerUnit ? values.amountPerUnit : initialValues.amountPerUnit,
			mealViolationRule: values?.mealViolationRule
				? values.mealViolationRule
				: initialValues.mealViolationRule,
			unionRules: values?.unionRules
				? values.unionRules
				: initialValues.unionRules,
			mealAllowance: values?.mealAllowance ? values?.mealAllowance : initialValues?.mealAllowance,
			overtime: { ...initialValues?.overtime, ...values?.overtime },
			guarantee: {
				...initialValues?.guarantee,
				...values?.guarantee,
			},
			holidayRules: { ...initialValues.holidayRules, ...values?.holidayRules },
			rounding: values?.rounding ? values.rounding : 0,
			gpsLocation: values?.gpsLocation ? values.gpsLocation : false,
			geofenceLocation: values?.geofenceLocation
				? values.geofenceLocation
				: false,
			signature: values?.signature ? values.signature : false,
			webcamOnMobile: values?.webcamOnMobile
				? values.webcamOnMobile
				: false,
			regularDefaultPayCode: values?.regularDefaultPayCode
				? values.regularDefaultPayCode
				: regularPayCode,
			unallocatedDefaultPayCode: values?.unallocatedDefaultPayCode
				? values.unallocatedDefaultPayCode
				: unallocatedPayCode,
			color: values?.color ? values.color : "#010a63",
			schedule: {
				...values?.schedule,
				shiftStart: values?.schedule?.shiftStart
					&& moment.parseZone(values?.schedule?.shiftStart),
				shiftEnd: values?.schedule?.shiftEnd
					&& moment.parseZone(values?.schedule?.shiftEnd)
			},
		});
	}, [form, values, regularPayCode, unallocatedPayCode]);

	useEffect(() => {
		if (!overtimeMap || !regularPayCode || values) {
			return;
		}

		const overtimeRules = form.getFieldValue("overtime");
		for (const type in overtimeRules) {
			const overtimeRule = overtimeRules[type];
			const defaultForType = overtimeMap[type];
			overtimeRule.defaultPayCode = defaultForType;
			overtimeRule.payCodes = [regularPayCode];
		}

		form.setFieldValue("overtime", overtimeRules);
	}, [form, overtimeMap, regularPayCode, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"code",
				"description",
				"status",
				"color",
				"isMain",
				"unallocatedDefaultPayCode",
				"regularDefaultPayCode",
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement.FormProvider onFormFinish={onFormProviderFinish}>
			<FormElement
				form={form}
				className="d-flex flex-column justify-content-between h-100"
				onFinish={onFinish}
				name="calculationGroup"
			>
				<div>
					<Content
						form={form}
						mode={mode}
						disabled={disabled || !isMasterCompany}
						rounding={values?.rounding}
						schedule={values?.schedule}
					/>
				</div>

				<div className="d-flex justify-content-end">
					<Button
						onClick={onClose}
						disabled={loading}
						className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					>
						{t("cancel")}
					</Button>

					{(!disabled && isMasterCompany) && (
						<Button
							type="submit"
							className="btn-dark btn-sm shadow-none"
							loading={loading}
						>
							{t("save")}
						</Button>
					)}
				</div>
			</FormElement>
		</FormElement.FormProvider>
	);
}

export default Form;
