import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Disclosure } from "@headlessui/react";
import { List, Field as BaseField } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import Select from "../../../../../components/Inputs/Select";
import Button from "../../../../../components/Button";
import Switch from "../../../../../components/Inputs/Switch";
import { renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import "./style.scss";

export const ListWorkflow = ({ data, selected }) => {
    const { t } = useTranslation();

    const filteredData = useMemo(() => {
        const selectedIds = selected?.map(({ id }) => id) || [];

        return data?.filter((item) => selectedIds.includes(item.id) && item.type === "select");
    }, [data, selected]);

    return filteredData?.length > 0 ? (
        <>
            <BaseField shouldUpdate>
                {({}, {}, { getFieldValue, setFieldValue }) => {
                    let workflows = getFieldValue("workflows");

                    const onChange = (e) => {
                        if (!e.target.checked) {
                            workflows = workflows.map((workflow) => ({
                                ...workflow,
                                enabled: false,
                            }));
                            setFieldValue("workflows", workflows);
                        }
                    };

                    return (
                        <Field name="enableWorkflows" label={t("workflows")} valuePropName="checked">
                            <Switch id="enableWorkflows" onChange={onChange} />
                        </Field>
                    );
                }}
            </BaseField>

            <List name="workflows">
                {(fields, { add, remove }) => {
                    return (
                        <div className="w-100">
                            {fields.map(({ key, name }) => {
                                return (
                                    <Disclosure defaultOpen={true} key={key}>
                                        {({ open }) => (
                                            <div className="mb-3">
                                                <Disclosure.Button className="disclosure-button-style">
                                                    <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />

                                                    <FontAwesomeIcon
                                                        className="text-red cursor-pointer"
                                                        icon={faTrash}
                                                        onClick={() => remove(name)}
                                                    />
                                                </Disclosure.Button>

                                                <Disclosure.Panel className="disclosure-panel-style">
                                                    <div className="d-flex gap-3">
                                                        <Field
                                                            name={[name, "customFieldCode"]}
                                                            label={t("select-custom-field")}
                                                            className="w-100"
                                                        >
                                                            <Select>
                                                                {filteredData?.map((item) => {
                                                                    return (
                                                                        <Select.Option value={item.code}>
                                                                            {renderSettingsResourceLabel(item)}
                                                                        </Select.Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </Field>

                                                        <BaseField shouldUpdate>
                                                            {({}, {}, { getFieldValue }) => {
                                                                const customFieldCode = getFieldValue([
                                                                    "workflows",
                                                                    name,
                                                                    "customFieldCode",
                                                                ]);
                                                                const customField = filteredData.find(
                                                                    (item) => item.code === customFieldCode
                                                                );

                                                                return (
                                                                    customField?.options?.length > 0 && (
                                                                        <Field
                                                                            name={[name, "value"]}
                                                                            label={t("option")}
                                                                            className="w-100"
                                                                        >
                                                                            <Select>
                                                                                {customField.options.map(
                                                                                    (item) => {
                                                                                        return (
                                                                                            <Select.Option
                                                                                                value={item.value}
                                                                                            >
                                                                                                {item.label}
                                                                                            </Select.Option>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            </Select>
                                                                        </Field>
                                                                    )
                                                                );
                                                            }}
                                                        </BaseField>
                                                    </div>

                                                    <Field name={[name, "operator"]} label={t("operator")}>
                                                        <Select>
                                                            <Select.Option value="equals">
                                                                {t("equal")}
                                                            </Select.Option>
                                                            <Select.Option value="notEquals">
                                                                {t("not-equal")}
                                                            </Select.Option>
                                                        </Select>
                                                    </Field>

                                                    <Field
                                                        name={[name, "title"]}
                                                        label={t("title")}
                                                        className="w-100"
                                                    >
                                                        <Text />
                                                    </Field>

                                                    <Field
                                                        name={[name, "body"]}
                                                        label={t("body")}
                                                        className="w-100"
                                                    >
                                                        <Text type="textarea" />
                                                    </Field>
                                                    
                                                    <div>
                                                        <span>{t("allowed-variables-for-title-and-body")}</span>

                                                        <ol type="i" style={{ columns: 2, columnGap: 16 }}>
                                                            <li>{`{{first-name}}`}</li>
                                                            <li>{"{{last-name}}"}</li>
                                                            <li>{"{{employee-number}}"}</li>
                                                            <li>{"{{manager-first-name}}"}</li>
                                                            <li>{"{{manager-last-name}}"}</li>
                                                            <li>{"{{manager-employee-number}}"}</li>
                                                        </ol>

                                                        <BaseField shouldUpdate>
                                                            {({}, {}, { getFieldValue }) => {
                                                                const enableWorkflows = getFieldValue("enableWorkflows")

                                                                return (
                                                                    <Field name={[name, "enabled"]} valuePropName="checked">
                                                                        <Switch id="enabled" disabled={!enableWorkflows}>{t("status")}</Switch>
                                                                    </Field>
                                                                );
                                                            }}
                                                        </BaseField>
                                                    </div>
                                                </Disclosure.Panel>
                                            </div>
                                        )}
                                    </Disclosure>
                                );
                            })}

                            <Button className="w-100" color="primary" onClick={() => add()}>
                                {t("add")} +
                            </Button>
                        </div>
                    );
                }}
            </List>
        </>
    ) : null;
};
