import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { Input } from "reactstrap";
import Modal from "../../../../../../../components/Modal";
import Field from "../../../../../../../components/Field";
import WeekdaySelect from "../../../../../../../components/Inputs/WeekdaySelect";
import Button from "../../../../../../../components/Button";

function ClockInRuleModal({ isOpen, values, close, disabled }) {
	const { t } = useTranslation();

	return (
		<Modal
			title={t("round-based-next-full-time")}
			isOpen={isOpen}
			centered
		>
			<Form
				name="clock-in-rule-union"
				onFinish={close}
				labelAlign="left"
				labelCol={{ span: 8 }}
				wrapperCol={{ span: 14, offset: 2 }}
				colon={false}
				initialValues={{ unionRules: values }}
				scrollToFirstError
			>
				<Field
					name={["unionRules", "clockIn", "amount"]}
					label={t("clock-in-before-or-after")}
					rules={[
						{
							required: true,
							message: t("required-clock-in-amount"),
						},
						{
							validator(_, value) {
								if (Number(value) < 0) {
									return Promise.reject(new Error(t("amount-bigger-than-zero")))
								}
								return Promise.resolve()
							}
						}
					]}
				>
					<Input type="number" disabled={disabled} step="0.1" />
				</Field>

				<Field
					name={["unionRules", "clockIn", "clockOutAmount"]}
					label={t("clock-out-before-or-after")}
					rules={[
						{
							required: true,
							message: t("requierd-clock-out-amount"),
						},
						{
							validator(_, value) {
								if (Number(value) < 0) {
									return Promise.reject(new Error(t("amount-bigger-than-zero")))
								}
								return Promise.resolve()
							}
						}
					]}
				>
					<Input type="number" disabled={disabled} step="0.1" />
				</Field>

				<WeekdaySelect
					name={["unionRules", "clockIn", "days"]}
					label={t("dont-run-this-rules-these-days")}
					mode="multiple"
					disabled={disabled}
				/>

				<div className="d-flex justify-content-between my-2">
					{disabled ? (
						<div />
					) : (
						<Button
							type="submit"
							color="primary"
							size="xs"
							className="bg-dark"
						>
							{t("save-changes")}
						</Button>
					)}

					<Button
						size="small"
						className="border-primary-color mr-1"
						onClick={close}
					>
						{t("close")}
					</Button>
				</div>
			</Form>
		</Modal>
	);
}

export default ClockInRuleModal;
