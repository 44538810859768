import {
	faGears,
	faLayerGroup,
	faPeopleLine,
	faBuilding,
	faLocationDot,
	faPeopleRoof,
	faUser,
	faListCheck,
	faUsersRectangle,
	faBriefcase,
	faPersonChalkboard,
	faPeopleGroup,
	faFileInvoice,
	faSackDollar,
	faGifts,
	faStopwatch,
	faCalculator,
	faHome,
	faClone,
	faClock,
	faChildReaching,
	faCircleNodes,
	faReceipt,
	faTableList,
	faPhone,
	faQrcode,
	faProjectDiagram,
	faUserGroup,
	faUserClock,
	faBriefcaseClock,
	faPersonCircleCheck,
	faU,
	faTextHeight,
	faBook,
	faRectangleList,
	faUsersBetweenLines,
	faCalendarDays,
	faClipboardList,
	faFolderTree,
	faFileCode,
	faUserGear,
	faImages,
	faEnvelope,
	faCalendarCheck,
	faCoins,
	faCalendarWeek,
	faCalendar,
	faMugHot,
	faMessage,
	faComment,
	faBell,
	faFileLines,
	faMoneyBills,
	faUsers,
	faFileInvoiceDollar,
	faFileExport,
	faIdCard,
	faVenusMars,
	faAddressBook,
	faPersonRays,
	faCertificate,
	faBorderNone,
} from "@fortawesome/free-solid-svg-icons";
import { useMemo } from "react";
import { useUser } from "../../utils/hooks/user";
import { getViewPermissions } from "../../utils/helpers/access";
import { useAccess, useAccessInList } from "../../utils/hooks/access";
import { useCompany } from "../../utils/hooks/company";

export const useSidebarConfig = () => {
	const user = useUser();
	const accessMap = user?.permissions;
	const company = useCompany();

	const modules = useMemo(() => {
		return company?.settings?.modules || [];
	}, [company?.settings?.modules]);

	const generalSettings = useMemo(
		() =>
			accessMap?.settings?.general
				? getViewPermissions(accessMap?.settings?.general, "settings.general")
				: [],
		[accessMap?.settings?.general]
	);

	const { hasAccess: canViewGeneralSettings } =
		useAccessInList(generalSettings);

	const { hasAccess: canViewConfiguration } = useAccessInList([
		'settings.configuration.canView',
		'settings.configuration.rate.canView',
	]);

	const { hasAccess: canManageUsers } = useAccess('canManageUsers');

	const timeClockPermissions = useMemo(
		() =>
			accessMap?.clockin
				? getViewPermissions(accessMap?.clockin, 'clockin')
				: [],
		[accessMap?.clockin]
	);
	const { hasAccess: canViewClockin } = useAccessInList(timeClockPermissions);

	const { hasAccess: canViewTimesheet } = useAccessInList([
		'timeSheet.canViewMyTimesheet',
		'timeSheet.canView',
	]);

	const { hasAccess: canViewScheduler } = useAccessInList([
		"schedule.canView",
		"schedule.canViewMySchedule"
	])

	const { hasAccess: canViewRequest } = useAccessInList([
		// "request.canViewTeamRequest",
		"request.canView",
	]);

	const { hasAccess: canViewTimeOff } = useAccessInList([
		"timeOff.canViewMyTeamTimeOff",
		"timeOff.canViewMyTimeOff"
	]);

	const payPoliciesSettings = useMemo(
		() =>
			accessMap?.settings?.payPolicies
				? getViewPermissions(
						accessMap?.settings?.payPolicies,
						'settings.payPolicies'
				  )
				: [],
		[accessMap?.settings?.payPolicies]
	);
	const { hasAccess: canViewPayPolicies } =
		useAccessInList(payPoliciesSettings);

	const integrationSettings = useMemo(
		() =>
			accessMap?.settings?.integration
				? getViewPermissions(
						accessMap?.settings?.integration,
						'settings.integration'
				  )
				: [],
		[accessMap?.settings?.integration]
	);

	const { hasAccess: canViewIntegration } =
		useAccessInList(integrationSettings);

	const { hasAccess: canViewClockinSettings } = useAccessInList([
		"device.canView",
		"qrCode.canView",
		"whitelistNumber.canView",
		"settings.flyer.canView",
		"settings.attestation.canView"
	]);

	const { hasAccess: canViewGroup } = useAccessInList([
		"settings.general.organizationLevelGroup.canView",
		"settings.general.organizationLevelGroup.organizationLevel.canView",
	]);

	const customFieldSettings = useMemo(
		() =>
			accessMap?.settings?.general?.customFields
				? getViewPermissions(
						accessMap?.settings?.general?.customFields,
						'settings.general.customFields'
				  )
				: [],
		[accessMap?.settings?.general?.customFields]
	);

	const { hasAccess: canViewCustomFields } =
		useAccessInList(customFieldSettings);

	const ruleSettings = useMemo(
		() =>
			accessMap?.settings?.rules
				? getViewPermissions(accessMap?.settings?.rules, 'settings.rules')
				: [],
		[accessMap?.settings?.rules]
	);

	const schedulingSettings = useMemo(
		() =>
			accessMap?.settings?.scheduling
				? getViewPermissions(
						accessMap?.settings?.scheduling,
						'settings.scheduling'
				  )
				: [],
		[accessMap?.settings?.scheduling]
	);

	const { hasAccess: canViewUserDemographics } = useAccess("settings.userDemographic.canView") ;

	const { hasAccess: canViewRules } = useAccessInList(ruleSettings);
	const { hasAccess: canViewScheduling } = useAccessInList(schedulingSettings);

	const { hasAccess: canViewReports } = useAccess("reports.canView");

	const { hasAccess: canViewBillingRate } = useAccess("billingRate.invoices.canView");

	const canViewCalRules = useAccess("settings.payPolicies.calculationGroup.canView");

	const { hasAccess: canViewCommunication } = useAccess('communication.canView')

	return useMemo(
		() => [
			{
				index: true,
				title: 'dashboard',
				icon: faHome,
				permissions: ['dashboard.canView'],
			},
			{
				path: 'people',
				icon: faUser,
				permissions: ['people.canView'],
			},
			{
				path: 'time-clock',
				icon: faClock,
				access: canViewClockin && modules.includes("timeClock"),
				children: canManageUsers
					? [
							{
								index: true,
								path: 'team',
								title: 'my-team',
								icon: faUserGroup,
								permissions: ['canManageUsers'],
							},
							{
								path: 'my',
								title: 'my-shift',
								icon: faUserClock,
								permissions: ['clockin.canView'],
							},
							{
								path: 'original-clocks',
								title: 'original-clocks',
								icon: faBriefcaseClock,
								permissions: ['clockin.canViewOriginalClock'],
							},
					  ]
					: [
							{
								index: true,
								path: 'my',
								title: 'my-shift',
								icon: faUserClock,
								permissions: ['clockin.canView'],
							},
							{
								path: 'original-clocks',
								title: 'original-clocks',
								icon: faBriefcaseClock,
								permissions: ['clockin.canViewOriginalClock'],
							},
					  ],
			},
			{
				path: 'time-sheet',
				icon: faClone,
				access: canViewTimesheet && modules.includes("timesheet"),
				children: canManageUsers
					? [
						{
							index: true,
							path: "team",
							title: "my-team",
							icon: faUserGroup,
							permissions: ["timeSheet.canView"],
						},
						{
							path: "weekly",
							title: "weekly",
							icon: faCalendarWeek,
							permissions: ["timeSheet.canView"],
						},
						{
							path: "manager-view",
							title: "manager-view",
							icon: faUsersBetweenLines,
							permissions: ["timeSheet.canView"],
						},
						{
							path: "my",
							title: "my-timesheet",
							icon: faUserClock,
							permissions: ["timeSheet.canViewMyTimesheet"],
						},
					]
					: [
							{
								index: true,
								path: 'my',
								title: 'my-timesheet',
								icon: faUserClock,
								permissions: ['timeSheet.canViewMyTimesheet'],
							},
					  ],
			},
			{
				path: "scheduler",
				icon: faCalendarCheck,
				access: canViewScheduler && modules.includes("schedule"),
				children: canManageUsers
					? [
						{
							index: true,
							path: "team",
							title: "my-team",
							icon: faUserGroup,
							permissions: ["schedule.canView"],
						},
						{
							path: "my",
							title: "my-schedule",
							icon: faUserClock,
							permissions: ["schedule.canViewMySchedule"],
						},
						{
							path: "team-template",
							title: "my-team-template",
							icon: faUserGroup,
							permissions: ["schedule.canView"],
						},
					]
					: [
						{
							index: true,
							path: "my",
							title: "my-schedule",
							icon: faUserClock,
							permissions: ["schedule.canViewMySchedule"],
						},
					],
			},
			{
				path: "time-off",
				icon: faStopwatch,
				access: canViewTimeOff && modules.includes("timeOff"),
				children: [
					{
						path: "team",
						title: "my-team-timeoff",
						icon: faUserGroup,
						permissions: ["timeOff.canViewMyTeamTimeOff"]
					},
					{
						index: true,
						path: "my",
						title: "my-timeoff",
						icon: faUserClock,
						permissions: ["timeOff.canViewMyTimeOff"], 
					}
				],
			},
			{
				path: "request",
				icon: faChildReaching,
				access: canViewRequest && modules.includes("requests"),
				children: [
					{
						index: true,
						path: "clock",
						title: "clock-request",
						icon: faClock,
						permissions: ["request.canView"],
					},
					{
						path: "pto",
						title: "pto-request",
						icon: faCalendarDays,
						permissions: ["request.canView"],
					},
					{
						path: "schedule",
						title: "schedule-request",
						icon: faCalendarWeek,
						permissions: ["request.canView"],
					},
					{
						path: "availability",
						title: "availability",
						icon: faFileInvoice,
						permissions: ["request.canView"],
					},
				]
			},
			{
				path: "reports",
				icon: faTableList,
				access: canViewReports && modules.includes("reports"),
				children: [
					{
						index: true,
						path: "custom-reports",
						title: "custom-reports",
						icon: faFileLines,
						permissions: ['reports.canView'],
					},
					{
						path: "exports",
						title: "export",
						icon: faFileExport,
						permissions: ["reports.canView"],
					},
					{
						path: 'old-system-reports',
						permissions: ['reports.canView'],
						title: 'old-system-reports',
						icon: faFolderTree,
					},
					{
						path: 'old-custom-reports',
						title: 'old-custom-reports',
						permissions: ['reports.canView'],
						icon: faFileCode,
					},
				],
			},
			{
				path: "billing",
				icon: faMoneyBills,
				access: canViewBillingRate && modules.includes("billingRate"),
				children: [
					{
						index: true,
						path: "clients",
						permissions: ["billingRate.clients.canView"],
						icon: faUsers,
					},
					// {
					// 	path: "reports",
					// 	permissions: ["billingRate.reports.canView"],
					// 	icon: faFileCode,
					// },
					// {
					// 	path: "invoices",
					// 	permissions: ["billingRate.invoices.canView"],
					// 	icon: faFileInvoiceDollar,
					// },
				],
			},
			{
				path: 'communication',
				icon: faMessage,
				access: canViewCommunication && modules.includes("communication"),
				children: [
					{
						index: true,
						path: 'messages',
						title: 'messages',
						permissions: ['communication'],
						icon: faComment
					},
					{
						path: 'posts',
						title: 'posts',
						permissions: ['communication'],
						icon: faUsersRectangle
					}
				]
			},
			{
				type: "divider",
				access:
					!canViewGeneralSettings &&
					!canViewClockinSettings &&
					!canViewPayPolicies &&
					!canViewIntegration &&
					!canViewConfiguration &&
					!canViewScheduling &&
					!canViewRules &&
					!canViewCustomFields &&
					!canViewUserDemographics
			},
			{
				type: 'title',
				title: 'settings',
				access:
					!canViewGeneralSettings &&
					!canViewClockinSettings &&
					!canViewPayPolicies &&
					!canViewIntegration &&
					!canViewConfiguration &&
					!canViewScheduling &&
					!canViewRules &&
					!canViewCustomFields &&
					!canViewUserDemographics
			},
			{
				path: 'settings',
				inSidebar: false,
				children: [
					{
						path: 'general',
						icon: faGears,
						access: canViewGeneralSettings,
						children: [
							{
								index: true,
								inSidebar: false,
							},
							{
								path: 'groups',
								icon: faLayerGroup,
								access: canViewGroup,
								children: [
									{ index: true, inSidebar: false },
									{
										path: ':id/levels',
										permissions: [
											'settings.general.organizationLevelGroup.organizationLevel.canView',
										],
									},
								],
							},
							{
								path: 'companies',
								icon: faBuilding,
								permissions: ['settings.general.companies.canView'],
							},
							{
								path: "scheduler",
								icon: faCalendarDays,
								permissions: [
									"settings.general.canView"
								]
							},
							{
								path: "locations",
								icon: faLocationDot,
								permissions: ['settings.general.locations.canView'],
							},
							{
								path: 'employee-type',
								icon: faPeopleLine,
								permissions: ['settings.general.employeeTypes.canView'],
							},
							{
								path: 'user-group',
								icon: faUserGroup,
								permissions: ['settings.general.userGroup.canView'],
							},
							{
								path: 'union',
								icon: faU,
								permissions: ['settings.general.union.canView'],
							},
							{
								path: 'projects',
								icon: faRectangleList,
								permissions: ['settings.general.project.canView'],
							},
							{
								path: 'job-groups',
								icon: faUsersRectangle,
								permissions: ['settings.general.jobGroups.canView'],
							},
							{
								path: 'job-families',
								icon: faPeopleRoof,
								permissions: ['settings.general.jobFamilies.canView'],
							},
							{
								path: 'jobs',
								icon: faBriefcase,
								permissions: ['settings.general.jobs.canView'],
							},
							{
								path: "working-shift",
								icon: faCalendar,
								permissions: [
									"settings.general.workingShifts.canView",
								],
							},
							{
								path: "tasks",
								icon: faListCheck,
								permissions: ['settings.general.tasks.canView'],
							},
							{
								path: 'roles',
								icon: faPersonChalkboard,
								permissions: ['settings.general.roles.canView'],
							},
							{
								path: "notification-settings",
								icon: faBell,
								permissions: ["notificationSettings.canManage"],
							},
							{
								path: "skills",
								icon: faPersonRays,
								permissions: ["settings.general.skill.canView"],
							},
							{
								path: "certifications",
								icon: faCertificate,
								permissions: ["settings.general.certification.canView"]
							},
							{
								path: "licenses",
								icon: faIdCard,
								permissions: ["settings.general.license.canView"]
							},
						],
					},
					{
						path: 'pay-policy',
						icon: faReceipt,
						access: canViewPayPolicies,
						children: [
							{
								path: 'pay-group',
								icon: faPeopleGroup,
								permissions: ['settings.payPolicies.payGroup.canView'],
							},
							{
								path: 'pay-code',
								icon: faFileInvoice,
								permissions: ['settings.payPolicies.payCode.canView'],
							},
							{
								path: 'earning-group',
								icon: faFileInvoice,
								permissions: ['settings.payPolicies.earningGroup.canView'],
							},
							{
								path: 'budget',
								icon: faSackDollar,
								permissions: ['settings.payPolicies.budget.canView'],
							},
							{
								path: "holiday-calendar",
								icon: faGifts,
								permissions: ['settings.payPolicies.holidays.canView'],
							},
							{
								path: 'time-off',
								icon: faStopwatch,
								permissions: ['settings.payPolicies.timeOff.canView'],
							},
							{
								path: "calculation-rules",
								icon: faBook,
								access: canViewCalRules,
								children: [
									{
										path: "meal-premium",
										icon: faMugHot,
										permissions: [
											"settings.payPolicies.calculationGroup.canView",
										],
									},
									{
										path: "clock-exceptions",
										icon: faClock,
										permissions: [
											"settings.payPolicies.calculationGroup.canView",
										],
									},
									{
										path: "shift-premium",
										icon: faCalculator,
										permissions: [
											"settings.payPolicies.calculationGroup.canView",
										],
									},
									{
										path: "amount-per-unit",
										icon: faBorderNone,
										permissions: [
											"settings.payPolicies.calculationGroup.canView",
										],
									},
								],
							},
							{
								path: "calculation-group",
								icon: faCalculator,
								permissions: ['settings.payPolicies.calculationGroup.canView'],
							},
						],
					},
					{
						path: 'clock-in',
						icon: faClock,
						access: canViewClockinSettings,
						children: [
							{
								path: 'whitelist-number',
								icon: faPhone,
								permissions: ['whitelistNumber.canView'],
							},
							{
								path: 'qr-code',
								icon: faQrcode,
								permissions: ['qrCode.canView'],
							},
							{
								path: 'device',
								icon: faProjectDiagram,
								permissions: ['device.canView'],
							},
							{
								path: 'flyers',
								icon: faImages,
								permissions: ['settings.flyer.canView'],
							},
							{
								path: "attestation",
								icon: faEnvelope,
								permissions: ["settings.attestation.canView"],
							},
						],
					},
					{
						path: 'custom-fields',
						icon: faTextHeight,
						access: canViewCustomFields,
						children: [
							{
								path: 'timesheet',
								icon: faUserClock,
								permissions: ['settings.general.customFields.canView'],
							},
							{
								path: "clock-in",
								icon: faClock,
								permissions: [
									"settings.general.customFields.canView",
								],
							},
						],
					},
					{
						path: 'integration',
						icon: faCircleNodes,
						access: canViewIntegration,
						children: [
							{
								path: 'ultipro',
								icon: faPersonCircleCheck,
								permissions: ['settings.integration.ultipro.canView'],
							},
							{
								path: 'uta',
								icon: faPersonChalkboard,
								permissions: ['settings.integration.uta.canView'],
							},
						],
					},
					{
						path: 'rules',
						icon: faBook,
						access: canViewRules,
						children: [
							{
								path: 'calculation-group',
								icon: faCalculator,
								permissions: ['settings.rules.calculationGroup.canView'],
							},
						],
					},
					{
						path: 'job-scheduling',
						icon: faCalendarDays,
						access: canViewScheduling,
						children: [
							{
								path: 'reports',
								icon: faClipboardList,
								permissions: ['settings.scheduling.reports.canView'],
							},
							{
								path: 'jobs',
								icon: faBriefcaseClock,
								permissions: ['settings.scheduling.jobs.canView'],
							},
						],
					},
					{
						path: "user-demographics",
						icon: faIdCard,
						access: canViewUserDemographics,
						children: [
							{
								path: "gender",
								icon: faVenusMars,
								permissions: ["settings.userDemographic.canView"],
							},
							{
								path: "ethnicity",
								icon: faFileInvoice,
								permissions: ["settings.userDemographic.canView"],
							},
							{
								path: "employment-type",
								icon: faAddressBook,
								permissions: ["settings.userDemographic.canView"],
							},
						],
					},
					{
						path: "black-out-calendar",
						icon: faCalendar,
						permissions: ["settings.configuration.canView"] //TODO permission
					},
					{
						path: "groupings",
						icon: faLayerGroup,
						permissions: ["settings.groupings.canView"], 
					},
					{
						path: "configuration",
						icon: faUserGear,
						access: canViewConfiguration,
						children: canManageUsers
							? [
									{
										path: 'timesheet',
										icon: faClone,
										access: canViewConfiguration,
										children: [
											{
												path: 'team',
												icon: faUserGroup,
												title: 'my-team',
												permissions: ['settings.configuration.canView'],
											},
											{
												path: 'manager-view',
												icon: faUsersBetweenLines,
												permissions: ['settings.configuration.canView'],
											},
											{
												path: 'my',
												icon: faUserClock,
												title: 'my-timesheet',
												permissions: ['settings.configuration.canView'],
											},
											{
												path: 'rate',
												icon: faCoins,
												permissions: ['settings.configuration.canView'],
											},
										],
									},
									{
										path: "schedule",
										icon: faCalendarDays,
										permissions: ["settings.configuration.canView"] 
									}
							  ]
							: [
									{
										path: 'timesheet',
										icon: faClone,
										access: canViewConfiguration,
										children: [
											{
												path: 'my',
												icon: faUserClock,
												title: 'my-timesheet',
												permissions: ['settings.configuration.canView'],
											},
											{
												path: 'rate',
												icon: faCoins,
												permissions: ['settings.configuration.canView'],
											},
										],
									},
								]
					},
				],
			},
		],
		[
			canManageUsers,
			canViewGeneralSettings,
			canViewClockin,
			canViewTimesheet,
			canViewRequest,
			canViewPayPolicies,
			canViewClockinSettings,
			canViewIntegration,
			canViewGroup,
			canViewCustomFields,
			canViewRules,
			canViewScheduling,
			canViewConfiguration,
			canViewReports,
			canViewScheduler,
			canViewCalRules,
			canViewCommunication,
			canViewTimeOff,
			canViewUserDemographics,
			canViewBillingRate,
			modules
		],
	);
};
