import { useEffect, useMemo } from "react";
import { FeatureGroup, MapContainer, Marker, Polygon, TileLayer, useMap } from "react-leaflet";
import L from "leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-path-drag";
import { useQrCodeClockIn } from "../../context";

const icon = L.icon({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
});

const MarkerPosition = ({ marker }) => {
    const map = useMap();

    useEffect(() => {
        if (marker?.lat && marker?.lng) {
            map.setView([marker.lat, marker.lng], map.getZoom());
        }
    }, [marker, map]);

    return marker?.lat && marker?.lng ? <Marker position={[marker.lat, marker.lng]} icon={icon} /> : null;
};

const PolygonComponent = ({ coordinates }) => {
	const polygon = coordinates?.[0]?.map((coordinate) => {
		return {
			lat: coordinate[1],
			lng: coordinate[0],
		};
	});

    return coordinates?.length > 0 ? (
        <FeatureGroup>
            <Polygon positions={polygon} />
        </FeatureGroup>
    ) : null;
};

function QrMap() {
    const { area, position } = useQrCodeClockIn();

    const mapCenter = useMemo(() => [position?.lat || 0, position?.lng || 0], [position]);

    return (
        <MapContainer 
            center={mapCenter} 
            zoom={16} 
            minZoom={5} 
            style={{ 
                height: "100%", 
                width: "100%" 
            }} 
            maxBounds={[
                [-90, -180],
                [90, 180],
            ]}
            maxBoundsViscosity={1.0}
        >
            <TileLayer url={process.env.REACT_APP_MAP_TILE_URL} />
            <MarkerPosition marker={position} />
            <PolygonComponent coordinates={area} />
        </MapContainer>
    );
}

export default QrMap;
