import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { noop } from "lodash";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Card, CardHeader, CardBody } from "reactstrap";
import { useClockIn, useLastClock, useMode, useWorkSummary } from "../store";
import Home from "./Home";
import Clocks from "./Clocks";
import Running from "./Running";
import ClockModal from "../ClockModal";
import Loading from "../../../../components/Loaders/Loading";
import ShiftSummary from "../ShiftSummary";
import useApi from "../../../../utils/api";
import { getErrorMessage } from "../../../../utils/helpers/errors";
import { useTime } from "../../../../utils/hooks/time";
import { useUser } from "../../../../utils/hooks/user";
import "./style.scss";
import { useBreakErrorModal } from "../ClockModal/useBreakErrorModal";

export const useCurrentShift = () => {
	const { t } = useTranslation();
	const { authGet } = useApi();
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line
	const [_, { setWorkSummary }] = useClockIn();

	const fetch = useCallback(
		async (controller) => {
			setLoading(true);
			try {
				const response = await authGet("/shift/get-active", {
					signal: controller.signal,
				});
				setWorkSummary(response);
			} catch (err) {
				getErrorMessage(err, t);
			} finally {
				setLoading(false);
			}
		},
		[setLoading, setWorkSummary, authGet, t],
	);

	useEffect(() => {
		const controller = new AbortController();
		fetch(controller);
		return () => controller.abort();
	}, [fetch]);

	return loading;
};

const ShiftView = ({ setRefetchTimesheet = noop }) => {
	const { t } = useTranslation();
	const mode = useMode();
	const time = useTime();
	const user = useUser();
	const workSummary = useWorkSummary();

	const clocks = useMemo(
		() =>
			(workSummary?.shifts || [])
				.map(({ clocks, note, project, location, job }) =>
					clocks?.map((clock) => ({ ...clock, note, user: workSummary?.user, project, location, job })),
				)
				.flat(),
		[workSummary?.shifts, workSummary?.user],
	);

	const { open: openErrorModal, modal } = useBreakErrorModal();

	const greeting = useMemo(() => {
		const hour = moment(time).hour();
		if (hour >= 0 && hour < 12) {
			return t("good-morning");
		} else if (hour >= 12 && hour < 20) {
			return t("good-afternoon");
		} else {
			return t("good-evening");
		}
	}, [time, t]);

	const modeColor = useMemo(() => {
		switch (mode) {
			case "shift":
			case "break": {
				return "text-info";
			}
			case "home":
			case "end": {
				return "text-danger";
			}
			default:
				return "text-info";
		}
	}, [mode]);

	const loading = useCurrentShift();

	return (
		<Row className="h-100">
			<Col className="h-100 w-100" sm={12} md={12} lg={mode === "home" ? 12 : 8}>
				<Card className="h-100 mb-4 w-100">
					<CardHeader className="py-3 ">
						<Row>
							<Col
								xs="12"
								className="d-flex justify-content-between align-items-center"
							>
								<div>
									<h3 className="mb-0">
										{greeting},{" "}
										<span className="text-info">
											{user?.firstName}
										</span>
									</h3>

									<small className="text-muted">
										{t("have-great-day", { day: moment().format("dddd").toLocaleLowerCase() })}.
									</small>
								</div>

								<div>
									<FontAwesomeIcon
										className={modeColor}
										icon={faClock}
										style={{
											fontSize: "30px",
										}}
									/>
								</div>
							</Col>
						</Row>
					</CardHeader>

					<CardBody className="scroll">
						{loading ? (
							<div className="h-100 d-flex justify-content-center align-items-center">
								<Loading />
							</div>
						) : mode === "home" ? (
							<Home />
						) : (
							<Running />
						)}
					</CardBody>
				</Card>

				<ClockModal setRefetchTimesheet={setRefetchTimesheet} openErrorModal={openErrorModal} />

				<ShiftSummary setRefetchTimesheet={setRefetchTimesheet} />
			</Col>

			{mode !== "home" && <Clocks clocks={clocks} isShiftView={true} />}

			{modal}
		</Row>
	);
};

export default ShiftView;
