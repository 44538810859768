import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../../../../components/Field";
import Checkbox from "../../../../../../../../../components/Inputs/Checkbox";
import Modal from "../../../../../../../../../components/Modal";
import Details from "./Details";
import { firstToUpper } from "../../../../../../../../../utils/helpers/string";

const getFieldName = (name) => ["overtime", "six-consecutive-day", name];

function SixConsecutiveDay({ disabled }) {
	const { t } = useTranslation();

	const [visible, setVisible] = useState(false);

	const openModal = useCallback(() => setVisible(true), [setVisible]);

	const closeModal = useCallback(() => setVisible(false), [setVisible]);

	return (
		<div className="calculation-group-rule">
			<div className="d-flex align-items-center mr-1 w-100">
				<Field
					name={getFieldName("status")}
					valuePropName="checked"
					noStyle
					className="mb-0"
				>
					<Checkbox id="six-consecutive-day" disabled={disabled} >
						{t("six-consecutive-day")}
					</Checkbox>
				</Field>
			</div>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, getFieldError }) => {
					const data = getFieldValue([
						"overtime",
						"six-consecutive-day",
					]);

					const status = data.status;
					const error = getFieldError(getFieldName("value"));

					return (
						<>
							<div className="d-flex align-items-center">
								<Field
									name={getFieldName("value")}
									rules={
										status
											? [
												{
													required: status,
													message: t(
														"required-consecutive-day"
													),
												},
											]
											: undefined
									}
									noStyle
									dependencies={[getFieldName("status")]}
									className="mb-0"
								>
									<Input
										type="number"
										size="sm"
										className="border-primary"
										disabled
									/>
								</Field>

								<BaseField
									name={getFieldName("defaultPayCode")}
									hidden
								/>

								<BaseField
									name={getFieldName("payCodes")}
									hidden
								/>

								<BaseField
									name={getFieldName("hourTypes")}
									hidden
								/>

								<BaseField
									name={getFieldName("overtimes")}
									hidden
								/>

								<BaseField
									name={getFieldName("calculateOtherOvertime")}
									hidden
								/>

								<p className="text-sm mx-2 mb-0 text-center">
									{firstToUpper(t("day"))}
								</p>
							</div>

							<div className="d-flex align-items-center justify-content-end">
								<FontAwesomeIcon
									className="text-sm cursor-pointer"
									icon={faPen}
									onClick={(status || disabled) ? openModal : undefined}
								/>
							</div>

							{error && (
								<p className="text-danger text-sm">{error}</p>
							)}

							{visible && (
								<Modal
									title={t("six-consecutive-day-rule-parameters")}
									isOpen={visible}
									close={closeModal}
									centered={true}
								>
									<Details
										close={closeModal}
										overtime={data}
										disabled={disabled}
									/>
								</Modal>
							)}
						</>
					);
				}}
			</BaseField>
		</div>
	);
}

export default SixConsecutiveDay;
