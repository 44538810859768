import FormElement from "rc-field-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";
import { Label } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CreateCustomFieldModal from "../CreateCustomFieldModal";
import Button from "../../../../../components/Button";
import BaseSettingsFormContent from "../../../../../components/Form/BaseSettingsFormContent";
import Select from "../../../../../components/Inputs/Select";
import ManageAbility from "./MangeAbility";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { useClockInCustomFields } from "./api";
import { renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import { getSettingsResourceSearchFilters } from "../../../../../utils/helpers/settings";
import { ListWorkflow } from "./List";

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.attestation");
	const disabled = useIsFieldDisabled({ ...access, mode });
	const [locations, setLocations] = useState(values?.location || []);
	const [search, setSearch] = useState("");
	const [selectedCustomFields, setSelectedCustomFields] = useState(
		values?.customFields || []
	);
	const { 
		visible: isModalOpen, 
		close: closeModal, 
		open: openModal 
	} = useVisible();

	const { 
		fetch,
		create, 
		data, 
		setData,
		loading: loadingCustomFields
	} = useClockInCustomFields();

	const onFinish = useCallback((formValues) => {
		const data = {
			...formValues,
			manageAbility: formValues?.manageAbility,
            companies: formValues?.companies?.map((value) => value?.id) || [],
            locations: formValues?.locations?.map((value) => value?.id) || [],
            managedLevels: formValues?.managedLevels?.map((value) => value?.id) || [],
            payGroups: formValues?.payGroups?.map((value) => value?.id) || [],
            projects: formValues?.projects?.map((value) => value?.id) || [],
            unions: formValues?.unions?.map((value) => value?.id) || [],
            employeeTypes: formValues?.employeeTypes?.map((value) => value?.id) || [],
			customFields: selectedCustomFields.map(({ id }) => id),
			workflows: formValues?.workflows || [],
			enabledWorkflows: formValues?.workflows?.length === 0 ? false : formValues.enabledWorkflows,
		};
		submit(data)
	}, [submit, selectedCustomFields])

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const onSelect = useCallback((value) => {
		if(value === "create") {
			openModal()
		} else {
			setSelectedCustomFields(prev => prev.concat({ id: value }))
		}
	}, [setSelectedCustomFields, openModal]);

	const onDeselect = useCallback((value) => {
		setSelectedCustomFields((prev) => prev.filter((field) => field.id !== value));
	}, [setSelectedCustomFields]);

	const debouncedSearch = useMemo(
		() => {
			return debounce((value) => {
				let params = { pagination: false };
				if (value && value!== "") {
					params = {
						...params,
						...getSettingsResourceSearchFilters(value),
					};
				}
				fetch(params);
			}, 600);
		},
		[fetch],
	);

	const onSearch = useCallback((value) => {
		setSearch(value);
		if (debouncedSearch) {
			debouncedSearch(value);
		}
	}, [setSearch, debouncedSearch]);

	useEffect(() => {
		return () => debouncedSearch?.cancel();
	}, [debouncedSearch])

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			...values,
			workflows: values?.workflows || [{
				customFieldCode: undefined,
				value: undefined,
				operator: undefined,
				title: undefined,
				body: undefined,
				enabled: false,
			}],
		});
	}, [form, values]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);

	useEffect(() => {
		const controller = new AbortController();
		fetch({ pagination: false }, controller);
		return ()=> controller.abort();
	}, [fetch]);

	return (
		<>
			<FormElement
				form={form}
				className="d-flex flex-column justify-content-between h-100"
				onFinish={onFinish}
			>
				<div className="mb-5">
					<BaseSettingsFormContent
						mode={mode}
						disabled={disabled || !isMasterCompany}
					/>

					<Label>
						{t("custom-fields")}
						<small className="text-red mx-1">*</small>
					</Label>

					<Select 
						className="mb-4"
						disabled={disabled || !isMasterCompany} 
						placeholder={t("custom-fields")}
						mode="multiple"
						onSearch={onSearch}
						searchValue={search}
						value={selectedCustomFields.map(({ id }) => id)}
						onSelect={onSelect}
						onDeselect={onDeselect}
						showSearch={true}
						filterOption={false}
					>
						{data?.map((item)=> (
							<Select.Option key={item.id} value={item.id}>
								{renderSettingsResourceLabel(item)}
							</Select.Option>
						))}

						<Select.Option
							key="create"
							className="font-weight-bold"
							onClick={openModal}
							value="create"
						>
							<FontAwesomeIcon 
								icon={faPlus}
								className="text-primary mr-2"
							/>
							{t("create-new-custom-field")}
						</Select.Option>
					</Select>

					<ManageAbility 
						form={form}
						values={values}
						disabled={disabled}
						locations={locations}
						setLocations={setLocations}
					/>

					<ListWorkflow data={data} selected={selectedCustomFields} />
				</div>				

				<div className="d-flex justify-content-end">
					<Button
						onClick={onClose}
						disabled={loading}
						className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					>
						{t("cancel")}
					</Button>

					{(!disabled || isMasterCompany) && (
						<Button
							type="submit"
							className="btn-dark btn-sm shadow-none"
							loading={loading}
						>
							{t("save")}
						</Button>
					)}
				</div>
			</FormElement>

			{isModalOpen && (
				<CreateCustomFieldModal 
					isOpen={isModalOpen}
					submit={create}
					setData={setData}
					close={closeModal}
					loading={loadingCustomFields}
				/>
			)}
		</>
	);
}

export default Form;
