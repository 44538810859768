import { useEffect, useMemo, useState } from "react";
import Form from "rc-field-form";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../components/Button";
import Content from "./Content";
import { useEarningGroupsContext } from "../../../TimeSheet/earningGroupsContext";
import { useAddedPayCode } from "../../../TimeSheet/components/TimeSheetTable/hooks/useAddedPayCode";
import { getPrimaryActiveResource } from "../../../../utils/helpers/user";
import { useUser } from "../../../../utils/hooks/user";
import { useGroups } from "../../../Settings/General/OrganisationLevelGroups/context";
import { useCompany } from "../../../../utils/hooks/company";
import { combineDateAndTime, renderDurationAsFormat } from "../../../../utils/helpers/date";
import { calculateSectionsWithAutomaticBreaks, getAutomaticBreaks } from "../../../NewScheduler/Component/helpers/sections";
import { useCalculatedSections } from "../../../NewScheduler/Component/hooks/useCalculatedSections";
import { generateErrorsConfigForForm } from "../../../../utils/helpers/errors";

function AddSchedule({ values, onClose, onFinish, error, loading, mode }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const user = useUser();
	const company = useCompany();
	const [isFormInitialized, setFormInitialized] = useState(false);
	const { data: earningGroups } = useEarningGroupsContext();
	const { allocatedGroups } = useGroups();
	const defaultEarningGroup = earningGroups?.find(group => group?.code === "REG");
	const defaultPayCode = useAddedPayCode(user);
	const primaryJob = getPrimaryActiveResource(user?.jobs);
	const primaryLocation = getPrimaryActiveResource(user?.locations);

	const levels = useMemo(() => {
		const data = values?.data;
		return allocatedGroups?.reduce((total, group) => {
			const level = group?.level.replace("_", "");
			if (user && user[level]) {
				return { ...total, [level]: user[level] };
			} else if (data && data[level]) {
				return { ...total, [level]: data[level] };
			} else {
				return total;
			}
		}, {});
	}, [allocatedGroups, user, values]);

	const date = Form.useWatch("date", form);
	const startDate = Form.useWatch("startDate", form);
	const endDate = Form.useWatch("endDate", form);

	const defaultSection = useMemo(() => {
		const data = values?.data;
		const scheduleSettings = data?.calculationGroup?.schedule || user?.calculationGroup?.schedule || company?.settings?.schedule;
		const shiftStart = scheduleSettings?.shiftStart ? moment.parseZone(scheduleSettings.shiftStart) : moment().startOf("day").hour(9);
		const shiftEnd = scheduleSettings?.shiftEnd ? moment.parseZone(scheduleSettings.shiftEnd) : moment().startOf("day").hour(17);

		let start = moment.parseZone(shiftStart);
		let end = moment.parseZone(shiftEnd);

		if (data) {
			start = moment.parseZone(data?.startDate);
			start = moment.parseZone(data?.endDate);
		}

		start = combineDateAndTime(moment(date), moment(start));
		end = combineDateAndTime(moment(date), moment(end));

		const duration = moment(end).diff(moment(start), "seconds");

		return {
			type: "regular",
			payCode: defaultPayCode,
			location: primaryLocation,
			job: primaryJob,
			duration: renderDurationAsFormat(duration, "HH:mm"),
			start,
			end,
			...levels
		};
	}, [
		values?.data,
		user?.calculationGroup?.schedule,
		company?.settings?.schedule,
		date,
		defaultPayCode,
		primaryLocation,
		primaryJob,
		levels
	]);

	const sectionsWithAutomaticBreak = useMemo(() => {
		const data = values?.data;
		let end = moment(endDate).isBefore(moment(startDate), "seconds") ? moment(endDate).add(1, "day") : endDate;
		const duration = moment(end).diff(moment(startDate), "seconds");

		let sections = [{
			...defaultSection,
			start: startDate,
			end,
			duration: renderDurationAsFormat(duration, "HH:mm"),
			canCalculateAutomaticBreaks: data?.isCreating,
		}];

		const breaks = getAutomaticBreaks(
			data?.calculationGroup?.schedule || user?.calculationGroup?.breaks,
			sections,
			sections[0]
		);

		if (breaks?.length > 0) {
			sections = calculateSectionsWithAutomaticBreaks(sections, breaks);
		}
		return sections;
	}, [values?.data, endDate, startDate, defaultSection, user?.calculationGroup?.breaks]);

	const sections = useCalculatedSections({
		startTime: startDate,
		endTime: endDate,
		eventRecord: { ...values?.data, isCreating: !values?.data },
		schedulerInstance: { viewPreset: { data: { id: "hourAndDay" } } },
		defaultSection,
		sections: sectionsWithAutomaticBreak
	});

	const initialValues = useMemo(() => {
		const data = values?.data;
		const startDate = data?.startDate ? moment.parseZone(data?.startDate).toDate() : defaultSection?.start;
		let endDate = data?.endDate ? moment.parseZone(data?.endDate).toDate() : defaultSection?.end;

		if (moment(endDate).isBefore(moment(startDate), "seconds")) {
			endDate = moment(endDate).add(1, "day");
		}

		const duration = moment(endDate).diff(moment(startDate), "seconds");
		const eventType = data?.eventType || defaultEarningGroup;
		return {
			date: data?.date && moment(data.date).toDate(),
			startDate: !eventType?.allowSegmentation ? moment.parseZone(sections?.[0]?.start).toDate() : startDate,
			endDate: !eventType?.allowSegmentation ? moment.parseZone(sections?.[sections?.length - 1]?.end).toDate() : endDate,
			duration: renderDurationAsFormat(duration, "HH:mm"),
			resourceId: data?.user || user?.id,
			eventType,
			payCode: !eventType?.allowSegmentation && (data?.payCode || defaultPayCode),
			job: !eventType?.allowSegmentation && (sections?.[0]?.job || primaryJob),
			location: !eventType?.allowSegmentation && (sections?.[0]?.location || primaryLocation),
			sections: sections?.length > 0 ? sections : [defaultSection],
			...levels
		};
	}, [values?.data, defaultSection, defaultEarningGroup, sections, user?.id, defaultPayCode, primaryJob, primaryLocation, levels]);

	const eventType = Form.useWatch("eventType", form);
	const _sections = Form.useWatch("sections", form);

	useEffect(() => {
		const currentSections = form.getFieldValue("sections");
		if (!values?.data && sections?.length > 0 && ((!currentSections || currentSections?.length === 1) && !currentSections?.[0]?.start)) {
			form.setFieldsValue({
				sections,
				...defaultSection,
			});
		}

		if (!eventType?.allowSegmentation && !values?.data && !isFormInitialized) {
			form.setFieldsValue(defaultSection);
			setFormInitialized(true);
		}
	}, [defaultSection, eventType?.allowSegmentation, form, isFormInitialized, sections, values?.data]);

	useEffect(() => {
		if (eventType?.allowSegmentation) {
			if (_sections?.length > 0) {
				form.setFieldValue("payCode", _sections?.[0]?.payCode);
				form.setFieldValue("location", sections?.[0]?.location);
				form.setFieldValue("job", sections?.[0]?.job);
				if (_sections?.[0]?.start) {
					form.setFieldValue("startDate", _sections[0].start);
				};
				if (_sections?.[_sections?.length - 1]?.end) {
					form.setFieldValue("endDate", _sections[_sections.length - 1]?.end);
				}
			}
		}
	}, [form, eventType?.allowSegmentation, _sections, sections]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"startDate",
				"endDate",
				"date",
				"location",
				"job",
				"level1",
				"level2",
				"level3",
				"level4",
				"sections",
				"eventType"
			],
			error,
		);
		form.setFields(fieldErrors);
	}, [form, error]);

	return (
		<Form
			form={form}
			onFinish={onFinish}
			initialValues={initialValues}
			className="h-100"
		>
			<div
				className="p-4"
				style={{
					height: "calc(100% - 70px)",
					overflowY: "auto",
					overflowX: "hidden"
				}}
			>
				<Content
					form={form}
					eventRecord={values?.data}
					defaultSection={defaultSection}
					mode={mode}
					resource={user}
					disabled={mode === "timesheet-view" || mode === "view"}
				/>
			</div>

			{!mode && (
				<div className="border-top py-3 text-center align-items-center justify-content-center">
					<Button
						className="btn-icon text-muted"
						type="button"
						color="white"
						onClick={onClose}
						loading={loading}
					>
						<span className="btn-inner--icon">
							<FontAwesomeIcon icon={faCircleXmark} />
						</span>
						<span className="btn-inner--text">{t("cancel")}</span>
					</Button>

					<Button
						className="btn-icon text-info"
						type="submit"
						color="white"
						disabled={loading}
					>
						<span className="btn-inner--icon">
							<FontAwesomeIcon icon={faCircleCheck} />
						</span>

						<span className="btn-inner--text">
							{t("send-for-approval")}
						</span>
					</Button>
				</div>
			)}
		</Form>
	)
}

export default AddSchedule;
