import { faCheck, faCircleInfo, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "rc-field-form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { read, utils } from "xlsx";
import Field from "../../../../../components/Field";
import Select from "../../../../../components/Inputs/Select";
import UploadInput from "../../../../../components/Inputs/Upload";
import Modal from "../../../../../components/Modal";
import useApi from "../../../../../utils/api";
import { useGroups } from "../../../../Settings/General/OrganisationLevelGroups/context";
import { renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import useSWRMutation from "swr/mutation";
import Button from "../../../../../components/Button";
import { Tooltip } from "reactstrap";
import { useVisible } from "../../../../../utils/hooks/useVisible";
/*
import { useUserPreferenceActions, useUserPreferences as _useUserPreferences } from "../../../../Settings/Configuration/api/useUserPreferences";
import Text from "../../../../../components/Inputs/Text";

const useUserPreferences = () => {
    const { data: _data, ...rest } = _useUserPreferences();

        no existing preferences on db
        first save, before the save
        {}

        no existing preferences on db
        first save, after the save
        {
            "id": "8a8ebe9e-4515-47d1-b03e-fbc62fbcc006",
            "mainCompany": "92852b94-6c68-4a4b-a1c5-499c0d5d4d56",
            "entity": "user",
            "entityId": "67898ea1-c6c3-430d-80fd-8da090820899",
            "preferenceKey": "timesheet-import-template",
            "config": {
                "first": {
                    "fields": {
                        "employeeNumber": "STAFF"
                    }
                }
            }
        }

        on something weird
        {
            "timesheet-import-template": {
                "first": {
                    "fields": {
                        "employeeNumber": "STAFF"
                    }
                }
            }
        }

        no existing preferences on db
        second save
        {
            "id": "8a8ebe9e-4515-47d1-b03e-fbc62fbcc006",
            "mainCompany": "92852b94-6c68-4a4b-a1c5-499c0d5d4d56",
            "entity": "user",
            "entityId": "67898ea1-c6c3-430d-80fd-8da090820899",
            "preferenceKey": "timesheet-import-template",
            "config": {
                "second": {
                    "fields": {
                        "employeeNumber": "STAFFID"
                    }
                }
            }
        }

        existing preferences on db
        first save, before the save
        {
            "timesheet-import-template": {
                "second": {
                    "fields": {
                        "employeeNumber": "STAFFID"
                    }
                }
            }
        }

        existing preferences on db
        first save, after the save
        {
            "id": "8a8ebe9e-4515-47d1-b03e-fbc62fbcc006",
            "mainCompany": "92852b94-6c68-4a4b-a1c5-499c0d5d4d56",
            "entity": "user",
            "entityId": "67898ea1-c6c3-430d-80fd-8da090820899",
            "preferenceKey": "timesheet-import-template",
            "config": {
                "existing-first": {
                    "fields": {
                        "employeeNumber": "PATIENT / ACTIVITY"
                    }
                }
            }
        }

        existing preferences on db
        second save, after the save
        {
            "id": "8a8ebe9e-4515-47d1-b03e-fbc62fbcc006",
            "mainCompany": "92852b94-6c68-4a4b-a1c5-499c0d5d4d56",
            "entity": "user",
            "entityId": "67898ea1-c6c3-430d-80fd-8da090820899",
            "preferenceKey": "timesheet-import-template",
            "config": {
                "existing-second": {
                    "fields": {
                        "employeeNumber": "PATIENT / ACTIVITY",
                        "payCode": "STAFFID"
                    }
                }
            }
        }

    const data = useMemo(() => {
        // workaround!
        return _data.config || _data['timesheet-import-template'] || _data;
    }, [_data]);

    useMemo(() => {
        console.log({ data })
    }, [data])

    return { data, ...rest };
}
*/

const fileTypes = [
    "xlsx",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];
const fileSizeLimit = 2;

const fileToWorkbook = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
            resolve(read(reader.result));
        };
        reader.onerror = () => {
            // could not parse the file
            reject();
        };
        reader.readAsArrayBuffer(file);
    });
}

const useLoadWorkSheet = (file) => {
    const [isLoading, setIsLoading] = useState(false);
    const [workSheet, setWorkSheet] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!file) {
            setWorkSheet(null);
            return;
        }
        (async () => {
            try {
                const workBook = await fileToWorkbook(file)
                setWorkSheet(workBook.Sheets[workBook.SheetNames[0]])
            } catch (err) {
                setError(err);
            }
        })();
    }, [file, setIsLoading]);

    return { isLoading, error, workSheet };
}

function ColumnSelect({ columns, info, ...props }) {
    const { visible, toggle } = useVisible();
    const ref = useRef();

    let allowClear = true;
    if (props.rules && Array.isArray(props.rules)) {
        allowClear = !props.rules.some((rule) => rule.required);
    }
    if (info && props.label) {
        props.label = (
            <div>
                <span className="mr-1">{props.label}</span>

                <span className="cursor-pointer">
                    <FontAwesomeIcon ref={ref} icon={faCircleInfo} />
                </span>

                <Tooltip target={ref} isOpen={visible} toggle={toggle}>
                    {info}
                </Tooltip>
            </div>
        );
    }

    return (
        <Field {...props}>
            {({ value, onChange }) => {
                return (
                    <Select
                        value={value}
                        onChange={onChange}
                        showSearch
                        allowClear={allowClear}
                    >
                        {value && (
                            <Select.Option key={value} value={value}>
                                {value}
                            </Select.Option>
                        )}

                        {columns.map((column) => {
                            return (
                                <Select.Option key={column} value={column}>
                                    {column}
                                </Select.Option>
                            );
                        })}
                    </Select>
                );
            }}
        </Field>
    );
}

function ImportModal({ visible, close, onSuccess, onFailure }) {
    const { t } = useTranslation();

    const [form] = Form.useForm();
    const [file, setFile] = useState(null);
    const { isLoading, workSheet, error } = useLoadWorkSheet(file);

    const onChange = useCallback(({ target }) => {
        setFile(target.files[0]);
    }, []);

    const formValues = Form.useWatch([], form);
    const columns = useMemo(() => {
        if (!workSheet) {
            return [];
        }

        return utils
            .sheet_to_csv(workSheet)
            .split("\n")[0]
            .split(",")
            .filter((item) => {
                return !Object.values(formValues).includes(item);
            });
    }, [workSheet, formValues]);

    const { call } = useApi();
    const {
        data: response,
        error: mutationError,
        isMutating,
        trigger,
    } = useSWRMutation(
        "/time-sheet/import",
        async (url, { arg }) => {
            const data = new FormData();
            for (const key in arg) {
                const value = arg[key];
                if (value) {
                    data.append(key, value);
                }
            }
            data.append("file", file);
            const response = await call({
                method: "POST",
                url,
                data,
            });
            await onSuccess?.();
            return response;
        },
    );

    /*
    const [templateName, setTemplateName] = useState("");
    const {
        submit: submitPreferences,
        submitting: submittingPreferences,
    } = useUserPreferenceActions("timesheet-import-template")
    const {
        isLoading: isLoadingPreferences,
        data: preferences,
    } = useUserPreferences()
     */

    const { allocatedGroups } = useGroups();

    return (
        <Modal
            className="payroll-export-modal"
            size="lg"
            title={
                <div className="d-flex justify-content-between align-items-center text-primary">
                    {t("timesheet-import")}
                    <i
                        className="fa-solid fa-close cursor-pointer"
                        onClick={close}
                    />
                </div>
            }
            toggle={close}
            isOpen={visible}
            scrollable
            centered
        >
            {/*
            <Button>Load template</Button>

            <Select>
            </Select>

            <Button
                onClick={() => {
                    submitPreferences({
                        entity: "user",
                        config: {
                            ...(preferences || {}),
                            [templateName]: {
                                fields: form.getFieldsValue(),
                            },
                        },
                    });
                }}
            >
                Save template
            </Button>

             TODO: dont allow 'id', 'timesheet-import-template' or 'config' as names

            <Text
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
            />
              */}

            {!response && (
                <>
                    {file ? (
                        <div
                            className="px-4 py-4 d-flex justify-content-between mb-4"
                            style={{
                                border: '2px solid rgba(0, 0, 0, 0.2)',
                                borderRadius: '.5rem',
                            }}
                        >
                            <span>
                                {file.name}
                            </span>

                            <FontAwesomeIcon
                                className="text-danger cursor-pointer"
                                icon={faClose}
                                onClick={() => {
                                    setFile(null);
                                    form.resetFields();
                                }}
                            />
                        </div>
                    ) : (
                        <UploadInput
                            multiple={false}
                            onChange={onChange}
                            fileTypes={fileTypes}
                            fileList={[]}
                            fileSizeLimit={fileSizeLimit}
                            insertText={t("upload-file")}
                            label={t("source")}
                            name="documents"
                            inputClassname="upload-input"
                        />
                    )}
                </>
            )}

            <div className={(file && !response) ? "d-block" : "d-none"}>
                <Form form={form} onFinish={trigger}>
                    <div className="d-flex gap-x-4">
                        <ColumnSelect
                            className="flex-fill"
                            columns={columns}
                            name="employeeNumber"
                            label={t("employee-number")}
                            rules={[
                                {
                                    required: true,
                                    message: t('required-employee-number'),
                                }
                            ]}
                        />

                        <ColumnSelect
                            className="flex-fill"
                            columns={columns}
                            name="payCode"
                            label={t("pay-code")}
                            rules={[
                                {
                                    required: true,
                                    message: t('required-pay-code'),
                                }
                            ]}
                        />
                    </div>

                    <div className="d-flex gap-x-4">
                        <ColumnSelect
                            className="flex-fill"
                            columns={columns}
                            name="date"
                            label={t("date")}
                            rules={[
                                {
                                    required: true,
                                    message: t('required-date'),
                                }
                            ]}
                        />

                        {/* only units are not required to have this field */}
                        <ColumnSelect
                            className="flex-fill"
                            columns={columns}
                            name="startDate"
                            label={t("start-date")}
                            info={t("start-date-is-required-for-timesheets-that-are-not-unit-or-amount")}
                        />

                        {/* only units are not required to have this field */}
                        <ColumnSelect
                            className="flex-fill"
                            columns={columns}
                            name="endDate"
                            label={t("end-date")}
                            info={t("end-date-is-required-for-timesheets-that-are-not-unit-or-amount")}
                        />
                    </div>

                    <div className="d-flex gap-x-4">
                        <ColumnSelect
                            className="flex-fill"
                            columns={columns}
                            name="job"
                            label={t("job")}
                        />

                        <ColumnSelect
                            className="flex-fill"
                            columns={columns}
                            name="location"
                            label={t("location")}
                        />
                    </div>

                    <div className="d-flex gap-x-4">
                        <ColumnSelect
                            className="flex-fill"
                            columns={columns}
                            name="duration"
                            label={t("duration")}
                            info={t("duration-is-required-for-unit-timesheets")}
                        />

                        <ColumnSelect
                            className="flex-fill"
                            columns={columns}
                            name="total"
                            label={t("total")}
                            info={t("total-is-required-for-amount-timesheets")}
                        />

                        <ColumnSelect
                            className="flex-fill"
                            columns={columns}
                            name="regRate"
                            label={t("rate")}
                        />
                    </div>

                    <div style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: "0rem 1rem",
                    }}>
                        {allocatedGroups.map((group) => {
                            return (
                                <ColumnSelect
                                    key={group.id}
                                    columns={columns}
                                    name={group.level.replace("_", "")}
                                    label={renderSettingsResourceLabel(group)}
                                />
                            );
                        })}
                    </div>

                    <Button type="submit" color="primary" className="w-100" loading={isMutating}>
                        {t("submit")}
                    </Button>
                </Form>
            </div>

            {response && (
                <div>
                    {Object.keys(response).map((employeeNumber) => {
                        const { status, message, ...rows } = response[employeeNumber];
                        const { inserted, failed } = Object
                            .entries(rows)
                            .reduce(
                                (total, [row, value]) => {
                                    if (value.status) {
                                        total.inserted[row] = value;
                                    } else {
                                        total.failed[row] = value;
                                    }
                                    return total;
                                },
                                { inserted: {}, failed: {} }
                            );

                        return (
                            <div
                                key={employeeNumber}
                                className="p-3 rounded mb-4"
                                style={{ backgroundColor: "#f7fafc" }}
                            >
                                <h3>
                                    <span className="mr-3">
                                        {employeeNumber === 'emptyUser' ? (
                                            t('other')
                                        ) : `${t("employee")} ${employeeNumber}`}
                                    </span>

                                    {status ? (
                                        <>
                                            {!!Object.keys(inserted).length && (
                                                <span className="text-success mr-3">
                                                    <FontAwesomeIcon icon={faCheck} />
                                                    {Object.keys(inserted).length}
                                                </span>
                                            )}

                                            {!!Object.keys(failed).length && (
                                                <span className="text-danger mr-3">
                                                    <FontAwesomeIcon icon={faClose} />
                                                    {Object.keys(failed).length}
                                                </span>
                                            )}
                                        </>
                                    ) : (
                                        <span className="text-danger">{message}</span>
                                    )}
                                </h3>

                                {status && (
                                    <div>
                                        {!!Object.keys(failed).length && (
                                            <hr className="my-2" />
                                        )}

                                        {Object.keys(failed).map((row) => {
                                            return (
                                                <div key={row} className="text-danger">
                                                    <b>{t("row-{{count}}", { count: row })}:</b>
                                                    {" "}
                                                    <span>{failed[row].message}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                )}
                            </div>
                        );
                    })}

                    <div className="d-flex gap-x-4">
                        <Button color="danger" className="w-100" onClick={() => close()}>
                            {t("close")}
                        </Button>
                    </div>
                </div>
            )}
        </Modal>
    );
}

export default ImportModal;
