import { Field } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import Text from "../../../../../../../components/Inputs/Text";

function Item({ name, disabled, tier }) {
    const { t } = useTranslation();

    return (
        <div key={name} className="d-flex gap-2 align-items-center mb-2">
            <div className="w-100">
                <Label>{t("name")}</Label>
                <Text disabled={true} value={tier.name} />
            </div>

            <div className="w-100">
                <Label>{t("start")}</Label>
                <Text disabled={true} value={tier.start || ""} />
            </div>

            <div className="w-100">
                <Label>{t("end")}</Label>
                <Text disabled={true} value={tier.end || ""} />
            </div>

            <div className="w-100">
                <Label>{t("value")}</Label>
                <Field
                    name={["intervals", name, "value"]}
                    rules={[
                        {
                            required: true,
                            message: t("required-value")
                        },
                        {
                            validator: (_, value) => {
                                if (value && Number(value) < 0) {
                                    return Promise.reject(
                                        new Error(t("readableField-bigger-than-zero", { readableField: t("value") }))
                                    );
                                }
                                return Promise.resolve();
                            }
                        }
                    ]}
                >
                    <Text type="number" disabled={disabled} step="0.1" />
                </Field>
            </div>
        </div>
    );
}

export default Item;
