import { useState, useMemo, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "../../../../components/Drawer";
import Action from "../../Actions/Action";
import ChangeHiringInformation from "./ChangeHiringInformation";
import ChangeJobInformation from "./ChangeJobInformation";
import ChangeOrganisation from "./ChangeOrganisation";
import { useAccess } from "../../../../utils/hooks/access";
import { ProfileContext } from "../../context";
import ChangeUserGroups from "./ChangeUserGroups";
import ChangeEmploymentType from "./ChangeEmploymentType";
import ChangeExpectedHours from "./ChangeExpectedHours";

function Actions() {
	const { t } = useTranslation();
	const [action, setAction] = useState(null);
	const { isSelf, user } = useContext(ProfileContext);

	const { hasAccess: canChangeJobInformation } = useAccess(
		isSelf ? "profile.changeJobInformation" : "people.changeJobInformation",
	);

	const { hasAccess: canChangeHiringInfromation } = useAccess(
		isSelf
			? "profile.changeHiringInformation"
			: "people.changeHiringInformation",
	);

	const { hasAccess: canChangeOrganisation } = useAccess(
		isSelf ? "profile.changeOrganisation" : "people.changeOrganisation",
	);

	const { hasAccess: canChangeUserGroups } = useAccess(
		isSelf ? "profile.changeUserGroups" : "people.changeUserGroups",
	);

	const drawerTitle = useMemo(() => {
		switch (action) {
			case "job-information":
				return t("change-job-information");
			case "hiring-information":
				return t("change-hiring-information");
			case "organization":
				return t("change-organization");
			case "user-groups":
				return t("change-user-groups");
			case "employment-type": {
				return t("change-employment-type");
			}
			case "expected-hours": {
				return t("change-expected-hours");
			}
			default:
				return '';
		}
	}, [action, t]);

	const close = useCallback(() => {
		setAction(null);
	}, [setAction]);

	const drawerContent = useMemo(() => {
		switch (action) {
			case "job-information":
				return <ChangeJobInformation close={close} />;
			case "hiring-information":
				return <ChangeHiringInformation close={close} />;
			case "organization":
				return <ChangeOrganisation close={close} />;
			case "user-groups":
				return <ChangeUserGroups close={close} />
			case "employment-type":
				return <ChangeEmploymentType close={close} />
			case "expected-hours":
				return <ChangeExpectedHours close={close} />
			default:
				return null;
		}
	}, [action, close]);

	return (
		<>
			{canChangeJobInformation && (
				<Action
					title={t("job-information")}
					onClick={() => setAction("job-information")}
					disabled={user?.isApi}
				>
					{t("change")}
				</Action>
			)}

			{canChangeHiringInfromation && (
				<hr className="my-4" />
			)}

			{canChangeHiringInfromation && (
				<Action
					title={t("hiring-information")}
					onClick={() => setAction("hiring-information")}
					disabled={user?.isApi}
				>
					{t("change")}
				</Action>
			)}

			{canChangeOrganisation && (
				<hr className="my-4" />
			)}

			{canChangeOrganisation && (
				<Action
					title={t("organization")}
					onClick={() => setAction("organization")}
					disabled={user?.isApi}
				>
					{t("change")}
				</Action>
			)}

			{canChangeUserGroups && (
				<hr className="my-4" />
			)}

			{canChangeUserGroups && (
				<Action
					title={t("user-groups")}
					onClick={() => setAction("user-groups")}
				>
					{t("change")}
				</Action>
			)}

			{canChangeJobInformation && 
				<hr className="my-4" />			
			}

			{canChangeJobInformation && 
				<Action
					title={t("employment-type")}
					onClick={() => setAction("employment-type")}
				>
					{t("change")}
				</Action>			
			}
			{canChangeJobInformation && (
				<hr className="my-4" />
			)}

			{canChangeJobInformation && (
				<Action 
					title={t("expected-hours")} 
					onClick={() => setAction("expected-hours")}
				>
					{t("change")}
				</Action>
			)}

			<div id="custom-drawer" />

			<Drawer
				title={drawerTitle}
				open={action !== null}
				close={close}
				style={{
					maxWidth: "initial",
				}}
			>
				{drawerContent}
			</Drawer>
		</>
	);
}

export default Actions;
