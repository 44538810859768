import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { createColumnHelper } from "@tanstack/react-table";
import moment from "moment-timezone";
import { renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import { useCompanyDateFormat } from "../../../../utils/hooks/company";

const columnHelper = createColumnHelper();

export const StatusCell = ({ value }) => {
    const { t } = useTranslation();

    let color, backgroundColor;
    switch (value) {
        case "expired": {
            color = "red";
            backgroundColor = "#ff000012"
            break;
        }
        case "active": {
            color = "green";
            backgroundColor = "#00800014";
            break;
        }
        case "pending": {
            color = "orange";
            backgroundColor = "#ffa5001c";
            break;
        }
        default:
            break;
    }

    return value && (
        <span className="px-3 py-1 rounded" style={{ color, backgroundColor }}>
            {t(value)}
        </span>
    );
}

export const useColumns = ({ edit, remove }) => {
    const { t } = useTranslation();
    const dateFormat = useCompanyDateFormat();

    return useMemo(() => {
        const columns = [
            columnHelper.accessor("type", {
                header: t("type"),
                cell: (info) => {
                    return renderSettingsResourceLabel(info.getValue());
                },
            }),
            columnHelper.accessor("startDate", {
                header: t("start-date"),
                cell: (info) => {
                    const value = info.getValue();
                    return value ? moment(value).format(dateFormat) : null;
                },
            }),
            columnHelper.accessor("endDate", {
                header: t("end-date"),
                cell: (info) => {
                    const value = info.getValue();
                    return value ? moment(value).format(dateFormat) : null;
                },
            }),
            columnHelper.accessor("status", {
                header: t("status"),
                cell: (info) => {
                    const value = info.getValue();
                    return <StatusCell value={value} />
                },
            }),
        ];
        const actionColumn = columnHelper.display({
            id: "actions",
            enableHiding: false,
            enableColumnFilter: false,
            header: <div className="text-right">{t("actions")}</div>,
            cell: (info) => {
                const row = info.row.original;
                return (
                    <div className="actions text-primary text-right">
                        {(edit && row.status !== "expired") && (
                            <span
                                className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                onClick={() => edit(row)}
                                style={{ cursor: "pointer" }}
                            >
                                <i className="fas fa-pen" />
                            </span>
                        )}

                        {remove && (
                            <span
                                className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                onClick={() => remove(row.id)}
                                style={{ cursor: "pointer" }}
                            >
                                <i className="fas fa-trash" />
                            </span>
                        )}
                    </div>
                );
            },
        });
        columns.push(actionColumn);
        return columns;
    }, [dateFormat, edit, remove, t]);
};
