import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const usePayCodeDescriptionColumn = ({ canEdit }) => {
    const { t } = useTranslation();
    return useMemo(() => ({
        Header: t("time-code-description"),
        accessor: "payCode.description",
        alwaysVisible: false,
        disableFilters: false,
        headCustomProps: {},
        Cell: ({ row }) => {
            return row.original.payCode?.description;
        },
    }), [t, canEdit]);
};
