import { useCallback, useEffect, useMemo } from "react";
import { useFilterFields } from "../api";

export const useBindPayGroupWithTable = ({ filters, setFilters, mode, payGroup, report }) => {
    const { filterFields, getFilterFields } = useFilterFields();
    const config = useMemo(() => {
        const columnTypes = report?.reportData?.columns?.map((item) =>
            item?.name?.split(".")[0]
        );

        const fields = filterFields?.reduce((total, current) => {
            if (columnTypes?.includes(current?.name)) {
                const fields = current?.fields?.map((field) => ({ ...field, table: current?.name }));
                total.push(fields);
            }
            return total.flat();
        }, []);

        return fields?.filter((field) => field?.canFilter);
    }, [filterFields, report?.reportData?.columns]);

    const tablePayGroupCodes = useMemo(() => {
        const pFilter = filters
            .find((filter) => (filter.name || "").includes("payGroup"));
        return pFilter && Array.isArray(pFilter.values) ? pFilter.values?.map(({ id }) => id) : [];
    }, [filters]);

    const setTablePayGroup = useCallback((payGroupId) => {
        let member = config?.find((item) => (item.name.toLowerCase()).includes("paygroup"));
        member = member ? `${member.table}.${member.name}` : "payGroup";
        const hasExisting = !!filters
            .find((filter) => ((filter?.member || "").toLowerCase() || "").includes("paygroup"));
        let newValue;
        if (payGroupId) {
            if (hasExisting) {
                newValue = filters.map((filter) => {
                    if (!((filter?.member || "").toLowerCase() || "").includes("paygroup")) {
                        return filter;
                    }
                    return {
                        ...filter,
                        values: [{ id: payGroupId }],
                    };
                });
            } else {
                const existingIndex = filters?.findIndex(
                    (filter) => filter?.values === "" || filter?.values?.length === 0
                );
                if (existingIndex === -1) {
                    newValue = [
                        {
                            operator: "exact",
                            member,
                            values: [{ id: payGroupId }],
                        },
                        ...filters,
                    ];
                } else {
                    newValue = [...filters];
                    newValue.splice(existingIndex, 1, {
                        operator: "exact",
                        member,
                        values: [{ id: payGroupId }],
                    });
                }
            }
        } else {
            newValue = filters.filter((filter) => {
                return !((filter?.member || "").toLowerCase() || "").includes("paygroup");
            });
        }

        if (JSON.stringify(newValue) !== JSON.stringify(filters)) {
            setFilters(newValue);
        }
    }, [config, filters, setFilters]);

    useEffect(() => {
        const controller = new AbortController();
        getFilterFields(controller);
        return () => controller.abort();
    }, [getFilterFields]);

    useEffect(() => {
        if (mode === "pay-group" && payGroup) {
            if (tablePayGroupCodes.length === 1) {
                if (tablePayGroupCodes[0] !== payGroup.id) {
                    setTablePayGroup(payGroup?.id);
                }
            } else {
                setTablePayGroup(payGroup?.id);
            }
        }
    }, [mode, tablePayGroupCodes, payGroup, setTablePayGroup]);
}
