import { useContext, useEffect, useLayoutEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { ProfileContext } from "../../context";
import { useColumns } from "./useColumns";
import { useNewTable } from "../../../../components/NTable/hook";
import NTable from "../../../../components/NTable";
import Button from "../../../../components/Button";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import { useQualificationsApi } from "../api";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useDeleteModal } from "../../../Settings/helpers/useDeleteModal";

const useCustomDrawer = (getChildren) => {
    const [wrapperElement, setWrapperElement] = useState(null);

    useLayoutEffect(() => {
        const element = document.getElementById("certifications-drawer");
        setWrapperElement(element);
    }, [setWrapperElement]);

    if (wrapperElement === null) return null;

    return createPortal(getChildren(), wrapperElement);
};

const Certifications = () => {
    const { t } = useTranslation();
    const { isSelf, user } = useContext(ProfileContext);
    const {
        getCertifications,
        addCertification,
        updateCertification,
        deleteCertification,
        data,
        upload,
        remove
    } =
        useQualificationsApi();

    const { open: openDrawer, close: closeDrawer, visible: isDrawerOpen, selected } = useVisible();

    const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
        message: t("do-you-want-to-delete-this-certification"),
        onConfirm: (id) => {
            const url = isSelf ? `/profile/certification/${id}` : `/users/${user?.id}/certification/${id}`;
            deleteCertification(url, id);
        },
        permission: isSelf ? "profile.changeCertification" : "people.changeCertification",
    });

    const columns = useColumns({
        edit: openDrawer,
        remove: openDeleteModal,
    });

    const table = useNewTable({
        data,
        columns,
        totalItems: data?.length,
        enableRowSelection: false,
    });

    useEffect(() => {
        const controller = new AbortController();
        const url = isSelf ? "/profile/certifications" : `/users/${user?.id}/certifications`;
        if (user?.id) {
            getCertifications(url, controller);
        }
        return () => controller.abort();
    }, [getCertifications, isSelf, user]);

    const drawer = useCustomDrawer(() => {
        return (
            <Drawer
                title={selected?.id ? t("edit-certificate") : t("add-certificate")}
                open={isDrawerOpen}
                close={closeDrawer}
                style={{
                    maxWidth: "initial",
                }}
            >
                <Form
                    closeDrawer={closeDrawer}
                    selected={selected}
                    add={addCertification}
                    update={updateCertification}
                    upload={upload}
                    remove={remove}
                />
            </Drawer>
        );
    });

    return (
        <>
            <div className="mt-4">
                <div className="d-flex justify-content-end mx-4 mb-3">
                    <Button
                        className="border-dark btn-round btn-icon shadow-none btn btn-none btn-sm h-25"
                        onClick={() => openDrawer()}
                    >
                        {t("add-certification")}
                    </Button>
                </div>
                <NTable table={table} />
            </div>

            {drawer}

            {deleteModal}
        </>
    );
};

export default Certifications;
