import { useCallback, useState } from "react";
import { Field } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import Text from "../../../../../../components/Inputs/Text";
import Switch from "../../../../../../components/Inputs/Switch";

function MaxRequestedHours({ form, data, disabled }) {
    const { t } = useTranslation();
    const [visible, setVisible] = useState(data?.maxRequestedHours || false);

    const onChange = useCallback(() => {
        setVisible((prev) => {
            if (prev) {
                form.setFieldValue("maxRequestedHours", null);
            }
            return !prev;
        });
    }, [form]);

    return (
        <div style={{ background: "#f8f9f9" }} className="p-4 rounded mb-4">
            <Switch
                checked={visible}
                onChange={onChange}
            >
                <h4 className="m-0 p-0">{t("max-hours-allowed-to-be-taken")}</h4>
            </Switch>


            {visible && data.tiers.map((tier, index) => {
                return (
                    <div key={index} className="d-flex flex-column my-3">
                        <div className="d-flex gap-2 align-items-center">
                            <div className="w-100">
                                <Label>{t("name")}</Label>
                                <Text disabled={true} value={tier.name} />
                            </div>

                            <div className="w-100">
                                <Label>{t("start")}</Label>
                                <Text disabled={true} value={tier.start || ""} />
                            </div>

                            <div className="w-100">
                                <Label>{t("end")}</Label>
                                <Text disabled={true} value={tier.end || ""} />
                            </div>

                            <div className="w-100">
                                <Label>{t("value")}</Label>
                                <Field
                                    name={["maxRequestedHours", index, "value"]}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("required-value")
                                        },
                                        {
                                            validator: (_, value) => {
                                                if (value && Number(value) < 0) {
                                                    return Promise.reject(
                                                        new Error(t("readableField-bigger-than-zero", { readableField: t("value") }))
                                                    );
                                                }
                                                return Promise.resolve();
                                            }
                                        }
                                    ]}
                                >
                                    <Text type="number" disabled={disabled} step="0.1" />
                                </Field>
                            </div>
                        </div>

                        <Field shouldUpdate>
                            {({ }, { }, { getFieldError }) => {
                                const [error] = getFieldError(["maxRequestedHours", index, "value"]);

                                return (
                                    <div className="d-block invalid-feedback">
                                        {error}
                                    </div>
                                );
                            }}
                        </Field>
                    </div>
                );
            }
            )}
        </div>
    );
}

export default MaxRequestedHours;
