import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import useApi, { useUploadApi } from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { toast } from "react-toastify";

export const useQualificationsApi = () => {
    const { t } = useTranslation();
    const { authGet, authPut, authPost, authDelete } = useApi();
    const { uploadPost, uploadDelete } = useUploadApi();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState();

    const getSkills = useCallback(
        async (url, controller) => {
            setLoading(true);
            try {
                const response = await authGet(url, {
                    signal: controller.signal,
                });
                if (response) {
                    setData(response);
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLoading(false);
            }
        },
        [authGet, t]
    );

    const addSkills = useCallback(
        async (url, data) => {
            try {
                await authPut(url, {
                    data,
                });
                toast.success(t("skill-added-successfully"));
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authPut, t]
    );

    const getLicenses = useCallback(
        async (url, controller) => {
            setLoading(true);
            try {
                const response = await authGet(url, {
                    signal: controller.signal,
                });
                if (response) {
                    setData(response);
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLoading(false);
            }
        },
        [authGet, t]
    );

    const addLicense = useCallback(
        async (url, data) => {
            try {
                const response = await authPost(url, {
                    data,
                });
                if (response) {
                    setData((prev) => [...(prev || []), response]);
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authPost, t]
    );

    const updateLicense = useCallback(
        async (url, data) => {
            try {
                const response = await authPut(url, {
                    data,
                });
                if (response) {
                    setData((prev) =>
                        prev.map((item) => {
                            if (item?.id === response?.id) {
                                return { ...item, ...response };
                            }
                            return item;
                        })
                    );
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authPut, t]
    );

    const deleteLicense = useCallback(
        async (url, id) => {
            try {
                await authDelete(url);
                setData((prev) => prev?.filter((item) => item?.id !== id));
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authDelete, t]
    );

    const getCertifications = useCallback(
        async (url, controller) => {
            setLoading(true);
            try {
                const response = await authGet(url, {
                    signal: controller.signal,
                });
                if (response) {
                    setData(response);
                }
            } catch (error) {
                getErrorMessage(error, t);
            } finally {
                setLoading(false);
            }
        },
        [authGet, t]
    );

    const addCertification = useCallback(
        async (url, data) => {
            try {
                const response = await authPost(url, {
                    data,
                });
                if (response) {
                    setData((prev) => [...(prev || []), response]);
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authPost, t]
    );

    const updateCertification = useCallback(
        async (url, data) => {
            try {
                const response = await authPut(url, {
                    data,
                });
                if (response) {
                    setData((prev) =>
                        prev.map((item) => {
                            if (item?.id === response?.id) {
                                return { ...item, ...response };
                            }
                            return item;
                        })
                    );
                }
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authPut, t]
    );

    const deleteCertification = useCallback(
        async (url, id) => {
            try {
                await authDelete(url);
                setData((prev) => prev?.filter((item) => item?.id !== id));
            } catch (error) {
                getErrorMessage(error, t);
            }
        },
        [authDelete, t]
    );

    const upload = useCallback((url, data) => {
        try {
            const formData = new FormData();

            for (let i = 0; i < data?.length; i++) {
                formData.append("files", data[i]);
            }

            return uploadPost(url, {
                baseURL: "",
                data: formData,
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
        } catch (error) {
            getErrorMessage(error, t)
        }
    }, [uploadPost, t]);

    const remove = useCallback((data) => {
        return uploadDelete("/upload/license-and-certification", {
            baseURL: "",
            data
        });
    }, [uploadDelete]);

    return {
        getSkills,
        addSkills,
        getLicenses,
        addLicense,
        updateLicense,
        deleteLicense,
        getCertifications,
        addCertification,
        updateCertification,
        deleteCertification,
        upload,
        remove,
        data,
        loading,
    };
};
