import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import Field from "../../Field";
import ResourceSelect from "../ResourceSelect";
import Modal from "./Modal";
import { useVisible } from "../../../utils/hooks/useVisible";

function ResourceSelectWithImport({ form, label, name, rules, ...props }) {
    const { visible, open, close } = useVisible();

    return (
        <>
            <div className="d-flex align-items-center gap-2 w-100 mb-3">
                <Field label={label} name={name} rules={rules} className="w-100">
                    <ResourceSelect allowClear {...props} />
                </Field>

                {props?.mode === "multiple" && <FontAwesomeIcon
                    className="text-info cursor-pointer"
                    icon={faUpload}
                    onClick={open}
                />}
            </div>

            <Modal
                form={form}
                name={name}
                resourcePath={props.resourcePath}
                getSearchFilters={props?.getSearchFilters}
                isOpen={visible}
                close={close}
            />
        </>
    );
}

export default ResourceSelectWithImport;
