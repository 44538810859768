import { useCallback, useEffect, useMemo, useState } from "react";
import { Card, CardHeader } from "reactstrap";
import { mutate as unboundMutate } from "swr";
import ProfileDrawer from "../../../components/Drawer/ProfileDrawer";
import { TableContext } from "../../../components/Table/context";
import { useActionWithAccess } from "../../../utils/hooks/access";
import { useElementHeight } from "../../../utils/hooks/useElementHeight";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useConfigColumns } from "../../Settings/Configuration/hooks/useConfigColumns";
import { useConfigurationModule } from "../../Settings/Configuration/hooks/useConfigurationModule";
import { useSyncColumnVisibilityWithConfig } from "../../Settings/Configuration/hooks/useSyncColumnVisibilityWithConfig";
import { useGroups } from "../../Settings/General/OrganisationLevelGroups/context";
import Summary from "../Home/Actions/Summary";
import { useTeam } from "../api/useTeam";
import CancelModal from "../components/TimeSheetTable/CancelModal";
import { useOverview } from "../components/Topbar/context";
import { useFilters } from "../filtersContext";
import { useBindPayGroupWithTable } from "../hooks/useBindPayGroupWithTable";
import { useDateFilters } from "../hooks/useDateFilters";
import Actions from "./Actions";
import CopyTimesheet from "./Actions/CopyTimesheet";
import Table from "./Table";
import { useTeamTable } from "./Table/hooks/useTable";
import { useColumns } from "./Table/useColumns";
import { useAddBulk } from "./hooks/useAddBulk";
import { useBulkActions } from "./hooks/useBulkActions";
import { getUserTimeSheetsSWRKey } from "./hooks/useFetchUserTimeSheets";
import { usePayrollExport } from "./hooks/usePayrollExport";
import { useSummary } from "./hooks/useSummary";
import { useImportModal } from "./hooks/useImportModal";

const Content = () => {
	const hideUserProfile = useConfigurationModule("timeSheetTeam.hideUserProfile");
	const { groups } = useGroups();
	const groupWithBudget = useMemo(
		() => groups?.find(({ hasBudget }) => hasBudget),
		[groups],
	);

	const { ref: actionsRef, height: actionsHeight } = useElementHeight();

	const { from, to, mode, payGroup } = useDateFilters();
	const [filters, changeFilters] = useFilters();
	const [state, setState] = useState();
	const [expandedUsers, setExpandedUsers] = useState([]);
	const tableFilters = useMemo(() => ({
		filters,
		...state,
	}), [filters, state]);

	const refetchExpandedUsers = useCallback(() => {
		return Promise.all(expandedUsers.map((user) => {
			return unboundMutate(getUserTimeSheetsSWRKey(user));
		}));
	}, [expandedUsers, unboundMutate]);

	const { fetch: baseFetchOverview } = useOverview();

	const {
		data: { result: data, totalItems },
		setData,
		isLoading: loadingUsers,
		mutate,
	} = useTeam(state && ({
		from,
		to,
		filters,
		...state
	}));

	const {
		visible: isProfileVisible,
		open: openProfile,
		selected: selectedUser,
		close: closeProfile,
	} = useVisible();

	const {
		visible: copyModalVisible,
		selected: selectedUsers,
		open: openCopyModal,
		close: closeCopyModal,
	} = useVisible();

	const {
		visible: isCancelModalVisible,
		open: openCancelModal,
		selected: cancelCallback,
		close: closeCancelModal,
	} = useVisible();

	const fetchOverview = useCallback(
		(config) => {
			baseFetchOverview({
				url: "/time-sheet/team/overview",
				...config,
				params: {
					...config.params,
					budgetBy:
						groupWithBudget &&
						`${groupWithBudget.level}`.replace("_", ""),
				},
			});
		},
		[baseFetchOverview, groupWithBudget],
	);

	const refetchOverview = useCallback(() => {
		fetchOverview({
			params: { from, to, filters: filters.filters },
		});
	}, [fetchOverview, from, to, filters]);

	const refetchUsers = useCallback(() => {
		return mutate();
	}, [mutate]);

	const { approve, unapprove, lock, unlock } = useBulkActions({
		refetch: useCallback(() => {
			return Promise.all([
				refetchOverview(),
				refetchUsers(),
				refetchExpandedUsers(),
			]);
		}, [refetchOverview, refetchUsers, refetchExpandedUsers]),
		filters: tableFilters,
	});

	const {
		visible: summaryVisible,
		selected: summaryData,
		openDaySummary,
		openAllSummary,
		close: closeSummary,
	} = useSummary();

	const columns = useColumns({
		viewSummary: openAllSummary,
		viewProfile: !hideUserProfile && openProfile,
	});

	const table = useTeamTable({
		columns: useConfigColumns("timeSheetTeam", columns),
		data,
		setData,
		totalItems,
		refetchOverview,
		refetchUsers,
		openCancelModal,
	});

	useEffect(() => {
		setExpandedUsers(Object.keys(table.state.expanded));
	}, [table.state.expanded, setExpandedUsers]);

	useSyncColumnVisibilityWithConfig({
		table,
		configuration: "timeSheetTeam",
		userPreferences: "timeSheetTeam",
	});

	const { pageIndex, pageSize, sortBy } = table.state;

	useEffect(() => {
		setState({ pageIndex, pageSize, sortBy });
	}, [pageIndex, pageSize, sortBy]);

	const [addBulk, addBulkModal] = useAddBulk({
		refetch: useCallback(() => {
			refetchOverview();
			refetchUsers();
		}, [refetchOverview, refetchUsers]),
		filters,
	});

	const [_payrollExport, payrollExportModal] = usePayrollExport({
		onSuccess: useCallback(() => {
			refetchOverview();
			refetchUsers();
		}, [refetchOverview, refetchUsers]),
	});
	const payrollExport = useActionWithAccess(
		_payrollExport,
		"settings.payPolicies.payGroup.canLock",
	);

	const [openImportModal, importModal] = useImportModal({
		onSuccess: useCallback(() => {
			refetchOverview();
			refetchUsers();
		}, [refetchOverview, refetchUsers]),
	});

	useEffect(() => {
		const controller = new AbortController();
		fetchOverview({
			params: {
				from,
				to,
				filters,
			},
			signal: controller.signal,
		});
		return () => controller.abort();
	}, [fetchOverview, from, to, filters]);

	useBindPayGroupWithTable({
		filters,
		setFilters: changeFilters,
		mode,
		payGroup,
	});

	return (
		<TableContext.Provider value={table}>
			<Card innerRef={actionsRef} className="mb-0 position-static">
				<CardHeader className="border-top p-3">
					<Actions
						approve={approve}
						unapprove={unapprove}
						lock={lock}
						unlock={unlock}
						addBulk={addBulk}
						payrollExport={payrollExport}
						copy={openCopyModal}
						import={openImportModal}
					/>
				</CardHeader>
			</Card>

			<div style={{ maxHeight: `calc(100% - ${actionsHeight}px)` }}>
				<Table 
					loading={loadingUsers} 
					openDaySummary={openDaySummary} 
					openAllSummary={openAllSummary}
				/>
			</div>

			{isProfileVisible && (
				<ProfileDrawer
					user={selectedUser}
					visible={isProfileVisible}
					close={closeProfile}
				/>
			)}

			{summaryVisible && (
				<Summary
					open={summaryVisible}
					close={closeSummary}
					user={summaryData?.user}
					range={summaryData?.range}
					tableFilters={tableFilters}
					filters={filters}
				/>
			)}

			{addBulkModal}

			{payrollExportModal}

			{importModal}

			<CancelModal
				isOpen={isCancelModalVisible}
				close={closeCancelModal}
				reset={cancelCallback}
			/>

			{copyModalVisible && (
				<CopyTimesheet
					isOpen={copyModalVisible}
					close={closeCopyModal}
					refetch={refetchUsers}
					filters={filters}
					users={selectedUsers}
				/>
			)}
		</TableContext.Provider>
	);
};

export default Content;
