import { useEffect, useRef } from "react";
import { MapContainer, TileLayer, useMap } from "react-leaflet";
import DraggablePolygon from "../../../../General/OrganisationLevels/Form/Map/Polygon";
import MarkerPosition from "../../../../General/OrganisationLevels/Form/Map/Marker";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-path-drag";

const HandleZoom = ({ setZoom }) => {
    const map = useMap();

    useEffect(() => {
        const handleZoom = () => {
            setZoom(map.getZoom() || 11);
        }

        map.on("zoomend", handleZoom);
        return () => {
            map.off("zoomend", handleZoom)
        }
    }, [map, setZoom]);
};

const Map = ({ marker, setCoordinates, coordinates, defaultCenter, zoom, setZoom }) => {
    const ref = useRef();

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        ref.current.setZoom?.(zoom);
    }, [ref, zoom]);

    return (
        <MapContainer
            ref={ref}
            center={defaultCenter}
            zoom={zoom}
            zoomSnap={0.5}
            zoomDelta={0.5}
            style={{ height: "100%" }}
            minZoom={1}
            maxBounds={[
                [-90, -180],
                [90, 180],
            ]}
            maxBoundsViscosity={1.0}
        >
            <TileLayer url={process.env.REACT_APP_MAP_TILE_URL} />
            <MarkerPosition marker={marker} />
            <DraggablePolygon setCoordinates={setCoordinates} coordinates={coordinates} />
            <HandleZoom setZoom={setZoom} />
        </MapContainer>
    );
};

export default Map;
