import Modal from "../../../../components/Modal";
import { useVisible } from "../../../../utils/hooks/useVisible";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/Button";
import { cloneElement, isValidElement, useCallback, useEffect } from "react";
import Form, { useForm } from "rc-field-form";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";

function FiltersModal({ isOpen, close: _close, config, value, onChange }) {
    const { t } = useTranslation();

    const [form] = useForm();

    const close = useCallback(() => {
        form.resetFields();
        _close();
    }, [_close]);

    const clear = useCallback(() => {
        onChange({});
        close();
    }, [onChange]);

    const onFinish = useCallback((values) => {
        const data = Object
            .entries(values)
            .reduce((total, [key, value]) => {
                if (
                    value === ""
                    || (Array.isArray(value) && !value.length)
                    || value === null
                    || value === undefined
                ) {
                    return total;
                }
                total[key] = value;
                return total;
            }, {});
        onChange(data);
        close();
    }, [onChange]);

    useEffect(() => {
        form.setFieldsValue(value);
    }, [value]);

    return (
        <Modal
            className="time-clock-filters-modal"
            size="md"
            toggle={close}
            isOpen={isOpen}
            scrollable
            centered
            footer={(
                <>
                    <Button className="text-dark" color="link" style={{ flex: 1 }} onClick={close}>
                        {t("cancel")}
                    </Button>

                    <Button color="danger" style={{ flex: 1 }} onClick={clear}>
                        {t("clear")}
                    </Button>

                    <Button color="primary" style={{ flex: 2 }} onClick={() => form.submit()}>
                        {t("filter")}
                    </Button>
                </>
            )}
        >
            <Form
                form={form}
                onFinish={onFinish}
                style={{ maxHeight: "50vh", minHeight: 300 }}
                initialValues={value}
            >
                {config.map((item) => {
                    let input;
                    const { filter: component, disableFilters } = item;
                    if (component) {
                        if (!isValidElement(component)) {
                            return component;
                        }
                        if (component.props.disabled !== undefined) {
                            return component;
                        }
                        input = cloneElement(
                            component,
                            { disabled: disableFilters },
                        );
                    } else {
                        input = (
                            <Text
                                className="px-4"
                                type="text"
                                placeholder={t("value")}
                                disabled={disableFilters}
                            />
                        );
                    }

                    return (
                        <div key={item.id} className="d-flex align-items-center mb-3">
                            <Field
                                name={item.id}
                                className="mb-0 mr-2 w-100"
                                label={t(item.label)}
                            >
                                {input}
                            </Field>
                        </div>
                    );
                })}
            </Form>
        </Modal>
    );
}

export const useFiltersModal = ({ config, value, onChange }) => {
    const { visible, open, close } = useVisible();
    const modal = visible && (
        <FiltersModal
            isOpen={visible}
            close={close}
            config={config}
            value={value}
            onChange={onChange}
        />
    );
    return [open, modal];
}
