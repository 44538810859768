export const initialValues = {
	startDay: "sunday",
	color: "#010a63",
	isMain: false,
	overtime: {
		"weekly-overtime": {
			type: "weekly-overtime",
			status: false,
			value: undefined,
			hourTypes: ["regular"],
			payCodes: [],
		},
		"daily-overtime": {
			type: "daily-overtime",
			status: false,
			value: undefined,
			hourTypes: ["regular"],
			payCodes: [],
		},
		"daily-double-time": {
			type: "daily-double-time",
			status: false,
			value: undefined,
			hourTypes: ["regular"],
			payCodes: [],
		},
		"daily-double-time-and-half": {
			type: "daily-double-time-and-half",
			status: false,
			value: undefined,
			hourTypes: ["regular"],
			payCodes: [],
		},
		"six-consecutive-day": {
			type: "six-consecutive-day",
			status: false,
			value: 6,
			hourTypes: ["regular"],
			payCodes: [],
			calculateOtherOvertime: false,
		},
		"consecutive-day": {
			type: "consecutive-day",
			status: false,
			value: 7,
			hourTypes: ["regular"],
			payCodes: [],
			calculateOtherOvertime: false,
		},
		"all-hours-worked-on": {
			type: "all-hours-worked-on",
			status: false,
			value: undefined,
			hourTypes: ["regular"],
			payCodes: [],
			workedHours: undefined,
			payCode: undefined,
		},
	},
	guarantee: {
		"shift-guarantee": {
			type: "shift-guarantee",
			status: false,
			payCode: undefined,
			value: undefined,
		},
		"daily-guarantee": {
			type: "daily-guarantee",
			status: false,
			payCode: undefined,
			value: undefined,
		},
		"weekly-guarantee": {
			type: "weekly-guarantee",
			status: false,
			value: undefined,
			payCode: undefined,
		},
		"weekend-guarantee": {
			type: "weekend-guarantee",
			status: false,
			payCode: undefined,
			value: undefined,
			extra: undefined,
		},
	},
	breaks: {
		automatic: {
			status: false,
			payCode: undefined,
			hideBreakAndDeductTotal: false,
			breaks: [
				{
					after: undefined,
					amount: undefined,
				},
			],
		},
		manual: {
			status: false,
			mandatory: false,
			payCode: undefined,
			duration: undefined,
		},
		rest: {
			status: false,
			breaks: [
				{
					after: undefined,
					amount: undefined,
				},
			],
		},
	},
	shiftPremium: {
		status: false,
		hourTypes: ["regular"],
		payCodes: [],
	},
	mealPremium: {
		status: false,
		hourTypes: ["regular"],
		payCodes: [],
	},
	clockException: {
		status: false,
		hourTypes: ["regular"],
		payCodes: [],
	},
	amountPerUnit: {
		status: false,
		hourTypes: ["unit"],
		payCodes: [],
	},
	mealViolationRule: {
		status: false,
		minimum: undefined,
		after: undefined,
		duration: undefined,
		type: "paid",
	},
	unionRules: {
		deduct: {
			status: false,
			payCode: undefined,
			deduct: undefined,
			mark: undefined,
			amount: undefined,
		},
		add: {
			status: false,
			payCode: undefined,
			start: undefined,
			end: undefined,
		},
		clockOut: {
			status: false,
			data: [{ start: undefined, end: undefined }]
		}
	},
	holidayRules: {
		status: false,
		base: {
			status: false,
			payCode: undefined,
			amount: undefined,
			hourTypes: ["regular"],
			payCodes: [],
		},
		worked: {
			status: false,
			amount: undefined,
			payCode: undefined,
			markedPayCode: undefined,
			hourTypes: ["regular"],
			payCodes: [],
		}
	},
	mealAllowance: {
		payCode: undefined,
		status: false,
		payment: undefined,
		amount: undefined,
	},
	rounding: 0,
	preventTimeClockToTimesheet: false,
	splitOvernight: false,
	weightedRate: false,
};
