import { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, Row, Col, Card } from "reactstrap";
import Button from "../../../components/Button";
import Actions from "../../../components/NTable/Actions";
import NTable from "../../../components/NTable";
import BulkActions from "./BulkActions";
import AddShift from "../AddShift";
import AddAbsence from "../AddAbsence";
import AddSchedule from "../AddSchedule";
import Drawer from "../../../components/Drawer";
import PlansTable from "../../TimeOff/Home/PlansTable";
import { useWarningModal } from "../AddAbsence/WarningModal";
import { useColumns } from "./columns";
import { useMyRequests } from "../api";
import { useDeleteModal } from "../../Settings/helpers/useDeleteModal";
import { formatParams } from "../../../utils/api/format";
import { useVisible } from "../../../utils/hooks/useVisible";
import { OrgLevelGroupsContext } from "../../Settings/General/OrganisationLevelGroups/context";
import { useEditDrawer } from "./hooks/useEditDrawer";
import { useNewTable } from "../../../components/NTable/hook";
import { useInitialState } from "../../../utils/hooks/useInitialState";
import { useQueryFilters } from "../../Settings/helpers/useQueryFilters";
import { useActionWithAccess } from "../../../utils/hooks/access";
import { useSearchParams } from "react-router-dom";
import { useViewDrawer } from "../Team/hooks/useViewDrawer";
import { useCancelModal } from "./hooks/useCancelModal";
import "./styles.scss";

function MyRequest({ timeoff = false, namespace, filterType, hasChanges, setHasChanges }) {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);
	const { visible, selected: addRequestType, open, close } = useVisible();
	const [searchParams] = useSearchParams();
	const [canRefetchPlans, setCanRefetchPlans] = useState(false);

	const {
		data,
		setData,
		totalItems,
		loading,
		fetch,
		deleteRequest,
		batchRemove,
		update,
		error,
		cancelRequest
	} = useMyRequests();

	const {
		open: openCancelModal,
		modal: cancelModal
	} = useCancelModal({ cancel: cancelRequest, setData });

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: (data) =>
			Array.isArray(data)
				? t("do-you-want-to-delete-requests", {
					count: data.length
				})
				: t("do-you-want-to-delete-request"),
		onConfirm: (data) =>
			Array.isArray(data)
				? batchRemove(data, () => {
					setData((prev) =>
						prev?.filter((item) => !data.includes(item?.id)),
					);
					setCanRefetchPlans(true);
				})
				: deleteRequest(data, () => {
					setData((prev) =>
						prev?.filter((item) => item?.id !== data),
					);
					setCanRefetchPlans(true);
				}),
		permission: "request.canDelete"
	});

	const {
		open: openWarningModal,
		warningModal,
	} = useWarningModal({ closeDrawer: () => { }, setData, setCanRefetchPlans })

	const {
		visible: isEditOpen,
		open: openEdit,
		close: closeEdit,
		content: editDrawerContent,
	} = useEditDrawer({ update, error, loading, setData, openWarningModal, setCanRefetchPlans });

	const { open: openViewDrawer, drawer } = useViewDrawer({ timeoff: timeoff || namespace === "pto" });

	const columns = useColumns({
		view: useActionWithAccess(openViewDrawer, "request.canView"),
		edit: useActionWithAccess(openEdit, "request.canEdit"),
		remove: useActionWithAccess(openDeleteModal, "request.canDelete"),
		cancel: openCancelModal,
		timeoff: timeoff || namespace === "pto",
	});

	const sessionFiltersKey = useMemo(() => {
		return !timeoff ? `my${namespace}Requests` : "myTimeoff"
	}, [timeoff, namespace]);

	const table = useNewTable({
		data,
		columns,
		totalItems,
		loading,
		initialState: useInitialState(() => {
			const columnVisibility = groups
				.filter(({ allocatedBy }) => !allocatedBy)
				.reduce((total, group) => {
					const level = group.level.replace("_", "");
					return {
						...total,
						[level]: false,
					};
				}, {});
			return { columnVisibility };
		}),
		sessionFiltersKey,
	});
	const queryFilters = useQueryFilters(table);

	useEffect(() => {
		const controller = new AbortController();
		const filters = {
			namespace,
			...queryFilters,
			filters: [
				...queryFilters.filters.filter((filter) => filter.name !== "type"),
				filterType !== "all" ? {
					name: "type",
					method: "exact",
					value: filterType
				} : {}
			]
		}
		fetch(formatParams(filters), controller);
		return () => controller.abort();
	}, [fetch, queryFilters, filterType, namespace]);

	useEffect(() => {
		const id = searchParams.get("id");
		const type = searchParams.get("type");
		if (id) {
			openViewDrawer({ id, type })
		}
	}, [openViewDrawer, searchParams]);

	useEffect(() => {
		setHasChanges(true);
	}, [namespace, setHasChanges]);

	return (
		<div className="d-flex flex-column h-100">
			<Card className="flex-fill mb-0" style={{ maxHeight: "calc(100% - 300px)" }}>
				{namespace === "pto" &&
					<div className="m-4">
						<PlansTable />
					</div>
				}
				<CardHeader className="border-0 rounded-0 mb-0 pb-1">
					<Row>
						{/* <Col sm={12} md={6}>
							<h3>{t("request")}</h3>
						</Col> */}

						<Col sm={12} md={12} className="text-md-right">
							{namespace === "clock" && (
								<Button
									onClick={() => open("add-shift")}
									className="border-primary px-3 btn-round mb-2 mb-md-2 btn btn-secondary btn-sm text-primary"
								>
									{t("add-shift-request")}
								</Button>
							)}

							{(namespace === "pto" || timeoff) && (
								<Button
									onClick={() => open("add-absence")}
									className="border-primary px-3 btn-round mb-2 mb-md-2 btn btn-secondary btn-sm text-primary ml-0"
								>
									{t("add-absence-request")}
								</Button>
							)}

							{namespace === "schedule" && (
								<Button
									onClick={() => open("add-schedule")}
									className="border-primary px-3 btn-round mb-2 mb-md-2 btn btn-secondary btn-sm text-primary ml-0"
								>
									{t("add-schedule-request")}
								</Button>
							)}
						</Col>
					</Row>
				</CardHeader>

				<Card className="mb-auto h-100">
					<Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
					>
						<BulkActions
							rows={table.getState().rowSelection}
							batchRemove={openDeleteModal}
						/>
					</Actions>

					<NTable
						table={table}
						hasChangedDateFilter={hasChanges}
						setHasChangedDateFilter={setHasChanges}
					/>
				</Card>

				{deleteModal}
			</Card>

			<Drawer
				open={isEditOpen}
				close={closeEdit}
				title={t("edit-request")}
				className="edit-request-drawer"
			>
				{editDrawerContent}
			</Drawer>

			<AddShift
				isOpen={visible && addRequestType === "add-shift"}
				onClose={close}
				setData={setData}
			/>

			<AddAbsence
				isOpen={visible && addRequestType === "add-absence"}
				onClose={close}
				setData={setData}
				setCanRefetchPlans={setCanRefetchPlans}
			/>

			<AddSchedule
				isOpen={visible && addRequestType === "add-schedule"}
				onClose={close}
				setData={setData}
			/>

			{cancelModal}
			{drawer}
			{warningModal}
		</div>
	);
}

export default MyRequest;
