import { useCallback, useEffect, useState } from "react";
import { Card } from "reactstrap";
import { useMyTeamRequests } from "../../../Request/api";
import { useViewDrawer } from "../../../Request/Team/hooks/useViewDrawer";
import Filters from "../../../../components/NTable/Actions/Filters";
import CalendarComponent from "../../CalendarComponent";
import { useColumns } from "../../../Request/Team/columns";
import { useFiltersConfig } from "../../hooks/useFiltersConfig";
import { useDenyModal } from "../../hooks/useDenyModal";
import { useApproveReasonModal } from "../../hooks/useApproveReasonModal";
import { useElementHeight } from "../../../../utils/hooks/useElementHeight";
import { formatParams } from "../../../../utils/api/format";
import { useFilters } from "../../../TimeSheet/filtersContext";
import { noop } from "lodash";
import "../../style.scss";

const MyTeamTimeOffCalendar = () => {
    const { height, ref } = useElementHeight();

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { fetch, approveOne, denyOne, setData, loading, data } = useMyTeamRequests();
    const { open: openDenyModal, modal: denyModal } = useDenyModal({ denyOne, setData });
    const { openApproveReasonModal, approveReasonModal } = useApproveReasonModal({ setData, approve: approveOne });
    const { open: openViewDrawer, drawer } = useViewDrawer({
        approve: openApproveReasonModal,
        deny: openDenyModal,
        timeoff: true,
        team: true,
    });

    const [filters, setFilters] = useFilters();

    const columns = useColumns({
        approve: noop,
        deny: noop,
        view: noop,
        namespace: "pto",
    });

    const { config } = useFiltersConfig({ columns, timeoffTeam: true, isTeam: true });

    const handleEventClick = useCallback(
        (info) => {
            const item = info.event.extendedProps.itemData;

            if(item.type !== "holiday") {
                openViewDrawer(item);
            }
        },
        [openViewDrawer]
    );

    useEffect(() => {
        const controller = new AbortController();
        const calendarParams = { namespace: "pto", filters, startDate, endDate };
        fetch(formatParams(calendarParams), controller);
        return () => controller.abort();
    }, [fetch, filters, startDate, endDate]);

    return (
        <>
            <Card className="p-4 calendar-container position-relative">
                <div ref={ref} className="mb-2">
                    <Filters config={config} filters={filters} setFilters={setFilters} />
                </div>
                <CalendarComponent
                    isTeam={true}
                    data={data}
                    height={height}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    handleEventClick={handleEventClick}
                    loading={loading}
                />
            </Card>

            {approveReasonModal}
            {drawer}
            {denyModal}
        </>
    );
};

export default MyTeamTimeOffCalendar;
