import { useMemo } from "react";
import { useVisible } from "../../../../../utils/hooks/useVisible";
import Modal from "./Modal";

export const useImportModal = ({ onSuccess, onFailure }) => {
    const { visible, open, close } = useVisible();

    const modal = useMemo(() => {
        if (!visible) {
            return null;
        }
        return (
            <Modal
                visible={visible}
                close={close}
                onSuccess={onSuccess}
                onFailure={onFailure}
            />
        );
    }, [visible, close, onSuccess, onFailure]);

    return [open, modal];
}
