import { useTranslation } from "react-i18next";
import { List, Field as BaseField } from "rc-field-form";
import Field from "../../../../../../../components/Field";
import Select from "../../../../../../../components/Inputs/Select";
import Button from "../../../../../../../components/Button";
import Item from "./Item";

function Tier({ disabled }) {
    const { t } = useTranslation();

    return (
        <div className="mb-3">
            <Field
                name="tierBy"
                label={t("tier-by")}
                rules={[
                    {
                        required: true,
                        message: t("required-tier")
                    }
                ]}
            >
                <Select placeholder={t("tier-by")} disabled={disabled}>
                    <Select.Option value="seniority" key="seniority">
                        {t("seniority")}
                    </Select.Option>
                    <Select.Option value="hire-date" key="hire-date">
                        {t("hire-date")}
                    </Select.Option>
                </Select>
            </Field>

            <BaseField shouldUpdate>
                {({ }, { }, { getFieldValue, getFieldsError, setFieldValue }) => (
                    <List name="tiers">
                        {(data, { add }) => {
                            const intervals = getFieldValue("intervals");
                            const addItem = () => {
                                add({})
                                setFieldValue(
                                    ["intervals", intervals.length],
                                    { start: undefined, end: undefined }
                                );
                            }

                            return (
                                <div>
                                    {data.map(({ name }) => {
                                        const [error] = getFieldsError([
                                            ["tiers", name, "start"],
                                            ["tiers", name, "end"],
                                            ["tiers", name, "name"],
                                        ]).map(({ errors }) => errors).flat();

                                        return (
                                            <>
                                                <Item
                                                    name={name}
                                                    data={data}
                                                    disabled={disabled}
                                                    getFieldValue={getFieldValue}
                                                    setFieldValue={setFieldValue}
                                                />

                                                <div className="invalid-feedback d-block">
                                                    {error}
                                                </div>
                                            </>
                                        )
                                    })}

                                    <div className="d-flex justify-content-end">
                                        <Button
                                            color="info"
                                            className="py-2 px-3 mr-4"
                                            onClick={!disabled ? addItem : undefined}
                                        >
                                            + {t("add")}
                                        </Button>
                                    </div>
                                </div>
                            )
                        }}
                    </List>
                )}
            </BaseField>
        </div>
    );
}

export default Tier;
