import { useCallback, useEffect, useMemo, useState } from "react";
import { FeatureGroup, useMap, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import L from "leaflet";

const DraggablePolygon = ({ setCoordinates, coordinates }) => {
    const map = useMap();
    const [drawPolygon, setDrawPolygon] = useState(true);
    const [editingEnabled, setEditingEnabled] = useState(false);

    const onCreate = useCallback(
        (e) => {
            const { layer } = e;

            if (layer instanceof L.Polygon) {
                setDrawPolygon(false);
                const coords = layer.getLatLngs();
                const flatCoords = coords.flat().map((latlng) => ({
                    lat: latlng.lat,
                    lng: latlng.lng,
                }));
                setCoordinates(flatCoords);

                if (!editingEnabled && layer.makeDraggable) {
                    layer.makeDraggable();
                    layer.dragging.enable();

                    layer.on("dragend", function () {
                        const updatedCoords = layer.getLatLngs();
                        const updatedFlatCoords = updatedCoords.flat().map((latlng) => ({
                            lat: latlng.lat,
                            lng: latlng.lng,
                        }));
                        setCoordinates(updatedFlatCoords);
                    });
                } else if (!layer.makeDraggable) {
                    console.error("leaflet-path-drag plugin not loaded properly.");
                }
            }
            layer.addTo(map);
        },
        [map, setCoordinates, editingEnabled]
    );

    const onDelete = useCallback(
        (e) => {
            const layersDeleted = e.layers;
            layersDeleted.eachLayer((layer) => {
                if (layer instanceof L.Polygon) {
                    setDrawPolygon(true);
                    setCoordinates(null);
                }
            });
        },
        [setCoordinates]
    );

    const draw = useMemo(
        () => ({
            polygon: drawPolygon,
            rectangle: false,
            polyline: false,
            circle: false,
            circlemarker: false,
            marker: false,
        }),
        [drawPolygon]
    );

    useEffect(() => {
        if (coordinates?.length > 0) {
            setDrawPolygon(false);
        }
    }, [coordinates?.length]);

    const handleEditStart = useCallback(() => {
        setEditingEnabled(true);
        map.eachLayer((layer) => {
            if (layer instanceof L.Polygon && layer.dragging) {
                layer.dragging.disable();
            }
        });
    }, [map]);

    const handleEditStop = useCallback(() => {
        setEditingEnabled(false);
        map.eachLayer((layer) => {
            if (layer instanceof L.Polygon && layer.dragging) {
                layer.dragging.enable();
            }
        });
    }, [map]);

	return (
        <FeatureGroup>
            <EditControl
                key={drawPolygon}
                position="topright"
                onCreated={onCreate}
                onDeleted={onDelete}
                draw={draw}
                onEdited={(e) => {
                    e.layers.eachLayer((layer) => {
                        if (layer instanceof L.Polygon) {
                            const updatedCoords = layer
                                .getLatLngs()
                                .flat()
                                .map(({ lat, lng }) => [lng, lat]);
                            setCoordinates([[...updatedCoords, updatedCoords[0]]]);
                        }
                    });
                }}
                onEditStart={handleEditStart}
                onEditStop={handleEditStop}
            />

            {coordinates && (
                <Polygon
                    positions={coordinates}
                    draggable={!editingEnabled}
                    eventHandlers={{
                        dragend: (e) => {
                            const updatedCoords = e.target
                                .getLatLngs()
                                .flat()
                                .map(({ lat, lng }) => [lng, lat]);
                            setCoordinates([[...updatedCoords, updatedCoords[0]]]);
                        },
                    }}
                />
            )}
        </FeatureGroup>
    );
};

export default DraggablePolygon;