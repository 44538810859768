import { useCallback, useEffect } from "react";
import FormElement from "rc-field-form";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";

function Form({ mode, values, error, loading, submit, close }) {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const isMasterCompany = useIsMasterCompany();
    const { access } = useModuleAccess("settings.general.jobs");
    const disabled = useIsFieldDisabled({ ...access, mode });

    const onClose = useCallback(() => {
        form.resetFields();
        close();
    }, [form, close]);

    const onFinish = useCallback((formValues) => {
        const data = {
            ...formValues,
            group: formValues.group ? formValues.group.id : null,
            family: formValues.family ? formValues.family.id : null,
            skills: formValues?.skills.map((item) => ({
                id: item?.skill?.id,
                isRequired: item?.isRequired,
            })),
            licenses: formValues?.licenses.map((item) => ({
                id: item?.license?.id,
                isRequired: item.isRequired,
            })),
            certifications: formValues?.certifications.map((item) => ({
                id: item?.certification?.id,
                isRequired: item.isRequired,
            })),
        };
        submit(data);
    }, [submit]);

	useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            status: "active",
            salary: 0,
			...values,
            skills: values?.skills
                ? values.skills.map((item) => {
					return {
						skill: item,
						isRequired: item?.isRequired
					};
                })
                : [
					{
						skill: undefined,
						isRequired: false,
					},
                ],
            licenses: values?.licenses
                ? values.licenses.map((item) => {
                    return {
						license: item,
						isRequired: item?.isRequired
					};
                })
                : [
                    {
						license: undefined,
						isRequired: false,
					},
                ],
            certifications: values?.certifications
                ? values.certifications.map((item) => {
					return {
						certification: item,
						isRequired: item?.isRequired
					};
				})
                : [
					{
						certification: undefined,
						isRequired: false,
					},
				],
        });
    }, [form, values]);

	useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            [
                "code", 
                "description", 
                "status", 
                "group", 
                "family", 
                "skills", 
                "license"
            ],
            error
        );
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <FormElement form={form} className="d-flex flex-column justify-content-between h-100" onFinish={onFinish}>
            <div>
                <Content mode={mode} form={form} disabled={disabled || !isMasterCompany} />
            </div>

            <div className="d-flex justify-content-end">
                <Button
                    onClick={onClose}
                    disabled={loading}
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                >
                    {t("cancel")}
                </Button>

                {!disabled && isMasterCompany && (
                    <Button type="submit" className="btn-dark btn-sm shadow-none" loading={loading}>
                        {t("save")}
                    </Button>
                )}
            </div>
        </FormElement>
    );
}

export default Form;
