import { List } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "../../../../../../../../../../components/Button";
import Item from "./Condition";

function Conditions({ name, form, groups }) {
    const { t } = useTranslation();

    return (
        <List name={[name, "conditions"]}>
            {(conditions, { add: addCondition, remove: removeCondition }) => (
                <div className="amount-per-unit-form__rules__item__content__conditions">
                    {conditions.map(({ name: conditionName }) => (
                        <Item
                            name={name}
                            conditionName={conditionName}
                            form={form}
                            groups={groups}
                            removeCondition={removeCondition}
                        />
                    ))}

                    <Row>
                        <Col md={12}>
                            <Button
                                className="w-100 d-flex justify-content-center"
                                icon={<FontAwesomeIcon icon={faPlus} />}
                                onClick={() => addCondition({ resource: "job" })}
                            >
                                {t("add-condition")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}
        </List>

    );
}

export default Conditions;
