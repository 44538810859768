import { useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { getUploadUrl } from "../../../../utils/helpers/upload";
import "./style.scss";

export default function FileUploader({ selectedFiles, setSelectedFiles }) {
    const handleFileChange = useCallback(
        (event) => {
            setSelectedFiles((prev) => {
                const prevItemsNames = selectedFiles?.length > 0 ? selectedFiles.map(({ name }) => name) : [];

                const newFiles = Object.values(event.target.files).filter(
                    (file) => !prevItemsNames?.includes(file?.name)
                );

                const files = prev ? [...prev, ...newFiles] : newFiles;

                return files;
            });
        },
        [selectedFiles, setSelectedFiles]
    );

    const handleDelete = useCallback(
        (index) => {
            setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
        },
        [setSelectedFiles]
    );

    return (
        <div className="upload-license-certification-input">
            <input
                type="file"
                accept={["application/pdf", "image/jpeg", "image/jpg", "image/png"]}
                multiple
                onChange={handleFileChange}
            />
            <div className="d-flex align-items-center justify-content-center w-100">
                <div className="license-upload-style">
                    {selectedFiles
                        ? selectedFiles.map((file, i) => {
                              let fileUrl = "";
                              let fileName = "";

                                if (typeof file === "string") {
                                  fileUrl = getUploadUrl(file);
                                  fileName = file.split("/");
                                  fileName = fileName[fileName?.length - 1];
                                } else {
                                  fileUrl = URL.createObjectURL(file);
                                  fileName = file.name;
                                }

                                return (
                                    <div key={i} className="preview-files-style px-3">
                                        {file?.type === "application/pdf" || (fileName || "").includes("pdf") ? (
                                            <a
                                                href={fileUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                    textDecoration: "underline",
                                                    color: "blue",
                                                }}
                                            >
                                                {fileName}
                                            </a>
                                        ) : (
                                            <div className="d-flex align-items-center">
                                                <img src={fileUrl} alt={fileName} width={80} />
                                                <span className="mx-3">{fileName}</span>
                                            </div>
                                        )}
                                        <FontAwesomeIcon
                                            onClick={() => handleDelete(i)}
                                            icon={faXmark}
                                            className="text-red cursor-pointer"
                                        />
                                    </div>
                              );
                          })
                        : null}
                </div>
            </div>
        </div>
    );
}
