import { useState, useCallback } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { getGeocoderResultProperties } from "../helpers";
import { useGetLocationFields } from "../../../../../../utils/hooks/useGetLocationFields";
import { fromLatLng } from "../../../../../../utils/helpers/geocode";

const defaultCenter = [40.6974034, -74.1197633];

export const useSearch = (changeMarker) => {
	const [search, setSearch] = useState("");

	const handleSelect = useCallback(
		async (value) => {
			const [result] = await geocodeByAddress(value);
			const marker = await getLatLng(result);

			const { address1 } = getGeocoderResultProperties(result);
			changeMarker(marker);
			setSearch(address1);
		},
		[setSearch, changeMarker],
	);

	return { search, setSearch, handleSelect };
};

export const useLocation = (form) => {
	const [marker, setMarker] = useState(null);
	const [zoom, setZoom] = useState(11);
	const [center, setCenter] = useState(defaultCenter);
	const getFields = useGetLocationFields();

	const changeMarker = useCallback(
		async (marker) => {
			setMarker(marker);
			setCenter(marker);
			setZoom(16);

			const response = await fromLatLng(marker);
			if (!response) {
				return;
			}

			// const {
			// 	results: [result],
			// } = response;

			const geocodeValues = getGeocoderResultProperties(response);
			const fields = await getFields(geocodeValues);

			form.setFieldsValue({ ...geocodeValues, ...fields });
		},
		[form, getFields, setMarker, setCenter, setZoom],
	);

	return {
		marker,
		setMarker,
		zoom,
		setZoom,
		center,
		setCenter,
		changeMarker,
	};
};
