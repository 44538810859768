import { useTranslation } from "react-i18next";
import Automatic from "./Automatic";
import Manual from "./Manual";
import Rest from "./Rest";

function Breaks({ disabled }) {
	const { t } = useTranslation();

	return (
		<div className="border-bottom mb-5">
			<h3 className="text-sm">{t("breaks")}</h3>

			<Automatic disabled={disabled} />

			<Manual disabled={disabled} />

			<Rest disabled={disabled} />
		</div>
	);
}

export default Breaks;
