import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../../../utils/helpers/settings";

export const useGroupingsFilter = () => {
	const { t } = useTranslation();
	return useMemo(
		() => [
			{
				id: "grouping",
				Header: t("groupings-worked"),
				canFilter: true,
				filter: "resource-select",
				Filter: (
					<ResourceSelect
						mode="multiple"
						resourcePath="/grouping?pagination=off"
						renderLabel={renderSettingsResourceLabel}
						valuePropName="id"
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
					/>
				),
			},
			{
				id: "groupingHome",
				Header: t("groupings-home"),
				canFilter: true,
				filter: "resource-select",
				Filter: (
					<ResourceSelect
						resourcePath="/grouping?pagination=off"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
					/>
				),
			},
		],
		[t],
	);
};
