import { useMemo, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import debounce from "lodash/debounce";
import {
	Input,
	Card,
	CardHeader,
	CardBody,
} from "reactstrap";
import List from "./List";
import Pagination from "../../../../components/Table/Pagination";
import Menu from "./Menu";
import Button from "../../../../components/Button";
import Checkbox from "../../../../components/Inputs/Checkbox";
import BulkClockModal from "./BulkClockModal";
import Loading from "../../../../components/Loaders/Loading";
import { useVisible } from "../../../../utils/hooks/useVisible";
import "./style.scss";
import { useFiltersModal } from "./useFiltersModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { getSettingsOrgResourceSearchFilters, getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../utils/helpers/settings";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import { useGroups } from "../../../Settings/General/OrganisationLevelGroups/context";

function Team({
	data,
	loading,
	totalItems,
	setStatus,
	onSearchChange,
	setPage,
	page,
	status,
	openProfileDrawer,
	setData,
	filters,
	setFilters,
}) {
	const { t } = useTranslation();
	const [selectedUsers, setSelectedUsers] = useState([]);

	const { groups, allocatedGroups } = useGroups();
	const isWorking = useMemo(() => {
		return ["clocked-in", "clocked-out", "on-break"].includes(status);
	}, [status]);
	const filterColumns = useMemo(() => {
		return [
			// { id: 'employeeNumber', label: 'Employee number' },
			// { id: 'name', label: 'Name' },
			...groups.map((group) => ({
				id: `${group.level.replace("_", "")}Home`,
				label: `${renderSettingsResourceLabel(group)} (Home)`,
				filter: (
					<ResourceSelect
						mode="multiple"
						resourcePath={`/${group.id}/org-levels`}
						renderLabel={(option) => {
							const { code, description, glSegment } = option;
							return `${glSegment || code}-${description}`;
						}}
						valuePropName="id"
						getSearchFilters={getSettingsOrgResourceSearchFilters}
						hasSearch
						placeholder={t('value')}
					/>
				),
			})),
			...(
				isWorking ? allocatedGroups.map((group) => ({
					id: group.level.replace("_", ""),
					label: `${renderSettingsResourceLabel(group)} (Worked)`,
					filter: (
						<ResourceSelect
							mode="multiple"
							resourcePath={`/${group.id}/org-levels`}
							renderLabel={(option) => {
								const { code, description, glSegment } = option;
								return `${glSegment || code}-${description}`;
							}}
							valuePropName="id"
							getSearchFilters={getSettingsOrgResourceSearchFilters}
							hasSearch
							placeholder={t('value')}
						/>
					),
				})) : []
			),
			isWorking && {
				id: 'job',
				label: 'Job (Worked)',
				filter: (
					<ResourceSelect
						resourcePath="/jobs?pagination=off"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
						placeholder={t('value')}
					/>
				)
			},
			isWorking && {
				id: 'location',
				label: 'Locations (Worked)',
				filter: (
					<ResourceSelect
						resourcePath="/locations?pagination=off"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
						placeholder={t('value')}
					/>
				)
			},
			{
				id: 'jobHome',
				label: 'Job (Home)',
				filter: (
					<ResourceSelect
						resourcePath="/jobs?pagination=off"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
						placeholder={t('value')}
					/>
				)
			},
			{
				id: 'locationHome',
				label: 'Locations (Home)',
				filter: (
					<ResourceSelect
						resourcePath="/locations?pagination=off"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
						placeholder={t('value')}
					/>
				)
			},
			{
				id: 'payGroup',
				label: 'pay-group',
				filter: (
					<ResourceSelect
						mode="multiple"
						resourcePath="/pay-group?pagination=off"
						renderLabel={renderSettingsResourceLabel}
						valuePropName="id"
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
						placeholder={t('value')}
					/>
				),
			},
			isWorking && {
				id: 'grouping', label: 'Groupings (Worked)', filter: (
					<ResourceSelect
						resourcePath="/grouping?pagination=off"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
						placeholder={t('value')}
					/>
				)
			},
			{
				id: 'groupingHome',
				label: 'Groupings (Home)',
				filter: (
					<ResourceSelect
						resourcePath="/grouping?pagination=off"
						mode="multiple"
						renderLabel={renderSettingsResourceLabel}
						getSearchFilters={getSettingsResourceSearchFilters}
						hasSearch
						placeholder={t('value')}
					/>
				)
			},
			{
				id: 'employeeType',
				label: 'Employee type',
				filter: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/employee-type"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
						placeholder={t('value')}
					/>
				)
			},
			{
				id: 'project',
				label: 'Project',
				filter: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/project"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
						placeholder={t('value')}
					/>
				),
			},
			{
				id: 'union',
				label: 'Union',
				filter: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/union"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
						placeholder={t('value')}
					/>
				),
			},
			{
				id: 'userGroups',
				label: 'Group',
				filter: (
					<ResourceSelect
						labelPropName="description"
						renderLabel={renderSettingsResourceLabel}
						resourcePath="/user-group"
						hasSearch
						mode="multiple"
						getSearchFilters={getSettingsResourceSearchFilters}
						placeholder={t('value')}
					/>
				)
			},
		].filter((item) => !!item);
	}, [groups, allocatedGroups, isWorking]);

	useEffect(() => {
		const extraFilters = Object
			.keys(filters)
			.filter((key) => !filterColumns.find((column) => column.id === key));
		if (extraFilters.length) {
			setFilters((prev) => {
				return Object
					.entries(prev)
					.reduce((total, [key, value]) => {
						if (!extraFilters.includes(key)) {
							total[key] = value;
						}
						return total;
					}, {});
			});
		}
	}, [filters, setFilters, filterColumns]);

	const [openFiltersModal, filtersModal] = useFiltersModal({
		config: filterColumns,
		value: filters,
		onChange: setFilters,
	});

	const {
		open: openBulkModal,
		visible: isBulkModalVisible,
		close: closeBulkModal,
	} = useVisible();

	const renderStatus = useMemo(() => {
		let color, text;
		switch (status) {
			case "all":
				text = "all-memebers";
				color = "#9f9f9f";
				break;
			case "clocked-in":
				text = "clocked-in";
				color = "#5428e0";
				break;
			case "clocked-out":
				text = "clocked-out";
				color = "red";
				break;
			case "on-break":
				text = "on-break";
				color = "#F99B16";
				break;
			case "not-active":
				text = "not-active";
				color = "#6B7280";
				break;
			case "time-off":
				text = "time-off";
				color = "#FB7185";
				break;
			default:
				break;
		}

		return (
			<span
				className="font-weight-bolder"
				style={{ color }}
			>
				{t(text)}
			</span>
		);
	}, [t, status]);

	const onCheckboxClick = useCallback(
		(e) => {
			if (e.target.checked) {
				setSelectedUsers(data);
			} else {
				setSelectedUsers([]);
			}
		},
		[data, setSelectedUsers],
	);

	const onChange = useMemo(
		() =>
			debounce((e) => {
				onSearchChange(e.target.value);
			}, 1000),
		[onSearchChange],
	);

	useEffect(() => {
		return () => onChange.cancel();
	}, [onChange]);

	return (
		<Card className="team-members-wrapper card-height h-100">
			<CardHeader className="d-flex justify-content-between align-items-center">
				<div className="d-flex align-items-center">
					<h3 className="mr-1 mb-0">{t("team-members")}</h3>

					<Button
						size="sm"
						type="button"
						color="primary"
						className="my-2"
						disabled={
							status === "time-off" ||
							selectedUsers?.length === 0
						}
						onClick={openBulkModal}
					>
						{t("crew-action")}
					</Button>
				</div>

				<div className="d-flex gap-x-4">
					<button
						className="bg-white rounded-pill d-flex justify-content-center align-items-center"
						style={{ width: 36, height: 30, border: '1px solid rgb(84, 40, 224)', color: 'rgb(84, 40, 224)' }}
						onClick={openFiltersModal}
					>
						<span className="mr-1">{Object.keys(filters).length}</span>
						<FontAwesomeIcon icon={faFilter} />
					</button>

					<div>
						{renderStatus}

						<Menu
							setSelectedUsers={setSelectedUsers}
							setStatus={setStatus}
							setPage={setPage}
						/>
					</div>
				</div>
			</CardHeader>

			<CardBody className="m-0 p-0 h-100">
				<div className="d-flex align-items-center border-bottom w-100">
					<div className="ml-5">
						<Checkbox
							id="team"
							onClick={onCheckboxClick}
							checked={selectedUsers?.length === data?.length && selectedUsers?.length > 0}
							disabled={totalItems === 0}
							labelClassName={classNames(
								selectedUsers?.length > 0 &&
								selectedUsers?.length < data?.length &&
								"select-team-checkbox",
							)}
							hidden={status === "time-off"}
						/>
					</div>

					<div className="d-flex align-items-center px-2 m-0 w-100">
						<i className="fa-solid fa-magnifying-glass text-muted" />
						<Input
							className="w-100 border-none"
							placeholder={t("search-by-name")}
							onChange={onChange}
						/>
					</div>
				</div >

				{
					loading ? (
						<div className="w-100 d-flex align-items-center justify-content-center py-3" >
							<Loading />
						</div>
					) : (
						<List
							data={data}
							setSelectedUsers={setSelectedUsers}
							selectedUsers={selectedUsers}
							status={status}
							openProfileDrawer={openProfileDrawer}
						/>
					)
				}
			</CardBody >

			<Pagination
				page={page}
				perPage={10}
				pageCount={Math.ceil(totalItems / 10)}
				totalItems={totalItems}
				gotoPage={setPage}
				showOnSizeChange={false}
				isListPagination={true}
			/>

			<BulkClockModal
				selectedUsers={selectedUsers}
				close={closeBulkModal}
				isOpen={isBulkModalVisible}
				status={status}
				setSelectedUsers={setSelectedUsers}
				setData={setData}
			/>

			{filtersModal}
		</Card>
	);
}

export default Team;
