import classNames from 'classnames';
import { omit } from 'lodash';
import moment from 'moment-timezone';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from "react-router-dom";
import { Card, CardBody, CardHeader } from "reactstrap";
import Button from '../../../components/Button';
import DateRangeFilters from '../../../components/DateRangeFilters';
import { useDateFilters } from '../../../components/DateRangeFilters/useDateFilters';
import NTable from '../../../components/NTable';
import { useNewTable } from '../../../components/NTable/hook';
import Pagination from '../../../components/NTable/Pagination';
import { useAccess } from '../../../utils/hooks/access';
import { useInitialState } from "../../../utils/hooks/useInitialState";
import { useUser } from '../../../utils/hooks/user';
import { useGenerateReport, useGetReport } from "../api";
import { useGenerateModal } from '../ReportTable/useGenerateModal';
import Filters from './Filters';
import { useColumns } from './useColumns';
import { useBindPayGroupWithTable } from './useBindPayGropWithTable';

const LIMIT = 1200;

const TableView = ({ values, isPreview }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const user = useUser();
  const { hasAccess: canExport } = useAccess("reports.canExport");
  const { hasAccess: canEdit } = useAccess("reports.canEdit");
  const navigate = useNavigate();
  const { open, modal } = useGenerateModal();
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [offset, setOffset] = useState(0);
  const [hasChanges, setHasChanges] = useState(false);
  const { from, to, mode, payGroup } = useDateFilters();

  const {
    generateReport,
    generate,
    data: generatedData,
    totalItems,
    loading,
  } = useGenerateReport();

  const { getReport, report, loading: reportLoading } = useGetReport();

  const processFilterValues = (values) => {
    if (!values) return [];

    const processValue = (v) => {
      if (v == null) return null;

      if (v instanceof Date || moment.isMoment(v)) {
        return moment(v).format("YYYY-MM-DD");
      }

      if (typeof v === "object") {
        if (v.id) {
          return {
            id: v?.id,
            code: v?.code,
            description: v?.description,
            glSegment: v?.glSegment,
            name: v?.name,
          }
        }
        return null;
      }

      if (typeof v === "string") {
        if (moment(v, moment.ISO_8601, true).isValid()) {
          return moment(v).format("YYYY-MM-DD");
        }
        return v;
      }

      return String(v);
    };

    if (Array.isArray(values)) {
      return values.map(processValue).filter(Boolean);
    }

    if (typeof values === "string" && values.includes(",")) {
      return values
        .split(",")
        .map((s) => s.trim())
        .filter(Boolean);
    }

    const result = processValue(values);
    return result ? [result] : [];
  };

  const generateSegment = useCallback((itemsPerPage, page, totalItems) => {
    const segmentSize = Math.ceil(LIMIT / itemsPerPage);
    const totalSegments = Math.ceil(totalItems / itemsPerPage);
    const segmentIndex = Math.floor(page / segmentSize);
    const minPage = segmentIndex * segmentSize;
    const maxPage = Math.min(minPage + segmentSize - 1, totalSegments - 1);
    return { minPage, maxPage };
  }, []);

  const [segment, setSegment] = useState({ minPage: 0, maxPage: 59 });

  const goToPage = useCallback((value) => {
    setPage(value);
    if (!(value <= segment.maxPage && value >= segment.minPage)) {
      const generatedSegment = generateSegment(perPage, value, totalItems);
      setSegment(generatedSegment);
      setOffset(generatedSegment.minPage * perPage);
    }
  }, [segment, generateSegment, perPage, totalItems]);

  const onPerPageChange = useCallback((value) => {
    setPerPage(value);
    setPage(0);
    const generatedSegment = generateSegment(value, 0, totalItems);
    if (generatedSegment.minPage !== segment.minPage || generatedSegment.maxPage !== segment.maxPage) {
      setSegment(generatedSegment);
      setOffset(generatedSegment.minPage * value);
    }
  }, [generateSegment, segment.maxPage, segment.minPage, totalItems]);

  const handleEdit = useMemo(() => (reportId) => {
    navigate(`/reports/edit/${reportId}`);
  }, [navigate]);

  const handleExportItem = useCallback(
    (item) => {
      open({ ...item, type: 'export' });
    },
    [open]
  );

  const columns = useColumns({
    config: useMemo(() => {
      return !values ? report?.reportData?.columns : (values?.dimensions || [])
    }, [report, values])
  });

  const findPageIndex = useCallback(() => {
    const values = [];
    for (let i = segment.minPage; i <= segment.maxPage; i++) {
      values.push(i);
    }
    return values.indexOf(page);
  }, [segment, page]);

  const data = useMemo(() => {
    if (!generatedData || generatedData.length === 0) return [];
    const index = findPageIndex();
    return generatedData.slice(index * perPage, (index * perPage) + perPage);
  }, [findPageIndex, generatedData, perPage]);

  const table = useNewTable({
    data,
    columns,
    totalItems,
    loading: loading || reportLoading,
    enableRowSelection: false,
    enableSorting: false,
    initialState: useInitialState(() => {
      const columnFilters = [
        {
          operator: "contains",
          member: columns?.[0]?.name,
          values: "",
        },
      ];
      return { columnFilters };
    }),
    sessionFiltersKey: `report-${id}`,
  });

  const onFiltersChange = useCallback((values) => {
    setHasChanges(true);
    table.setColumnFilters(values);
    setOffset(0);
  }, [table]);

  const customFilters = table.getState().columnFilters;

  useEffect(() => {
    const controller = new AbortController();
    const shouldSetUndefined = customFilters?.every(filter =>
      !filter.member ||
      !filter.operator ||
      (Array.isArray(filter?.values) && filter?.values.every(value => !value))
    );

    const customFilterData = shouldSetUndefined
      ? undefined
      : customFilters?.map(filter => ({
        ...filter,
        values: !Array.isArray(filter?.values) ? [filter?.values] : filter?.values,
        join: (filter?.join || "AND").toUpperCase(),
      }));

    if (id && !values && !isPreview) {
      generateReport({
        reportId: id,
        limit: LIMIT,
        offset,
        total: true,
        customFilters: customFilterData,
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      }, controller)
    } else {
      const dimensions = values?.dimensions?.map((dim) => omit(dim, ["relationships", "table"])) || [];
      if (values?.calculations?.length > 0) {
        const calculations = values.calculations.map((calc, i) => ({
          ...calc,
          dataIndex: "calculation_" + i
        }));
        dimensions.push(calculations);
      }

      const data = {
        ...values,
        filters: values?.filters?.map((filter, i) => ({
          member: filter.name,
          operator: filter.operator,
          values: processFilterValues(filter?.values),
          join: values?.filters?.[i - 1]?.join || "AND",
        })),
        customFilters: customFilterData,
        dimensions: dimensions?.flat(),
        limit: 10,
        offset: 0,
        total: true,
        order: values?.orders || [],
        measures: values?.measures?.map(measure => ({
          name: `${measure?.name}.${measure?.aggregation}`,
          alias: measure.alias || undefined,
        })) || [],
        union: values?.unions || [],
        from: moment(from).format("YYYY-MM-DD"),
        to: moment(to).format("YYYY-MM-DD"),
      }
      generate(omit(data, ["calculations"]), controller)
    }
    return () => controller.abort();
  }, [generate, generateReport, id, offset, customFilters, values, from, to, isPreview, t]);

  useEffect(() => {
    const controller = new AbortController();
    getReport(id, controller);
    return () => controller.abort();
  }, [id, getReport]);

  useEffect(() => {
    if (hasChanges) {
      table.setPageIndex(0);
      setPage(0);
      setHasChanges(false);
      if (!(0 <= segment.maxPage && 0 >= segment.minPage)) {
        const generatedSegment = generateSegment(perPage, 0, totalItems);
        setSegment(generatedSegment);
        setOffset(generatedSegment.minPage * perPage);
      }
    }
  }, [table.setPageIndex, setHasChanges, generateSegment, hasChanges, perPage, segment, table, totalItems])

  useBindPayGroupWithTable({
    filters: table.getState().columnFilters,
    setFilters: onFiltersChange,
    payGroup,
    mode,
    report
  });

  return (
    <Card
      className={classNames(values && "shadow-none")}
      style={{ height: `calc(100% - 150px)` }}
    >
      <CardHeader className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-4 align-items-center">
          <h3 className="mb-0">{values?.name || report?.name}</h3>
          <div className="d-flex gap-2">
            {
              !isPreview &&
              (
                <>
                  {canEdit && user?.id === report?.createdBy && !report?.reportData?.isDefault && (
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => handleEdit(id)}
                    >
                      {t("edit")}
                    </Button>)}
                  {canExport && (
                    <Button
                      color="secondary"
                      size="sm" onClick={() => handleExportItem(report)}
                    >
                      {t("export")}
                    </Button>)}
                </>
              )
            }
          </div>
        </div>
        <DateRangeFilters setHasChangedDateFilter={setHasChanges} />
      </CardHeader>

      <CardBody
        className={classNames("p-0 m-0", values && "shadow-none")}
        style={{ height: "calc(100% - 150px)" }}
      >
        {!values && (
          <Filters
            report={report}
            columns={report?.reportData?.columns}
            filters={table.getState().columnFilters}
            setFilters={onFiltersChange}
          />
        )}
        <NTable
          table={table}
          pagination={false}
          hasChangedDateFilter={hasChanges}
          setHasChangedDateFilter={setHasChanges}
        />
        {!values && (
          <Pagination
            totalItems={totalItems}
            pageCount={Math.ceil(totalItems / perPage)}
            page={page}
            perPage={perPage}
            showOnSizeChange
            gotoPage={goToPage}
            setPerPage={onPerPageChange}
          />
        )}
      </CardBody>
      {modal}
    </Card>
  );
}

export default TableView;
