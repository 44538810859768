import FormElement from "rc-field-form";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useAuthentication } from "../../../../Authentication/context";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import { OrgLevelGroupsContext } from "../../../General/OrganisationLevelGroups/context";
import { months } from "./Content";
import { weekdays } from "../../../../../components/Inputs/WeekdaySelect";

const getWeeksValue = (startOfWeek, year, values) => {
    const dow = weekdays.indexOf(startOfWeek.toLowerCase());
    const weeksOfYear = getWeeksInYear(Number(year), dow);
    return weeksOfYear.reduce((total, _, i) => {
        const week = `week_${i + 1}`;
        return {
            ...total,
            [week]: {
                amount: values?.weeks?.[week]?.amount || 0,
                hours: values?.weeks?.[week]?.hours || 0,
            },
        };
    }, {});
}

const getMonthsValue = (values) => {
    return months.reduce((total, month) => {
        return {
            ...total,
            [month]: {
                amount: values?.months?.[month]?.amount || 0,
                hours: values?.months?.[month]?.hours || 0,
            },
        };
    }, {});
}

function getWeeksInYear(year, startDayOfWeek) {
    const weeks = [];
    let startDate = moment().year(year).startOf("year");
    const startOfYear = startDate.clone();

    // Adjust to the first occurrence of the desired start day
    while (startDate.weekday() !== startDayOfWeek) {
        startDate.add(1, "day");
    }
    if (startOfYear.format("YYYY-MM-DD") !== startDate.format("YYYY-MM-DD")) {
        weeks.push({
            start: startOfYear.format("YYYY-MM-DD"),
            end: startDate.clone().subtract(1, "day").format("YYYY-MM-DD"),
        });
    }

    // Iterate through the year, adding weeks
    while (startDate.year() === year) {
        const weekStart = startDate.clone();
        const weekEnd = weekStart.clone().add(6, "days"); // Week is 7 days
        weeks.push({
            start: weekStart.format("YYYY-MM-DD"),
            end: weekEnd.year() === year ? weekEnd.format("YYYY-MM-DD") : weekStart.endOf("year").format("YYYY-MM-DD"),
        });
        startDate.add(7, "days"); // Move to the next week
    }

    return weeks;
}

function Form({ mode, values, error, loading, submit, close }) {
    const { t } = useTranslation();
    const isMasterCompany = useIsMasterCompany();
    const { company, user } = useAuthentication();
    const [form] = FormElement.useForm();

    const { access } = useModuleAccess("settings.payPolicies.budget");
    const disabled = useIsFieldDisabled({ ...access, mode });

    const { groups } = useContext(OrgLevelGroupsContext);
    const group = useMemo(() => groups?.find((group) => group.hasBudget), [groups]);

    const onClose = useCallback(() => {
        form.resetFields();
        close();
    }, [form, close]);

    const onFinish = useCallback(
        (values) => {
            values.year = moment(values.year).year();
            const data = {
                ...values,
                company: values?.company?.id,
                location: values?.location?.id,
                payGroup: values?.payGroup?.id,
                level: values?.level?.id,
                orgLevelGroupId: group?.id,
                year: values?.year,
                startOfWeek: values?.startOfWeek,
                type: values?.budgetType,
                weekDays: values?.days.map((day) => day),
                status: "active",
            };
            submit(data);
        },
        [submit, group]
    );

    const _budgetYear = FormElement.useWatch("year", form);
    const budgetYear = useMemo(() => {
        if (!_budgetYear) {
            return undefined;
        }
        return moment(_budgetYear).year();
    }, [_budgetYear]);
    useEffect(() => {
        // initialize budget year
        const year = values
            ? moment({ year: values.year }).startOf("year").toDate()
            : moment().startOf("year").toDate();
        form.setFieldValue("year", year);
    }, [values?.year]);

    const startOfWeek = FormElement.useWatch("startOfWeek", form);
    useEffect(() => {
        // initialize start of week
        let startOfWeek;
        if (values) {
            startOfWeek = values.startOfWeek;
        } else {
            startOfWeek = user?.calculationGroup?.startDay?.toLowerCase()
                || company?.settings?.startDay?.toLowerCase()
                || "sunday";
        }
        form.setFieldValue("startOfWeek", startOfWeek);
    }, [values?.startOfWeek]);

    const weekDates = useMemo(() => {
        if (!startOfWeek || !budgetYear) {
            return [];
        }
        return getWeeksInYear(budgetYear, weekdays.indexOf(startOfWeek.toLowerCase()));
    }, [startOfWeek, budgetYear]);

    useEffect(() => {
       const initial = {
            budgetType: values?.type || "weekly",
            payGroup: values?.payGroup,
            company: values?.company,
            days: values?.weekDays || weekdays,
            level: values?.level,
            location: values?.location,
        };
        if (startOfWeek && budgetYear) {
            initial.months = getMonthsValue(values);
            initial.weeks = getWeeksValue(startOfWeek, budgetYear, values);
        }
        form.setFieldsValue(initial);
    }, [form, values, startOfWeek, budgetYear]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(["location", "level", "year"], error);
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <FormElement form={form} className="d-flex flex-column justify-content-between h-100" onFinish={onFinish}>
            <div
                className="px-4 py-3"
                style={{
                    height: "calc(100% - 55px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                }}
            >
                <Content mode={mode} disabled={disabled || !isMasterCompany} weekDates={weekDates} />
            </div>

            <div className="d-flex justify-content-end p-3">
                <Button
                    onClick={onClose}
                    disabled={loading}
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                >
                    {t("cancel")}
                </Button>

                {(!disabled || isMasterCompany) && (
                    <Button type="submit" className="btn-dark btn-sm shadow-none" loading={loading}>
                        {t("save")}
                    </Button>
                )}
            </div>
        </FormElement>
    );
}

export default Form;
