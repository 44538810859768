import { useState, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { omit } from "lodash";
import Details from "./Details";
import OptionalSettings from "./OptionalSettings";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";
import "./style.scss";

function Form({ mode, values, error, loading, submit, close }) {
    const { t } = useTranslation();
    const [step, setStep] = useState(0);
    const [timeOffData, setTimeOffData] = useState(values || {});
    const isMasterCompany = useIsMasterCompany();
    const { access } = useModuleAccess("settings.payPolicies.timeOff");
    const disabled = useIsFieldDisabled({ ...access, mode });

    const goBack = useCallback(() => {
        if (step > 0) {
            setStep((prev) => prev - 1);
        }
    }, [step]);

    const onFinish = useCallback(
        (formValues) => {
            const formattedData = {
                ...timeOffData,
                ...formValues,
                payCode: timeOffData.payCode.id,
            };
            formattedData.accural = {
                ...formattedData.accural,
                earningGroups: formattedData.accural.earningGroups?.map((item) => item.id) || undefined,
                payCodes: formattedData.accural.payCodes?.map((item) => item.id) || undefined
            }
    
            formattedData.reset = {
                type: formattedData.reset.type,
                value: formattedData?.reset?.value ? moment(formattedData?.reset?.value).format("YYYY-MM-DD") : null
            }

            if (formattedData.minBalance !== undefined) {
                formattedData.minBalance = Number(formattedData.minBalance);
            }

            if (formattedData.minRequestedHours !== undefined) {
                formattedData.minRequestedHours = Number(formattedData.minRequestedHours);
            }

            formattedData.prohibition = formattedData?.prohibition?.value ? {
                value: Number(formattedData.prohibition.value),
                payCode: formattedData?.prohibition?.payCode?.id,
                allowRequests: formattedData?.prohibition?.allowRequests || false,
                allowAccrual: formattedData?.prohibition?.allowAccrual || false
            } : null;

            if (!formValues?.maxBalance) {
                formattedData.maxBalance = null;
            }

            if (!formValues?.maxRequestedHours) {
                formattedData.maxRequestedHours = null;
            }

            if (!formValues?.carryover) {
                formattedData.carryover = null;
            }

            submit(omit(formattedData, ["intervals"]));
            close();
        },
        [timeOffData, submit, close]
    );

    const stepSubmit = useCallback(
        (data) => {
            setTimeOffData((prev) => ({ ...prev, ...data }));
            if (step < 1) {
                setStep((prev) => prev + 1);
            } else {
                onFinish(data);
            }
        },
        [setStep, setTimeOffData, step, onFinish]
    );

    const stepComponent = useMemo(() => {
        switch (step) {
            case 0: {
                return (
                    <Details
                        mode={mode}
                        data={
                            mode === "clone" ? { ...timeOffData, code: "" } : timeOffData
                        }
                        submit={stepSubmit}
                        submitting={loading}
                        goBack={close}
                        error={error}
                        disabled={disabled || !isMasterCompany}
                    />
                );
            }
            case 1: {
                return (
                    <OptionalSettings
                        data={timeOffData}
                        submit={stepSubmit}
                        goBack={goBack}
                        error={error}
                        disabled={disabled || !isMasterCompany}
                    />
                );
            }
            default:
                return null;
        }
    }, [
        step,
        mode,
        timeOffData,
        loading,
        goBack,
        error,
        stepSubmit,
        close,
        disabled,
        isMasterCompany,
    ]);

    return (
        <>
            <div className="content">
                <div className="dashed w-100 p-4">
                    <div className="justify-content-end d-flex gap-1 mb-3">
                        <h2>{`${t("step")} ${step + 1}`}</h2>
                        <h2>{`${t("of")}`}{" 2"}</h2>
                    </div>
                    <div style={{ position: "relative" }}>
                        <div className="progress">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: `${(step + 1) * 50}%` }}
                            />
                        </div>
                    </div>
                </div>
                <div>{stepComponent}</div>
            </div>
        </>
    );
}

export default Form;
