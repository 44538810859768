import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import PhoneInput from "react-phone-input-2";
import Field from "../../../../../components/Field";
import Button from "../../../../../components/Button";
import Text from "../../../../../components/Inputs/Text";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { ProfileContext } from "../../../context";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { useCompany } from "../../../../../utils/hooks/company";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";
import "./style.scss";

function EditPersonalInformation({ close }) {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const company = useCompany();

	const { user, submitting, submittingError, submit } =
		useContext(ProfileContext);
	const { changePersonalInformation: profileSubmit } = useProfileApi();
	const { changePersonalInformation: peopleSubmit } = usePeopleApi();

	const onFinish = useCallback(
		(data) => {
			const values = {
				firstName: data?.firstName,
				middleName: data?.middleName,
				lastName: data?.lastName,
				phone: data?.phone,
				badgeId: data?.badgeId,
				race: data?.race?.id,
				gender: data?.gender?.id
			};
			submit(profileSubmit, peopleSubmit, values, close);
		},
		[submit, profileSubmit, peopleSubmit, close],
	);

	useEffect(() => {
		form.setFieldsValue({
			employeeNumber: user?.employeeNumber,
			badgeId: user?.badgeId,
			firstName: user?.firstName,
			middleName: user?.middleName,
			lastName: user?.lastName,
			email: user?.email,
			phone: user?.phone,
			race: user?.race,
			gender: user?.gender
		});
	}, [form, user]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			[
				"employeeNumber",
				"firstName",
				"middleName",
				"lastName",
				"phone",
				"email",
			],
			submittingError,
		);
		form.setFields(fieldErrors);
	}, [form, submittingError]);

	return (
		<Form
			form={form}
			layout="vertical"
			onFinish={onFinish}
			scrollToFirstError
		>
			<Field name="employeeNumber" label={t("employee-number")}>
				<Text
					value={user?.employeeNumber}
					placeholder={t("employee-number")}
					disabled
				/>
			</Field>

			<Field
				name="badgeId"
				label={t("badge-id")}
				rules={[
					{
						required: true,
						message: t("required-badge-id"),
					},
					{
						message: t(
							"badge-id-length",
							{
								badgeIdLength:
									Number(company?.badgeIdLength || 4),
							},
						),
						min: Number(company?.badgeIdLength || 4),
						max: Number(company?.badgeIdLength || 4),
					},
				]}
			>
				<Text type="number" placeholder={t("badge-id")} />
			</Field>

			<Field
				name="firstName"
				label={t("first-name")}
				rules={[
					{
						required: true,
						message: t("required-firstname"),
					},
				]}
			>
				<Text placeholder={t("first-name")} />
			</Field>

			<Field name="middleName" label={t("middle-name")}>
				<Text placeholder={t("middle-name")} />
			</Field>

			<Field
				name="lastName"
				label={t("last-name")}
				rules={[
					{
						required: true,
						message: t("required-lastname"),
					},
				]}
			>
				<Text placeholder={t("last-name")} />
			</Field>

			<Field name="gender" label={t("gender")}>
                <ResourceSelect
                    resourcePath="/user-demographic/gender"
                    renderLabel={renderSettingsResourceLabel}
                    hasSearch
                    getSearchFilters={getSettingsResourceSearchFilters}
                />
            </Field>
			
            <Field name="race" label={t("ethnicity")}>
                <ResourceSelect
                    resourcePath="/user-demographic/race"
                    renderLabel={renderSettingsResourceLabel}
                    hasSearch
                    getSearchFilters={getSettingsResourceSearchFilters}
                />
            </Field>

			<Field
				label={t("email")}
				name="email"
				rules={[
					{
						required: true,
						message: t("required-email"),
					},
				]}
			>
				<Text placeholder={t("email")} value={user?.email} disabled />
			</Field>

			<Field
				name="phone"
				label={t("phone-number")}
				rules={[
					{
						required: true,
						message: t("required-phone-number"),
					},
				]}
			>
				<PhoneInput country="us" enableSearch />
			</Field>

			<div className="d-flex justify-content-end">
				<Button
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
					onClick={close}
					disabled={submitting}
				>
					{t("cancel")}
				</Button>

				<Button
					color="primary"
					type="submit"
					loading={submitting}
					className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
				>
					{t("save")}
				</Button>
			</div>
		</Form>
	);
}

export default EditPersonalInformation;
