import { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { ProfileContext } from "../../../context";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import Button from "../../../../../components/Button";
import Field from "../../../../../components/Field";
import { renderDurationAsFormat } from "../../../../../utils/helpers/date";
import DurationInput, { getDurationInputValue } from "../../../../../components/Inputs/DurationInput";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";

function ExtendedDurationInput({ value: _value, onChange: _onChange, ...props }) {
    const value = useMemo(() => {
        return renderDurationAsFormat(_value || 0, "HH:mm");
    }, [_value]);

    const onChange = useCallback(
        (value) => {
            if (!_onChange) {
                return;
            }
            _onChange(getDurationInputValue(value));
        },
        [_onChange]
    );

    return <DurationInput value={value} onChange={onChange} {...props} />;
}

const ChangeExpectedHours = ({ close }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { user, submit, submitting, submittingError } = useContext(ProfileContext);

    const { changeExpectedHours: profileSubmit } = useProfileApi();
    const { changeExpectedHours: peopleSubmit } = usePeopleApi();

    const onFinish = useCallback(
        (values) => {
            submit(profileSubmit, peopleSubmit, values, close);
        },
        [submit, profileSubmit, peopleSubmit, close]
    );

    useEffect(() => {
        form.setFieldValue("expectedHours", user?.expectedHours);
    }, [form, user]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(["expectedHours"], submittingError);
        form.setFields(fieldErrors);
    }, [form, submittingError]);

    return (
        <Form form={form} onFinish={onFinish}>
            <Field name="expectedHours" label={t("expected-hours")}>
                <ExtendedDurationInput />
            </Field>
            <div className="d-flex justify-content-end">
                <Button
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                    onClick={close}
                    disabled={submitting}
                >
                    {t("cancel")}
                </Button>

                <Button
                    className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
                    color="primary"
                    type="submit"
                    loading={submitting}
                >
                    {t("save")}
                </Button>
            </div>
        </Form>
    );
};

export default ChangeExpectedHours;
