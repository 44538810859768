import { useContext, useMemo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseForm from "rc-field-form";
import Search from "./Map/Search";
import Field from "../../../../../components/Field";
import Text from "../../../../../components/Inputs/Text";
import Button from "../../../../../components/Button";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import StatusField from "../../../../../components/Field/StatusField";
import {
    renderSettingsResourceLabel,
    getSettingsResourceSearchFilters,
    renderOrgLevelResourceLabel,
    getSettingsOrgResourceSearchFilters,
} from "../../../../../utils/helpers/settings";
import { OrgLevelGroupsContext } from "../../../General/OrganisationLevelGroups/context";
import { useLocation, useSearch } from "./Map/helpers";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import Map from "./Map";
import { Wrapper } from "@googlemaps/react-wrapper";

const defaultCenter = [40.6974034, -74.1197633];
const fields = ["id", "code", "description", "glSegment", "location", "mapCenter", "mapZoom"];

function Form({ values, submit, loading, error, close }) {
    const { t } = useTranslation();
    const [coordinates, setCoordinates] = useState(null);
    const [form] = BaseForm.useForm();
    const { groups } = useContext(OrgLevelGroupsContext);
    const [polygonError, setPolygonError] = useState(false);

    const onClose = useCallback(() => {
        form.resetFields();
        close();
    }, [form, close]);

    const calculateMarker = useCallback((polygonCoords) => {
        if (!Array.isArray(polygonCoords) || polygonCoords.length === 0) {
            return null;
        }

        let x = 0,
            y = 0,
            area = 0;
        const points = [...polygonCoords, polygonCoords[0]];

        for (let i = 0; i < points.length - 1; i++) {
            const [x1, y1] = points[i];
            const [x2, y2] = points[i + 1];

            const a = x1 * y2 - x2 * y1;
            x += (x1 + x2) * a;
            y += (y1 + y2) * a;
            area += a;
        }

        area /= 2;
        if (area === 0) {
            return null;
        }

        x /= 6 * area;
        y /= 6 * area;

        return [x, y];
    }, []);

    const coords = useMemo(() => {
        const validCoord = (coordinates || values?.polygon?.coordinates || []).filter((c) => {
            return Array.isArray(c) && c.every((l) => l !== null);
        });

        if (validCoord.length) {
            const coordinates = validCoord[0]?.map((coordinate) => {
                return {
                    lat: coordinate[1],
                    lng: coordinate[0],
                };
            });

            return coordinates?.slice(0, -1);
        }
        return undefined;
    }, [values?.polygon?.coordinates, coordinates]);

    const formattedCoords = useMemo(() => {
        let coords = [];
        if (Array.isArray(coordinates?.[0]?.[0])) {
            return coordinates[0];
        } else {
            coordinates?.forEach(({ lat, lng }) => coords.push([lng, lat]));
        }
        return coords;
    }, [coordinates]);

    const { marker, setMarker, zoom, setZoom, center, setCenter, changeMarker } = useLocation(form);

    const onFinish = useCallback(
        (formValues) => {
            const centroid = calculateMarker(formattedCoords);

            const data = {
                ...formValues,
                marker: centroid ? { lat: centroid[1], lng: centroid[0] } : marker,
                mapCenter: center,
                mapZoom: zoom,
                location: formValues?.location?.id,
                timezone: formValues?.timezone?.id,
                project: formValues?.project?.id,
                polygon: {
                    type: "Polygon",
                    coordinates:
                    coordinates === null
                            ? values?.polygon?.coordinates
                            : [[...formattedCoords, formattedCoords[0]]],
                },
            };

            if(!values?.polygon?.coordinates && formattedCoords?.length === 0) {
                setPolygonError(true);
            } else {
                setPolygonError(false);          
                submit(data);
            }
        },
        [submit, marker, zoom, center, formattedCoords, coordinates, values?.polygon?.coordinates, calculateMarker]
    );

    const onLevelChange = useCallback((value) => {
        const validCoord = (value?.location?.coordinates || []).filter(
            (c) => Array.isArray(c) && c.every((l) => l !== null)
        );

        if (validCoord?.length > 0) {
            setCoordinates(validCoord);
        } else {
            setCoordinates(null);
        }
    }, []);

    const { search, setSearch, handleSelect } = useSearch(changeMarker);

    const group = useMemo(() => groups?.find(({ useInClockIn }) => useInClockIn), [groups]);

    useEffect(() => {
        const centroid = formattedCoords.length ? calculateMarker(formattedCoords) : null;
        if (centroid) {
            setMarker({ lat: centroid[1], lng: centroid[0] });
            setCenter({ lat: centroid[1], lng: centroid[0] });
        } else {
            setMarker(values?.marker || null);
            setCenter(values?.mapCenter || defaultCenter);
            setZoom(values?.mapZoom || 11);
        }
    }, [
        setMarker,
        setCenter,
        setZoom,
        formattedCoords,
        values?.marker,
        values?.mapCenter,
        values?.mapZoom,
        calculateMarker,
    ]);

    useEffect(() => {
        form.setFieldsValue({
            status: "active",
            timeDifference: values?.timeDifference ? Number(values?.timeDifference) : 60,
            ...values,
        });
    }, [form, values, coords]);
    
    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["description", "status", "project", "timezone", "location"],
            error
        );
        form.setFields(fieldErrors);
    }, [error, form]);

    return (
        <BaseForm form={form} onFinish={onFinish} style={{ flex: 1 }}>
            <Field
                label={t("description")}
                name="description"
                rules={[{ required: true, message: t("required-description") }]}
            >
                <Text placeholder={t("description")} />
            </Field>

            <Field label={t("location")} name="location">
                <ResourceSelect
                    labelPropName="description"
                    resourcePath="/locations"
                    renderLabel={renderSettingsResourceLabel}
                    placeholder={t("location")}
                    hasSearch
                    getSearchFilters={getSettingsResourceSearchFilters}
                />
            </Field>

            <Field
                label={t("timezone")}
                name="timezone"
                rules={[
                    {
                        required: true,
                        message: t("required-timezone"),
                    },
                ]}
            >
                <ResourceSelect
                    labelPropName="text"
                    resourcePath="/timezone?pagination=false"
                    hasCompany={false}
                    hasSearch
                    placeholder={t("timezone")}
                />
            </Field>

            {group && (
                <Field name="project" label={t(group.code + " - " + group.description)}>
                    <ResourceSelect
                        labelPropName="description"
                        resourcePath={`${group.id}/org-levels`}
                        renderLabel={renderOrgLevelResourceLabel}
                        getSearchFilters={(search) => getSettingsOrgResourceSearchFilters(search, fields)}
                        hasSearch
                        onChange={onLevelChange}
                        placeholder={group.code + " - " + group.description}
                    />
                </Field>
            )}

            <Wrapper apiKey={`${process.env.REACT_APP_GOOGLE_MAP_KEY}&libraries=places,drawing`}>
                <Search search={search} setSearch={setSearch} handleSelect={handleSelect} />
            </Wrapper>
            
            <div style={{ height: "380px" }}>
                <Map
                    marker={marker}
                    setCoordinates={setCoordinates}
                    coordinates={coords}
                    defaultCenter={defaultCenter}
                    setZoom={setZoom}
                    zoom={zoom}
                />
            </div>
            {polygonError && <div className="text-danger my-2">{t("area-required")}</div>}

            <Field
                name="timeDifference"
                label={t("time-difference")}
                rules={[
                    {
                        required: true,
                        message: t("required-time-difference"),
                    },
                    {
                        validator(_, value) {
                            if (Number(value) < 0) {
                                return Promise.reject(new Error(t("time-difference-pattern")));
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <Text type="number" placeholder={t("time-difference")} />
            </Field>

            <StatusField />

            <div className="d-flex justify-content-end">
                <Button
                    onClick={onClose}
                    disabled={loading}
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                >
                    {t("cancel")}
                </Button>

                <Button type="submit" className="btn-dark btn-sm shadow-none" loading={loading}>
                    {t("save")}
                </Button>
            </div>
        </BaseForm>
    );
}

export default Form;
