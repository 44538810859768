import { useMemo } from "react";
import moment from "moment-timezone";
import { useDateFilters } from "../../../hooks/useDateFilters";
import { enumerateDaysBetweenDates } from "../../../ManagerView/Table/useColumns";

export const useDateAvailabilityMap = ({ user, payGroupItems }) => {
    const { from, to } = useDateFilters();
    const payGroup = user?.payGroup;
    return useMemo(() => {
        if (!payGroupItems || !payGroup) {
            return {};
        }
        const dates = enumerateDaysBetweenDates(moment(from), moment(to));

        const currentPayGroupDates = enumerateDaysBetweenDates(
            moment.parseZone(payGroup.periodStartDate).utcOffset(moment().utcOffset()),
            moment.parseZone(payGroup.periodEndDate).utcOffset(moment().utcOffset()),
        );
        const base = currentPayGroupDates.reduce((total, date) => {
            total[date] = false;
            return total;
        }, {});

        return dates.reduce((total, date) => {
            const item = payGroupItems.find((item) => {
                const start = moment.parseZone(item.periodStartDate);
                const end = moment.parseZone(item.periodEndDate);
                const _date = moment(date, "YYYY-MM-DD").utcOffset(start.utcOffset(), true);
                return _date.isSameOrAfter(start, "date") 
                    && _date.isSameOrBefore(end, "date");
            });
            if (item) {
                total[date] = item.status
                    ? item.status === "locked"
                    : false
            } else {
                // total[date] = moment(date, "YYYY-MM-DD")
                //     .isSameOrAfter(moment.parseZone(payGroup.periodStartDate).startOf("day"));
                total[date] = false;
            }
            return total;
        }, base);
    }, [from, to, payGroupItems, payGroup]);
}
