import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
    faCheckCircle,
    faEdit,
    faInbox,
    faLock,
    faRotateRight,
    faUnlock,
    faUserCheck,
    faUserXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Disclosure } from "@headlessui/react";
import moment from "moment-timezone";
import Loading from "../../../../components/Loaders/Loading";
import Avatar from "../../../../components/Avatar";
import { renderUserName } from "../../../../utils/helpers/user";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../../utils/hooks/company";
import { useTimeOffHistoryApi } from "./api";
import { ProfileContext } from "../../context";

const HistoryContent = ({ planId, filters }) => {
    const { t } = useTranslation();
    const { getHistory, history, loadingHistory } = useTimeOffHistoryApi();
    const { isSelf, user } = useContext(ProfileContext);

    const dateFormat = useCompanyDateFormat();

    const timeFormat = useCompanyTimeFormat();

    useEffect(() => {
        const controller = new AbortController();
        if (planId) {
            const params = {
                plan: planId,
                year: filters.year,
                start: filters?.from && moment(filters.from).format("YYYY-MM-DD"),
                end: filters?.to && moment(filters.to).format("YYYY-MM-DD"),
                type: filters?.type,
            };
            const url = isSelf ? "/profile/time-off-history" : `/users/${user?.id}/time-off-history/`;

            getHistory(url, params, controller);
        }
        return () => controller.abort();
    }, [getHistory, planId, isSelf, user?.id, filters.year, filters?.from, filters?.to, filters?.type]);

    const renderHistoryTypes = useCallback(
        (item) => {
            switch (item.type) {
                case "approve-request": {
                    return (
                        <div>
                            {t("approved-request", {
                                user: renderUserName(item.user),
                                hours: -item.diff,
                            })}{" "}
                            <FontAwesomeIcon icon={faCheckCircle} className="ml-1 mt-1 text-green" />
                        </div>
                    );
                }
                case "approve-cancel-request": {
                    return (
                        <>
                            {t("approved-cancel-request", {
                                hours: item.diff,
                            })}{" "}
                            <FontAwesomeIcon icon={faCheckCircle} className="ml-1 mt-1 text-green" />
                        </>
                    );
                }
                case "assign": {
                    return (
                        <>
                            {t("assigned-this-plan", {
                                user: renderUserName(item.user),
                                hours: item.diff,
                            })}
                            <FontAwesomeIcon icon={faUserCheck} className="ml-2 text-green" />
                        </>
                    );
                }
                case "unassign": {
                    return (
                        <>
                            {t("unassigned-this-plan", {
                                user: renderUserName(item.user),
                                hours: item.hours,
                            })}
                            <FontAwesomeIcon icon={faUserXmark} className="ml-2 text-danger" />
                        </>
                    );
                }
                case "lock-pay-group": {
                    return (
                        <>
                            {t("locked-pay-group", {
                                user: renderUserName(item.user),
                                amount: item.diff,
                            })}
                            <FontAwesomeIcon icon={faLock} className="ml-1 mt-1" />
                        </>
                    );
                }
                case "unlock-pay-group": {
                    return (
                        <>
                            {t("unlocked-pay-group", {
                                user: renderUserName(item.user),
                                amount: -item.diff,
                            })}
                            <FontAwesomeIcon icon={faUnlock} className="ml-1 mt-1" />
                        </>
                    );
                }
                case "update-total": {
                    return (
                        <>
                            {t("updated-total", {
                                user: renderUserName(item.user),
                                oldTotal: item.oldBalance,
                                newTotal: item.newBalance,
                            })}
                            <FontAwesomeIcon icon={faEdit} className="ml-2 text-orange" />
                        </>
                    );
                }
                case "regenerate-from-year-change": {
                    return (
                        <>
                            {t("regenerate-from-year-change")}
                            <FontAwesomeIcon icon={faRotateRight} className="ml-2 text-primary" />
                        </>
                    );
                }
                default:
                    return null;
            }
        },
        [t]
    );

    return (
        <Disclosure.Panel key={planId} className="history-accordion-panel">
            {loadingHistory ? (
                <div className="d-flex h-100 align-items-center justify-content-center">
                    <Loading />
                </div>
            ) : history?.data?.length > 0 ? (
                <div>
                    {history?.data?.map((item, i) => {
                        return (
                            <div key={i} className="d-flex gap-4 mb-1 border px-2 py-3 rounded shadow-sm">
                                {item?.type !== "regenerate-from-year-change" ? (
                                    <Avatar user={item.user} size={45} />
                                ) : null}
                                <div
                                    className={`d-flex flex-column pt-1 ${
                                        item?.type === "regenerate-from-year-change" && "ml-3"
                                    }`}
                                >
                                    <div className="d-flex gap-2">
                                        <span>{renderHistoryTypes(item)}</span>
                                    </div>
                                    <div className="d-flex gap-2 text-sm">
                                        <span>{moment(item.date).format(dateFormat)}</span>
                                        <span>{moment(item.date).format(timeFormat)}</span>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <div className="d-flex h-100 text-gray flex-column align-items-center justify-content-center">
                    <FontAwesomeIcon icon={faInbox} size="2xl" />
                    <span>{t("no-data")}</span>
                </div>
            )}
        </Disclosure.Panel>
    );
};

export default HistoryContent;
