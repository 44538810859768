import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../../../components/Field";
import Checkbox from "../../../../../../../../components/Inputs/Checkbox";
import ResourceSelect from "../../../../../../../../components/Inputs/ResourceSelect";
import Modal from "../../../../../../../../components/Modal";
import Details from "./Details";
import { 
    renderSettingsResourceLabel, 
    getSettingsResourceSearchFilters, 
    getPathWithParams
} from "../../../../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../../../helper";
import { useVisible } from "../../../../../../../../utils/hooks/useVisible";

function BaseRule({ disabled, holidayStatus }) {
    const { t } = useTranslation();
    const { visible, open: openModal, close } = useVisible();

    return (
        <BaseField shouldUpdate>
        {({}, {}, { getFieldValue, getFieldsError }) => {
            const values = getFieldValue("holidayRules")
            const status = getFieldValue(["holidayRules", "base", "status"]);
            const [error] = getFieldsError([
                ["holidayRules", "base", "amount"],
                ["holidayRules", "base", "payCode"],
            ])
                .map(({ errors }) => errors)
                .flat();

            return (
                <>
                    <div className="d-flex align-items-center mb-2">
                        <Field
                            name={["holidayRules", "base", "status"]}
                            valuePropName="checked"
                            noStyle
                            className="mb-0 pt-2"
                        >
                            <Checkbox disabled={disabled} />
                        </Field>

                        <div className="rule-container d-flex align-items-center mb-2 justify-content-between">
                            <div className="d-flex align-items-center mb-2 flex-wrap">
                                <p className="mr-2 text-sm mb-0">
                                    {t("if-employee-dosent-work-on-holiday")}
                                </p>

                                <BaseField
                                    name={["holidayRules", "base", "amount"]}
                                    className="mb-0"
                                    rules={
                                        status
                                            ? [
                                                {
                                                    required: status && holidayStatus,
                                                    message:
                                                        t("required-amount"),
                                                },
                                                {
                                                    validator(_, value) {
                                                        if (Number(value) < 0) {
                                                            return Promise.reject(new Error(t("amount-bigger-than-zero")))
                                                        }
                                                        return Promise.resolve()
                                                    }
                                                }
                                            ]
                                            : undefined
                                    }
                                    noStyle
                                    dependencies={[
                                        ["holidayRules", "base", "status"],
                                    ]}
                                >
                                    <Input
                                        type="number"
                                        size="sm"
                                        readOnly={!(status && holidayStatus)}
                                        className="input-number"
                                        disabled={disabled}
                                        step="0.1"
                                    />
                                </BaseField>
                                <p className="mx-2 text-sm mb-0">
                                    {t("minutes")}
                                    {", "}
                                    {t("with-paycode")}
                                </p>
                                <BaseField
                                    name={["holidayRules", "base", "payCode"]}
                                    noStyle
                                    rules={
                                        (status && holidayStatus)
                                            ? [
                                                {
                                                    required: true,
                                                    message:
                                                        t("required-type"),
                                                },
                                            ]
                                            : undefined
                                    }
                                    dependencies={[
                                        ["holidayRules", "base", "status"],
                                    ]}
                                >
                                    <ResourceSelect
                                        disabled={disabled || !(status && holidayStatus)}
                                        labelPropName="description"
                                        renderLabel={
                                            renderSettingsResourceLabel
                                        }
                                        resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields, {
                                            "hourType": "unit"
                                       })}
                                        hasSearch
                                        getSearchFilters={getSettingsResourceSearchFilters}
                                    />
                                </BaseField>
                            </div>

                            <div className="d-flex align-items-center justify-content-end">
                                <FontAwesomeIcon
                                    className="text-sm cursor-pointer"
                                    icon={faPen}
                                    onClick={status ? openModal : undefined}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col mb-1">
                        {error && (
                            <span className="invalid-feedback d-block">
                                {error}
                            </span>
                        )}
                    </div>
                    <Modal
                        title={t("details")}
                        isOpen={visible}
                        close={close}
                        centered={true}
                    >
                        <Details
                            close={close}
                            disabled={disabled}
                            values={values}
                        />
                    </Modal>
                </>
            )
        }}
    </BaseField>
    )
}

export default BaseRule;
