import { useCallback } from "react";
import { Field } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import Text from "../../../../../../../../../../../components/Inputs/Text";
import ResourceSelect from "../../../../../../../../../../../components/Inputs/ResourceSelect";
import {
    renderSettingsResourceLabel,
    getSettingsResourceSearchFilters,
    getPathWithParams,
} from "../../../../../../../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../../../../../../helper";

function Item({
    name,
    disabled,
    overtimes,
    setFieldValue,
    getFieldValue,
}) {
    const { t } = useTranslation();

    const remove = useCallback((name) => {
        let data = overtimes.filter((_, i) => name !== i);
        data = data.map((overtime, i) => ({
            ...overtime,
            start: data[i - 1]?.end ? (Number(data[i - 1]?.end)) + 1 : 0,
            end: overtime.end || "",
        }));

        setFieldValue("overtimes", data);
    }, [overtimes, setFieldValue]);

    return (
        <div className="d-flex flex-row gap-2 mb-2">
            <Field
                name={[name, "start"]}
                dependencies={[
                    ["overtimes", name, "end"],
                    ["overtimes", name - 1, "end"]
                ]}
                rules={[
                    {
                        required: true,
                        message: t("required-start")
                    },
                    {
                        validator: (_, value) => {
                            if (value) {
                                if (Number(value) < 0) {
                                    return Promise.reject(new Error(t("start-bigger-than-zero")));
                                }
                                const prevEnd = getFieldValue(["overtimes", name - 1, "end"]);
                                const currentEnd = getFieldValue(["overtimes", name, "end"]);

                                if (!isNaN(prevEnd) && prevEnd !== null && prevEnd !== "" && Number(value) !== (Number(prevEnd) + 1)) {
                                    return Promise.reject(new Error(t("start-equal-to-prev-end")));
                                }

                                if (!isNaN(currentEnd) && currentEnd !== null && currentEnd !== "" && Number(value) >= Number(currentEnd)) {
                                    return Promise.reject(
                                        new Error(t("timeoff-start-smaller-than-end"))
                                    );
                                }
                            }
                            return Promise.resolve();
                        }
                    }
                ]}
            >
                <Text
                    type="number"
                    disabled={disabled}
                    placeholder={t("start")}
                />
            </Field>

            <Field
                name={[name, "end"]}
                dependencies={[
                    ["overtimes", name, "start"],
                    ["overtimes", name + 1, "start"]
                ]}
                rules={[
                    {
                        required: name < overtimes.length - 1,
                        message: t("required-end")
                    },
                    {
                        validator: (_, value) => {
                            if (value) {
                                if (Number(value) < 0) {
                                    return Promise.reject(new Error(t("end-bigger-than-zero")));
                                }
                                const currentStart = getFieldValue(["tiers", name, "start"]);
                                const nextStart = getFieldValue(["tiers", name + 1, "start"]);

                                if (!isNaN(currentStart) && currentStart !== "" && currentStart !== null && Number(value) <= Number(currentStart)) {
                                    return Promise.reject(
                                        new Error(t("timeoff-end-bigger-than-start"))
                                    );
                                }

                                if (!isNaN(nextStart) && nextStart !== "" && nextStart !== null && Number(value) !== (Number(nextStart) - 1)) {
                                    return Promise.reject(
                                        new Error(t("end-equal-to-following-start"))
                                    );
                                }
                            }
                            return Promise.resolve();
                        }
                    }

                ]}
            >
                <Text
                    type="number"
                    disabled={disabled}
                    placeholder={t("end")}
                />
            </Field>

            <Field
                name={[name, "payCode"]}
                rules={[
                    {
                        required: true,
                        message: t("required-pay-code")
                    }
                ]}
            >
                <ResourceSelect
                    resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields)}
                    hasSearch
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    placeholder={t("pay-code")}
                />
            </Field>

            {!disabled && (
                <FontAwesomeIcon
                    icon={faMinus}
                    className="text-danger cursor-pointer pt-3"
                    onClick={() => remove(name)}
                />
            )}
        </div>
    );
}

export default Item;
