import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import Field from "../../../../../../../components/Field";
import Checkbox from "../../../../../../../components/Inputs/Checkbox";
import Button from "../../../../../../../components/Button";
import HourTypeSelect from "../../../../../../../components/Inputs/HourTypeSelect";
import PayCodeSelect from "../PayCodeSelect";

function AmountPerUnitForm({ close, amountPerUnit, disabled }) {
	const { t } = useTranslation();
	const [hidden, setHidden] = useState(false);

	const onChange = useCallback(
		(e) => {
			setHidden(!e.target.checked);
		},
		[setHidden],
	);

	return (
		<Form
			name="amount-per-unit"
			onFinish={close}
			labelAlign="left"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 14, offset: 2 }}
			colon={false}
			initialValues={amountPerUnit}
			scrollToFirstError
		>
			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, setFieldsValue }) => {
					const payCodes = getFieldValue("payCodes");
					const onChange = (hourTypes) => {
						setFieldsValue({
							payCodes: payCodes?.filter((payCode) =>
								hourTypes.includes(payCode.hourType),
							),
						});
					};

					return (
						<Field
							label={t("eligible-hour-types")}
							name={["amountPerUnit", "hourTypes"]}
						>
							<HourTypeSelect
								mode="multiple"
								placeholder={t("eligible-hour-types")}
								onChange={onChange}
								disabled={disabled}
							/>
						</Field>
					);
				}}
			</BaseField>

			<div className="mb-3">
				<Checkbox
					checked={!hidden}
					onChange={onChange}
					id="show-pay-code"
					disabled={disabled}
				>
					{t("show-eligible-pay-code")}
				</Checkbox>
			</div>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) =>
					!hidden && (
						<PayCodeSelect
							hourTypes={getFieldValue("hourTypes")}
							name={["amountPerUnit", "payCodes"]}
							hidden={hidden}
							disabled={disabled}
						/>
					)
				}
			</BaseField>

			<div className="d-flex justify-content-between my-2">
				<Button
					type="submit"
					color="primary"
					size="xs"
					className="bg-dark"
					hidden={disabled}
				>
					{t("save-changes")}
				</Button>

				<Button
					size="small"
					className="border-primary-color mr-1"
					onClick={close}
				>
					{t("close")}
				</Button>
			</div>
		</Form>
	);
}

export default AmountPerUnitForm;
