import Form from "rc-field-form";
import { useCallback, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Field from "../../../../../components/Field";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Button from "../../../../../components/Button";
import { ProfileContext } from "../../../context";
import { useProfileApi } from "../../../../../utils/api/profile";
import { usePeopleApi } from "../../../../../utils/api/people";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../../utils/helpers/settings";

const ChangeEmploymentType = ({ close }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { user, submit, submitting, submittingError, } = useContext(ProfileContext);

    const { changeEmploymentType: profileSubmit } = useProfileApi();
    const { changeEmploymentType: peopleSubmit } = usePeopleApi();

    const onFinish = useCallback((values) => {
        submit(profileSubmit, peopleSubmit, { employmentType: values?.employmentType?.id }, close)
    }, [submit, profileSubmit, peopleSubmit, close]);


    useEffect(() => {
        form.setFieldValue("employmentType", user?.employmentType);
    }, [form, user]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["employmentType"],
            submittingError,
        );
        form.setFields(fieldErrors);
    }, [form, submittingError]);

    return (
        <Form onFinish={onFinish} form={form}>
            <Field
                name="employmentType"
                label={t("employment-type")}
                rules={[
                    {
                        required: true,
                        message: t("required-employment-type"),
                    },
                ]}
            >
                <ResourceSelect
                    resourcePath="/user-demographic/employment-type"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    hasSearch
                    placeholder={t("employment-type")}
                />
            </Field>

            <div className="d-flex justify-content-end">
                <Button
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                    onClick={close}
                    disabled={submitting}
                >
                    {t("cancel")}
                </Button>

                <Button
                    className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
                    color="primary"
                    type="submit"
                    loading={submitting}
                >
                    {t("save")}
                </Button>
            </div>
        </Form>
    );
};

export default ChangeEmploymentType;
