import { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Row, Col, InputGroup, InputGroupText } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPercent } from "@fortawesome/free-solid-svg-icons";
import ResourceSelect from "../../../../../../../../../components/Inputs/ResourceSelect";
import Select from "../../../../../../../../../components/Inputs/Select";
import MoneyInput from "../../../../../../../../../components/Inputs/MoneyInput";
import Text from "../../../../../../../../../components/Inputs/Text";
import Field from "../../../../../../../../../components/Field";
import Conditions from "./Conditions";
import { useGroups } from "../../../../../../../General/OrganisationLevelGroups/context";
import {
    getPathWithParams,
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel
} from "../../../../../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../../../../../CalculationGroup/helper";

function Item({ form, name }) {
    const { t } = useTranslation();
    const { groups } = useGroups();

    return (
        <div className="p-4">
            <Row>
                <Col md={12}>
                    <Field
                        name={[name, "payCode"]}
                        label={t("pay-code")}
                        rules={[
                            {
                                required: true,
                                message: t("pay-code-required"),
                            },
                            // ({ getFieldValue }) => ({
                            //     validator: (_, value) => {
                            //         // const rules = getFieldValue("rules");
                            //         // const hasError = rules?.find(
                            //         //     (rule, i) => rule?.payCode?.id === value?.id && i !== name
                            //         // );
                            //         // if (hasError && value) {
                            //         //     return Promise.reject( new Error(t("unique-pay-code")));
                            //         // }
                            //         return Promise.resolve();
                            //     }
                            // })
                        ]}
                    >
                        <ResourceSelect
                            resourcePath={getPathWithParams("/pay-code", true, {}, payCodeCalcFields, { hourType: "amount" })}
                            renderLabel={renderSettingsResourceLabel}
                            getSearchFilters={getSettingsResourceSearchFilters}
                            placeholder={t("pay-code")}
                            hasSearch
                        />
                    </Field>
                </Col>
            </Row>

            <Row>
                <Col md={6}>
                    <Field
                        name={[name, "method"]}
                        label={t("method")}
                        rules={[
                            {
                                required: true,
                                message: t("method-required"),
                            },
                        ]}
                    >
                        <Select
                            placeholder={t("method")}
                            onChange={() => {
                                form.resetFields([["rules", "value"]]);
                            }}
                        >
                            <Select.Option value="amount-per-hour">
                                {t("amount-per-hour")}
                            </Select.Option>
                            <Select.Option value="amount-per-shift">
                                {t("amount-per-shift")}
                            </Select.Option>
                            <Select.Option value="percentage">{t("percentage")}</Select.Option>
                        </Select>
                    </Field>
                </Col>

                <Col md={6}>
                    <BaseField name={[name, "method"]}>
                        {({ value }) => {
                            switch (value) {
                                default:
                                case "amount-per-hour":
                                case "amount-per-shift": {
                                    return (
                                        <Field
                                            name={[name, "value"]}
                                            label={t("value")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("value-required"),
                                                },
                                            ]}
                                        >
                                            <MoneyInput
                                                placeholder={t("value")}
                                                className="w-100 d-block" />
                                        </Field>
                                    );
                                }
                                case "percentage": {
                                    return (
                                        <Field
                                            name={[name, "value"]}
                                            label={t("value")}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t("value-required"),
                                                },
                                            ]}
                                        >
                                            {(field) => {
                                                return (
                                                    <InputGroup>
                                                        <InputGroupText>
                                                            <FontAwesomeIcon className="px-1" icon={faPercent} />
                                                        </InputGroupText>
                                                        <Text type="number" placeholder={t("value")} min={1} {...field} />
                                                    </InputGroup>
                                                );
                                            }}
                                        </Field>
                                    );
                                }
                            }
                        }}
                    </BaseField>
                </Col>
            </Row>

            <Conditions
                name={name}
                form={form}
                groups={groups}
            />
        </div>
    );
}

export default Item;
