import classNames from "classnames";
import Form from "rc-field-form";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Field from "../../../../../components/Field";
import DateInput from "../../../../../components/Inputs/DateInput";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import Select from "../../../../../components/Inputs/Select";
import Text from "../../../../../components/Inputs/Text";
import { getSettingsResourceSearchFilters } from "../../../../../utils/helpers/settings";
import { firstToUpper } from "../../../../../utils/helpers/string";
import { getCompaniesSearchFilters } from "../../../../Profile/Personal/Actions/ChangeCompanyInformation";
import { renderLabel } from "../../../../Reports/CustomReports/CreateReport/ReportDispaly/useColumns";
import { useDateFilters } from "../../../../../components/DateRangeFilters/useDateFilters";

export const getDefaultFilterValue = (type) => {
  switch (type) {
    case "text":
    case "datepicker":
      return "";
    case "resource-select":
      return [];
    case "select":
      return null;
    default:
      throw new Error(`Type '${type}' not recognized`);
  }
}

export const getFieldOperator = (type) => {
  switch (type) {
    case "text":
      return "contains";
    case "datepicker":
    case "resource-select":
    case "select":
      return "exact";
    default:
      return "contains";
  }
};

function Item({
  changeFieldMethod,
  changeFieldValue,
  config,
  name,
  add,
  remove,
}) {
  const { t } = useTranslation();
  const list = Form.useWatch("filters");
  const current = useMemo(() => list?.[name], [name, list]);
  const [searchField, setSearchField] = useState("");
  const { mode, payGroup } = useDateFilters();
  const hasPayGroup = useMemo(() => {
    return mode === "pay-group" &&
      payGroup &&
      (current?.member || "").toLowerCase().includes("paygroup")
  }, [current?.member, mode, payGroup]);

  const { fieldType: type, disabled } = useMemo(() => {
    return config?.find((configItem) => configItem?.name === current?.member) || {};
  }, [config, current?.member]);

  const fields = useMemo(() => {
    return config
      ?.filter(({ name }) => {
        return (
          !(list?.findIndex((item) => item?.member === name) !== -1) ||
          current?.member === name
        );
      })
      .map(({ name, fieldType, endpoint, display_name, table }) => ({ name, type: fieldType, endpoint, display_name, table }));
  }, [config, list, current]);

  const input = useMemo(() => {
    const itemConfig = config?.find(
      (configItem) => `${configItem?.table}.${configItem?.name}` === current?.member,
    ) || {};

    if (itemConfig?.fieldType === "resource-select" || itemConfig?.type === "RESOURCE-SELECT") {
      const endpoint = itemConfig?.endpoint === "/companies"
        ? "/companies?pagination=false"
        : itemConfig?.endpoint;

      return (
        <ResourceSelect
          disabled={disabled || hasPayGroup}
          placeholder={t("value")}
          resourcePath={endpoint}
          renderLabel={(item) =>
            renderLabel(itemConfig?.display_name || itemConfig?.name, item)
          }
          mode={itemConfig?.type === "RESOURCE-SELECT" ? "multiple" : "single"}
          getSearchFilters={
            itemConfig?.endpoint === "/companies"
              ? getCompaniesSearchFilters
              : getSettingsResourceSearchFilters
          }
          hasSearch
        />
      );
    } else if (itemConfig?.fieldType === "select") {
      return (
        <Select
          disabled={disabled}
          placeholder={t("value")}
        >
          {itemConfig?.options?.map(({ value, label }) => (
            <Select.Option key={value} value={value}>{label}</Select.Option>
          ))}
        </Select>
      );
    } else if (itemConfig?.fieldType === "datepicker") {
      return (
        <DateInput placeholderText={t("date")} />
      );
    } else {
      return (
        <Text
          type="text"
          placeholder={t("value")}
          disabled={disabled}
        />
      );
    }
  }, [config, current?.member, disabled, hasPayGroup, t]);

  const onFieldChange = useCallback(
    (fieldName) => {
      const type = config?.find(
        (configItem) => configItem?.name === fieldName,
      )?.fieldType || "text";
      const operator = getFieldOperator(type);
      changeFieldMethod(name, operator);
      if (type !== "text") {
        changeFieldValue(name, getDefaultFilterValue(type));
      }
    },
    [config, name, changeFieldMethod, changeFieldValue],
  );

  useEffect(() => {
    const operator = getFieldOperator(type);
    changeFieldMethod(name, operator);
  }, [name, type, changeFieldMethod]);

  return (
    <div className="d-flex gap-2 align-items-center w-100">
      <Field
        name={[name, "member"]}
        className="mb-0 mr-2"
      >
        <Select
          placeholder={t("field")}
          onChange={onFieldChange}
          disabled={disabled || hasPayGroup}
          showSearch
          filterOption={false}
          onSearch={(value) => setSearchField(value)}
        >
          {fields?.filter((item) => {
            if (!searchField) return true;
            const lowercasedSearchField = searchField.toLowerCase().trim();
            const searchWords = lowercasedSearchField.split(/\s+/);
            return searchWords.every(word =>
              (item.display_name.toLowerCase()).includes(word)
              // || (item.name.toLowerCase()).includes(word)
            );
          })?.map(({ name, display_name, table }) => (
            <Select.Option key={`${table}.${name}`} value={`${table}.${name}`}>
              {firstToUpper(t(display_name) || t(name))}
            </Select.Option>
          ))}
        </Select>
      </Field>

      <Field name={[name, "values"]} className="mb-0 w-100 h-100">
        {input}
      </Field>

      {(list?.length < config?.length) && (
        <span className="mr-1" style={{ height: 25, width: 25 }}>
          <i
            className="ni ni-fat-add text-info"
            style={{ fontSize: "25px", cursor: "pointer" }}
            onClick={add}
          />
        </span>
      )}

      {list?.length > 1 && (
        <span style={{ height: 25, width: 25 }}>
          <i
            className={classNames("ni ni-fat-delete", (disabled || hasPayGroup) ? "text-gray" : "text-info")}
            style={{ fontSize: "25px", cursor: (disabled || hasPayGroup) ? "initial" : "pointer" }}
            onClick={(disabled || hasPayGroup) ? undefined : remove}
          />
        </span>
      )}
    </div>
  );
}

export default Item;
