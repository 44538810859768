import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BryntumSchedulerPro } from "@bryntum/schedulerpro-react";
import moment from "moment-timezone";
import { omit } from "lodash";
import Header from "./Header";
import Loading from "../../../../../components/Loaders/Loading";
import Pagination from "../../../../../components/NTable/Pagination";
import { usePreset } from "../PresetContext";
import { useElementHeight } from "../../../../../utils/hooks/useElementHeight";
import { useProject } from "../hooks/useConfig/useProject";
import { useEventRenderer } from "../hooks/useConfig/useEventRenderer";
import { useEventDrawer } from "../hooks/useEventDrawer";
import { useRowHeight } from "../hooks/useConfig/useRowHeight";
import { firstToUpper } from "../../../../../utils/helpers/string";
import { useViewPreset } from "../hooks/useConfig/useViewPreset";
import { useConfig } from "../hooks/useConfig";
import { useModals } from "../hooks/useModals";
import { useCustomDrawer } from "../hooks/useCustomDrawer";
import { useEventMenuFeature } from "../hooks/useConfig/features/useEventMenuFeature";
import { useEventResizeFeature } from "../hooks/useConfig/features/useEventResizeFeature";
import { useProfileDrawer } from "../../../../NewScheduler/Component/hooks/useProfileDrawer";
import { useAccess } from "../../../../../utils/hooks/access";
import { getFormFormattedValues } from "../../../../NewScheduler/Component/helpers/getFormFormattedValues";
import { useCreateEvent, useEditEvent } from "../api/useEventsApi";

function Content({
    isTeamScheduler,
    isSelf,
    mutate,
    loading,
    params,
    events,
    resources,
    total,
    page,
    perPage,
    setPage,
    setPerPage
}) {
    const schedulerRef = useRef(null);
    const { ref: actionsRef, height: actionsHeight } = useElementHeight();
    const {
        hasAccess: canCopy
    } = useAccess(isTeamScheduler ? "schedule.canCopy" : "schedule.canCopyMySchedule");
    const {
        hasAccess: canEdit
    } = useAccess(isTeamScheduler ? "schedule.canEdit" : "schedule.canEditMySchedule");
    const [schedulerInstance, setSchedulerInstance] = useState(null);
    const [schedulerConfig, setSchedulerConfig] = useState();
    const [selectedEvents, setSelectedEvents] = useState([]);
    const { mode } = usePreset();
    const rowHeight = useRowHeight();
    const eventRenderer = useEventRenderer();
    const { create: createEvent } = useCreateEvent({ isTeamScheduler });
    const { edit: editEvent } = useEditEvent({ isTeamScheduler });
    const { drawer: profileDrawer, open: openProfile } = useProfileDrawer();

    const {
        open: openEventDrawer,
        close: closeEventDrawer,
        drawer: eventDrawer,
        visible: isDrawerOpen
    } = useEventDrawer({ isTeamScheduler, mutate });

    const _eventDrawer = useCustomDrawer(() => {
        return eventDrawer;
    });

    const editEventDrawer = useMemo(() => {
        if (isTeamScheduler) {
            return eventDrawer;
        } else {
            return _eventDrawer;
        }
    }, [isTeamScheduler, eventDrawer, _eventDrawer]);

    const project = useProject({
        isTeamScheduler,
        schedulerInstance,
        resources,
        events,
        isSelf,
        isDrawerOpen
    });

    const {
        transferModal,
        openTransferModal,
        copyModal,
        openCopyModal,
        deleteModal,
        openDeleteModal
    } = useModals({ params, mutate, setSelectedEvents, isTeamScheduler, isSelf });

    const onCloseEditDrawer = useCallback(() => {
        if (schedulerInstance?.editingRecord?.isCreating) {
            schedulerInstance?.editingRecord.remove();
            delete schedulerInstance?.editingRecord;
        }
        closeEventDrawer();
    }, [closeEventDrawer, schedulerInstance]);

    const preset = useViewPreset();

    const onSuccess = useCallback((response, type, _event) => {
        mutate((prev) => {
            const data = {
                data: {
                    resources: prev?.data?.resources,
                    events: type === "create" ? [
                        ...prev?.data?.events,
                        response,
                    ] : [
                        ...prev?.data?.events?.filter((event) => event?.id !== _event?.id),
                        response
                    ]
                },
                meta: prev?.meta,
            };
            return data;
        }, { revalidate: false });
    }, [mutate]);

    const onFailure = useCallback(() => {
        if (schedulerRef?.current?.instance?.editingRecord) {
            schedulerRef?.current?.instance?.editingRecord.remove();
            delete schedulerRef?.current?.instance?.editingRecord;
        }

        mutate((prev) => {
            const data = {
                meta: prev?.meta,
                data: {
                    events: [...prev?.data?.events, ...events],
                    resources: [...prev?.data?.resources, ...resources]
                }
            };

            return data;
        }, { revalidate: false });
    }, [events, mutate, resources]);

    const saveEventOnDb = useCallback((event, source) => {
        const canSaveEvent = event?.context?.eventRecord?._data?.saveEvent;
        if ((canSaveEvent || !event?.type) || !isSelf) {
            if (event?.type === "aftereventdrop") {
                const copyKeyPressed = (event?.context?.browserEvent || event?.domEvent)
                    && (event?.context?.browserEvent?.metaKey || event?.domEvent?.metaKey
                        || event?.context?.browserEvent?.ctrlKey || event?.domEvent?.ctrlKey);

                if (copyKeyPressed && canCopy) {
                    const data = getFormFormattedValues(event?.eventRecords?.[0]?.toJSON());
                    createEvent(
                        "default",
                        omit(data, ["id"]),
                        source,
                        (response) => onSuccess(response, "create"),
                        onFailure
                    );
                } else {
                    const data = getFormFormattedValues(event?.eventRecords?.[0]?.toJSON());
                    const submit = data.id ? editEvent : createEvent;
                    submit(
                        "default",
                        data,
                        source,
                        (response) => onSuccess(response, "", data),
                        onFailure
                    );
                }
            } else {
                const data = getFormFormattedValues(event?.toJSON());
                const submit = data.id ? editEvent : createEvent;
                submit(
                    "default",
                    data,
                    source,
                    (response) => onSuccess(response, "", data),
                    onFailure
                );
            }
        }
    }, [isSelf, canCopy, createEvent, onFailure, onSuccess, editEvent]);

    const config = useConfig({
        isTeamScheduler,
        isSelf,
        schedulerRef,
        setSelectedEvents,
        openProfile: isTeamScheduler && openProfile,
        openTransferModal: isTeamScheduler && openTransferModal,
        openDeleteModal,
        saveEventOnDb
    });

    const eventMenuFeature = useEventMenuFeature({
        isTeamScheduler,
        openCopyModal,
        openTransferModal,
        openDeleteModal,
        openEventDrawer,
        schedulerRef,
    });

    const eventResizeFeature = useEventResizeFeature({ isTeamScheduler });

    useEffect(() => {
        if (schedulerInstance && !schedulerInstance?.isDestroyed && mode && preset) {
            // schedulerInstance.suspendRefresh();
            schedulerInstance.viewPreset = preset;
            schedulerInstance.scrollLeft = 0;
            const startDate = mode === "day"
                ? moment().day(firstToUpper(params.day)) : moment();
            const endDate = mode === "day"
                ? moment().day(firstToUpper(params.day)) : moment();

            schedulerInstance.setTimeSpan(
                moment(startDate).startOf(mode).toDate(),
                moment(endDate).endOf(mode).toDate()
            );
            schedulerInstance.weekStartDay = moment(startDate).day()
            schedulerInstance.resumeRefresh(true);
        }
    }, [schedulerInstance, preset, params?.day, mode]);

    useEffect(() => {
        if (schedulerRef && schedulerRef.current && (!schedulerInstance || schedulerInstance?.isDestroyed)) {
            setSchedulerInstance(schedulerRef?.current?.instance);
            setSchedulerConfig(config);
            schedulerRef?.current?.instance.refresh();
        }
    }, [schedulerRef, config, schedulerInstance, setSchedulerInstance, setSchedulerConfig]);

    return (
        <>
            <Header
                actionsRef={actionsRef}
                openCopyModal={openCopyModal}
                openDeleteModal={openDeleteModal}
                isTeamScheduler={isTeamScheduler}
                selectedEvents={selectedEvents}
                events={events}
                isSelf={isSelf}
                isDrawerOpen={isDrawerOpen}
            />

            {loading ? (
                <div
                    className="w-100 position-absolute"
                    style={{
                        top: isTeamScheduler ? "8%" : 0,
                        zIndex: 1,
                        backgroundColor: "#eeeeeec2",
                        height: `calc(100% - ${actionsHeight}px - 33px)`,
                        right: 0,
                    }}
                >
                    <div className="position-relative text-center" style={{ top: "50%" }}>
                        <Loading />
                    </div>
                </div>
            ) : null}

            <div style={{ height: `calc(100% - ${actionsHeight}px - 135px)`, pointerEvents: isDrawerOpen ? "none" : "all" }}>
                <BryntumSchedulerPro
                    ref={schedulerRef}
                    {...(schedulerConfig || config || {})}
                    rowHeight={rowHeight}
                    project={project}
                    fillTicks={mode !== "date" || mode === undefined}
                    eventRenderer={eventRenderer}
                    onEventClick={onCloseEditDrawer}
                    onCellClick={onCloseEditDrawer}
                    onDragCreateStart={onCloseEditDrawer}
                    eventResizeFeature={!isSelf ? eventResizeFeature : false}
                    eventMenuFeature={eventMenuFeature}
                    multiEventSelect={true}
                    autoHeight={!isTeamScheduler}
                    onEventDblClick={({ eventRecord, resourceRecord, source }) => {
                        if (!isSelf || isTeamScheduler) {
                            openEventDrawer({
                                eventRecord,
                                resourceRecord,
                                source,
                                onEventSave: () => { },
                                canEdit: !eventRecord?.isCreating
                            });
                        }
                        return false;
                    }}
                    onBeforeEventEdit={({ eventRecord, resourceRecord, source }) => {
                        if (!isSelf || isTeamScheduler) {
                            openEventDrawer({
                                eventRecord,
                                resourceRecord,
                                source,
                            });
                            source.editingRecord = eventRecord;
                        }
                        return false;
                    }}
                />

                {isTeamScheduler && (
                    <Pagination
                        page={page}
                        gotoPage={setPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                        totalItems={total}
                        pageCount={Math.ceil(total / perPage)}
                    />
                )}
            </div>

            {editEventDrawer}
            {profileDrawer}
            {copyModal}
            {deleteModal}
            {transferModal}
        </>
    );
}

export default Content;
