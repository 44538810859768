import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { CardHeader, Col, Row } from "reactstrap";
import Filters from "../../../components/NTable/Actions/Filters";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import ColumnToggle from "../../../components/NTable/Actions/ColumnToggle";
import { useColumnFiltersConfig } from "../../../components/NTable/hook/useColumnFiltersConfig";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../utils/helpers/settings";

const Actions = ({ children, filters, setFilters, columns }) => {
    const { t } = useTranslation();

    const baseConfig = useColumnFiltersConfig(columns);

    const config = useMemo(() => {
        const filterConfig = [
            ...baseConfig,
            {
                name: "employmentType",
                label: t("employment-type"),
                type: "select",
                component: (
                    <ResourceSelect
                        resourcePath="/user-demographic/employment-type"
                        mode="multiple"
                        renderLabel={renderSettingsResourceLabel}
                        getSearchFilters={getSettingsResourceSearchFilters}
                        hasSearch
                    />
                )
            },
        ];
        return filterConfig;
    }, [baseConfig, t]);

    return (
        <CardHeader className="border-top p-3">
            <Row className="d-flex justify-content-between mb-3 mb-md-0">
                <Col>
                    {children}
                </Col>

                <Col>
                    <div className="d-flex align-items-start">
                        <Filters
                            config={config}
                            filters={filters}
                            setFilters={setFilters}
                        />

                        <ColumnToggle columns={columns} />
                    </div>
                </Col>
            </Row>
        </CardHeader>
    );
};

export default Actions;
