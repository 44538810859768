import { useCallback, useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import Form from "rc-field-form";
import { Row, Col } from "reactstrap";
import { noop } from "lodash";
import moment from "moment-timezone";
import Button from "../../../../components/Button";
import { useClockIn, useLastShift, useWorkSummary } from "../store";
import Field from "../../../../components/Field";
import Text from "../../../../components/Inputs/Text";
import List from "../ShiftView/Clocks/List";
import Loading from "../../../../components/Loaders/Loading";
import Attestation from "./Attestation";
import { useClock, useGetIpAddress } from "../api";
import { useUser } from "../../../../utils/hooks/user";
import { getAddress } from "../../../../utils/helpers/geocode";
import {
	dateTimeFormat,
	renderDurationAsFormat,
} from "../../../../utils/helpers/date";
import { getPaidHours } from "../../../../utils/helpers/shift";
import { useCompany } from "../../../../utils/hooks/company";
import { useCurrentShift } from "../ShiftView";
import { useGeolocated } from "../../../../utils/hooks/useGeolocated";
import { applyTimezone } from "../../../../utils/helpers/date";

const ShiftSummaryForm = ({ 
	openEdit, 
	setRefetchTimesheet = noop, 
	attestations, 
	loading: loadingAttestation 
}) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	// eslint-disable-next-line
	const [_, { setShiftAction }] = useClockIn();
	const workSummary = useWorkSummary();
	const shift = useLastShift();
	const user = useUser();
	const company = useCompany();
	const currentShift = useCurrentShift();
	const { ip, getIp } = useGetIpAddress();

	const isGeofenceRequired = user?.calculationGroup?.geofenceLocation

	const {
		coords,
		isGeolocationAvailable,
		isGeolocationEnabled,
		positionError,
	} = useGeolocated({
		positionOptions: {
			enableHighAccuracy: false,
		},
		userDecisionTimeout: 5000,
	});

	const { submit, loading } = useClock();

	const lastShift = useMemo(() => {
		if (!workSummary) {
			return undefined;
		}
		const shift = workSummary.shifts.slice(-1)[0];
		const timezone = shift?.location?.timezoneValue;
		return {
			...shift,
			clocks: shift.clocks.concat({
				mode: "END",
				time: applyTimezone(moment(), timezone, false).toISOString(
					true,
				),
				type: "SHIFT",
				timeZone: shift?.location?.timezoneValue,
			}),
		};
	}, [workSummary]);

	const filteredWorkSummary = useMemo(() => {
		if (!user?.calculationGroup || !workSummary || !lastShift) {
			return undefined;
		}
		return {
			...workSummary,
			shifts: [lastShift],
			calculationGroup: user.calculationGroup,
		};
	}, [user, workSummary, lastShift]);

	const clocks = useMemo(() => {
		if (!shift?.clocks) {
			return [];
		}
		const timeZone = shift?.clocks.slice(-1)[0]?.timeZone;
		return shift?.clocks.concat({
			id: Math.random().toString(),
			mode: "END",
			time: applyTimezone(moment(), timeZone, false).toISOString(true),
			timeZone,
			type: "SHIFT",
		});
	}, [shift?.clocks]);

	const onFinish = useCallback(
		async ({ note, ...values }) => {
			const data = {
				type: "SHIFT",
				mode: "END",
				worksummaryId: workSummary?.id,
				shiftId: shift?.id,
				note,
				project: shift?.project,
				location: shift?.location?.id,
				job: shift?.job?.id,
				customFields: values?.customFields
			};

			if (ip) {
				data.ip = ip;
			}

			if (/**isGeofenceRequired && */ isGeolocationEnabled) {
				if (!coords?.latitude || !coords?.longitude) {
					toast.error(t("required-geofence"));
					return;
				}
				const address = await getAddress({
					lat: coords?.latitude,
					lng: coords?.longitude,
				});
				data.geofence = {
					lat: coords?.latitude,
					lng: coords?.longitude,
					address,
				};
			}

			submit(data, () => {
				setShiftAction(null);
				setRefetchTimesheet((prev) => prev + 1)
				return currentShift;
			});
		},
		[
			submit,
			setShiftAction,
			workSummary?.id,
			shift,
			coords,
			setRefetchTimesheet,
			// isGeofenceRequired,
			isGeolocationEnabled,
			currentShift,
			ip,
			t,
		],
	);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);

		return () => controller.abort();
	}, [getIp]);

	useEffect(() => {
		if (!isGeolocationAvailable) {
			toast.error(t("browser-doesnt-support-geolocation"));
		} else if (!isGeolocationEnabled) {
			toast.error(t("enable-location"));
		} else if (positionError && typeof positionError === "object") {
			toast.error(positionError?.message);
		}
	}, [positionError, isGeolocationEnabled, isGeolocationAvailable, t]);

	return (
		<Form
			className="h-100"
			form={form}
			onFinish={onFinish}
		>
			<div className="d-flex gap-7 mb-4" style={{ height: "450px" }}>
				<div className={classNames(attestations?.length > 0 ?
					"d-flex flex-column w-100 border-right pr-4 h-100"
					: "d-flex w-100 border-0 gap-7")}
				>
					<div className={classNames(attestations?.length > 0 ? "w-100" : "w-50", "d-flex flex-column w-50")}>
						<div>
							<div className="d-flex justify-content-between pl-1">
								<h5>{t("shift-date")}</h5>
								<small>
									{dateTimeFormat(
										lastShift?.startDate,
										"dddd, MMMM DD, yyyy",
									)}
								</small>
							</div>

							<div style={{ maxHeight: 360, overflowY: "auto" }}>
								<List clocks={clocks} />
							</div>
						</div>

						<div className="d-flex justify-content-between border-top py-3 mb-4">
							<h5 className="text-primary">{t("total-paid-hours")}</h5>
							<h5 className="text-primary">
								{filteredWorkSummary &&
									renderDurationAsFormat(
										getPaidHours(filteredWorkSummary),
										company?.settings?.timeFormat,
									)}
							</h5>
						</div>
					</div>

					<div className={classNames(attestations?.length > 0 ? "w-100" : "w-50")}>
						<Field
							name="note"
							label={t("attach-request-note")}
							className="mb-1"
						>
							<Text
								resize="none"
								rows="5"
								type="textarea"
								className="h-100"
							/>
						</Field>
						<footer className="note-border  p-2 border-top-0 text-sm">
							*
							{t(
								"requests-send-for-manager-approval",
							)}
						</footer>
					</div>
				</div>

				{loadingAttestation ? (
					<div className="d-flex align-items-center justify-content-center w-100 h-100">
						<Loading />
					</div>
				) : attestations?.length > 0 && (
					<Attestation attestations={attestations} />
 				)}	
			</div>

			<div className="border-top text-center align-items-center justify-content-center pt-3">
				<Button
					className="btn-icon something-went-wrong-btn"
					type="button"
					color="white"
					disabled={loading}
					onClick={openEdit}
				>
					<span className="mr-1 ">
						<i class="fa-solid fa-circle-question" />
					</span>

					<span className="font-weight-bolder">
						{t("something-is-wrong")}
					</span>
				</Button>

				<Button
					className="btn-icon text-center approve-btn"
					type="submit"
					color="white"
					loading={loading}
					disabled={!isGeolocationEnabled}
				>
					<span className="mr-1">
						<i className="fa-solid fa-circle-check" />
					</span>

					<span className="font-weight-bolder">{t("approve")}</span>
				</Button>
			</div>
		</Form>
	);
};

export default ShiftSummaryForm;
