import i18next from "i18next";
import { toast } from "react-toastify";

export const generateErrorsConfigForForm = (fields, error) => {
	if (error === null) {
		return fields.map((name) => ({ name, errors: [] }));
	} else if (typeof error === "string") {
		return [];
	} else if (typeof error === "object") {
		return Object.entries(error).map(([name, errors]) => ({
			name,
			errors,
		}));
	} else {
		return [];
	}
};

export const isCancelError = (e) => {
	return e?.message === "canceled";
};

export const getErrorMessage = (err, t) => {
	if (err?.code === "ERR_CANCELED" || err?.message === "canceled" || isCancelError(err)) {
		return;
	} else if (typeof err === "string") {
		toast.error(err, { autoClose: 3000 });
	} else {
		toast.error(t("something-went-wrong"), { autoClose: 3000 });
	}
};

export const getToastErrorMessage = (
	e,
	defaultError = i18next.t("something-went-wrong"),
) => {
	if (typeof e === "string") {
		return e;
	}
	return defaultError;
};
