import { createColumnHelper } from "@tanstack/react-table";
import classNames from "classnames";
import { Option } from "rc-select";
import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import Select from "../../../components/Inputs/Select";
import StatusCell from "./StatusCell";
import { dateTimeFormat } from "../../../utils/helpers/date";
import {
	getSettingsOrgResourceSearchFilters,
	getSettingsResourceSearchFilters,
	renderOrgLevelResourceLabel,
	renderSettingsResourceLabel,
} from "../../../utils/helpers/settings";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../utils/hooks/company";
import { OrgLevelGroupsContext } from "../../Settings/General/OrganisationLevelGroups/context";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, view, cancel, timeoff }) => {
	const { t } = useTranslation();
	const dateFormat = useCompanyDateFormat();
	const timeFormat = useCompanyTimeFormat();
	const { groups } = useContext(OrgLevelGroupsContext);

	const columns = useMemo(() => {
		const cols = [
			columnHelper.accessor("startDate", {
				enableColumnFilter: true,
				header: t("start-date"),
				cell: (info) => {
					const { data } = info.row.original;
					return dateTimeFormat(data?.date || data?.startDate, dateFormat);
				},
			}),
			columnHelper.accessor("startTime", {
				header: t("start-time"),
				cell: (info) => {
					const { data } = info.row.original;
					return dateTimeFormat(data?.startDate, timeFormat);
				},
			}),
			columnHelper.accessor("endDate", {
				header: t("end-date"),
				cell: (info) => {
					const { data } = info.row.original;
					return dateTimeFormat(data?.date || data?.endDate, dateFormat);
				},
			}),
			columnHelper.accessor("endTime", {
				header: t("end-time"),
				cell: (info) => {
					const { data } = info.row.original;
					return dateTimeFormat(data?.endDate, timeFormat);
				},
			}),
			columnHelper.accessor("type", {
				enableColumnFilter: !!timeoff,
				header: !timeoff ? t("type") : t("plan"),
				cell: ({ row }) => {
					const type = row.original.type;
					const plan = row.original?.data?.plan?.plan;
					if (type === "absence" && plan) {
						return renderSettingsResourceLabel(plan);
					} else {
						return t(type);
					}
				},
				filterType: timeoff ? "resource-select" : undefined,
				Filter: timeoff && (
					<ResourceSelect
						mode="multiple"						
						labelPropName="description"
						resourcePath="/timeoff"
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				),
			}),
			columnHelper.accessor("status", {
				enableColumnFilter: true,
				header: t("status"),
				cell: ({ row }) => <StatusCell value={row.original} />,
				filterType: "select",
				Filter: (
					<Select>
						<Option value="approved">{t("approved")}</Option>
						<Option value="denied">{t("denied")}</Option>
						<Option value="pending">{t("pending")}</Option>
						<Option value="canceled">{t("canceled")}</Option>
						<Option value="expired">{t("expired")}</Option>
					</Select>
				),
			}),
		];

		if (!timeoff) {
            cols.splice(5, 0,
                columnHelper.accessor("data.location", {
                    enableColumnFilter: true,
                    header: t("location"),
                    cell: (info) => {
                        const value = info.getValue();
                        return value && renderSettingsResourceLabel(value);
                    },
                    filterType: "resource-select",
                    Filter: (
                        <ResourceSelect
                            labelPropName="description"
                            resourcePath="/locations"
                            mode="multiple"
                            renderLabel={renderSettingsResourceLabel}
                            getSearchFilters={getSettingsResourceSearchFilters}
                            hasSearch
                        />
                    ),
                })
            );
            cols.splice(6, 0,
                columnHelper.accessor("data.job", {
                    enableColumnFilter: true,
                    header: t("job"),
                    cell: (info) => {
                        const value = info.getValue();
                        return value && renderSettingsResourceLabel(value);
                    },
                    filterType: "resource-select",
                    Filter: (
                        <ResourceSelect
                            labelPropName="description"
                            resourcePath="/jobs"
                            mode="multiple"
                            renderLabel={renderSettingsResourceLabel}
                            getSearchFilters={getSettingsResourceSearchFilters}
                            hasSearch
                        />
                    ),
                })
            );
        }
				
		if (edit || remove || cancel) {
            const actionsColumn = columnHelper.display({
                id: "actions",
                enableHiding: false,
                enableColumnFilter: false,
                header: () => (
                    <div className="text-right">
                        {t("actions")}
                    </div>
                ),
                cell: ({ row: { original } }) => {
					if (
						original?.status === "canceled"
						|| (original?.status === "approved" && original?.type === "cancel")
					) {
						return null;
					}

					return (
						<div
							className={classNames(
								"d-flex justify-content-end",
							)}
						>
							{view && (
                                <span
                                    className="fa-solid fa-eye mr-3 pb-2 text-primary cursor-pointer"
                                    onClick={() => view(original)}
                                    style={{ fontSize: "17px" }}
                                />
							)}

							{
								edit
								&& original.type !== "availability"
								&& original.status === "pending"
								&& !["submit-schedule", "cancel", "swap-shifts", "offer-shifts"].includes(original?.type)
								&& (
									<span
										className="btn-inner--icon icon-sm cursor-pointer text-primary"
										onClick={() => edit(original)}
									>
										<i className="fas fa-pen" />
									</span>
								)}

							{remove && original.status === "pending" && original?.type !== "submit-schedule" && (
								<span
									className="btn-inner--icon mr-3 icon-sm cursor-pointer text-primary"
									onClick={() => remove(original.id)}
								>
									<i className="fas fa-trash" />
								</span>
							)}

							{cancel && original.status === "approved" && original?.type === "absence" && (
								<span
									className="btn-inner--icon mr-3 icon-sm cursor-pointer text-primary"
									onClick={() => cancel(original)}
									title={t("cancel-request")}
								>
									<i className="fas fa-ban" />
								</span>
							)}
						</div>
					);
                }
            });
            cols.push(actionsColumn);
        }

		if (groups) {
			const groupColumns = groups.map((group) => {
				const level = `${group.level}`.replace("_", "");
				return columnHelper.accessor(level, {
					enableColumnFilter: true,
					header: group.description,
					cell: (info) => {
						const value = info.getValue();
						if (value && group.allocatedBy) {
							return renderOrgLevelResourceLabel(value);
						}
						return renderSettingsResourceLabel(value);
					},
					filterType: "resource-select",
					Filter: (
						<ResourceSelect
							labelPropName="description"
							resourcePath={`/${group?.id}/org-levels`}
							mode="multiple"
							renderLabel={renderOrgLevelResourceLabel}
							getSearchFilters={
								getSettingsOrgResourceSearchFilters
							}
							valuePropName="id"
							hasSearch
						/>
					),
				});
			});
			cols.splice(4, 0, ...groupColumns);
		}

		return cols;
	}, [t, edit, remove, view, cancel, groups, dateFormat, timeFormat, timeoff]);

	return columns;
};
