import { Field as BaseField, List } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../../../../../../components/Field";
import Switch from "../../../../../../../../components/Inputs/Switch";
import Button from "../../../../../../../../components/Button";
import Break from "./Break";

function Rest({ disabled }) {
    const { t } = useTranslation();

    return (
        <div className="mb-4">
            <div className="d-flex align-items-center">

                <Field
                    name={["breaks", "rest", "status"]}
                    valuePropName="checked"
                    noStyle
                    className="mb-2"
                >
                    <Switch disabled={disabled}>
                        {t("rest-breaks")}:
                    </Switch>
                </Field>
            </div>

            <div className="pl-5 my-2">
                <BaseField shouldUpdate>
                    {({ }, { }, { getFieldValue }) => {
                        const status = getFieldValue(["breaks", "rest", "status"]);

                        return status && (
                            <List name={["breaks", "rest", "breaks"]}>
                                {(breaks, { add, remove }) => (
                                    <>
                                        {breaks.map(({ key, name }, i) => (
                                            <Break
                                                key={key}
                                                first={i === 0}
                                                remove={remove}
                                                name={name}
                                                disabled={disabled}
                                            />
                                        ))}


                                        <div className="d-flex justify-content-end my-3">
                                            {!disabled && (
                                                <Button
                                                    className="d-flex align-items-center text-sm py-2 border"
                                                    size="small"
                                                    type="text"
                                                    color="secondary"
                                                    disabled={!getFieldValue(["breaks", "rest", "status"])}
                                                    onClick={() => add({})}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faPlus}
                                                        className="text-info mr-1 font-weight-bolder"
                                                    />
                                                    {t("add-another-break")}
                                                </Button>
                                            )}
                                        </div>
                                    </>
                                )}
                            </List>
                        )
                    }}
                </BaseField>
            </div>
        </div >
    );
}

export default Rest;
