import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { Field as BaseField } from "rc-field-form";
import DateInput from "../../../../../../components/Inputs/DateInput";
import Field from "../../../../../../components/Field";
import Select from "../../../../../../components/Inputs/Select";

function Reset() {
    const { t } = useTranslation();

    return (
        <div className="rounded p-4 mb-4" style={{ background: "#f8f9f9" }}>
            <h4>{t("reset")}</h4>

            <div >
                <Field
                    label={t("type")}
                    name={["reset", "type"]}
                    rules={[
                        {
                            required: true,
                            message: t("required-type")
                        }
                    ]}
                >
                    <Select placeholder={t("type")}>
                        <Select.Option value="start-of-year">
                            {t("start-of-year")}
                        </Select.Option>

                        <Select.Option value="hiring-date">
                            {t("hiring-date")}
                        </Select.Option>

                        <Select.Option value="seniority">
                            {t("seniority")}
                        </Select.Option>
                        <Select.Option value="custom">
                            {t("custom")}
                        </Select.Option>
                    </Select>
                </Field>

                <BaseField shouldUpdate>
                    {({ }, { }, { getFieldValue }) => {
                        const type = getFieldValue(["reset", "type"]);
                        return type === "custom" && (
                            <Field
                                label={t("date")}
                                name={["reset", "value"]}
                                rules={[
                                    {
                                        required: true,
                                        message: t("required-date")
                                    },
                                    {
                                        validator: (_, value) => {
                                            if (value) {
                                                const currentDay = moment();
                                                if (moment(value).isSameOrBefore(moment(currentDay), "day")) {
                                                    return Promise.reject(new Error(t("date-bigger-than-current-day")))
                                                }
                                            }
                                            return Promise.resolve();
                                        }
                                    }
                                ]}
                            >
                                <DateInput placeholderText={t("date")} />
                            </Field>
                        );
                    }}
                </BaseField>
            </div>
        </div>
    );
}

export default Reset;
