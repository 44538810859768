import { useTranslation } from "react-i18next";
import { Card } from "reactstrap";
import Drawer from "../../../../components/Drawer";
import Form from "./Form";
import BulkActions from "../../components/BulkActions";
import Topbar from "../../components/Topbar";
import Actions from "../../../../components/NTable/Actions";
import NTable from "../../../../components/NTable";
import { useColumns } from "./columns";
import useSettingsCRUDHook from "../../hook";
import { useDrawerProps } from "../../helpers/useDrawerProps";
import { useDeleteModal } from "../../helpers/useDeleteModal";
import { useManageForm } from "../../helpers/useManageForm";
import { useFetchResource } from "../../helpers/useFetchResource";
import { useAccess, useActionWithAccess } from "../../../../utils/hooks/access";
import { useVisibleColumnKeys } from "../../helpers/useVisibleColumnKeys";
import { useOnExport } from "../../helpers/useOnExport";
import { useImportModal } from "../../helpers/useImportModal";
import { useQueryFilters } from "../../helpers/useQueryFilters";
import { useNewTable } from "../../../../components/NTable/hook";
import { useInitialState } from "../../../../utils/hooks/useInitialState";

function PayCode() {
	const { t } = useTranslation();

	const {
		state: { mode, data, totalItems, loading, submitting, error },
		selected,
		fetch,
		createSubmit,
		updateSubmit,
		goToHelp,
		goToView,
		goToCreate,
		goToEdit,
		goToClone,
		remove,
		batchRemove,
		settingsExport,
		loadingExport,
		setData,
	} = useSettingsCRUDHook("/pay-code");

	const { hasAccess: canAdd } = useAccess(
		"settings.payPolicies.payCode.canCreate",
	);

	const { modal: deleteModal, open: openDeleteModal } = useDeleteModal({
		message: (data) =>
			Array.isArray(data)
				? t("do-you-want-to-delete-pay-codes", {
					count: data?.length
				})
				: t("do-you-want-to-delete-pay-code"),
		onConfirm: (data) =>
			Array.isArray(data) ? batchRemove(data) : remove(data),
		permission: "settings.payPolicies.payCode.canDelete",
	});
	const { unarchiveModal, isFormOpen, formProps } = useManageForm({
		mode,
		selected,
		createSubmit,
		updateSubmit,
	});

	const columns = useColumns({
		edit: useActionWithAccess(
			goToEdit,
			"settings.payPolicies.payCode.canEdit",
		),
		remove: openDeleteModal,
		clone: useActionWithAccess(
			goToClone,
			"settings.payPolicies.payCode.canCreate",
		),
	});

	const table = useNewTable({
		data,
		columns,
		loading,
		totalItems,
		sessionFiltersKey: "payCodes",
		initialState: useInitialState({
			columnVisibility: {
				allowedAllocationType: false,
				includeOrExcludeAllocated: false,
				allowedCompanies: false,
				allowedPayGroups: false,
				excludedCompanies: false,
				excludedPayGroups: false
			}
		})
	});

	const queryFilters = useQueryFilters(table);

	const { open: drawerOpen, title: drawerTitle } = useDrawerProps({
		mode,
		help: t("help"),
		view: t("view-pay-code"),
		create: t("add-pay-code"),
		edit: t("edit-pay-code"),
		clone: t("clone-pay-code"),
	});

	useFetchResource({ fetch, state: table.getState() });

	const visibleColumnKeys = useVisibleColumnKeys(table);

	const onExport = useOnExport({
		url: "/export/pay-codes",
		exportCall: settingsExport,
		queryFilters,
		visibleColumnKeys
	});

	const { open: openImportModal, modal: importModal } = useImportModal({
		importUrl: "/pay-code/import",
		exportUrl: "/export/pay-codes",
		setData,
		exportCall: settingsExport,
		loading: loading || loadingExport,
	});

	return (
		<div className="d-flex h-100">
			<Card className="flex-fill mb-0">
				<Topbar
					title={t("pay-codes")}
					add={canAdd && goToCreate}
					help={goToHelp}
					importFile={openImportModal}
					onExport={onExport}
					loading={loading || loadingExport}
				/>

				<Card
					className="mb-auto"
					style={{ maxHeight: "calc(100% - 156px)" }}
				>
					<Actions
						filters={table.getState().columnFilters}
						setFilters={table.setColumnFilters}
						columns={table.getAllColumns()}
					>
						<BulkActions
							rows={table.getState().rowSelection}
							batchRemove={openDeleteModal}
						/>
					</Actions>

					<NTable table={table} />
				</Card>
			</Card>

			<Drawer open={drawerOpen} close={goToView} title={drawerTitle}>
				{isFormOpen ? (
					<Form
						{...formProps}
						error={error}
						loading={submitting}
						close={goToView}
					/>
				) : (
					<>{t("help")}</>
				)}
			</Drawer>

			{importModal}

			{deleteModal}

			{unarchiveModal}
		</div>
	);
}

export default PayCode;
