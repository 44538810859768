import { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { createColumnHelper } from "@tanstack/react-table";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import Select from "../../../components/Inputs/Select";
import StatusCell from "../Home/StatusCell";
import { OrgLevelGroupsContext } from "../../Settings/General/OrganisationLevelGroups/context";
import { useCompanyDateFormat, useCompanyTimeFormat } from "../../../utils/hooks/company";
import {
	renderSettingsResourceLabel,
	getSettingsOrgResourceSearchFilters,
	renderOrgLevelResourceLabel,
	getSettingsResourceSearchFilters,
} from "../../../utils/helpers/settings";
import { renderUserName } from "../../../utils/helpers/user";
import { dateTimeFormat } from "../../../utils/helpers/date";
import { useUser } from "../../../utils/hooks/user";
import { useAccess } from "../../../utils/hooks/access";

const columnHelper = createColumnHelper();

export const useColumns = ({ approve, deny, view, namespace }) => {
	const { t } = useTranslation();
	const { groups } = useContext(OrgLevelGroupsContext);
	const dateFormat = useCompanyDateFormat();
	const timeFormat = useCompanyTimeFormat();
	const { Option } = Select;
	const user = useUser();

	const { hasAccess: canApprove } = useAccess("request.canApproveTeamRequest");
	const { hasAccess: canDeny } = useAccess("request.canDenyTeamRequest");

	const columns = useMemo(() => {
		const cols = [
			columnHelper.accessor("user.employeeNumber", {
				enableColumnFilter: true,
				header: t("employee-number"),
			}),
			columnHelper.accessor("user", {
				enableColumnFilter: true,
				header: t("employee-name"),
				cell: (info) => {
					const value = info.getValue();
					return value && renderUserName(value);
				},
			}),
		];

		if (namespace !== "availability") {
			cols.splice(3, 0, columnHelper.accessor("startDate", {
				enableColumnFilter: true,
				header: t("start-date"),
				cell: (info) => {
					const { data } = info.row.original;
					return dateTimeFormat(data?.startDate, dateFormat);
				},
			}),
			columnHelper.accessor("startTime", {
				header: t("start-time"),
				cell: (info) => {
					const { data } = info.row.original;
					return dateTimeFormat(data?.startDate, timeFormat);
				},
			}),
			columnHelper.accessor("endDate", {
				header: t("end-date"),
				cell: (info) => {
					const { data } = info.row.original;
					return dateTimeFormat(data?.endDate, dateFormat);
				},
			}),
			columnHelper.accessor("endTime", {
				header: t("end-time"),
				cell: (info) => {
					const { data } = info.row.original;
					return dateTimeFormat(data?.endDate, timeFormat);
				},
			}),)
		}

		if (["clock", "schedule", "availability"].includes(namespace)) {
			cols.push(columnHelper.accessor("type", {
				header: t("type"),
				cell: (info) => t(info.getValue())
			}));
		}


		if (namespace === "pto") {
			cols.push(columnHelper.accessor("plan", {
				enableColumnFilter: true,
				header: t("plan"),
				cell: ({ row: { original } }) => {
					const plan = original?.data?.plan?.plan;
					return plan && renderSettingsResourceLabel(plan)
				},
				filterType: "resource-select",
				Filter: (
					<ResourceSelect
					    mode="multiple"
						labelPropName="description"
						resourcePath="/timeoff"
						renderLabel={renderSettingsResourceLabel}
						hasSearch
						getSearchFilters={getSettingsResourceSearchFilters}
					/>
				)
			}));
		}

		if (namespace === "clock") {
			cols.push(
				columnHelper.accessor("data.location", {
					enableColumnFilter: true,
					header: t("location"),
					cell: (info) => {
						const value = info.getValue();
						return value && renderSettingsResourceLabel(value);
					},
					filterType: "resource-select",
					Filter: (
						<ResourceSelect
							labelPropName="description"
							resourcePath="/locations"
							mode="multiple"
							renderLabel={renderSettingsResourceLabel}
							getSearchFilters={getSettingsResourceSearchFilters}
							hasSearch
						/>
					),
				}), 
				columnHelper.accessor("data.job", {
					enableColumnFilter: true,
					header: t("job"),
					cell: (info) => {
						const value = info.getValue();
						return value && renderSettingsResourceLabel(value);
					},
					filterType: "resource-select",
					Filter: (
						<ResourceSelect
							labelPropName="description"
							resourcePath="/jobs"
							mode="multiple"
							renderLabel={renderSettingsResourceLabel}
							getSearchFilters={getSettingsResourceSearchFilters}
							hasSearch
						/>
					),
				})
			);
		}

		if (groups) {
			const groupsColumns = groups.map((group) => {
				const level = group.level.replace("_", "");
				return columnHelper.accessor(level, {
					enableColumnFilter: true,
					header: group.description,
					cell: (info) => {
						const value = info.getValue();
						return value && renderOrgLevelResourceLabel(value);
					},
					filterType: "select",
					Filter: (
						<ResourceSelect
							labelPropName="description"
							resourcePath={`/${group?.id}/org-levels`}
							mode="multiple"
							renderLabel={renderOrgLevelResourceLabel}
							getSearchFilters={
								getSettingsOrgResourceSearchFilters
							}
							valuePropName="id"
							hasSearch
						/>
					),
				});
			});
			cols.push(...groupsColumns);
		}

		cols.push(
			columnHelper.accessor("status", {
				enableColumnFilter: true,
				header: t("status"),
				cell: ({ row }) => <StatusCell value={row.original} />,
				filterType: "select",
				Filter: (
					<Select>
						<Option value="approved">{t("approved")}</Option>
						<Option value="denied">{t("denied")}</Option>
						<Option value="pending">{t("pending")}</Option>
						<Option value="canceled">{t("canceled")}</Option>
						<Option value="expired">{t("expired")}</Option>
					</Select>
				),
			})
		);

		// if (approve || deny || view) {
		const actionsColumn = columnHelper.display({
			id: "actions",
			enableHiding: false,
			enableColumnFilter: false,
			header: () => (
				<div className="text-right">
					{t("actions")}
				</div>
			),
			cell: (info) => {
				const { id, status, requiredToBeApprovedBy } = info.row.original;
				const noop = () => {};
				return (
					<div
						className={classNames(
							"d-flex align-items-center justify-content-end gap-x-2",
						)}
					>
						{((
							(requiredToBeApprovedBy || []).includes(user.id) || canApprove) && 
							(status === "pending" || status === "pending-canceled")) && (
							<i
								className="fa-solid fa-check text-green cursor-pointer"
								onClick={() => approve({ id, close: noop })}
								style={{ fontSize: "17px" }}
							/>
						)}
						{((
							(requiredToBeApprovedBy || []).includes(user.id) || canDeny) && 
							(status === "pending" || status === "pending-canceled")) && (
							<i
								className="fa-solid fa-xmark text-danger cursor-pointer mx-2"
								onClick={() => deny({ id, close: noop})}
								style={{ fontSize: "17px" }}
							/>
						)}
						{view && (
							<i
								className="fa-solid fa-eye text-dark cursor-pointer"
								onClick={() => view(info.row.original)}
								style={{ fontSize: "17px" }}
							/>
						)}
					</div>
				);
			},
		});
		cols.push(actionsColumn);
		// }

		return cols;
	}, [t, namespace, groups, dateFormat, timeFormat, user.id, canApprove, canDeny, view, approve, deny]);

	return columns;
};
