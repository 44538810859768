import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import Form, { Field as BaseField } from "rc-field-form";
import Text from "../../../../../../../../../../components/Inputs/Text";
import Field from "../../../../../../../../../../components/Field";
import Checkbox from "../../../../../../../../../../components/Inputs/Checkbox";
import Button from "../../../../../../../../../../components/Button";
import HourTypeSelect from "../../../../../../../../../../components/Inputs/HourTypeSelect";
import PayCodeSelect from "../../../../PayCodeSelect";
import ResourceSelect from "../../../../../../../../../../components/Inputs/ResourceSelect";
import List from "./List"
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getPathWithParams,
} from "../../../../../../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../../../../../helper";

function Details({ close, overtime, disabled }) {
	const { t } = useTranslation();
	const [hidden, setHidden] = useState(false);

	const onChange = useCallback(
		(e) => setHidden(!e.target.checked),
		[setHidden],
	);

	return (
		<Form
			name="consecutive-day"
			onFinish={close}
			labelAlign="left"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 14, offset: 2 }}
			colon={false}
			initialValues={overtime}
			scrollToFirstError
		>
			<Field
				name="value"
				label={t("day-to-look")}
				rules={[
					{
						required: true,
						message: t("required-consecutive-day"),
					},
				]}
			>
				<Text
					type="number"
					placeholder={t("consecutive-day")}
					disabled
				/>
			</Field>

			<Field label={t("default-pay-code")} name="defaultPayCode">
				<ResourceSelect
					labelPropName="description"
					renderLabel={renderSettingsResourceLabel}
					resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields)}
					placeholder={t("default-pay-code")}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
					disabled={disabled}
					allowClear
				/>
			</Field>

			<Field name="calculateOtherOvertime" valuePropName="checked">
				<Checkbox id="calculateOtherOvertime">
					{t("calculate-other-overtime")}
				</Checkbox>
			</Field>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue, setFieldValue, getFieldsError }) => {
					const calculateOtherOvertime = getFieldValue("calculateOtherOvertime");
					const overtimes = getFieldValue("overtimes");
					const defaultPayCode = getFieldValue("defaultPayCode");

					return calculateOtherOvertime && (
						<List
							overtimes={overtimes}
							defaultPayCode={defaultPayCode}
							disabled={disabled}
							getFieldValue={getFieldValue}
							setFieldValue={setFieldValue}
							getFieldsError={getFieldsError}
						/>
					);
				}}
			</BaseField>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, setFieldValue }) => {
					const payCodes = getFieldValue("payCodes");
					const onChange = (hourTypes) => {
						setFieldValue("payCodes", payCodes?.filter((payCode) =>
							hourTypes.includes(payCode.hourType),
						));
					};

					return (
						<Field
							label={t("eligible-hour-types")}
							name="hourTypes"
						>
							<HourTypeSelect
								placeholder={t("eligible-hour-types")}
								mode="multiple"
								onChange={onChange}
								disabled={disabled}
							/>
						</Field>
					);
				}}
			</BaseField>

			<div className="mb-3">
				<Checkbox
					checked={!hidden}
					onChange={onChange}
					id="show-pay-code"
					disabled={disabled}
				>
					{t("show-eligible-pay-code")}
				</Checkbox>
			</div>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue }) =>
					!hidden && (
						<PayCodeSelect
							hourTypes={getFieldValue("hourTypes")}
							name="payCodes"
							hidden={hidden}
							disabled={disabled}
						/>
					)
				}
			</BaseField>

			<div className="d-flex justify-content-between my-2">
				{disabled ? (
					<div />
				) : (
					<Button
						type="submit"
						color="primary"
						size="xs"
						className="bg-dark"
					>
						{t("save-changes")}
					</Button>
				)}

				<Button
					size="small"
					className="border-primary-color mr-1"
					onClick={close}
				>
					{t("close")}
				</Button>
			</div>
		</Form>
	);
}

export default Details;
