import BaseSelect, { Option, OptGroup } from "rc-select";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faChevronDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import "./_library-style.scss";
import "./_style.scss";

function Select({ className, dropdownClassName, hasError, children, ...props }) {
  return (
    <BaseSelect
      {...props}
      clearIcon={<FontAwesomeIcon icon={faXmark} />}
      inputIcon={({ open }) => (
        <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
      )}
      removeIcon={<FontAwesomeIcon icon={faXmark} />}
      menuItemSelectedIcon={null}
      showArrow
      className={classNames(
        className,
        hasError && "invalid-select",
      )}
      dropdownClassName={dropdownClassName}
      dropdownAlign={{ dynamicInset: true }}
    >
      {children}
    </BaseSelect>
  );
}

Select.OptGroup = OptGroup;
Select.Option = Option;

export default Select;
