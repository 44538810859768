import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Form, { isInProhibition } from "./Form";
import Modal from "../../../components/Modal";
import { useWarningModal } from "./WarningModal";
import { useUser } from "../../../utils/hooks/user";
import { useMyRequests } from "../api";
import { getPrimaryActiveResource } from "../../../utils/helpers/user";
import { combineDateAndTime } from "../../../utils/helpers/date";
import { useGetIpAddress } from "../../TimeClock/ClockIn/api";
import { enumerateDaysBetweenDates } from "../../TimeSheet/ManagerView/Table/useColumns";
import "./_style.scss";

function AddAbsence({ isOpen, onClose, setData, setCanRefetchPlans }) {
    const { t } = useTranslation();
    const user = useUser();
    const { ip, getIp } = useGetIpAddress();

    const { loading, error, create } = useMyRequests();

    const onRequestSuccess = useCallback((request) => {
        setData((prev) => (prev || []).concat(request));
        setCanRefetchPlans(true);
        onClose();
    }, [setData, setCanRefetchPlans, onClose]);

    const {
        warningModal,
        open: openWarningModal
    } = useWarningModal({ closeDrawer: onClose, setData, setCanRefetchPlans });


    const onFinish = useCallback(
        (values) => {
            if (!user?.id) {
                return;
            }

            const timezone = getPrimaryActiveResource(user.locations || [])?.timezoneValue;
            let startDate;
            let endDate;
            if (values.allDay) {
                startDate = combineDateAndTime(values.startDate, moment().startOf("day"));
                endDate = combineDateAndTime(values.endDate, moment().endOf("day"));
            } else {
                startDate = combineDateAndTime(values.date, values.startDate);
                endDate = combineDateAndTime(values.date, values.endDate);
            }
            if (timezone) {
                startDate.tz(timezone, true);
                endDate.tz(timezone, true);
            }

            const data = {
                plan: values.plan.id,
                allDay: values.allDay,
                note: values.note,
                startDate: startDate.toISOString(true),
                endDate: endDate.toISOString(true),
            };

            const request = {
                type: "absence",
                user: user.id,
                data,
                device: "web-application",
                ip: ip ? ip : "",
                namespace: "pto"
            };
            const allDayDiff = (values?.allDay && startDate && endDate) && (moment(endDate, "YYYY-MM-DD").diff(moment(startDate, "YYYY-MM-DD"), "day") + 1) * 8;
            const diff = (startDate && endDate) && moment(endDate).diff(moment(startDate), "hours");
            const balance = values?.plan?.total;
            const hasError = (values.allDay && allDayDiff > Number(balance)) || (!values.allDay && diff > Number(balance));
            const holidayDates = user?.holiday?.days?.map((day) => {
                if (day?.isSameDate) {
                    return day?.date
                } else {
                    return day?.dates;
                }
            })?.flat();
            const dates =
                values?.startDate && values?.endDate ? enumerateDaysBetweenDates(startDate, endDate) : [];

            const weekends = dates.filter((date) =>
                moment(date).format("dddd").toLowerCase() === "sunday" ||
                moment(date).format("dddd").toLowerCase() === "saturday"
            );

            const holidays = dates?.filter((date) => (holidayDates || []).includes(date));
           
            if (!values?.plan) {
                return;
            }
            const { inProhibition, allowRequests } = isInProhibition(values?.plan, user);
            if (inProhibition && !allowRequests) {
                console.warn("inProhibition");
                return;
            }
            if (!hasError) {
                if (weekends?.length > 0 || holidays?.length > 0) {
                    let days = (weekends?.length > 0 && holidays?.length > 0)
                        ? [...weekends, ...holidays]
                        : holidays?.length > 0 ? holidays : [];
                    days = days.filter((item, i, arr) => arr.indexOf(item) === i);
                    days = days?.sort((a, b) => moment(a).diff(moment(b), "seconds"))
                    const content = (weekends?.length > 0 && holidays?.length)
                        ? t("you-have-selected-weekend-days-and-holidays")
                        : weekends?.length > 0
                            ? t("you-have-selected-weekend-days")
                            : t("you-have-selected-holidays")
                    openWarningModal({
                        content,
                        days,
                        request,
                        closeEdit: onClose
                    });
                } else {
                    create
                        (request,
                            (request) => {
                                onClose();
                                onRequestSuccess(request);
                                toast.success(t("request-created-successfully"));
                            },
                            // (error) => {
                            //     if (typeof error === "string") {
                            //         toast.error(error);
                            //     }
                            // }
                        );
                }
            }
        },
        [user, ip, create, onClose, onRequestSuccess, openWarningModal, t]
    );

    const values = { data: { allDay: true } };

    useEffect(() => {
        const controller = new AbortController();
        getIp(controller);
        return () => controller.abort();
    }, [getIp]);

    return (
        <Modal
            className="add-schedule-modal modal-dialog-centered modal-lg pb-0 shadow-none"
            title={t("add-absence-request")}
            isOpen={isOpen}
            toggle={onClose}
            size="lg"
            scrollable
            centered
        >
            <Form
                onClose={onClose}
                values={values}
                onFinish={onFinish}
                error={error}
                loading={loading}
                userId={user.id}
            />

            {warningModal}
        </Modal>
    );
}

export default AddAbsence;
