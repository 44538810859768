import { useCallback, useEffect, useState } from "react";
import { omit } from "lodash";
import { useTranslation } from "react-i18next";
import FormElement from "rc-field-form";
import moment from "moment-timezone";
import Button from "../../../../../../components/Button";
import Field from "../../../../../../components/Field";
import BaseSettingsFormContent from "../../../../../../components/Form/BaseSettingsFormContent";
import DateInput from "../../../../../../components/Inputs/DateInput";
import Rules from "./Rules";
import { useIsMasterCompany } from "../../../../../../utils/hooks/company";
import { useIsFieldDisabled } from "../../../../helpers/useIsFieldDisabled";
import { generateErrorsConfigForForm } from "../../../../../../utils/helpers/errors";
import { useModuleAccess } from "../../../../../../utils/hooks/access";
import "./style.scss";

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.payPolicies.calculationGroup");
	const disabled = useIsFieldDisabled({ ...access, mode });
	const [ruleError, setRuleError] = useState(null);
	const rules = FormElement.useWatch("rules", form);

	const onFinish = useCallback((values) => {
		values.effectiveDate = moment(values.effectiveDate).format("YYYY-MM-DD");
		values.rules = values?.rules?.map((rule) => ({
			...rule,
			payCode: omit(rule.payCode, "_id"),
			value: rule?.method === "percentage"
				? (Number(rule?.value) / 100)
				: Number(rule?.value || 0),
			conditions: rule?.conditions?.map((condition) => {
				if (!condition.value) {
					condition.value = [];
				}
				return {
					...condition,
					value: condition.value.map((item) => {
						return omit(item, "_id");
					}),
				};
			}) || []
		}));

		if (values?.rules?.length === 0 || !values?.rules) {
			setRuleError(t("rules-are-required"));
		} else {
			submit(values);
		}
	}, [submit, t]);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			...values,
			effectiveDate: values?.effectiveDate && moment(values.effectiveDate).toDate(),
			rules: values?.rules && values?.rules?.map((rule) => ({
				...rule,
				value: rule?.method === "percentage"
					? (rule?.value * 100 || 0).toString()
					: rule?.value
			}))
		});
	}, [form, values]);

	useEffect(() => {
		if (rules?.length > 0) {
			setRuleError(null);
		}
	}, [rules?.length]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status", "effectiveDate"],
			error,
		);
		form.setFields(fieldErrors);
	}, [error, form]);


	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100 amount-per-unit-form"
			onFinish={onFinish}
		>
			<div>
				<Field
					name="effectiveDate"
					label={t("effective-date")}
					rules={[
						{
							required: true,
							message: t("required-effective-date")
						}
					]}
					className="d-flex flex-column w-100"
				>
					<DateInput placeholderText={t("effective-date")} />
				</Field>

				<BaseSettingsFormContent
					mode={mode}
					disabled={disabled || !isMasterCompany}
				/>

				<div className="p-4">
					<Rules form={form} />

					{ruleError && (
						<div className="d-block invalid-feedback">
							{ruleError}
						</div>
					)}
				</div>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled || isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
						disabled={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement >
	);
}

export default Form;
