import { useCallback, useState } from "react";
import Form, { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import Text from "../../../../../../../../../components/Inputs/Text";
import HourTypeSelect from "../../../../../../../../../components/Inputs/HourTypeSelect";
import Field from "../../../../../../../../../components/Field";
import Checkbox from "../../../../../../../../../components/Inputs/Checkbox";
import Button from "../../../../../../../../../components/Button";
import ResourceSelect from "../../../../../../../../../components/Inputs/ResourceSelect";
import PayCodeSelect from "../../../PayCodeSelect";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getPathWithParams,
} from "../../../../../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../../../../helper";

function Details({ close, overtime, weeklyOvertime, disabled }) {
	const { t } = useTranslation();
	const [hidden, setHidden] = useState(false);

	const onChange = useCallback(
		(e) => setHidden(!e.target.checked),
		[setHidden],
	);

	return (
		<Form
			name="daily-overtime"
			onFinish={close}
			labelAlign="left"
			labelCol={{ span: 8 }}
			wrapperCol={{ span: 14, offset: 2 }}
			colon={false}
			initialValues={overtime}
			scrollToFirstError
		>
			<Field
				label={t("daily-overtime")}
				name="value"
				rules={[
					{
						required: true,
						message: t("required-daily-overtime-after"),
					},
					{
						validator(_, value) {
							if (
								weeklyOvertime.status &&
								weeklyOvertime.value &&
								value !== null
							) {
								if (
									Number(weeklyOvertime.value) <=
									Number(value)
								) {
									return Promise.reject(
										new Error(
											t("daily-overtime-smaller-than-weekly-overtime"),
										),
									);
								}
							}

							return Promise.resolve();
						},
					},
				]}
			>
				<Text type="number" placeholder={t("daily-overtime")} />
			</Field>

			<Field label={t("default-pay-code")} name="defaultPayCode">
				<ResourceSelect
					labelPropName="description"
					renderLabel={renderSettingsResourceLabel}
					resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields)}
					placeholder={t("default-pay-code")}
					hasSearch
					getSearchFilters={getSettingsResourceSearchFilters}
					disabled={disabled}
					allowClear
				/>
			</Field>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, setFieldValue }) => {
					const payCodes = getFieldValue("payCodes");
					const onChange = (hourTypes) => {
						setFieldValue("payCodes", payCodes?.filter((payCode) =>
							hourTypes.includes(payCode.hourType),
						));
					};

					return (
						<Field
							label={t("eligible-hour-types")}
							name="hourTypes"
						>
							<HourTypeSelect
								mode="multiple"
								placeholder={t("eligible-hour-types")}
								onChange={onChange}
								disabled={disabled}
							/>
						</Field>
					);
				}}
			</BaseField>

			<div className="mb-3">
				<Checkbox
					checked={!hidden}
					onChange={onChange}
					id="daily-overtime-show-pay-code"
					disabled={disabled}
				>
					{t("show-eligible-pay-code")}
				</Checkbox>
			</div>

			<BaseField shouldUpdate>
				{({ }, { }, { getFieldValue }) =>
					!hidden && (
						<PayCodeSelect
							hourTypes={getFieldValue("hourTypes")}
							name="payCodes"
							hidden={hidden}
							disabled={disabled}
						/>
					)
				}
			</BaseField>

			<div className="d-flex justify-content-between my-2">
				{disabled ? (
					<div />
				) : (
					<Button
						type="submit"
						color="primary"
						size="xs"
						className="bg-dark"
					>
						{t("save-changes")}
					</Button>
				)}

				<Button
					size="small"
					className="border-primary-color mr-1"
					onClick={close}
				>
					{t("close")}
				</Button>
			</div>
		</Form>
	);
}

export default Details;
