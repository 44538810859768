import { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Input } from "reactstrap";
import classNames from "classnames";
import Field from "../../../../../../../components/Field";
import ResourceSelect from "../../../../../../../components/Inputs/ResourceSelect";
import Modal from "../../../../../../../components/Modal";
import ShiftPremiumForm from "./Form";
import Switch from "../../../../../../../components/Inputs/Switch";
import { renderSettingsResourceLabel, getSettingsResourceSearchFilters } from "../../../../../../../utils/helpers/settings";;

function ShiftPremiumRule({ disabled }) {
	const { t } = useTranslation();
	const [visible, setVisible] = useState(false);

	const open = useCallback(() => {
		setVisible(true);
	}, [setVisible]);

	const close = useCallback(() => {
		setVisible(false);
	}, [setVisible]);

	return (
		<div className="border-bottom mb-10">
			<div className="mb-3 d-flex align-items-center gap-2 mb-4">
				<BaseField name={["shiftPremium", "status"]} valuePropName="checked">
					<Switch />
				</BaseField>
				<h3 className="font-weight-bolder text-sm mb-0">
					{t("shift-premium-rule")}
				</h3>
			</div>

			<div className="mb-10">
				<BaseField shouldUpdate>
					{({ }, { }, { getFieldValue }) => {
						const status = getFieldValue(["shiftPremium", "status"]);

						return status && (
							<div className="d-flex align-items-center justify-content-between">
								<Field
									name={["shiftPremium", "id"]}
									label={t("shift-premium")}
									rules={status ? [{
										required: true,
										message: t("required-shift-premium")
									}] : undefined}
									className="w-100"
								>
									<ResourceSelect
										resourcePath="/calc-rules/shift-premium"
										renderLabel={renderSettingsResourceLabel}
										hasSearch
										getSearchFilters={getSettingsResourceSearchFilters}
										disabled={disabled || !status}
										allowClear
									/>
								</Field>

								<FontAwesomeIcon
									icon={faPen}
									className={classNames(status && "cursor-pointer", "ml-2")}
									onClick={status ? open : undefined}
								/>
							</div>
						)
					}}
				</BaseField>
			</div>

			{visible && (
				<BaseField shouldUpdate noStyle>
					{({ }, { }, { getFieldValue }) => (
						<Modal
							title={t("shift-premium-parameters")}
							isOpen={visible}
							close={close}
							centered
						>
							<ShiftPremiumForm
								close={close}
								shiftPremium={getFieldValue("shiftPremium")}
								disabled={disabled}
							/>
						</Modal>
					)}
				</BaseField>
			)}

			<BaseField name={["shiftPremium", "hourTypes"]}>
				<Input hidden />
			</BaseField>

			<BaseField name={["shiftPremium", "payCodes"]}>
				<Input hidden />
			</BaseField>
		</div>
	);
}

export default ShiftPremiumRule;
