import FormElement from "rc-field-form";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../../../components/Button";
import Content from "./Content";
import { generateErrorsConfigForForm } from "../../../../../utils/helpers/errors";
import { useIsMasterCompany } from "../../../../../utils/hooks/company";
import { useLocation } from "../helpers";
import { useModuleAccess } from "../../../../../utils/hooks/access";
import { useIsFieldDisabled } from "../../../helpers/useIsFieldDisabled";

const formatSubbmittedFields = (values) => ({
	...values,
	country: values?.country?.id,
	state: values?.state,
	city: values?.city,
	timezone: values?.timezone?.id,
	level1: values?.level1?.map((level) => level?.id),
	level2: values?.level2?.map((level) => level?.id),
	level3: values?.level3?.map((level) => level?.id),
	level4: values?.level4?.map((level) => level?.id),
});

export const defaultCenter = { lat: 40.6974034, lng: -74.1197633 };
export const defaultZoom = 11;

function Form({ mode, values, error, loading, submit, close }) {
	const { t } = useTranslation();
	const [form] = FormElement.useForm();
	const isMasterCompany = useIsMasterCompany();
	const { access } = useModuleAccess("settings.general.locations");
	const disabled = useIsFieldDisabled({ ...access, mode });
	const [markerError, setMarkerError] = useState(false);

	const onClose = useCallback(() => {
		form.resetFields();
		close();
	}, [form, close]);

	const {
		marker,
		setMarker,
		zoom,
		setZoom,
		center,
		setCenter,
		changeMarker,
	} = useLocation(form);

	const onFinish = useCallback(
		(formValues) => {
			const data = {
				...formatSubbmittedFields(formValues),
				marker,
				mapCenter: center,
				mapZoom: zoom,
			};
			if(!marker?.lat && !marker?.lng) {
				setMarkerError(true);
			} else {
				setMarkerError(false);
				submit(data);
			}
		},
		[submit, marker, zoom, center],
	);

	useEffect(() => {
		form.resetFields();
		form.setFieldsValue({
			status: "active",
			...values,
		});
		setCenter(values?.mapCenter || defaultCenter);
		setZoom(values?.mapZoom || defaultZoom);
		setMarker(values?.marker || null);
	}, [form, values, setCenter, setZoom, setMarker]);

	useEffect(() => {
		const fieldErrors = generateErrorsConfigForForm(
			["code", "description", "status", "country"],
			error,
		);

		form.setFields(fieldErrors);
	}, [error, form]);

	return (
		<FormElement
			form={form}
			className="d-flex flex-column justify-content-between h-100"
			onFinish={onFinish}
		>
			<div>
				<Content
					mode={mode}
					changeMarker={changeMarker}
					marker={marker}
					markerError={markerError}
					zoom={zoom}
					center={center}
					disabled={disabled || !isMasterCompany}
				/>
			</div>

			<div className="d-flex justify-content-end">
				<Button
					onClick={onClose}
					disabled={loading}
					className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
				>
					{t("cancel")}
				</Button>

				{(!disabled && isMasterCompany) && (
					<Button
						type="submit"
						className="btn-dark btn-sm shadow-none"
						loading={loading}
					>
						{t("save")}
					</Button>
				)}
			</div>
		</FormElement>
	);
}

export default Form;
