import { useMemo } from "react";
import { useFilters as useTimeSheetFilters } from "../../../Home/Actions/useFilters";
import { useEmployeeNumberFilter } from "./useEmployeeNumberFilter";
import { useEmployeeTypeFilter } from "./useEmployeeTypeFilter";
import { useFirstNameFilter } from "./useFirstNameFilter";
import { useLastNameFilter } from "./useLastNameFilter";
import { usePayGroupFilter } from "./usePayGroupFilter";
import { useProjectFilter } from "./useProjectFilter";
import { useUnionFilter } from "./useUnionFilter";
import { useCalculationGroupFilter } from "./useCalculationGroupFilter";
import { useUserGroupFilter } from "./useUserGroupFilter";
import { useEmploymentTypeFilter } from "./useEmploymentTypeFilter";

export const useFilters = () => {
	const employeeNumberFilter = useEmployeeNumberFilter();
	const firstNameFilter = useFirstNameFilter();
	const lastNameFilter = useLastNameFilter();
	const timeSheetFilters = useTimeSheetFilters();
	const payGroupFilter = usePayGroupFilter();
	const employeeTypeFilter = useEmployeeTypeFilter();
	const projectFilter = useProjectFilter();
	const calculationGroupFilter = useCalculationGroupFilter();
	const unionFilter = useUnionFilter();
	const levelFilters = timeSheetFilters.filter(({ id }) => id.indexOf('level') === 0);
	const otherTimesheetFilters = timeSheetFilters.filter(({ id }) => !(id.indexOf('level') === 0));
	const userGroupFilter = useUserGroupFilter();
	const employmentType = useEmploymentTypeFilter();

	return useMemo(
		() => [
			employeeNumberFilter,
			firstNameFilter,
			lastNameFilter,
			...levelFilters,
			payGroupFilter,
			// employeeTypeFilter,
			projectFilter,
			calculationGroupFilter,
			unionFilter,
			userGroupFilter,
			employmentType,
			...otherTimesheetFilters,
		],
		[
			userGroupFilter,
			levelFilters,
			employeeNumberFilter,
			payGroupFilter,
			firstNameFilter,
			lastNameFilter,
			otherTimesheetFilters,
			// employeeTypeFilter,
			projectFilter,
			calculationGroupFilter,
			unionFilter,
			employmentType,
		],
	);
};
