import moment from "moment-timezone";
import { combineDateAndTime } from "../../../../utils/helpers/date";
import { getRateFromJobAndPayCode } from "../../../../utils/helpers/rateCalculation";

export const calculateRate = (val) => {
    if (val?.hourType !== 'amount') {
        if (val?.hourType === 'unpaid') {
            val.rateChangeManually = false;
            val.rate = 0;
        } else {
            val.rate = getRateFromJobAndPayCode(val.job, val.payCode);
            val.rateChangeManually = false;
        }
    } else {
        val.rateChangeManually = false;
    }
    val.rate = val.rate || 0;
    val.regRate = val.rate;
    val.rate = val.rate * (val.payCode && val.payCode.multiplier ? val.payCode.multiplier : 1);

    return val;
}

export const calculateTotal = (val) => {
    if (val?.hourType !== 'amount') {
        val.total = val.duration && val.duration > 0 ? ((val.duration / 3600) * val.rate) : 0;
    } else {
        val.total = val.rate;
    }
    return val;
}

export const calculateDuration = (val) => {
    if (val?.hourType !== 'unit') {
        if (val?.hourType !== 'amount') {
            let start = val.start;
            if (start > 30) {
                start = moment.unix(start); //.add(1, 'seconds');
            }

            if (val.end) {
                let end = val.end;
                if (end > 30) {
                    end = moment.unix(end); //.add(1, 'seconds');
                }
                val.end = moment(end).set("milliseconds", 0).set("seconds", 0).unix()
                val.duration = end.diff(start, 'seconds');
            } else {
                val.duration = 0;
            }

            val.start = moment(start).set("milliseconds", 0).set("seconds", 0).unix();
        } else {
            val.duration = 0;
            val.start = moment.tz(val.timezone).startOf('day').unix();
            val.end = moment.tz(val.timezone).startOf('day').unix();
        }
    }
    return val;
}

export const calculateOverNight = (val) => {
    val.overnight = '';
    if (val.end) {
        const start = moment.unix(val.start);
        const end = moment.unix(val.end);
        if (end.diff(start, 'days') > 0) {
            val.overnight = `+${end.diff(start, 'days')}`;
        }
    }
    return val;
}

export const makeCalculations = (section) => {
    section.hourType = section?.hourType || section?.payCode?.hourType || "regular";
    section.timezone = section?.timezone || section?.location?.timezoneValue;
    section = calculateDuration(section);
    section = calculateRate(section);
    section = calculateTotal(section);
    section = calculateOverNight(section);
    return section;
};

export const getFormFormattedValues = (values) => {
    let {
        date,
        startDate,
        endDate,
        resourceId,
        user,
        eventType,
        sections,
        status,
        rate,
        company,
        ...otherValues
    } = values;

    rate = typeof rate === "number" ? rate : 0;
    date = moment(date).format("YYYY-MM-DD");
    startDate = startDate || sections?.[0]?.start;
    endDate = endDate || sections?.[sections?.length - 1]?.end;

    const start = moment(startDate).set("milliseconds", 0).set("seconds", 0).unix();
    const end = moment(endDate).set("milliseconds", 0).set("seconds", 0).unix();
    const duration = end - start;

    if (eventType?.allowSegmentation) {
        let sectionStartDate = moment(startDate).clone();
        sections = sections.filter(s => typeof s.start !== 'undefined' && typeof s.end !== 'undefined').map((section) => {
            let shStart = undefined;
            if (typeof section?.start === "number") {
                shStart = moment(startDate).add(section.start, "seconds");
            } else if (section?.start) {
                shStart = combineDateAndTime(moment(sectionStartDate), moment(section.start));
            }
            let shEnd = undefined;
            if (typeof section?.end === "number") {
                shEnd = moment(startDate).add(section.end, "seconds");
            } else if (section?.start) {
                shEnd = combineDateAndTime(moment(sectionStartDate), moment(section.end));
            }
            if ( shEnd && shEnd.isBefore(shStart, "seconds")) {
                shEnd = shEnd.add(1, "day")
            }
            if (shEnd) {
                sectionStartDate = shEnd.clone();
            }

            const sectionStart = moment(shStart).unix();
            const sectionEnd = moment(shEnd).unix();
            const sStart = Math.abs(sectionStart - start);
            const sEnd = Math.abs(sectionEnd - start);

            if (section?.payCode?.hourType === "amount") {
                rate = section?.job?.hourlyRate || 0;
            }

            const duration = sEnd - sStart;

            const data = {
                payCode: section?.payCode,
                location: section?.location,
                job: section?.job,
                rate,
                type: section?.type,
                start: sStart,
                end: sEnd,
                duration: typeof section?.duration === "number"
                    ? section?.duration
                    : duration
            };
            ["level1", "level2", "level3", "level4"].forEach((level) => {
                if (section && section?.[level]) {
                    const levelData = section?.[level];
                    if (!!levelData) {
                        data[level] = {
                            code: levelData?.code,
                            description: levelData?.description,
                            id: levelData?.id,
                            orgLevelGroup: typeof levelData?.orgLevelGroup === "string"
                                ? levelData?.orgLevelGroup
                                : levelData?.orgLevelGroup?.id,
                            glSegment: levelData?.glSegment,
                        }
                    }
                }
            });
            return makeCalculations(data);
        });
    } else {
        const duration = end - start;
        if (otherValues?.payCode?.hourType === "amount") {
            rate = otherValues?.job?.hourlyRate || 0;
        }
        let data = {
            payCode: otherValues?.payCode,
            location: otherValues?.location,
            job: otherValues?.job,
            rate,
            type: "regular",
            start: 0,
            end: duration,
            duration: typeof otherValues?.duration === "number"
                ? otherValues?.duration
                : duration
        };

        ["level1", "level2", "level3", "level4"].forEach((level) => {
            if (otherValues && otherValues?.[level]) {
                const levelData = otherValues?.[level];
                if (!!levelData) {
                    data[level] = {
                        code: levelData?.code,
                        description: levelData?.description,
                        id: levelData?.id,
                        orgLevelGroup: typeof levelData?.orgLevelGroup === "string"
                            ? levelData?.orgLevelGroup
                            : levelData?.orgLevelGroup?.id,
                        glSegment: levelData?.glSegment,
                    }
                }
            }
        });

        data = makeCalculations(data);
        sections = [data];
    }
    const timezone = sections?.[0]?.location?.timezoneValue || company?.timezone?.value;

    const total = sections?.reduce((total, current) => {
        return total + current.total;
    }, 0);
   
    if (moment(values?.endDate || endDate).isSameOrBefore(moment(values?.startDate || startDate), "seconds")) {
        values.endDate = moment(values?.endDate || endDate).add(1, "day");
    }

    return {
        id: otherValues?.id?.indexOf("_generated") > -1 ? undefined : otherValues?.id,
        date,
        startDate: timezone
            ? moment.parseZone(values?.startDate).tz(timezone, true).toISOString(true)
            : moment.parseZone(values?.startDate).toISOString(true),
        endDate: timezone
            ? moment.parseZone(values?.endDate).tz(timezone, true).toISOString(true)
            : moment.parseZone(values?.endDate).toISOString(true),
        rate,
        endTimestamp: end,
        timestamp: start,
        status: status || "pending",
        duration,
        total,
        eventType: {
            id: eventType?.id,
            code: eventType?.code,
            description: eventType?.description,
            allowSegmentation: eventType?.allowSegmentation,
            forSchedule: eventType?.forSchedule
        },
        user: resourceId || user,
        sections,
        oldResourceId: otherValues?.oldResourceId
    }
}