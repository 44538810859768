import { List } from "rc-field-form";
import { useTranslation } from "react-i18next";
import Button from "../../../../../../../../../../../components/Button";
import Item from "./Item";

function OvertimesList({
    overtimes,
    defaultPayCode,
    disabled,
    setFieldValue,
    getFieldValue,
    getFieldsError
}) {
    const { t } = useTranslation();

    return (
        <List name="overtimes">
            {(data, { add }) => {
                const addItem = () => {
                    const values = { payCode: defaultPayCode };
                    if (data.length > 0) {
                        values.start = overtimes[overtimes.length - 1]?.end && Number(overtimes[overtimes.length - 1]?.end) + 1;
                    }
                    add(values);
                }

                return (
                    <div>
                        {data.map(({ name }) => {
                            const [error] = getFieldsError([
                                ["overtimes", name, "start"],
                                ["overtimes", name, "end"],
                                ["overtimes", name, "payCode"],
                            ]).map(({ errors }) => errors).flat();
                         
                            return (
                                <>
                                    <Item
                                        name={name}
                                        disabled={disabled}
                                        overtimes={overtimes}
                                        setFieldValue={setFieldValue}
                                        getFieldValue={getFieldValue}
                                    />

                                    <div className="invalid-feedback d-block">
                                        {error}
                                    </div>
                                </>
                            )
                        })}

                        <Button
                            color="primary"
                            className="w-100 mb-2"
                            type="button"
                            onClick={addItem}
                        >
                            + {t("add")}
                        </Button>
                    </div>
                )
            }}
        </List>
    )
}

export default OvertimesList;
