import { useCallback, useMemo, useState } from "react";
import Form from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import Button from "../../Button";
import BaseModal from "../../Modal";
import Text from "../Text";
import Field from "../../Field";
import useApi from "../../../utils/api";
import { getErrorMessage } from "../../../utils/helpers/errors";
import { useCompany } from "../../../utils/hooks/company";

function Modal({ form, name, isOpen, close, resourcePath, getSearchFilters }) {
    const { t } = useTranslation();
    const company = useCompany();
    const [loading, setLoading] = useState(false);
    const { authGet } = useApi();
    const values = Form.useWatch(name, form);

    const label = useMemo(() => {
        if (name === "managedUsers") {
            return t("employee-numbers");
        } else {
            return t("codes");
        }
    }, [name, t]);

    const submit = useCallback(async ({ value }) => {
        setLoading(true);
        try {
            const response = await authGet(resourcePath, {
                params: {
                    company: company?.id,
                    importData: value,
                    pagination: false,
                    withoutLookups: true,
                    ...(getSearchFilters("") || [])
                }
            });
            if (response) {
                const ids = values?.map((item) => item?.id) || [];
                const data = [
                    ...values,
                    ...response?.result?.filter((item) => !ids.includes(item?.id))
                ];

                form.setFieldValue(name, data);
                close();
            }
        } catch (err) {
            getErrorMessage(err, t);
        } finally {
            setLoading(false);
        }
    }, [authGet, resourcePath, company?.id, getSearchFilters, form, name, values, close, t]);

    return (
        <BaseModal
            isOpen={isOpen}
            close={close}
            centered
        >
            <Form onFinish={submit}>
                <div className="d-flex flex-column mb-3">
                    <Label className="mb-1 p-0">{label}</Label>
                    <span className="text-muted" style={{ fontSize: "11px" }}>
                        {t("split-character-new-line-comma")}
                    </span>
                </div>

                <Field
                    name="value"
                    rules={[
                        {
                            required: true,
                            message: t("required")
                        }
                    ]}
                >
                    <Text
                        type="textarea"
                        rows={10}
                        resize="none"
                    />
                </Field>

                <div className="d-flex justify-content-end my-3">
                    <Button disabled={loading} color="muted" onClick={close}>
                        {t("cancel")}
                    </Button>

                    <Button color="primary" loading={loading} disabled={loading}>
                        {t("import")}
                    </Button>
                </div>
            </Form>
        </BaseModal>
    );
}

export default Modal;
