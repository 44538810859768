import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import Item from "./Item";
import { useVisible } from "../../../../../../../../utils/hooks/useVisible";

function Rule({ form, name, remove }) {
    const { t } = useTranslation();
    const { visible, toggle } = useVisible();

    return (
        <div key={name} className="amount-per-unit-form__rules__item mb-3">
            <div
                className="d-flex justify-content-between p-4 amount-per-unit-form__rules__item__head">
                <div>
                    <FontAwesomeIcon
                        className="cursor-pointer"
                        icon={!visible ? faChevronUp : faChevronDown}
                        onClick={toggle}
                    />
                    <span className="mx-2 font-weight-bold">{t("rule-number", { rule: name + 1 })}</span>
                </div>

                <FontAwesomeIcon
                    className="text-danger"
                    icon={faTrash}
                    onClick={() => remove(name)}
                />
            </div>

            {!visible && (
                <Item
                    form={form}
                    name={name}
                />
            )}
        </div>
    )
}

export default Rule;