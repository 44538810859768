import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useCommonColumns } from "../../../../utils/hooks/useCommonColumns";
import { useIsMasterCompany } from "../../../../utils/hooks/company";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper();

export const useColumns = ({ edit, remove, clone, allocation }) => {
    const { t } = useTranslation();
    const isMasterCompany = useIsMasterCompany();

    const { code, description, status } = useCommonColumns({ edit });

    return useMemo(() => {
        const columns = [
            { ...code, header: t("name") },
            description,
            status,
            columnHelper.accessor("accural.method", {
                header: t("method"),
                cell: (info) => t(info.getValue()),
            }),
        ];

        if (isMasterCompany) {
            columns.push(
                edit || remove || clone || allocation
                    ? columnHelper.display({
                          id: "actions",
                          enableHiding: false,
                          enableColumnFilter: false,
                          header: <div className="text-right">{t("actions")}</div>,
                          cell: (info) => {
                              const { id, company, isDefault } = info.row.original;

                              return (
                                  <div className="actions text-primary text-right">
                                      {allocation && (
                                          <span
                                              className="btn-inner--icon icon-sm cursor-pointer mr-3"
                                              onClick={() => allocation(id)}
                                          >
                                              <i className="fa-solid fa-users" />
                                          </span>
                                      )}
                                      {company && !isDefault && edit && (
                                          <span
                                              className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                              onClick={() => edit(id)}
                                          >
                                              <i className="fas fa-pen" />
                                          </span>
                                      )}

                                      {company && !isDefault && remove && (
                                          <span
                                              className="btn-inner--icon mr-3 icon-sm cursor-pointer"
                                              onClick={() => remove(id)}
                                          >
                                              <i className="fas fa-trash" />
                                          </span>
                                      )}

                                      {clone && (
                                          <span
                                              className="btn-inner--icon icon-sm cursor-pointer"
                                              onClick={() => clone(id)}
                                          >
                                              <i className="ni ni-single-copy-04" />
                                          </span>
                                      )}
                                  </div>
                              );
                          },
                      })
                    : null
            );
        }
        return columns;
    }, [t, isMasterCompany, code, description, status, clone, edit, remove, allocation]);
};
