import { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import Field from "../../../../../../components/Field";
import Switch from "../../../../../../components/Inputs/Switch";
import { Input } from "reactstrap";

function AutoClockOut({ disabled }) {
    const { t } = useTranslation();

    return (
        <div className="border-bottom mb-5">
            <div className="mb-4">
                <Field
                    name={["autoClockOut", "status"]}
                    valuePropName="checked"
                >
                    <Switch disabled={disabled}>
                        <h3 className="m-0 p-0">{t("auto-clock-out")}</h3>
                    </Switch>
                </Field>

                <BaseField shouldUpdate>
                    {({ }, { }, { getFieldValue, getFieldError }) => {
                        const status = getFieldValue(["autoClockOut", "status"]);
                        const [error] = getFieldError(["autoClockOut", "value"]);
                       
                        return status && (
                            <>
                                <div className="d-flex align-items-center mb-2 pl-5">
                                    <p className="mr-2 text-sm mb-0">
                                        {t("auto-clock-out-employee-after")}
                                    </p>

                                    <BaseField
                                        name={["autoClockOut", "value"]}
                                        rules={[
                                            {
                                                required: true,
                                                message: t("required-value")
                                            },
                                            {
                                                validator(_, value) {
                                                    if (Number(value) < 0) {
                                                        return Promise.reject(new Error(t("value-bigger-than-zero")))
                                                    }
                                                    return Promise.resolve()
                                                }
                                            },
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            size="sm"
                                            className="input-number"
                                            disabled={disabled}
                                            step="0.1"
                                        />
                                    </BaseField>

                                    <p className="mx-2 text-sm mb-0">
                                        {t("minutes")}
                                    </p>
                                </div>

                                {error && (
                                    <div className="d-block invalid-feedback">
                                        {error}
                                    </div>
                                )}
                            </>
                        );
                    }}
                </BaseField>
            </div>
        </div>
    );
}

export default AutoClockOut;
