import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import { useCallback, useContext, useEffect } from "react";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import Field from "../../../components/Field";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../utils/helpers/settings";
import { useQualificationsApi } from "./api";
import { ProfileContext } from "../context";
import Button from "../../../components/Button";
import { useAccess } from "../../../utils/hooks/access";

const Skills = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { isSelf, user } = useContext(ProfileContext);
    const { getSkills, addSkills, data, loading } = useQualificationsApi();

    const { hasAccess: canAddSkill } = useAccess(
        isSelf ? "profile.changeSkill" : "people.changeSkill"
    );

    const onFinish = useCallback(
        (values) => {
            const url = isSelf ? "/profile/change-skills" : `/users/${user?.id}/change-skills`;
            const data = values?.skill?.map((item) => item?.id);
            if(data) {
                addSkills(url, data);
            }
        },
        [isSelf, user?.id, addSkills]
    );

    useEffect(() => {
        const controller = new AbortController();
        const url = isSelf ? "/profile/skills" : `/users/${user?.id}/skills`;
        if (user?.id) {
            getSkills(url, controller);
        }
        return () => controller.abort();
    }, [getSkills, isSelf, user?.id]);

    useEffect(() => {
        form.resetFields();
        form.setFieldValue(
            "skill",
            data?.map((item) => item)
        )      
    }, [form, data]);

    return (
        <Form form={form} onFinish={onFinish} className="mt-4 mx-5">
            <Field name="skill" label={t("skills")}>
                <ResourceSelect
                    labelPropName="description"
                    renderLabel={renderSettingsResourceLabel}
                    resourcePath="/skill"
                    placeholder={t("skills")}
                    allowClear
                    hasSearch
                    getSearchFilters={getSettingsResourceSearchFilters}
                    mode="multiple"
                    loading={loading}
                    disabled={!canAddSkill}
                />
            </Field>
            {canAddSkill ? 
                <div className="d-flex justify-content-end">
                    <Button type="submit" color="primary">
                        {t("save")}
                    </Button>
                </div>        
            : null}
        </Form>
    );
};

export default Skills;
