import { Field } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import Text from "../../../../../../components/Inputs/Text";
import Switch from "../../../../../../components/Inputs/Switch";

function Carryover({ data, disabled }) {
    const { t } = useTranslation();

    return (
        <Field shouldUpdate>
            {({ }, { }, { getFieldValue, setFieldValue }) => {
                const carryoverStatus = getFieldValue("carryoverStatus");
                const onStatusChange = (e) => {
                    if (e.target.checked) {
                        setFieldValue("carryover", data?.carryover);
                        setFieldValue("maxBalance", data?.maxBalance);
                    } else {
                        setFieldValue("carryover", null);
                        if (!["hourly", "per-pay-period"].includes(data?.accural?.method)) {
                            setFieldValue("maxBalance", null);
                        }
                    }
                }

                return (
                    <div style={{ background: "#f8f9f9" }} className="p-4 rounded mb-4">
                        <Field name="carryoverStatus" valuePropName="checked">
                            <Switch onChange={onStatusChange}>
                                <h4 className="m-0 p-0">{t("carryover")}</h4>
                            </Switch>
                        </Field>


                        {carryoverStatus && data.tiers.map((tier, index) => {
                            return (
                                <div key={index} className="d-flex flex-column my-3">
                                    <div className="d-flex gap-2 align-items-center">
                                        <div className="w-100">
                                            <Label>{t("name")}</Label>
                                            <Text disabled={true} value={tier.name} />
                                        </div>

                                        <div className="w-100">
                                            <Label>{t("start")}</Label>
                                            <Text disabled={true} value={tier.start || ""} />
                                        </div>

                                        <div className="w-100">
                                            <Label>{t("end")}</Label>
                                            <Text disabled={true} value={tier.end || ""} />
                                        </div>

                                        <div className="w-100">
                                            <Label>{t("value")}</Label>
                                            <Field
                                                name={["carryover", index, "value"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("required-value")
                                                    },
                                                    {
                                                        validator: (_, value) => {
                                                            if (value && Number(value) < 0) {
                                                                return Promise.reject(
                                                                    new Error(t("readableField-bigger-than-zero", { readableField: t("value") }))
                                                                );
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    }
                                                ]}
                                            >
                                                <Text type="number" disabled={disabled} step="0.1" />
                                            </Field>
                                        </div>
                                    </div>

                                    <Field shouldUpdate>
                                        {({ }, { }, { getFieldError }) => {
                                            const [error] = getFieldError(["carryover", index, "value"]);

                                            return (
                                                <div className="d-block invalid-feedback">
                                                    {error}
                                                </div>
                                            );
                                        }}
                                    </Field>
                                </div>
                            );
                        })}
                    </div>
                );
            }}
        </Field>
    );
}

export default Carryover;
