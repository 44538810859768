import { useState, useCallback } from "react";
import useApi from "..";

export const usePeopleApi = () => {
	const { authGet, authPost, authPut, authDelete } = useApi();

	const getEmployees = useCallback(
		(filters) => {
			return authGet("/users/", {
				params: filters,
			});
		},
		[authGet]
	);

	const getEmployee = useCallback(
		(id) => {
			return authGet(`/users/${id}`);
		},
		[authGet]
	);

	const addEmployee = useCallback(
		(data) => {
			return authPost("/users", {
				data,
			});
		},
		[authPost]
	);

	const updateEmployee = useCallback(
		(id, data) => {
			return authPut(`/users/${id}`, {
				data,
			});
		},
		[authPut]
	);

	const deleteEmployee = useCallback(
		(id) => {
			return authDelete(`/users/${id}`);
		},
		[authDelete]
	);

	const changePassword = useCallback(
		(id, data) => {
			return authPost(`/users/${id}/change-password`, {
				data,
			});
		},
		[authPost]
	);

	const changeJobInformation = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/job`, {
				data,
			});
		},
		[authPut]
	);

	const deleteSecondaryJob = useCallback((userId, jobId) => {
		return authDelete(`/users/${userId}/jobs/${jobId}`);
	},[authDelete]);

	const changeJobsInformation = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/jobs`, {
				data,
			});
		},
		[authPut]
	);

	const changeHiringInformation = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-hiring-information`, {
				data,
			});
		},
		[authPut]
	);

	const changeOrganisation = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-organisation`, {
				data,
			});
		},
		[authPut]
	);

	const changePersonalInformation = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-personal-information`, {
				data,
			});
		},
		[authPut]
	);

	const changeRole = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-role`, {
				data,
			});
		},
		[authPut]
	);

	const changeCompanyInformation = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-company-information`, {
				data,
			});
		},
		[authPut]
	);

	const changeManagement = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-management`, {
				data,
			});
		},
		[authPut]
	);

	const changeAddress = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-address`, {
				data,
			});
		},
		[authPut]
	);

	const changeStatus = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-status`, {
				data,
			});
		},
		[authPut]
	);

	const changeKeyDates = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-key-dates`, {
				data,
			});
		},
		[authPut]
	);

	const changeCalculationGroupRules = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-calculation-group-rules`, {
				data,
			});
		},
		[authPut]
	);

	const terminate = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-status`, {
				data,
			});
		},
		[authPut]
	);

	const uploadDocuments = useCallback(
		(id, data) => {
			return authPost(`/users/${id}/documents`, {
				data,
			});
		},
		[authPost]
	);

	const deleteDocuments = useCallback(
		(id, data) => {
			return authDelete(`/users/${id}/documents/${data.id}`);
		},
		[authDelete]
	);

	const twoFactorAuth = useCallback(
		(id, data) => {
			return authPost(`/users/${id}/two-factor-auth`, {
				data,
			});
		},
		[authPost],
	);

	const authVerify = useCallback(
		(id, data) => {
			return authPost(`/users/${id}/verify`, {
				data,
			});
		},
		[authPost],
	);

	const generateToken = useCallback(
		(id) => {
			return authPost(`/users/${id}/generate`);
		},
		[authPost],
	);
	
	const changeUserGroups = useCallback(
		(id, data) => {
			return authPut(`/users/${id}/change-user-groups`, {
				data,
			});
		},
		[authPut],
	);

	const changeEmploymentType = useCallback((id, data) => {
		return authPut(`/users/${id}/employment-type`, {
			data
		});
	}, [authPut]);
	const changeExpectedHours = useCallback((id, data) => {
		return authPut(`/users/${id}/expected-hours`, { data })
	}, [authPut])

	return {
		getEmployees,
		getEmployee,
		addEmployee,
		updateEmployee,
		deleteEmployee,
		changePassword,
		changeJobInformation,
		changeJobsInformation,
		changeHiringInformation,
		changeManagement,
		changeOrganisation,
		changePersonalInformation,
		changeCompanyInformation,
		changeAddress,
		changeStatus,
		changeKeyDates,
		changeCalculationGroupRules,
		uploadDocuments,
		terminate,
		deleteDocuments,
		changeRole,
		twoFactorAuth,
		authVerify,
		generateToken,
		changeUserGroups,
		deleteSecondaryJob,
		changeEmploymentType,
		changeExpectedHours
	};
};

export const usePeople = () => {
	const [data, setData] = useState([]);
	const [totalItems, setTotalItems] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const { getEmployees } = usePeopleApi();

	const fetch = useCallback(
		async (filters = {}) => {
			setLoading(true);
			setError(null);
			try {
				const response = await getEmployees(filters);
				if (response) {
					setData(response.result);
					setTotalItems(response.totalItems);
				}
			} catch (err) {
				setError(err);
			} finally {
				setLoading(false);
			}
		},
		[setData, setLoading, setError, getEmployees]
	);

	return { data, setData, totalItems, setTotalItems, loading, error, fetch };
};
