import { useCallback, useEffect, useMemo, useState } from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import ProfileDrawer from "../../../components/Drawer/ProfileDrawer";
import { useVisible } from "../../../utils/hooks/useVisible";
import { useUserPreferenceActions, useUserPreferences } from "../../Settings/Configuration/api/useUserPreferences";
import { useMyTeam } from "../../TimeClock/MyTeam/api";
import Map from "../../TimeClock/MyTeam/Map";
import Team from "../../TimeClock/MyTeam/Team";
import MessagesWidget from "../widgets/Messages";
import NotificationsWidget from "../widgets/Notifications";
import PostsWidgets from "../widgets/Posts";
import TimeoffPlans from "../widgets/TimeoffPlans";
import ReportWidget from "../widgets/Report";
import Schedule from "../widgets/Schedule";
import DashboardControls from "./components/DashboardControls";
import EditControls from "./components/EditControls";
import ResponsiveGridLayout from "./components/ResponsiveGridLayout";
import { useCardActions } from "./hooks/useCardActions";
import { useEditMode } from "./hooks/useEditMode";
import { useLayoutPersistence } from "./hooks/useLayoutPersistence";
import { useLayoutState } from "./hooks/useLayoutState";
import ManagerDrawer from "./ManagerDrawer";
import Statistic from "./Statistic";
import "./style.scss";
import TaskList from "./TaskList";

const perPage = 10;

const initialLayout = [
  { i: "taskList", x: 0, y: 0, w: 4, h: 15, minW: 2, minH: 6, static: false, hidden: false },
  { i: "myTeam", x: 4, y: 0, w: 4, h: 15, minW: 2, minH: 6, static: false, hidden: false },
  { i: "map", x: 8, y: 0, w: 4, h: 15, minW: 2, minH: 6, static: false, hidden: false },
  { i: "statistic", x: 0, y: 15, w: 8, h: 16, minW: 4, minH: 8, static: false, hidden: false },
  { i: "notifications", x: 8, y: 15, w: 4, h: 16, minW: 2, minH: 6, static: false, hidden: false },
  { i: "posts", x: 0, y: 31, w: 4, h: 15, minW: 2, minH: 6, static: false, hidden: false },
  { i: "messages", x: 4, y: 31, w: 4, h: 15, minW: 2, minH: 6, static: false, hidden: false },
  { i: "schedule", x: 8, y: 31, w: 4, h: 15, minW: 2, minH: 6, static: false, hidden: false },
  { i: "timeoffPlans", x: 0, y: 46, w: 4, h: 15, minW: 2, minH: 6, static: false, hidden: false },
];

function ManagerDashboard() {
  const [status, setStatus] = useState("clocked-in");
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");

  const { visible, open, selected, close } = useVisible();

  const [filters, setFilters] = useState({});
  const { data, loading, fetch, totalItems, setData } = useMyTeam();

  const { submit } = useUserPreferenceActions("dashboardSettings");
  const { data: userPreferences } = useUserPreferences();

  const {
    layout,
    setLayout,
    hiddenItems,
    setHiddenItems
  } = useLayoutState(initialLayout, userPreferences);

  const { handleHideCard, handleAddCard } = useCardActions(setLayout, setHiddenItems);

  const {
    editable,
    setEditable,
    toggleEditable,
    staticCards,
    setStaticCards,
    toggleStaticCard,
    resizeHandles,
    setResizeHandles,
    enableAllHandles
  } = useEditMode(layout, setLayout, submit, userPreferences);

  const {
    onLayoutChange,
    resetLayout
  } = useLayoutPersistence(
    layout,
    setLayout,
    hiddenItems,
    submit,
    userPreferences,
    initialLayout,
    setHiddenItems,
    setEditable,
    setResizeHandles,
    staticCards,
    setStaticCards
  );

  const onBreakpointChange = useCallback((breakpoint) => {
    setCurrentBreakpoint(breakpoint);
  }, []);

	const queryFilters = useMemo(() => {
		return Object
			.entries(filters)
			.reduce((total, [key, value]) => {
				if (Array.isArray(value)) {
					value = value.map((item) => {
						return item?.id || item;
					});
				} else {
					value = value?.id || value;
				}
				total[key] = value;
				return total;
			}, {});
	}, [filters]);
  const onSearchChange = useCallback((value) => {
    setSearch(value);
    if (page !== 1) setPage(0);
    fetch({ page, perPage, status, search: value, ...queryFilters });
  }, [status, setPage, page, fetch, queryFilters]);

  useEffect(() => {
    const controller = new AbortController();
    fetch({ status, page: page, perPage, search, ...queryFilters }, controller);
    return () => controller.abort();
  }, [fetch, status, page, search, queryFilters]);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const hideDrawer = () => {
    setDrawerVisible(false);
  };

  useEffect(() => {
    if (drawerVisible) {
      window.scrollTo(0, 0);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [drawerVisible]);

  return (
    <>
      <DashboardControls
        editable={editable}
        toggleEditable={toggleEditable}
        onLayoutChange={onLayoutChange}
        enableAllHandles={enableAllHandles}
        resetLayout={resetLayout}
        layout={layout}
        showDrawer={showDrawer}
      />
      <div className="mb-2 w-100 manager-dashboard">
        <ResponsiveGridLayout
          layout={layout}
          resizeHandles={resizeHandles}
          editable={editable}
          onLayoutChange={setLayout}
          onBreakpointChange={onBreakpointChange}
          currentBreakpoint={currentBreakpoint}
        >
          {layout
            ?.filter((item) => !item.hidden)
            .map((item) => {
              if (item.i.startsWith("report-")) {
                const parts = item.i.split("-");
                if (parts.length >= 3) {
                  const reportId = parts.slice(1, -1).join("-");
                  const chartType = parts[parts.length - 1];

                  return (
                    <div key={item.i} className="card m-0 p-0" data-grid={{ ...item }}>
                      <div className={`card-body p-0 m-0 ${!editable ? "transparent" : ""}`}>
                        {editable && (
                          <EditControls
                            item={item}
                            handleHideCard={handleHideCard}
                            toggleStaticCard={toggleStaticCard}
                            staticCards={staticCards}
                            editable={editable}
                          />
                        )}
                        <ReportWidget reportId={reportId} chartType={chartType} />
                      </div>
                    </div>
                  );
                }
              }

              return (
                <div key={item.i} className="card m-0 p-0" data-grid={{ ...item }}>
                  <div className={`card-body p-0 m-0 ${!editable ? "transparent" : ""}`}>
                    {editable && (
                      <EditControls
                        item={item}
                        handleHideCard={handleHideCard}
                        toggleStaticCard={toggleStaticCard}
                        staticCards={staticCards}
                        editable={editable}
                      />
                    )}
                    {item.i === "taskList" && <TaskList />}
                    {item.i === "myTeam" && (
                      <div className="dashboard-timeclock-team">
                        <Team
                          status={status}
                          setStatus={setStatus}
                          setPage={setPage}
                          onSearchChange={onSearchChange}
                          data={data}
                          loading={loading}
                          totalItems={totalItems}
                          page={page}
                          openProfileDrawer={open}
                          setData={setData}
                          filters={filters}
                          setFilters={setFilters}
                        />
                      </div>
                    )}
                    {item.i === "map" && <Map data={data} />}
                    {item.i === "statistic" && <Statistic />}
                    {item.i === "schedule" && <Schedule mode="day" />}
                    {item.i === "messages" && <MessagesWidget />}
                    {item.i === "posts" && <PostsWidgets />}
                    {item.i === "notifications" && <NotificationsWidget />}
                    {item.i === "timeoffPlans" && <TimeoffPlans />}
                  </div>
                </div>
              );
            })}
        </ResponsiveGridLayout>
        <ProfileDrawer user={selected} close={close} visible={visible} />
      </div>
      <ManagerDrawer
        visible={drawerVisible}
        onClose={hideDrawer}
        handleAddCard={handleAddCard}
        hiddenItems={hiddenItems}
        initialLayout={layout || initialLayout}
      />
    </>
  );
}

export default ManagerDashboard;
