import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field as BaseField, List } from "rc-field-form";
import { useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useFields } from "../../../api";
import ListItem from "./ListItem";

const Dimensions = ({ openColumnModal, setFormData }) => {
  const { t } = useTranslation();

  const {
    fields,
    getFields,
    loading,
  } = useFields();

  useEffect(() => {
    const controller = new AbortController();
    getFields(controller);
    return () => controller.abort();
  }, [getFields]);

  return loading ? null : (
    <BaseField shouldUpdate>
      {(_, __, { getFieldValue, getFieldError, setFieldsValue }) => {
        const dimensions = getFieldValue("dimensions") || [];

        return (
          <DndProvider backend={HTML5Backend}>
            <List name="dimensions">
              {(data, { add, remove }) => {
                return (
                  <div className="p-3 border rounded">
                    <h5 className="font-weight-bold">{t("dimensions")} {" ("}{data.length}{")"} *</h5>
                    <p className="text-muted mb-3">{t("dimensions-description")}</p>

                    {data?.map(({ key, name }, index) => {
                      const types = (dimensions?.length > 0 && dimensions?.[0]?.type) ? dimensions?.map(dim => dim?.type) : fields?.map(field => field.name);
                      let selected = fields?.filter((field) => (types || [])?.includes(field?.name));
                      let options = [];
                      if (!selected.length) return [];

                      options = selected.map(item => item?.name);

                      if (selected.length === 1) {
                        const relationships = fields?.find(el => el.name === selected[0]?.name)?.relationships || [];
                        options = [...options, ...relationships];
                      }

                      const selectedType = getFieldValue(["dimensions", name, "type"]);
                      let selectedFields = fields.find(f => f.name === getFieldValue(["dimensions", name, "type"]));
                      selectedFields = selectedFields?.fields?.map((field) => ({ ...field, table: selectedFields?.table })) || [];
                      const [error] = getFieldError(["dimensions", name, "name"]);
                      const moveCard = (dragIndex, hoverIndex) => {
                        const dragCard = dimensions[dragIndex];
                        const updatedCards = [...dimensions];
                        updatedCards.splice(dragIndex, 1);
                        updatedCards.splice(hoverIndex, 0, dragCard);
                        setFieldsValue({ dimensions: updatedCards });
                      }

                      return (
                        <ListItem
                          key={key}
                          index={index}
                          options={options}
                          name={name}
                          selectedType={selectedType}
                          selectedFields={selectedFields}
                          fields={fields}
                          remove={remove}
                          error={error}
                          moveCard={moveCard}
                          setFormData={setFormData}
                        />
                      );
                    })}

                    <div className="d-flex gap-2">
                      <Button
                        color="outline-info"
                        className="d-flex align-items-center gap-2"
                        onClick={() => add()}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {t("add-dimension")}
                      </Button>

                      <Button
                        color="outline-dark"
                        className="d-flex align-items-center gap-2"
                        onClick={() => openColumnModal('measures')}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {t("add-measure")}
                      </Button>

                      <Button
                        color="outline-dark"
                        className="d-flex align-items-center gap-2"
                        onClick={() => openColumnModal('calculations')}
                      >
                        <FontAwesomeIcon icon={faPlus} />
                        {t("add-calculation")}
                      </Button>
                    </div>
                  </div>
                );
              }}
            </List>
          </DndProvider>
        );
      }}
    </BaseField>
  );
};

export default Dimensions;
