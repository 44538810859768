import { getJobRates } from "../../../utils/helpers/job";
import { renderSettingsResourceLabel } from "../../../utils/helpers/settings";
import { renderUserName } from "../../../utils/helpers/user";

export const formatSubmittedData = (data) => ({
	...data,
	birthDate: data?.birthDate ? data?.birthDate : undefined,
	hiringDate: data?.hiringDate,
	jobs: [
		{
			primary: true,
			jobId: data?.jobs?.id,
			code: data?.jobs?.code,
			description: data?.jobs?.description,
			displayName: renderSettingsResourceLabel(data.jobs),
			startDate: data?.hiringDate,
			currency: data.currency,
			status: "active",
			...getJobRates({
				hourlyOrSalaried: data.hourlyOrSalaried,
				hourlyRate: data?.hourlyRate,
				salary: data?.salary,
			}),
		},
	],
	locations: data.locations.map(({ id, code, description, timezone }) => ({
		primary: id === data.primaryLocation,
		locationId: id,
		code,
		description,
		displayName: renderSettingsResourceLabel({ code, description }),
		timezoneId: timezone?.id,
		timezoneValue: timezone?.value,
		status: "active",
		startDate: data?.hiringDate,
		shared: false,
	})),
	supervisors: data.supervisors
		? data.supervisors.map(
			({
				id,
				firstName,
				lastName,
				middleName,
				employeeNumber,
				email,
			}) => ({
				primary: id === data.primarySupervisor,
				userId: id,
				firstName,
				lastName,
				employeeNumber,
				email,
				fullName: renderUserName({
					firstName,
					middleName,
					lastName,
				}),
			}),
		)
		: [],
	employeeType: data.employeeType?.id,
	calculationGroup: data.calculationGroup?.id,
	jobGroup: data.jobGroup?.id,
	payGroup: data.payGroup.id,
	roles: data.roles.map(({ id }) => id),
	level1: data.level1?.id,
	level2: data.level2?.id,
	level3: data.level3?.id,
	level4: data.level4?.id,
	manageAbility: data.manageAbility || null,
	managedLevels: data.managedLevels?.map(({ id }) => id) || [],
	managedLocations: data.managedLocations?.map(({ id }) => id) || [],
	managedPayGroups: data.managedPayGroups?.map(({ id }) => id) || [],
	managedCalcGroups: data.managedCalcGroups?.map(({ id }) => id) || [],
	managedProjects: data.managedProjects?.map(({ id }) => id) || [],
	managedGroupings: data?.managedGroupings?.map(({ id }) => id) || [],
	managedUserGroups: data?.managedUserGroups?.map(({ id }) => id) || [],
	additionalLevels: data.additionalLevels?.map(({ id }) => id) || [],
});
