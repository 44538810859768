import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import { Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { isNaN } from "lodash";
import Field from "../../../../../../../components/Field";
import Checkbox from "../../../../../../../components/Inputs/Checkbox";
import ResourceSelect from "../../../../../../../components/Inputs/ResourceSelect";
import Switch from "../../../../../../../components/Inputs/Switch";
import {
	renderSettingsResourceLabel,
	getSettingsResourceSearchFilters,
	getPathWithParams,
} from "../../../../../../../utils/helpers/settings";
import { payCodeCalcFields } from "../../../helper";
import RoundClockOutRule from "./RoundClockOutRule";
import { useVisible } from "../../../../../../../utils/hooks/useVisible";
import ClockInRuleModal from "./ClockInRuleModal";

function UnionRules({ disabled }) {
	const { t } = useTranslation();
	const { open: openModal, visible, close: closeModal, selected } = useVisible();

	return (
		<div className="border-bottom my-4 meal-allowance-pay-code-select">
			<div className="mb-3">
				<h3 className="font-weight-bolder text-sm">
					{t("union-rules")}
				</h3>
			</div>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, getFieldsError }) => {
					const status = getFieldValue([
						"unionRules",
						"deduct",
						"status",
					]);

					const [error] = getFieldsError([
						["unionRules", "deduct", "amount"],
						["unionRules", "deduct", "deduct"],
						["unionRules", "deduct", "mark"],
					])
						.map(({ errors }) => errors)
						.flat();

					return (
						<div className="d-flex align-items-center mb-2">
							<Field
								name={["unionRules", "deduct", "status"]}
								valuePropName="checked"
								noStyle
								className="mb-0 pt-2"
							>
								<Checkbox id="status" disabled={disabled} />
							</Field>
							<div className="rule-container">
								<div className="d-flex align-items-center mb-2">
									<p className="mr-2 text-sm mb-0">
										{t("shift-equal-or-longer-than")}
									</p>

									<BaseField
										name={["unionRules", "deduct", "amount"]}
										className="mb-0"
										rules={
											status
												? [
													{
														required: status,
														message:
															t("required-amount"),
													},
													{
														validator(_, value) {
															if (Number(value) < 0) {
																return Promise.reject(new Error(t("amount-bigger-than-zero")))
															}
															return Promise.resolve()
														}
													}
												]
												: undefined
										}
										noStyle
										dependencies={[
											["unionRules", "deduct", "status"],
										]}
									>
										<Input
											type="number"
											size="sm"
											readOnly={!status}
											className="input-number"
											disabled={disabled}
											step="0.1"
										/>
									</BaseField>

									<p className="mx-2 text-sm mb-0">
										{t("minutes")}
										{", "}
										{t("deduct")}
									</p>

									<BaseField
										name={["unionRules", "deduct", "deduct"]}
										className="mb-0"
										rules={
											status
												? [
													{
														required: status,
														message:
															t("required-deduct"),
													},
													{
														validator(_, value) {
															if (Number(value) < 0) {
																return Promise.reject(new Error(t("deduct-bigger-than-zero")))
															}
															return Promise.resolve()
														}
													}
												]
												: undefined
										}
										noStyle
										dependencies={[
											["unionRules", "deduct", "status"],
										]}
									>
										<Input
											size="sm"
											readOnly={!status}
											className="input-number"
											disabled={disabled}
											type="number"
											step="0.1"
										/>
									</BaseField>
									<p className="text-sm mb-0 mx-2 ">
										{t("minutes-at")}{" "}
									</p>

									<BaseField
										name={["unionRules", "deduct", "mark"]}
										className="mb-0"
										rules={
											status
												? [
													{
														required: status,
														message:
															t("required-mark"),
													},
													{
														validator(_, value) {
															if (Number(value) < 0) {
																return Promise.reject(new Error(t("mark-bigger-than-zero")))
															}
															return Promise.resolve()
														}
													}
												]
												: undefined
										}
										noStyle
										dependencies={[
											["unionRules", "deduct", "status"],
										]}
									>
										<Input
											size="sm"
											readOnly={!status}
											className="input-number"
											disabled={disabled}
											type="number"
											step="0.1"
										/>
									</BaseField>
								</div>
								<div className="d-flex align-items-center mb-2">
									<p className="text-sm mb-0 mx-2">{t("minutes-mark-as")}</p>
									<BaseField
										name={["unionRules", "deduct", "payCode"]}
										noStyle
										rules={
											status
												? [
													{
														required: true,
														message:
															t("required-type"),
													},
												]
												: undefined
										}
										dependencies={[
											["unionRules", "deduct", "status"],
										]}
									>
										<ResourceSelect
											disabled={disabled || !status}
											labelPropName="description"
											renderLabel={renderSettingsResourceLabel}
											resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields)}
											hasSearch
											getSearchFilters={getSettingsResourceSearchFilters}
										/>
									</BaseField>
								</div>

								<div className="d-flex align-items-center">
									<Field
										name={["unionRules", "deduct", "runOnManualUpdate"]}
										valuePropName="checked"
										initialValue={false}
										noStyle
										className="mb-0"
									>
										<Switch disabled={disabled || !status} />
									</Field>
									<p className="text-sm mb-0 ml-2">{t("run-this-rule-on-manual-updates-also")}</p>
								</div>

								<div className="flex flex-col mb-1">
									{error && (
										<span className="invalid-feedback d-block">
											{error}
										</span>
									)}
								</div>
							</div>
						</div>
					);
				}}
			</BaseField>


			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, getFieldsError }) => {
					const { status, min } = getFieldValue(["unionRules", "deductBetween"]) || {};

					const [error] = getFieldsError([
						["unionRules", "deductBetween", "min"],
						["unionRules", "deductBetween", "max"],
						["unionRules", "deductBetween", "mark"],
					])
						.map(({ errors }) => errors)
						.flat();

					return (
						<div className="d-flex align-items-center mb-2">
							<Field
								name={["unionRules", "deductBetween", "status"]}
								valuePropName="checked"
								noStyle
								className="mb-0 pt-2"
							>
								<Checkbox id="status" disabled={disabled} />
							</Field>

							<div className="rule-container">
								<div className="d-flex align-items-center mb-2">
									<p className="mr-2 text-sm mb-0">
										{t("shift-equal-or-longer-than")}
									</p>

									<BaseField
										name={["unionRules", "deductBetween", "min"]}
										className="mb-0"
										rules={
											status
												? [
													{
														required: true,
														message:
															t("min-duration-required"),
													},
													{
														validator(_, value) {
															if (Number(value) < 0) {
																return Promise.reject(new Error(t("min-duration-bigger-than-zero")))
															}
															return Promise.resolve()
														}
													}
												]
												: undefined
										}
										noStyle
										dependencies={[
											["unionRules", "deduct", "status"],
										]}
									>
										<Input
											type="number"
											size="sm"
											readOnly={!status}
											className="input-number"
											disabled={disabled}
											step="0.1"
										/>
									</BaseField>

									<p className="mx-2 text-sm mb-0">
										{t("minutes")}{", "}
										{t("shift-equal-or-smaller-than")}
									</p>

									<BaseField
										name={["unionRules", "deductBetween", "max"]}
										className="mb-0"
										rules={
											status
												? [
													{
														required: true,
														message: t("max-duration-required"),
													},
													{
														validator(_, value) {
															if (Number(value) < 0) {
																return Promise.reject(new Error(t("max-duration-bigger-than-zero")))
															}
															return Promise.resolve()
														}
													},
													{
														validator(_, _value) {
															const value = Number(_value);
															const min = Number(getFieldValue(["unionRules", "deductBetween", "min"]));
															if (isNaN(min) || isNaN(value)) {
																return Promise.resolve();
															}
															if (min >= value) {
																return Promise.reject(new Error(t("max-bigger-than-min")))
															}
															return Promise.resolve()
														}
													},
												]
												: undefined
										}
										noStyle
										dependencies={[
											["unionRules", "deductBetween", "status"],
											["unionRules", "deductBetween", "min"],
										]}
									>
										<Input
											type="number"
											size="sm"
											readOnly={!status}
											className="input-number"
											disabled={disabled}
											step="0.1"
										/>
									</BaseField>

									<p className="text-sm mb-0 mx-2 ">
										{t("minutes")}
									</p>
								</div>

								<div className="d-flex align-items-center mb-2">
									<p className="text-sm mb-0 mr-2">
										{t("deduct-remaining-after-minutes-at", { amount: min })}
									</p>

									<BaseField
										name={["unionRules", "deductBetween", "mark"]}
										className="mb-0"
										rules={
											status
												? [
													{
														required: status,
														message:
															t("required-mark"),
													},
													{
														validator(_, value) {
															if (Number(value) < 0) {
																return Promise.reject(new Error(t("mark-bigger-than-zero")))
															}
															return Promise.resolve()
														}
													},
													{
														validator(_, _value) {
															const value = Number(_value);
															const min = Number(getFieldValue(["unionRules", "deductBetween", "min"]));
															const max = Number(getFieldValue(["unionRules", "deductBetween", "max"]));

															if (isNaN(value) || isNaN(min) || isNaN(max)) {
																return Promise.resolve();
															}
															const duration = max - min;
															if (value >= min) {
																return Promise.reject(new Error(t("mark-before-time", { amount: min })))
															}
															return Promise.resolve()
														}
													},
												]
												: undefined
										}
										noStyle
										dependencies={[
											["unionRules", "deductBetween", "status"],
											["unionRules", "deductBetween", "min"],
											["unionRules", "deductBetween", "max"],
										]}
									>
										<Input
											size="sm"
											readOnly={!status}
											className="input-number"
											disabled={disabled}
											type="number"
											step="0.1"
										/>
									</BaseField>

									<p className="text-sm mb-0 mx-2">{t("minutes-mark-as")}</p>

									<BaseField
										name={["unionRules", "deductBetween", "payCode"]}
										noStyle
										rules={
											status
												? [
													{
														required: true,
														message:
															t("required-type"),
													},
												]
												: undefined
										}
										dependencies={[
											["unionRules", "deductBetween", "status"],
										]}
									>
										<ResourceSelect
											disabled={disabled || !status}
											labelPropName="description"
											renderLabel={renderSettingsResourceLabel}
											resourcePath={getPathWithParams('/pay-code', true, {}, payCodeCalcFields)}
											hasSearch
											getSearchFilters={getSettingsResourceSearchFilters}
										/>
									</BaseField>
								</div>

								<div className="d-flex align-items-center">
									<Field
										name={["unionRules", "deductBetween", "runOnManualUpdate"]}
										valuePropName="checked"
										initialValue={false}
										noStyle
										className="mb-0"
									>
										<Switch disabled={disabled || !status} />
									</Field>
									<p className="text-sm mb-0 mx-2">{t("run-this-rule-on-manual-updates-also")}</p>
								</div>

								<div className="flex flex-col mb-1">
									{error && (
										<span className="invalid-feedback d-block">
											{error}
										</span>
									)}
								</div>
							</div>
						</div>
					);
				}}
			</BaseField>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, getFieldsError }) => {
					const status = getFieldValue([
						"unionRules",
						"add",
						"status",
					]);

					const [error] = getFieldsError([
						["unionRules", "add", "start"],
						["unionRules", "add", "end"],
					])
						.map(({ errors }) => errors)
						.flat();

					return (
						<div className="d-flex align-items-center mb-2">
							<Field
								name={["unionRules", "add", "status"]}
								valuePropName="checked"
								noStyle
								className="mb-0 pt-2"
							>
								<Checkbox id="status" disabled={disabled} />
							</Field>

							<div className="rule-container">
								<div className="d-flex align-items-center mb-2">
									<p className="mr-2 text-sm mb-0">
										{t("shift-equal-or-longer-than")}
									</p>

									<BaseField
										name={["unionRules", "add", "start"]}
										className="mb-0"
										rules={
											status
												? [
													{
														required: status,
														message:
															t("required-start"),
													},
													{
														validator(_, value) {
															if (Number(value) < 0) {
																return Promise.reject(new Error(t("start-bigger-than-zero")))
															}
															return Promise.resolve()
														}
													}
												]
												: undefined
										}
										noStyle
										dependencies={[
											["unionRules", "add", "status"],
										]}
									>
										<Input
											type="number"
											size="sm"
											readOnly={!status}
											className="input-number"
											disabled={disabled}
											step="0.1"
										/>
									</BaseField>

									<p className="mx-2 text-sm mb-0">
										{t("minutes-less-than")}
									</p>

									<BaseField
										name={["unionRules", "add", "end"]}
										className="mb-0"
										rules={
											status
												? [
													{
														required: status,
														message:
															t("required-end"),
													},
													{
														validator(_, value) {
															if (Number(value) < 0) {
																return Promise.reject(new Error(t("end-bigger-than-zero")))
															}
															return Promise.resolve()
														}
													},
												]
												: undefined
										}
										noStyle
										dependencies={[
											["unionRules", "add", "status"],
										]}
									>
										<Input
											size="sm"
											readOnly={!status}
											className="input-number"
											disabled={disabled}
											type="number"
											step="0.1"
										/>
									</BaseField>

									<p className="text-sm mb-0 ml-1">
										{t("minutes-round-to")} {getFieldValue(["unionRules", "add", "end"]) > 0 ? `${getFieldValue(["unionRules", "add", "end"])}` : '--'} {t('minutes')}.
									</p>
								</div>
								<div className="d-flex align-items-center">
									<Field
										name={["unionRules", "add", "runOnManualUpdate"]}
										valuePropName="checked"
										initialValue={false}
										noStyle
										className="mb-0"
									>
										<Switch disabled={disabled || !status} />
									</Field>
									<p className="text-sm mb-0 mx-2">{t("run-this-rule-on-manual-updates-also")}</p>
								</div>
								<div className="flex flex-col mb-1">
									{error && (
										<span className="invalid-feedback d-block">
											{error}
										</span>
									)}
								</div>
							</div>
						</div>
					);
				}}
			</BaseField>

			<BaseField shouldUpdate noStyle>
				{({ }, { }, { getFieldValue, getFieldsError }) => {
					const status = getFieldValue([
						"unionRules",
						"clockIn",
						"status",
					]);

					const [error] = getFieldsError([
						["unionRules", "clockIn", "amount"],
						["unionRules", "clockIn", "clockOutAmount"],
					])
						.map(({ errors }) => errors)
						.flat();

					return (
						<div className="d-flex align-items-center mb-2">
							<Field
								name={["unionRules", "clockIn", "status"]}
								valuePropName="checked"
								noStyle
								className="mb-0 pt-2"
							>
								<Checkbox id="status" disabled={disabled} />
							</Field>

							<div className="rule-container">
								<h5>{t("round-based-next-full-time")}</h5>
								<div className="d-flex align-items-center w-100 gap-3 justify-content-between">
									<div className="w-100">
										<div className="d-flex align-items-center mb-2">
											<p className="mr-2 text-sm mb-0">
												{t("employee-clocks-in")}
											</p>

											<BaseField
												name={["unionRules", "clockIn", "amount"]}
												className="mb-0"
												rules={
													status
														? [
															{
																required: status,
																message: t("required-clock-in-amount"),
															},
															{
																validator(_, value) {
																	if (Number(value) < 0) {
																		return Promise.reject(new Error(t("amount-bigger-than-zero")))
																	}
																	return Promise.resolve()
																}
															}
														]
														: undefined
												}
												noStyle
												dependencies={[
													["unionRules", "clockIn", "status"],
												]}
											>
												<Input
													type="number"
													size="sm"
													readOnly={!status}
													className="input-number"
													disabled={disabled}
													step="0.1"
												/>
											</BaseField>

											<p className="mx-2 text-sm mb-0">
												{t("min-before-next-full-time")}
											</p>
										</div>
										<div className="d-flex align-items-center mb-2">
											<p className="mr-2 text-sm mb-0">
												{t("employee-clocks-out")}
											</p>

											<BaseField
												name={["unionRules", "clockIn", "clockOutAmount"]}
												className="mb-0"
												rules={
													status
														? [
															{
																required: status,
																message: t("requierd-clock-out-amount"),
															},
															{
																validator(_, value) {
																	if (Number(value) < 0) {
																		return Promise.reject(new Error(t("amount-bigger-than-zero")))
																	}
																	return Promise.resolve()
																}
															}
														]
														: undefined
												}
												noStyle
												dependencies={[
													["unionRules", "clockIn", "status"],
												]}
											>
												<Input
													type="number"
													size="sm"
													readOnly={!status}
													className="input-number"
													disabled={disabled}
													step="0.1"
												/>
											</BaseField>

											<p className="mx-2 text-sm mb-0">
												{t("min-before-next-full-time")}
											</p>
										</div>
										<div className="d-flex align-items-center">
											<Field
												name={["unionRules", "clockIn", "runOnManualUpdate"]}
												valuePropName="checked"
												initialValue={false}
												noStyle
												className="mb-0"
											>
												<Switch disabled={disabled || !status} />
											</Field>
											<p className="text-sm mb-0 mx-2">{t("run-this-rule-on-manual-updates-also")}</p>
										</div>
										<div className="flex flex-col mb-1">
											{error && (
												<span className="invalid-feedback d-block">
													{error}
												</span>
											)}
										</div>
									</div>

									<FontAwesomeIcon
										className={classNames("text-sm", status && "cursor-pointer")}
										icon={faPen}
										onClick={status
											? () => openModal(getFieldValue(["unionRules"]))
											: undefined
										}
									/>
								</div>
							</div>
						</div>
					);
				}}
			</BaseField>

			<BaseField name={["unionRules", "clockIn", "days"]} />

			<RoundClockOutRule disabled={disabled} />

			{visible && (
				<ClockInRuleModal
					isOpen={visible}
					values={selected}
					close={closeModal}
					disabled={disabled}
				/>
			)}
		</div>
	);
}

export default UnionRules;