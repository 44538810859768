import { useTranslation } from "react-i18next";
import { Field as BaseField } from "rc-field-form";
import Field from "../../../../../components/Field";
import ResourceSelect from "../../../../../components/Inputs/ResourceSelect";
import { getCompaniesSearchFilters } from "../../../../Profile/Personal/Actions/ChangeCompanyInformation";
import { useGroups } from "../../../General/OrganisationLevelGroups/context";
import {
    getSettingsOrgResourceSearchFilters,
    getSettingsResourceSearchFilters,
    renderOrgLevelResourceLabel,
    renderSettingsResourceLabel,
} from "../../../../../utils/helpers/settings";
import DateInput from "../../../../../components/Inputs/DateInput";
import moment from "moment-timezone";
import Select from "../../../../../components/Inputs/Select";
import WeekdaySelect, { weekdays } from "../../../../../components/Inputs/WeekdaySelect";
import { isNumber } from "lodash";
import { useCallback, useMemo } from "react";
import { parseFloat2, renderDurationAsFormat } from "../../../../../utils/helpers/date";
import MoneyInput from "../../../../../components/Inputs/MoneyInput";
import DurationInput, { getDurationInputValue } from "../../../../../components/Inputs/DurationInput";
import { renderCurrencySymbol } from "../../../../../utils/helpers/job";
import { useCompany, useCompanyCurrency, useCompanyDateFormat } from "../../../../../utils/hooks/company";
import { firstToUpper } from "../../../../../utils/helpers/string";
import { useUser } from "../../../../../utils/hooks/user";

function ExtendedMoneyInput({ value: _value, onChange: _onChange, ...props }) {
    const value = useMemo(() => {
        if (!isNumber(_value)) {
            return undefined;
        }
        return parseFloat2(_value);
    }, [_value]);

    const onChange = useCallback(
        (value) => {
            if (!_onChange) {
                return;
            }
            if (isNaN(value)) {
                value = Number(value?.replace(/[^0-9\.]+/g, ""));
            }
            _onChange(value || 0);
        },
        [_onChange]
    );

    return <MoneyInput value={value} onChange={onChange} {...props} />;
}

function ExtendedDurationInput({ value: _value, onChange: _onChange, ...props }) {
    const value = useMemo(() => {
        return renderDurationAsFormat(_value || 0, "HH:mm");
    }, [_value]);

    const onChange = useCallback(
        (value) => {
            if (!_onChange) {
                return;
            }
            _onChange(getDurationInputValue(value));
        },
        [_onChange]
    );

    return <DurationInput value={value} onChange={onChange} {...props} />;
}

export const months = [
    "january",
    "february",
    "march",
    "april",
    "may",
    "june",
    "july",
    "august",
    "september",
    "october",
    "november",
    "december",
];

function Content({ disabled, form, weekDates }) {
    const { t } = useTranslation();
    const { allocatedGroups } = useGroups();
    const currency = useCompanyCurrency();
    const dateFormat = useCompanyDateFormat();

    return (
        <>
            <Field
                name="company"
                label={t("company")}
                className="w-100"
                rules={[
                    {
                        required: true,
                        message: t("required-company"),
                    },
                ]}
            >
                <ResourceSelect
                    labelPropName="name"
                    resourcePath="/companies"
                    placeholder={t("select-companies")}
                    hasSearch
                    getSearchFilters={getCompaniesSearchFilters}
                />
            </Field>
            <Field name="location" label={t("location")} className="w-100">
                <ResourceSelect
                    labelPropName="description"
                    resourcePath="/locations"
                    renderLabel={renderSettingsResourceLabel}
                    placeholder={t("locations")}
                    hasSearch
                    getSearchFilters={getSettingsResourceSearchFilters}
                />
            </Field>
            <Field name="payGroup" label={t("pay-group")}>
                <ResourceSelect
                    labelPropName="description"
                    resourcePath="/pay-group"
                    placeholder={t("pay-group")}
                    renderLabel={renderSettingsResourceLabel}
                    hasSearch
                    getSearchFilters={getSettingsResourceSearchFilters}
                />
            </Field>
            {allocatedGroups?.map((group) => {
                return (
                    <Field
                        name="level"
                        label={group.description}
                        rules={[
                            {
                                required: group.isRequired,
                                message: t("required-group", { group: group.description }),
                            },
                        ]}
                    >
                        <ResourceSelect
                            resourcePath={`/${group.id}/org-levels`}
                            renderLabel={renderOrgLevelResourceLabel}
                            getSearchFilters={getSettingsOrgResourceSearchFilters}
                            placeholder={group.description}
                            allowClear={!group.isRequired}
                            hasSearch
                        />
                    </Field>
                );
            })}
            <BaseField shouldUpdate>
                {({}, {}, { getFieldValue }) => {
                    const value = getFieldValue("year");

                    return (
                        <Field
                            className="d-flex flex-column"
                            name="year"
                            label={t("year")}
                            rules={[
                                {
                                    required: true,
                                    message: t("required-year"),
                                },
                            ]}
                        >
                            <DateInput
                                showYearPicker
                                dateFormat="yyyy"
                                disabled={disabled}
                                placeholderText={t("year")}
                                customInput={<div>{moment(value).format("YYYY")}</div>}
                            />
                        </Field>
                    );
                }}
            </BaseField>
            <Field name="budgetType" label={t("budget-type")}>
                <Select placeholder={t("budget-type")} value="weekly">
                    <Select.Option value="weekly">{t("weekly")}</Select.Option>
                    <Select.Option value="monthly">{t("monthly")}</Select.Option>
                </Select>
            </Field>
            <WeekdaySelect name="days" label={t("select-days")} placeholder={t("select-days")} mode="multiple" />
            <WeekdaySelect
                name="startOfWeek"
                rules={[
                    {
                        required: true,
                        message: t("required-start-day"),
                    },
                ]}
                label={t("select-start-day-of-week")}
                placeholder={t("select-start-day-of-week")}
            />
            <BaseField shouldUpdate>
                {({}, {}, { getFieldValue }) => {
                    let type = getFieldValue("budgetType");
                    if (type === "weekly") {
                        return (
                            <div
                                style={{
                                    display: "grid",
                                    gridTemplateColumns: "2fr 2fr",
                                }}
                            >
                                {weekDates.map(({ start, end }, i) => {
                                    return (
                                        <div key={i}>
                                            <span className="text-nowrap font-weight-bold mb-1">
                                                {t("week")} {i + 1}{" "}
                                                <span className="font-weight-light text-dark">
                                                    (
                                                    {moment(start).format(dateFormat) +
                                                        " - " +
                                                        moment(end).format(dateFormat)}
                                                    )
                                                </span>
                                            </span>

                                            <div className="d-flex flex-row align-items-center gap-3 mb-4 mr-3">
                                                <Field name={["weeks", `week_${i + 1}`, "amount"]} className="w-50 mb-0">
                                                    <ExtendedMoneyInput
                                                        prefix={renderCurrencySymbol(currency)}
                                                        className="form-control"
                                                        disabled={disabled}
                                                        placeholder={t("value")}
                                                    />
                                                </Field>

                                                <Field name={["weeks", `week_${i + 1}`, "hours"]} className="w-50 mb-0">
                                                    {({ value, onChange }) => {
                                                        return (
                                                            <ExtendedDurationInput
                                                                value={moment.duration(value, "hours").asSeconds()}
                                                                onChange={(value) => onChange(moment.duration(value, "seconds").asHours())}
                                                                placeholder={firstToUpper(t("hours"))}
                                                            />
                                                        );
                                                    }}
                                                </Field>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    } else {
                        return months.map((month) => (
                            <>
                                <span className="text-nowrap font-weight-bold">{t(month)}</span>

                                <div className="d-flex align-items-center gap-3 w-100">
                                    <Field name={["months", month, "amount"]} className="w-50 mb-3">
                                        <ExtendedMoneyInput
                                            prefix={renderCurrencySymbol(currency)}
                                            className="form-control"
                                            disabled={disabled}
                                            placeholder={t("value")}
                                        />
                                    </Field>

                                    <Field name={["months", month, "hours"]} className="w-50 mb-3">
                                        {({ value, onChange }) => {
                                            return (
                                                <ExtendedDurationInput
                                                    value={moment.duration(value, "hours").asSeconds()}
                                                    onChange={(value) => onChange(moment.duration(value, "seconds").asHours())}
                                                    placeholder={firstToUpper(t("hours"))}
                                                />
                                            );
                                        }}
                                    </Field>
                                </div>
                            </>
                        ));
                    }
                }}
            </BaseField>
        </>
    );
}

export default Content;
