import { useState, useCallback, useEffect } from "react";
import Form, { Field as BaseField } from "rc-field-form";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import Field from "../../../../../../components/Field";
import Text from "../../../../../../components/Inputs/Text";
import DescriptionField from "../../../../../../components/Field/DescriptionField";
import StatusField from "../../../../../../components/Field/StatusField";
import Checkbox from "../../../../../../components/Inputs/Checkbox";
import Button from "../../../../../../components/Button";
import Select from "../../../../../../components/Inputs/Select";
import ResourceSelect from "../../../../../../components/Inputs/ResourceSelect";
import Tier from "./Tier";
import FixedOption from "./FixedOption";
import HourlyOption from "./HourlyOption";
import PerPayPeriod from "./PerPayPeriod";
import { generateErrorsConfigForForm } from "../../../../../../utils/helpers/errors";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel,
} from "../../../../../../utils/helpers/settings";

function Details({ mode, data, submit, goBack, error, disabled }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [method, setMethod] = useState(data?.accural?.method || "hourly");

    const onFinish = useCallback(
        (values) => {
            const intervals = values.intervals.map((interval) => {
                const item = {};

                if (method === "fixed") {
                    item.value = Number(interval.value);
                    
                } else if (method === "per-pay-period") {
                    item.value = Number(interval.value);
                    item.max = Number(interval.max);
                } else {
                    item.earned = Number(interval.earned);
                    item.worked = Number(interval.worked);
                    item.max = Number(interval.max);
                }

                return item;
            });

            const tiers = values.tiers.map((tier, i) => ({
                start: i === 0 ? null : Number(tier.start),
                end: tier?.end ? Number(tier.end) : null,
                name: tier.name
            }));

            submit({
                ...(omit(values, ["intervals", "earningGroups", "payCodes"])),
                tiers,
                accural: {
                    intervals,
                    method,
                    type: values?.accural?.type || undefined,
                    earningGroups: values?.accural?.earningGroups || undefined,
                    payCodes: values?.accural?.payCodes || undefined
                }
            });
        },
        [submit, method]
    );

    const onTypeChange = useCallback(
        (value) => {
            if (value === "pay-codes") {
                form.setFieldValue(["accural", "earningGroups"], []);
            } else {
                form.setFieldValue(["accural", "payCodes"], []);
            }
        },
        [form]
    );

    const getValueFromEvent = useCallback((e) => e.target.value.toUpperCase(), []);

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            ...data,
            code: data?.code ? data.code : "",
            description: data.description,
            status: data.status ? data.status : "active",
            payCode: data?.payCode,
            intervals: data?.accural?.intervals || [],
            tierBy: data?.tierBy,
            tiers: data?.tiers || [{ start: null, end: null }],
            accural: {
                type: data?.accural?.type,
                payCodes: data?.accural?.payCodes || [],
                earningGroups: data?.accural?.earningGroups || []
            },
        });
    }, [form, data]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm(
            ["code", "description", "timeOffStatus", "payCode"],
            error
        );
        form.setFields(fieldErrors);
    }, [form, error]);

    return (
        <Form form={form} onFinish={onFinish} className="d-flex flex-column justify-content-between h-100">
            <div>
                <Field
                    name="code"
                    label={t("name")}
                    getValueFromEvent={getValueFromEvent}
                    rules={[
                        {
                            required: true,
                            message: t("required-name"),
                        },
                        {
                            message: t("name-length"),
                            min: 1,
                            max: 10,
                        },
                        {
                            pattern: /^[a-zA-Z0-9]+$/,
                            message: t("name-pattern"),
                        },
                    ]}
                >
                    <Text placeholder={t("name")} disabled={disabled} />
                </Field>

                <DescriptionField />

                <StatusField />

                <Field
                    name="payCode"
                    className="mt-2"
                    label={t("pay-code")}
                    rules={[
                        {
                            required: true,
                            message: t("required-pay-codes"),
                        },
                    ]}
                >
                    <ResourceSelect
                        hasSearch
                        placeholder={t("pay-codes")}
                        resourcePath="/pay-code"
                        renderLabel={renderSettingsResourceLabel}
                        getSearchFilters={getSettingsResourceSearchFilters}
                    />
                </Field>

                <Tier disabled={disabled} />

                <h5>{t("accrual-method")}</h5>

                <Checkbox
                    id="fixed"
                    onChange={() => setMethod("fixed")}
                    checked={method === "fixed"}
                    disabled={disabled}
                >
                    {t("fixed-option")}
                </Checkbox>

                <Checkbox
                    id="hourly"
                    onChange={() => setMethod("hourly")}
                    checked={method === "hourly"}
                    disabled={disabled}
                >
                    {t("hourly-option")}
                </Checkbox>

                <Checkbox
                    id="per-pay-period"
                    onChange={() => setMethod("per-pay-period")}
                    checked={method === "per-pay-period"}
                    disabled={disabled}
                >
                    {t("per-pay-period")}
                </Checkbox>

                {method === "fixed" ? (
                    <FixedOption disabled={disabled} />
                ) : method === "hourly" ? (
                    <HourlyOption form={form} disabled={disabled} />
                ) : (
                    <PerPayPeriod disabled={disabled} />
                )}

                {["hourly", "per-pay-period"].includes(method) && (
                    <>
                        <Field
                            name={["accural", "type"]}
                            label={t("earning-type")}
                            className="pt-2"
                            rules={[
                                {
                                    required: true,
                                    message: t("earning-type-required"),
                                },
                            ]}
                        >
                            <Select placeholder={t("earning-type")} onChange={onTypeChange}>
                                <Select.Option value="pay-codes">{t("pay-codes")}</Select.Option>
                                <Select.Option value="earning-groups">{t("earning-groups")}</Select.Option>
                            </Select>
                        </Field>

                        <BaseField>
                            {({ }, { }, { getFieldValue }) => {
                                const type = getFieldValue(["accural", "type"]);

                                return (
                                    <>
                                        {type === "pay-codes" && (
                                            <Field
                                                name={["accural", "payCodes"]}
                                                className="mt-2"
                                                label={t("eligible-pay-codes")}
                                                placeholder={t("eligible-pay-codes")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("required-pay-codes"),
                                                    },
                                                ]}
                                            >
                                                <ResourceSelect
                                                    hasSearch
                                                    resourcePath="/pay-code"
                                                    renderLabel={renderSettingsResourceLabel}
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    mode="multiple"
                                                    placeholder={t("eligible-pay-codes")}
                                                />
                                            </Field>
                                        )}

                                        {type === "earning-groups" && (
                                            <Field
                                                name={["accural", "earningGroups"]}
                                                label={t("earning-groups")}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("earning-group-required"),
                                                    },
                                                ]}
                                            >
                                                <ResourceSelect
                                                    hasSearch
                                                    resourcePath="/earning-group"
                                                    mode="multiple"
                                                    renderLabel={renderSettingsResourceLabel}
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    placeholder={t("earning-groups")}
                                                />
                                            </Field>
                                        )}
                                    </>
                                );
                            }}
                        </BaseField>
                    </>
                )}
            </div>

            <div className="d-flex justify-content-end">
                <Button className="btn-round btn-icon shadow-none border" size="sm" onClick={goBack}>
                    {t("cancel")}
                </Button>

                <Button
                    type="submit"
                    className="btn-round btn-icon shadow-none border px-3"
                    color="dark"
                    size="sm"
                >
                    {t("continue")}
                </Button>
            </div>
        </Form>
    );
}

export default Details;
