import { MapContainer, TileLayer } from "react-leaflet";
import { useTranslation } from "react-i18next";
import { Card, CardHeader } from "reactstrap";
import UserMarker from "./UserMarker";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import "leaflet-path-drag";

const Map = ({ data }) => {
    const { t } = useTranslation();

    return (
        <Card className="h-100">
            <CardHeader>
                <h3 className="pb-1">{t("real-time")}</h3>
            </CardHeader>
            <MapContainer
                style={{ width: "100%", height: "100%", zIndex: 1 }}
                zoom={5}
                minZoom={1}
                zoomSnap={0.5}
                zoomDelta={0.5}
                maxBounds={[
                    [-90, -180],
                    [90, 180],
                ]}
                maxBoundsViscosity={1.0}
            >
                <TileLayer url={process.env.REACT_APP_MAP_TILE_URL} />
                <UserMarker data={data} />
            </MapContainer>
        </Card>
    );
};

export default Map;
