import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CardHeader, Col, Row } from "reactstrap";
import ResourceSelect from "../../../components/Inputs/ResourceSelect";
import Filters from "../../../components/NTable/Actions/Filters";
import ColumnToggle from "../../../components/NTable/Actions/ColumnToggle";
import { useColumnFiltersConfig } from "../../../components/NTable/hook/useColumnFiltersConfig";
import {
    getSettingsResourceSearchFilters,
    renderSettingsResourceLabel
} from "../../../utils/helpers/settings";

function Actions({ children, filters, setFilters, columns }) {
    const { t } = useTranslation();
    const baseConfig = useColumnFiltersConfig(columns);

    const config = useMemo(() => [
        ...baseConfig,
        {
            name: "grouping",
            label: t("groupings"),
            type: "resource-select",
            component: (
                <ResourceSelect
                    resourcePath="/grouping?pagination=off"
                    mode="multiple"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    hasSearch
                />
            ),
        },
        {
            name: "employmentType",
            label: t("employment-type"),
            type: "select",
            component: (
                <ResourceSelect
                    resourcePath="/user-demographic/employment-type"
                    renderLabel={renderSettingsResourceLabel}
                    getSearchFilters={getSettingsResourceSearchFilters}
                    hasSearch
                    mode="multiple"
                />
            )
        },
    ], [baseConfig, t]);

    return (
        <CardHeader className="border-top p-3">
            <Row className="d-flex align-items-center justify-content-between mb-3 mb-md-0">
                <Col md={12} lg={4}>{children}</Col>

                <Col md={12} lg={children ? 8 : 12}>
                    <div className="d-flex align-items-start">
                        <Filters
                            config={config}
                            filters={filters}
                            setFilters={setFilters}
                        />

                        <ColumnToggle columns={columns} />
                    </div>
                </Col>
            </Row>
        </CardHeader>
    );
}

export default Actions;
