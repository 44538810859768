import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import _, { noop } from "lodash";
import Form from "rc-field-form";
import Field from "../../../../components/Field";
import Button from "../../../../components/Button";
import Select from "../../../../components/Inputs/Select";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import Map from "../../../../components/Map";
import {
	useUser,
	useUserJobs,
	useUserLocations,
} from "../../../../utils/hooks/user";
import {
	renderOrgLevelResourceLabel,
	renderSettingsResourceLabel,
	getSettingsOrgResourceSearchFilters,
} from "../../../../utils/helpers/settings";
import { useClock, useGetIpAddress } from "../api";
import { getPrimaryActiveResource } from "../../../../utils/helpers/user";
import { useLastClock, useLastShift, useShiftAction, useWorkSummary } from "../store";
import { OrgLevelGroupsContext } from "../../../Settings/General/OrganisationLevelGroups/context";
import { getAddress } from "../../../../utils/helpers/geocode";
import { useGeolocated } from "../../../../utils/hooks/useGeolocated";
import { useCompany } from "../../../../utils/hooks/company";
import "./style.scss";

const ClockModalForm = ({ setRefetchTimesheet = noop, openErrorModal }) => {
	const { t } = useTranslation();
	const [form] = Form.useForm();
	const user = useUser();
	const company = useCompany();
	const clock = useLastClock();
	const isGeofenceRequired = user?.calculationGroup?.geofenceLocation;

	const { ip, getIp } = useGetIpAddress();

	const userLevels = useMemo(
		() => [user?.level1, user?.level2, user?.level3, user?.level4],
		[user],
	);

	const { groups } = useContext(OrgLevelGroupsContext);

	const useInClockInGroup = useMemo(
		() => groups?.find(({ useInClockIn }) => useInClockIn),
		[groups],
	);

	const userUseInClockInGroup = useMemo(
		() =>
			userLevels?.find(
				(level) => level?.orgLevelGroup === useInClockInGroup?.id,
			),
		[userLevels, useInClockInGroup],
	);

	const [shiftAction, { setShiftAction }] = useShiftAction();
	const workSummary = useWorkSummary();
	const shift = useLastShift();

	const { coords, isGeolocationEnabled } = useGeolocated({
		positionOptions: {
			enableHighAccuracy: false,
		},
		userDecisionTimeout: 5000,
	});

	const { submit, loading  } = useClock();

	const jobs = useUserJobs();
	const locations = useUserLocations();

	

	const job = useMemo(
		() => {
			if (clock?.mode === "END" && clock?.type === "SHIFT") {
				return getPrimaryActiveResource(jobs || [])
			} else if (clock?.mode !== "END" && clock?.type !== "SHIFT" && !shift?.job) {
				return getPrimaryActiveResource(jobs || [])
			} else {
				return shift?.job
			}
		},
		[clock?.mode, clock?.type, shift?.job, jobs],
	);

	const location = useMemo(
		() => {
			if (clock?.mode === "END" && clock?.type === "SHIFT") {
				return getPrimaryActiveResource(locations || [])
			} else if (clock?.mode !== "END" && clock?.type !== "SHIFT" && !shift?.location) {
				return getPrimaryActiveResource(locations || [])
			} else {
				return shift?.location
			}
		},
		[shift?.location, clock?.mode, clock?.type, locations],
	);

	const [selectedLocation, setSelectedLocation] = useState(location?.id);

	const onSuccess = useCallback(
		(data) => {
			if (shiftAction === "switch") {
				submit(data);
			}
			setShiftAction(null);
			setRefetchTimesheet((prev) => prev + 1);
		},
		[shiftAction, setShiftAction, setRefetchTimesheet, submit],
	);

	const onFinish = useCallback(
		async (values) => {
			const data = { ...values };

			const workSummaryId = workSummary?.id;
			const shiftId = shift?.id;
			switch (shiftAction) {
				case "start-shift": {
					data.type = "SHIFT";
					data.mode = "START";
					data.project = {
						id: values?.project?.id,
						code: values?.project?.code,
						description: values?.project?.description,
						glSegment: values?.project?.glSegment,
						orgLevelGroup: values?.project?.orgLevelGroup,
						level: useInClockInGroup?.level?.replace("_", ""),
					};
					break;
				}
				case "switch": {
					data.type = "SHIFT";
					data.mode = "END";
					data.project = shift?.project;
					data.location = shift?.location?.id;
					data.job = shift?.job?.id;
					data.worksummaryId = workSummaryId;
					data.shiftId = shiftId;
					break;
				}
				case "start-break": {
					data.type = "BREAK";
					data.mode = "START";
					data.location = shift?.location?.id;
					data.job = shift?.job?.id;
					data.worksummaryId = workSummaryId;
					data.shiftId = shiftId;
					data.project = shift?.project;
					break;
				}
				case "end-break": {
					data.type = "BREAK";
					data.mode = "END";
					data.location = shift?.location?.id;
					data.job = shift?.job?.id;
					data.worksummaryId = workSummaryId;
					data.shiftId = shiftId;
					data.project = shift?.project;
					break;
				}
				default:
					return;
			}

			const switchData = {
				...values,
				type: "SHIFT",
				mode: "START",
				project: {
					id: values?.project?.id,
					code: values?.project?.code,
					description: values?.project?.description,
					glSegment: values?.project?.glSegment,
					orgLevelGroup: values?.project?.orgLevelGroup,
					level: useInClockInGroup?.level?.replace("_", ""),
				},
				ip,
			};

			if (/** isGeofenceRequired &&  */isGeolocationEnabled) {
				if (!coords?.latitude || !coords?.longitude) {
					toast.error(t("required-geofence"));
					return;
				}

				const address = await getAddress({
					lat: coords?.latitude,
					lng: coords?.longitude,
				});
				data.geofence = {
					lat: coords?.latitude,
					lng: coords?.longitude,
					address,
				};
				switchData.geofence = data.geofence;
			}

			if (ip) {
				data.ip = ip;
				switchData.ip = data.ip;
			}

			submit(
				data,
				() => onSuccess(switchData),
				(err) => {
					const isMandatoryError = 
						/It is required for the break to last at least \d* minutes\. \d* minutes should pass unitl you can take a break\./.test(err)
					if (isMandatoryError) {
						openErrorModal(err);
					} else {
						if (typeof err === "string") {
							toast.error(err);
						}
					}
					setShiftAction(null);
				}
			);
		},
		[
			workSummary,
			shift,
			shiftAction,
			// isGeofenceRequired,
			useInClockInGroup,
			isGeolocationEnabled,
			submit,
			onSuccess,
			coords,
			ip,
			t,
			openErrorModal,
			setShiftAction
		],
	);

	const buttonText = useMemo(() => {
		switch (shiftAction) {
			case "start-shift": {
				return t("confirm-clock-in");
			}
			case "start-break": {
				return t("confirm-the-break");
			}
			case "end-break": {
				return t("confirm-end-break");
			}
			case "switch": {
				return t("swap");
			}
			default:
				return t("confirm-clock-in");
		}
	}, [t, shiftAction]);

	const buttonClass = useMemo(() => {
		switch (shiftAction) {
			case "start-break":
			case "end-break": {
				return "break-btn";
			}
			case "switch": {
				return "switch-btn";
			}
			default:
			case "start-shift": {
				return "clock-btn";
			}
		}
	}, [shiftAction]);

	const onLocationChange = useCallback(
		(value) => {
			setSelectedLocation(value);
		},
		[setSelectedLocation],
	);

	const groupUrl = useMemo(() => {
		const query = new URLSearchParams();
		query.append("withManageAbility", true); 
		if (company) {
			query.append("company", company.id);
		}
		if(user?.payGroup) {
			query.append("payGroup", user?.payGroup?.id);
		}
		if (user?.union) {
			query.append("union", user?.union?.id);
		}
		if (selectedLocation) {
			const locationId = locations?.find(
				({ id }) => id === selectedLocation,
			)?.locationId;

			query.append("locationId", locationId);
		}

		return `/${useInClockInGroup?.id}/org-levels?${query.toString()}`;
	}, [useInClockInGroup, selectedLocation, locations, user, company]);

	const formContent = useMemo(() => {
		switch (shiftAction) {
			case "start-break":
			case "end-break": {
				return (
					<>
						<h4>{t("location")}</h4>
						<div>{renderSettingsResourceLabel(location)}</div>

						<hr className="my-3" />

						{useInClockInGroup && (
							<>
								<h4>{useInClockInGroup.description}</h4>

								<div>
									{shift?.project
										? renderOrgLevelResourceLabel(
											shift?.project,
										)
										: "--"}
								</div>

								<hr />
							</>
						)}

						<h4>{t("job")}</h4>
						<div>{renderSettingsResourceLabel(job)}</div>

						<hr className="my-3" />
					</>
				);
			}
			case "switch": {
				return (
					<>
						<Field name="location" label={t("location")}>
							<Select
								placeholder={t("location")}
								onChange={onLocationChange}
								disabled
							>
								{locations.map((location) => (
									<Select.Option
										key={location.id}
										value={location.id}
									>
										{renderSettingsResourceLabel(location)}
									</Select.Option>
								))}
							</Select>
						</Field>

						{useInClockInGroup && (
							<Field
								name="project"
								label={useInClockInGroup.description}
								rules={[
									{
										required: useInClockInGroup.isRequired,
										message: t(
											`${useInClockInGroup.description} is required`,
										),
									},
								]}
							>
								<ResourceSelect
									className="w-44"
									labelPropName="description"
									resourcePath={groupUrl}
									renderLabel={renderOrgLevelResourceLabel}
									getSearchFilters={
										getSettingsOrgResourceSearchFilters
									}
									valuePropName="id"
									hasSearch
									placeholder={useInClockInGroup.description}
									disabled={!location}
								/>
							</Field>
						)}

						<Field name="job" label={t("job")}>
							<Select placeholder={t("job")}>
								{jobs.map((job) => (
									<Select.Option key={job.id} value={job.id}>
										{renderSettingsResourceLabel(job)}
									</Select.Option>
								))}
							</Select>
						</Field>
					</>
				);
			}
			default:
			case "start-shift": {
				return (
					<>
						<Field name="location" label={t("location")}>
							<Select
								placeholder={t("location")}
								onChange={onLocationChange}
							>
								{locations.map((location) => (
									<Select.Option
										key={location.id}
										value={location.id}
									>
										{renderSettingsResourceLabel(location)}
									</Select.Option>
								))}
							</Select>
						</Field>

						{useInClockInGroup && (
							<Field
								name="project"
								label={useInClockInGroup.description}
								rules={[
									{
										required: useInClockInGroup.isRequired,
										message: t(
											`${useInClockInGroup.description} is required`,
										),
									},
								]}
							>
								<ResourceSelect
									className="w-44"
									labelPropName="description"
									resourcePath={groupUrl}
									renderLabel={renderOrgLevelResourceLabel}
									getSearchFilters={
										getSettingsOrgResourceSearchFilters
									}
									valuePropName="id"
									hasSearch
									placeholder={useInClockInGroup.description}
									disabled={!location}
								/>
							</Field>
						)}

						<Field name="job" label={t("job")}>
							<Select placeholder={t("job")}>
								{jobs.map((job) => (
									<Select.Option key={job.id} value={job.id}>
										{renderSettingsResourceLabel(job)}
									</Select.Option>
								))}
							</Select>
						</Field>
					</>
				);
			}
		}
	}, [
		t,
		shiftAction,
		jobs,
		useInClockInGroup,
		location,
		job,
		groupUrl,
		onLocationChange,
		locations,
		shift?.project,
	]);

	useEffect(() => {
		const controller = new AbortController();
		getIp(controller);

		return () => controller.abort();
	}, [getIp]);

	useEffect(() => {
		form.setFieldsValue({
			location: location?.id,
			job: job?.id,
			project:
				shiftAction === "switch"
					? shift?.project
					: userUseInClockInGroup,
		});
	}, [
		form,
		job?.id,
		location?.id,
		userUseInClockInGroup,
		shift,
		shiftAction,
	]);

	useEffect(() => {
		if (!isGeolocationEnabled) {
			toast.error(t("enable-location"));
		}
	}, [isGeolocationEnabled, t]);
	
	return (
		<>
			<Form form={form} onFinish={onFinish} className="clockin-modal-form">
				{formContent}

				{!isGeolocationEnabled ? (
					<div className="text-danger mb-3 font-weight-bold">
						{t("please-enable-location-to-clock-in")}
					</div>
				) : (
					<div style={{ height: 300, width: "100%" }}>
						{!!coords && (
							<Map
								marker={{lat: coords?.latitude, lng: coords?.longitude}}
								center={{lat: coords?.latitude, lng: coords?.longitude}}
								zoom={18}
							/>
						)}						
					</div>
				)}

				<Button
					className={`mt-2 btn-block ${buttonClass}`}
					loading={loading}
					disabled={!isGeolocationEnabled}
				>
					{buttonText}
				</Button>
			</Form>
		</>
	);
};

export default ClockModalForm;
