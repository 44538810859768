import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FormElement from "rc-field-form";
import { Input, Label } from "reactstrap";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import Field from "../../../../components/Field";
import DateInput from "../../../../components/Inputs/DateInput";
import Button from "../../../../components/Button";
import ResourceSelect from "../../../../components/Inputs/ResourceSelect";
import FileUploader from "../UploadInput";
import { ProfileContext } from "../../context";
import { getSettingsResourceSearchFilters, renderSettingsResourceLabel } from "../../../../utils/helpers/settings";

const Form = ({ closeDrawer, selected, upload, remove, updateLicense, addLicense }) => {
    const { t } = useTranslation();
    const [form] = FormElement.useForm();
    const [selectedFiles, setSelectedFiles] = useState(selected?.documents);
    const [filesError, setFilesError] = useState(null);
    const [uploadLoading, setUploadLoading] = useState(false);
    const { isSelf, user } = useContext(ProfileContext);

    const onFinish = useCallback(
        async (values) => {
            const submitUrl = isSelf ? "/profile/license" : `/users/${user?.id}/license`;
            const updateUrl = isSelf
                ? `/profile/license/${selected?.id}`
                : `/users/${user?.id}/license/${selected?.id}`;
            const uploadUrl = isSelf ? "/upload/profile/license" : `/upload/users/${user?.id}/license`
            if (selectedFiles?.length === 0 || !selectedFiles) {
                setFilesError(t("upload-at-least-a-file"));
                return;
            }

            setFilesError(null);
            setUploadLoading(true);

            try {
                const _selectedFiles = selectedFiles?.filter((item) => typeof item !== "string");
                let uploadedFiles = [];
                if (_selectedFiles?.length > 0) {
                    uploadedFiles = await upload(uploadUrl, _selectedFiles);
                }

                const data = {
                    ...values,
                    startDate: moment(values?.startDate).format("YYYY-MM-DD"),
                    endDate: values?.endDate ? moment(values?.endDate).format("YYYY-MM-DD") : undefined,
                    type: values?.type?.id,
                    documents: [
                        ...(selectedFiles?.filter((file) => typeof file === "string") || []),
                        ...uploadedFiles?.map((item) => item.path)
                    ],
                };
                const filesToRemove = data.documents.filter((document) => !selected?.documents?.includes(document));
                if (selected) {
                    await updateLicense(updateUrl, data);
                } else {
                    await addLicense(submitUrl, data);
                }
                if (filesToRemove?.length > 0) {
                    await remove(filesToRemove);
                }
                closeDrawer();
                setSelectedFiles([]);
            } catch (error) {
                toast.error(t("upload-failed"));
            } finally {
                setUploadLoading(false);
            }
        },
        [isSelf, user?.id, selected, selectedFiles, t, upload, closeDrawer, remove, updateLicense, addLicense]
    );

    useEffect(() => {
        form.resetFields();
        if (selected?.id) {
            form.setFieldsValue({
                type: selected?.type,
                startDate: moment(selected?.startDate).toDate(),
                endDate: selected?.endDate ? moment(selected?.endDate).toDate() : null,
                state: selected?.state,
            })
        }
    }, [selected, form]);

    return (
        <FormElement form={form} onFinish={onFinish}>
            <Field name="type" label={t("type")}
                rules={[
                    {
                        required: true,
                        message: t("license-required")
                    }
                ]}
            >
                <ResourceSelect
                    labelPropName="description"
                    renderLabel={renderSettingsResourceLabel}
                    resourcePath="/license"
                    placeholder={t("licenses")}
                    allowClear
                    hasSearch
                    getSearchFilters={getSettingsResourceSearchFilters}
                />
            </Field>

            <Field
                name="startDate"
                label={t("start-date")}
                rules={[
                    {
                        required: true,
                        message: t("required-start-date"),
                    },
                ]}
                className="d-flex flex-column"
            >
                <DateInput placeholderText={t("start-date")} />
            </Field>

            <Field
                name="endDate"
                label={t("end-date")}
                className="d-flex flex-column"
                rules={[
                    ({ getFieldValue }) => ({
                        validator: (_, value) => {
                            const startDate = getFieldValue("startDate");
                            if (startDate && value && moment(value).isSameOrBefore(moment(startDate), "date")) {
                                return Promise.reject(new Error(t("end-date-bigger-than-start-date")));
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}
            >
                <DateInput placeholderText={t("end-date")} />
            </Field>

            <Field name="state" label={t("state")}>
                <Input type="string" placeholder={t("state")} />
            </Field>

            <Label>{t("documents")} <span className="text-danger mx-1">*</span></Label>
            <FileUploader selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} />
            {filesError && (
                <div className="invalid-feedback d-block">{filesError}</div>
            )}

            <div className="d-flex justify-content-end my-4">
                <Button
                    className="btn-round btn-icon shadow-none border btn btn-secondary btn-sm"
                    onClick={closeDrawer}
                    disabled={uploadLoading}
                >
                    {t("cancel")}
                </Button>

                <Button
                    className="btn-round btn-icon shadow-none border px-3 btn btn-dark btn-sm"
                    color="primary"
                    type="submit"
                    loading={uploadLoading}
                    disabled={uploadLoading}
                >
                    {t("save")}
                </Button>
            </div>
        </FormElement>
    );
};

export default Form;
