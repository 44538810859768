import { useTranslation } from "react-i18next";
import { Col, Label, Row } from "reactstrap";
import { Field } from "rc-field-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Select from "../../../../../../../../../../components/Inputs/Select";
import ResourceSelect from "../../../../../../../../../../components/Inputs/ResourceSelect";
import Button from "../../../../../../../../../../components/Button";
import {
    getSettingsOrgResourceSearchFilters,
    getSettingsResourceSearchFilters,
    renderOrgLevelResourceLabel,
    renderSettingsResourceLabel
} from "../../../../../../../../../../utils/helpers/settings";

function Condition({ form, name, conditionName, groups, removeCondition }) {
    const { t } = useTranslation();

    return (
        <div
            key={conditionName}
            className="amount-per-unit-form__rules__item__content__conditions__item"
        >
            <Row>
                <Col md={6} className="mb-2">
                    <div>
                        <Label>
                            {t("resource")} <span className="text-danger">*</span>
                        </Label>

                        <Field
                            name={[conditionName, "resource"]}
                            rules={[
                                {
                                    required: true,
                                    message: t("resource-required"),
                                },
                            ]}
                        >
                            <Select
                                placeholder={t("resource")}
                                onChange={() => {
                                    form.resetFields([
                                        [
                                            "rules",
                                            name,
                                            "conditions",
                                            conditionName,
                                            "value",
                                        ],
                                    ]);
                                }}
                            >
                                <Select.Option value="job">
                                    {t("job")}
                                </Select.Option>

                                <Select.Option value="pay-code">
                                    {t("pay-code")}
                                </Select.Option>

                                <Select.Option value="location">
                                    {t("location")}
                                </Select.Option>
                                {groups.map((group) => (
                                    <Select.Option value={group.level}>
                                        {renderOrgLevelResourceLabel(group)}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Field>
                    </div>
                </Col>

                <Col md={6} className="mb-2">
                    <Field name={[conditionName, "resource"]}>
                        {({ value }) => {
                            switch (value) {
                                case "job": {
                                    return (
                                        <div>
                                            <Label>
                                                {t("jobs")} <span className="text-danger">*</span>
                                            </Label>

                                            <Field
                                                name={[conditionName, "value"]}
                                                dependencies={[["rules", name, "conditions"]]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("value-required"),
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator: (_, value) => {
                                                            const conditions = getFieldValue(["rules", name, "conditions"]);

                                                            if (value && value?.length > 0) {
                                                                const ids = value?.map((val) => val?.id) || [];
                                                                const exist = conditions?.find((item, i) =>
                                                                    i !== conditionName &&
                                                                    item?.resource === "job" &&
                                                                    item?.value?.find((val) => ids.includes(val.id))
                                                                );

                                                                if (!!exist) {
                                                                    return Promise.reject(
                                                                        new Error(t("unique-condition"))
                                                                    );
                                                                } else {
                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                            >
                                                <ResourceSelect
                                                    resourcePath="/jobs"
                                                    mode="multiple"
                                                    renderLabel={renderSettingsResourceLabel}
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    placeholder={t("jobs")}
                                                    hasSearch
                                                />
                                            </Field>
                                        </div>
                                    );
                                }
                                case "location": {
                                    return (
                                        <div>
                                            <Label>
                                                {t("locations")} <span className="text-danger">*</span>
                                            </Label>

                                            <Field
                                                name={[conditionName, "value"]}
                                                dependencies={[["rules", name, "conditions"]]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("value-required"),
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator: (_, value) => {
                                                            const conditions = getFieldValue(["rules", name, "conditions"]);

                                                            if (value && value?.length > 0) {
                                                                const ids = value?.map((val) => val?.id) || [];
                                                                const exist = conditions?.find((item, i) =>
                                                                    i !== conditionName &&
                                                                    item?.resource === "location" &&
                                                                    item?.value?.find((val) => ids.includes(val?.id))
                                                                );
                                                                if (!!exist) {
                                                                    return Promise.reject(
                                                                        new Error(t("unique-condition"))
                                                                    );
                                                                } else {
                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                            >
                                                <ResourceSelect
                                                    resourcePath="/locations"
                                                    mode="multiple"
                                                    renderLabel={renderSettingsResourceLabel}
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    placeholder={t("locations")}
                                                    hasSearch
                                                />
                                            </Field>
                                        </div>
                                    );
                                }
                                case "union": {
                                    return (
                                        <div>
                                            <Label>
                                                {t("unions")} <span className="text-danger">*</span>
                                            </Label>

                                            <Field
                                                name={[conditionName, "value"]}
                                                dependencies={[["rules", name, "conditions"]]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("value-required"),
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator: (_, value) => {
                                                            const conditions = getFieldValue(["rules", name, "conditions"]);

                                                            if (value && value?.length > 0) {
                                                                const ids = value?.map((val) => val?.id) || [];
                                                                const exist = conditions.find((item, i) =>
                                                                    i !== conditionName &&
                                                                    item?.resource === "union" &&
                                                                    item?.value?.find((val) => ids.includes(val?.id))
                                                                );
                                                                if (!!exist) {
                                                                    return Promise.reject(
                                                                        new Error(t("unique-condition"))
                                                                    );
                                                                } else {
                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                            >
                                                <ResourceSelect
                                                    resourcePath="/union"
                                                    mode="multiple"
                                                    renderLabel={renderSettingsResourceLabel}
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    placeholder={t("unions")}
                                                    hasSearch
                                                />
                                            </Field>
                                        </div>
                                    );
                                }
                                case "pay-code": {
                                    return (
                                        <div>
                                            <Label>
                                                {t("pay-code")} <span className="text-danger">*</span>
                                            </Label>

                                            <Field
                                                name={[conditionName, "value"]}
                                                dependencies={[["rules", name, "conditions"]]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("value-required"),
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator: (_, value) => {
                                                            const conditions = getFieldValue(["rules", name, "conditions"]);

                                                            if (value && value?.length > 0) {
                                                                const ids = value?.map((val) => val?.id) || [];
                                                                const exist = conditions?.find((item, i) =>
                                                                    i !== conditionName &&
                                                                    item?.resource === "pay-code" &&
                                                                    item?.value?.find((val) => ids.includes(val?.id))
                                                                );
                                                                if (!!exist) {
                                                                    return Promise.reject(
                                                                        new Error(t("unique-condition"))
                                                                    );
                                                                } else {
                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                            >
                                                <ResourceSelect
                                                    resourcePath="/pay-code"
                                                    mode="multiple"
                                                    renderLabel={renderSettingsResourceLabel}
                                                    getSearchFilters={getSettingsResourceSearchFilters}
                                                    placeholder={t("pay-code")}
                                                    hasSearch
                                                />
                                            </Field>
                                        </div>
                                    );
                                }
                                default: {
                                    const group = groups.find(
                                        (group) => group.level === value
                                    );
                                    if (!group) {
                                        break;
                                    }
                                    return (
                                        <div>
                                            <Label>
                                                {renderSettingsResourceLabel(group)} <span className="text-danger">*</span>
                                            </Label>

                                            <Field
                                                name={[conditionName, "value"]}
                                                dependencies={[["rules", name, "conditions"]]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t("value-required"),
                                                    },
                                                    ({ getFieldValue }) => ({
                                                        validator: (_, value) => {
                                                            const conditions = getFieldValue(["rules", name, "conditions"]);
                                                           
                                                            if (value && value?.length > 0) {
                                                                const ids = value?.map((val) => val?.id) || [];
                                                                const exist = conditions?.find((item, i) =>
                                                                    i !== conditionName &&
                                                                    item?.resource === group?.level &&
                                                                    item?.value?.find((val) => ids.includes(val?.id))
                                                                );
                                                                if (!!exist) {
                                                                    return Promise.reject(
                                                                        new Error(t("unique-condition"))
                                                                    );
                                                                } else {
                                                                    return Promise.resolve();
                                                                }
                                                            }
                                                            return Promise.resolve();
                                                        }
                                                    })
                                                ]}
                                            >
                                                <ResourceSelect
                                                    resourcePath={`${group.id}/org-levels`}
                                                    mode="multiple"
                                                    renderLabel={renderOrgLevelResourceLabel}
                                                    getSearchFilters={getSettingsOrgResourceSearchFilters}
                                                    placeholder={renderSettingsResourceLabel(group)}
                                                    hasSearch
                                                />
                                            </Field>
                                        </div>
                                    );
                                }
                            }
                        }}
                    </Field>
                </Col>

                <Field shouldUpdate>
                    {({ }, { }, { getFieldsError }) => {
                        const [error] = getFieldsError([
                            ["rules", name, "conditions", conditionName, "value"],
                            ["rules", name, "conditions", conditionName, "resource"]
                        ])?.map(({ errors }) => errors)?.flat();
                    
                        return error && (
                            <Col md={12} className="mb-2">
                                <div className="invalid-feedback d-block">
                                    {error}
                                </div>
                            </Col>
                        );
                    }}
                </Field>

                <Col md={12}>
                    <Button
                        color="danger"
                        className="w-100 d-flex justify-content-center"
                        icon={<FontAwesomeIcon icon={faTrash} />}
                        onClick={() => removeCondition(conditionName)}
                    >
                        {t("remove-condition")}
                    </Button>
                </Col>
            </Row>
        </div>
    );
}

export default Condition;
