import { useContext, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CardHeader, Row, Col } from "reactstrap";
import { useAccess, useAccessInList } from "../../../utils/hooks/access";
import { ProfileContext } from "../context";
import { useUser } from "../../../utils/hooks/user";

const notesPermissions = ["people.manageNote", "people.manageAllNotes"];

function Topbar() {
	const { t } = useTranslation();
	const { isSelf } = useContext(ProfileContext);
	const user = useUser();

	const { hasAccess: canManageDocuments } = useAccess(
		isSelf ? "profile.manageDocuments" : "people.manageDocuments",
	);
	const { hasAccess: canManageNotes } = useAccessInList(notesPermissions);
	const { hasAccess: canManageTimeoff } = useAccess(
		isSelf ? "profile.manageTimeOff" : "people.manageTimeOff",
	);
	const { hasAccess: canViewHistory } = useAccess(
		isSelf ? "profile.viewHistory" : "people.viewHistory"
	);

	const { hasAccess: canViewSchedule } = useAccess(
		isSelf ? "profile.canViewSchedule" : "people.canViewSchedule"
	);
	
	const canViewNotificationSettings = useMemo(() => {
		return isSelf && user?.permissions?.notificationSettings?.canView;
	}, [isSelf, user?.permissions?.notificationSettings]);

	const canViewQualifications = useMemo(() => {
		return isSelf ? 
		(user?.permissions.profile.changeSkill || 
			user?.permissions.profile.changeLicense || 
			user?.permissions.profile.changeCertification) 
			: 
		(user?.permissions.people.changeSkill || 
			user?.permissions.people.changeLicense || 
			user?.permissions.people.changeCertification)
	}, [isSelf, user?.permissions])

	const { hasAccess: canManageUsers } = useAccess("canManageUsers");

	return (
		<CardHeader className="bg-secondary " style={{ paddingRight: "15px" }}>
			<Row>
				<Col xs="12" xl="4" className="pl-4 mb-3">
					<h3 className="mb-0">{t("profile")}</h3>
					<h5 className="text-muted">
						{t("improve-business-efficiency")}
					</h5>
				</Col>
				<Col
					className="d-flex flex-wrap justify-content-md-start justify-content-xl-end   align-items-baseline  text-right h-unset"
					xs="12"
					xl="8"
				>
					<NavLink
						className="  px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border "
						to="job-pay"
					>
						{t("job-and-pay")}
					</NavLink>

					<NavLink
						className="  px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border "
						to="personal"
					>
						{t("personal")}
					</NavLink>

					{canViewQualifications && 
						<NavLink
							className="px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border"
							to="qualifications"
						>
							{t("qualifications")}
						</NavLink>					
					}

					{(isSelf || (!isSelf && canManageUsers)) && (
						<NavLink
							className="px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border "
							to="availability"
						>
							{t("availability")}
						</NavLink>
					)}

					{canManageTimeoff && (
						<NavLink
							className="  px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border"
							to="time-off"
						>
							{t("time-off")}
						</NavLink>
					)}

					{canManageDocuments && (
						<NavLink
							className="  px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border"
							to="documents"
						>
							{t("documents")}
						</NavLink>
					)}

					{!isSelf && canManageNotes && (
						<NavLink
							className="  px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border"
							to="notes"
						>
							{t("notes")}
						</NavLink>
					)}

					<NavLink
						className="px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border "
						to="settings"
					>
						{t("settings")}
					</NavLink>

					{canViewNotificationSettings && (
						<NavLink
							className="px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border"
							to="notification-settings"
						>
							{t("notification-settings")}
						</NavLink>
					)}

					{/* {canViewSchedule && ( */}
						<NavLink
							className="px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border "
							to="schedule"
						>
							{t("schedule")}
						</NavLink>
					{/* )} */}

					{canViewHistory && <NavLink
						className="px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border"
						to="history"
						activeClassName='active-people-nav'
					>
						{t("history")}
					</NavLink>
					}
				</Col>
			</Row>
		</CardHeader>
	);
}

export default Topbar;
