import { Field } from "rc-field-form";
import Item from "./Item";

function PerPayPeriod({ disabled }) {
    return (
        <Field shouldUpdate>
            {({ }, { }, { getFieldsError, getFieldValue }) => {
                const tiers = getFieldValue("tiers");
                return (

                    <div className="my-3">
                        {tiers?.map((tier, index) => {
                            const [error] = getFieldsError([
                                ["intervals", index, "value"],
                                ["intervals", index, "max"],
                            ]).map(({ errors }) => errors).flat();

                            return (
                                <>
                                    <Item
                                        name={index}
                                        disabled={disabled}
                                        tier={tier}
                                    />

                                    <div className="invalid-feedback d-block">
                                        {error}
                                    </div>
                                </>
                            )
                        })}
                    </div>
                );
            }}
        </Field>
    );
}

export default PerPayPeriod;
