import { useCallback } from "react";
import { Col, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import Select from "../../../../components/Inputs/Select";
import DateInput from "../../../../components/Inputs/DateInput";
import RangeDateInput from "../../../../components/Inputs/DateInput/Range";
import { useDateFilters } from "../../hooks/useDateFilters";

function DateFilters() {
    const { t } = useTranslation();
    const { mode, from, to, setFilters } = useDateFilters();

    const onModeChange = useCallback((mode) => {
        let from, to;
        switch (mode) {
            case "week": {
                from = moment().startOf("week").toISOString(true);
                to = moment().endOf("week").toISOString(true);
                break;
            }
            case "custom": {
                from = undefined;
                to = undefined;
                break;
            }
            default: {
                from = undefined;
                to = undefined;
                break;
            }
        }

        setFilters({ mode, from, to });
    }, [setFilters]);

    const onWeekChange = useCallback(([value]) => {
        if (!mode) {
            return;
        }

        setFilters({
            from: moment(value).startOf("week").toISOString(true),
            to: moment(value).endOf("week").toISOString(true),
        });
    }, [setFilters, mode]);

    const onRangeChange = useCallback(([from, to]) => {
        setFilters({ from: moment(from), to: moment(to) });
    }, [setFilters]);

    return (
        <Row>
            <Col xs="12" md="auto">
                <Select
                    placeholder={t("mode")}
                    value={mode}
                    onChange={onModeChange}
                    className="mr-1"
                    showSearch
                >
                    <Select.Option value="week">{t("week")}</Select.Option>
                    <Select.Option value="custom">
                        {t("custom")}
                    </Select.Option>
                </Select>
            </Col>
            
            <Col xs="12" md="auto">
                {mode === "custom" ? (
                    <div className="d-flex w-100">
                        <RangeDateInput
                            startDate={moment(from).toDate()}
                            endDate={moment(to).toDate()}
                            onChange={onRangeChange}
                        />
                    </div>
                ) : (
                    <InputGroup className="input-group-alternative border shadow-none flex-nowrap">
                        <DateInput
                            startDate={moment(from).toDate()}
                            endDate={moment(to).toDate()}
                            onChange={onWeekChange}
                            selectsRange
                        />
                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                <FontAwesomeIcon icon={faCalendar} />
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>
                )}
            </Col>
        </Row>
    );
}

export default DateFilters;
