import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { noop } from "lodash";
import Modal from "../../../../components/Modal";
import Form from "./Form";
import { useTime } from "../../../../utils/hooks/time";
import { useCompanyTimeFormat } from "../../../../utils/hooks/company";
import { useShiftAction } from "../store";
import { dateTimeFormat } from "../../../../utils/helpers/date";

function ClockModal({ setRefetchTimesheet = noop, openErrorModal }) {
    const { t } = useTranslation();
    const time = useTime();
    const timeFormat = useCompanyTimeFormat();

    const [shiftAction, { setShiftAction }] = useShiftAction();

    const title = useMemo(() => (
        <h3>
            {t("current-time")}:{' '}
            <span className='text-info'>
                {dateTimeFormat(time, timeFormat)}
            </span>
        </h3>
    ), [t, time, timeFormat]);

    const isOpen = useMemo(() => {
        return ["start-shift", "start-break", "end-break", "switch"].includes(shiftAction);
    }, [shiftAction]);

    return (
        <Modal
            className='modal-dialog-centered'
            title={title}
            isOpen={isOpen}
            toggle={() => setShiftAction(null)}
        >
            <Form setRefetchTimesheet={setRefetchTimesheet} openErrorModal={openErrorModal} />
        </Modal>
    );
}

export default ClockModal;
