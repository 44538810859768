import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Table as BaseTable, Card } from "reactstrap";
import TimeSheetTable from "./TimeSheetTable";
import Loading from "../../../../components/Loaders/Loading";
import Pagination from "../../../../components/Table/Pagination";
import { useTableContext } from "../../../../components/Table/context";
import { TimeSheetTableDateFilterContext } from "../../hooks/useDateFilters";
import "./style.scss";

function Table({
	loading,
	hasChangedDateFilter,
	setHasChangedDateFilter,
	openDaySummary,
	openHistory,
	colSpan
}) {
	const { t } = useTranslation();

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		footerGroups,
		pageCount,
		state: { pageIndex, pageSize },
		gotoPage,
		setPageSize,
		totalItems,
	} = useTableContext();

	useEffect(() => {
		if (hasChangedDateFilter) {
			gotoPage(0);
			setHasChangedDateFilter(false);
		}
	}, [hasChangedDateFilter, gotoPage, setHasChangedDateFilter]);

	return (
		<Card className="team-table mb-0 h-100 w-100 position-static">
			<div style={{ overflowX: "auto" }}>
				<BaseTable 
					hover
					className="table-flush"
					{...getTableProps()}
				>
					<table className="manager-view-table">
						<thead className="manager-view-table-header" style={{ zIndex: 5 }}>
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<th
											{...column.getHeaderProps()}
											className={column?.className}
											style={{ color: "#000000b5" }}
										>
											{column.render("Header")}
										</th>
									))}
								</tr>
							))}
						</thead>

						<tbody {...getTableBodyProps()}>
							{loading ? (
								<tr>
									<td colSpan={999}>
										<div className="d-flex justify-content-center">
											<Loading />
										</div>
									</td>
								</tr>
							) : totalItems === 0 ? (
								<tr className="w-100">
									<td
										colSpan={9}
										className="text-center p-3"
									>
										{t("no-data")}
									</td>
								</tr>
							) : (
								rows.map((row) => {
									prepareRow(row);
									return (
										<>
											<tr
												{...row.getRowProps()}
												className="manager-view-tr"
											>
												{row.cells.map((cell) => (
													<td
														{...cell.getCellProps()}
													>
														{cell.render("Cell")}
													</td>
												))}
											</tr>

											{row.isExpanded && (
												<tr>
													<td
														className="timesheet-table-container"
														colSpan={colSpan + 2}
													>
														<TimeSheetTableDateFilterContext
															date={
																row.state.date
															}
														>
															<TimeSheetTable
																user={
																	row.original
																		.user
																}
																state={
																	row.state
																}
																openDaySummary={
																	openDaySummary
																}
																openHistory={openHistory}
															/>
														</TimeSheetTableDateFilterContext>
													</td>
												</tr>
											)}
										</>
									);
								})
							)}
						</tbody>

						{totalItems > 0 && (
							<tfoot>
								{footerGroups.map((group) => (
									<tr
										{...group.getFooterGroupProps()}
										className="manager-view-tfoot-tr"
									>
										{group.headers.map((column) => (
											<td {...column.getFooterProps()}>
												{column.render("Footer")}
											</td>
										))}
									</tr>
								))}
							</tfoot>
						)}
					</table>
				</BaseTable>
			</div>

			<Pagination
				page={pageIndex}
				gotoPage={gotoPage}
				pageCount={pageCount}
				setPerPage={setPageSize}
				perPage={pageSize}
				totalItems={totalItems}
			/>
		</Card>
	);
}

export default Table;
