import { useEffect } from "react";
import { Marker, useMap } from "react-leaflet";
import L from "leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const icon = L.icon({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
});

const MarkerPosition = ({ marker }) => {
    const map = useMap();

    useEffect(() => {
        if (marker) {
            map.setView([marker.lat, marker.lng]);
        }
    }, [marker, map]);

    return marker ? <Marker icon={icon} position={[marker.lat, marker.lng]} /> : null;
};

export default MarkerPosition;