import { useTranslation } from "react-i18next";
import { NavLink, Outlet } from "react-router-dom";
import { CardBody, CardHeader } from "reactstrap";
import { useAccess } from "../../../utils/hooks/access";
import { useContext } from "react";
import { ProfileContext } from "../context";

function Qualifications() {
    const { t } = useTranslation();
    const { isSelf } = useContext(ProfileContext);

    const { hasAccess: canViewSkills } = useAccess(
		isSelf ? "profile.changeSkill" : "people.changeSkill"
	);

    const { hasAccess: canViewLicenses } = useAccess(
		isSelf ? "profile.changeLicense" : "people.changeLicense"
	);

    const { hasAccess: canViewCertification } = useAccess(
		isSelf ? "profile.changeCertification" : "people.changeCertification"
	);

    return (
        <>
            <CardHeader className="border-0">
                {canViewSkills && 
                    <NavLink
                        className="px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border"
                        to="skills"
                        activeClassName="active-people-nav"
                    >
                        {t("Skills")}
                    </NavLink>                
                }

                {canViewLicenses && 
                    <NavLink
                        className="px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border"
                        to="licenses"
                    >
                        {t("licenses")}
                    </NavLink>                
                }

                {canViewCertification && 
                    <NavLink
                        className="px-3 p-2 btn-round mb-2 mb-md-2 shadow-lg mx-1 small-radius border"
                        to="certifications"
                    >
                        {t("certifications")}
                    </NavLink>                
                }
            </CardHeader>
            <CardBody className="p-0">
                <Outlet />
            </CardBody>
        </>
    );
}

export default Qualifications;
