import { useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Form from "rc-field-form";
import moment from "moment-timezone";
import { omit } from "lodash";
import Field from "../../../../../../components/Field";
import Button from "../../../../../../components/Button";
import Select from "../../../../../../components/Inputs/Select";
import Text from "../../../../../../components/Inputs/Text";
import MaxBalance from "./MaxBalance";
import Prohibition from "./Prohibition";
import MaxRequestedHours from "./MaxRequestedHours";
// import MinBalance from "./MinBalance";
import Reset from "./Reset";
import Carryover from "./Carryover";
import { generateErrorsConfigForForm } from "../../../../../../utils/helpers/errors";

function OptionalSettings({ data, submit, goBack, error, disabled }) {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const onFinish = useCallback(
        (values) => {
            submit(omit(values, ["carryoverStatus", "maxBalanceStatus"]));
        },
        [submit],
    );

    useEffect(() => {
        form.resetFields();
        form.setFieldsValue({
            ...data,
            minBalance: data?.minBalance || '0',
            minRequestedHours: data?.minRequestedHours || '8',
            maxBalance: data?.maxBalance || null,
            maxRequestedHours: data?.maxRequestedHours || null,
            carryoverStatus: data?.carryover?.length > 0,
            maxBalanceStatus: data?.maxBalance?.length > 0,
            reset: data?.reset
                ? {
                    type: data.reset.type,
                    value: data?.reset?.value ? moment(data.reset.value).toDate() : null
                } : {
                    type: "start-of-year",
                    value: null
                },
            prohibition: data ? data.prohibition : null
        });
    }, [form, data]);

    useEffect(() => {
        const fieldErrors = generateErrorsConfigForForm([], error);
        form.setFields(fieldErrors);
    }, [form, error]);

    return (
        <Form
            form={form}
            onFinish={onFinish}
            className="d-flex flex-column justify-content-between h-100"
        >
            <div>
                <Carryover data={data} disabled={disabled} />

                <MaxBalance data={data} disabled={disabled} />
                
                <MaxRequestedHours form={form} data={data} disabled={disabled} />

                <Field
                    label={t("min-balance")}
                    name="minBalance"
                    rules={[
                        {
                            required: true,
                            message: t("required-min-balance")
                        }
                    ]}
                >
                    <Text type="number" placeholder={t("min-balance")} />
                </Field>

                <Field
                    label={t("min-requested-hours")}
                    name="minRequestedHours"
                    rules={[
                        {
                            required: true,
                            message: t("required-minimal-requested-hours")
                        }
                    ]}
                >
                    <Select placeholder={t("min-requested-hours")}>
                        <Select.Option value="1">
                            {t("number-hours", { number: 1 })}
                        </Select.Option>
                        <Select.Option value="4">
                            {t("number-hours", { number: 4 })}
                        </Select.Option>
                        <Select.Option value="8">
                            {t("number-hours", { number: 8 })}
                        </Select.Option>
                    </Select>
                </Field>

                <Prohibition form={form} data={data} disabled={disabled} />

                <Reset />
            </div>

            <div className="d-flex justify-content-end">
                <Button
                    className="btn-round btn-icon shadow-none border"
                    size="sm"
                    onClick={goBack}
                >
                    {t("back")}
                </Button>

                <Button
                    type="submit"
                    className="btn-round btn-icon shadow-none border px-3"
                    color="dark"
                    size="sm"
                >
                    {t("save")}
                </Button>
            </div>
            {error && <div className="error">{error}</div>}
        </Form>
    );
}

export default OptionalSettings;
