import { useEffect, useMemo } from "react";
import { MapContainer, TileLayer, Marker, useMapEvents, useMap } from "react-leaflet";
import L from "leaflet";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import "leaflet/dist/leaflet.css";

const icon = L.icon({
    iconRetinaUrl: iconRetina,
    iconUrl: iconMarker,
    shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
});

const MapEventsHandler = ({ changeMarker, setSearch }) => {
    useMapEvents({
        click: (event) => {
            const { lat, lng } = event.latlng;
            changeMarker({ lat, lng });
            setSearch("");
        },
    });
    return null;
};

const MarkerPosition = ({ marker }) => {
    const map = useMap();

    useEffect(() => {
        if (marker?.lat && marker?.lng) {
            map.setView([marker.lat, marker.lng], map.getZoom());
        }
    }, [marker, map]);

    return <Marker position={[marker?.lat, marker?.lng]} icon={icon} />
}

const Map = ({ marker, center, zoom, setSearch, changeMarker, options }) => {
    const mapOptions = useMemo(
        () => ({
            center: [center?.lat, center?.lng],
            zoom,
            ...options,
        }),
        [center, zoom, options]
    );

    return (
        <MapContainer
            center={mapOptions.center}
            zoom={mapOptions.zoom}
            style={{ height: "100%", width: "100%" }}
            minZoom={3}
            maxBounds={[
                [-90, -180],
                [90, 180],
            ]}
            maxBoundsViscosity={1.0}
            {...mapOptions}
        >
            <TileLayer url={process.env.REACT_APP_MAP_TILE_URL} />
            {(marker?.lat && marker?.lng) && <MarkerPosition marker={marker} />}
            {changeMarker && <MapEventsHandler changeMarker={changeMarker} setSearch={setSearch} />}
        </MapContainer>
    );
};

export default Map;
